@use "../../../global-styles/palette" as c;
@use "././IconAndTitleCardSquared/IconAndTitleSquaredCard";

.icon-title-card-squared-deck {
    .g-container {
        h2 {
            text-align: center;
            margin-bottom: 24px;
        }

        .icon-title-card-squared__cardWrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            justify-content: space-around;
        }

        .icon-title-card-squared__link {
            display: block;
            text-align: center;
            margin-inline: auto;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            color: c.$dark-default;
            margin-top: 24px;
        }
    }
}

.medium-view {
    .icon-title-card-squared-deck {
        .g-container {
            .icon-title-card-squared__cardWrapper {
                max-width: 800px;
                margin-inline: auto;
            }
        }
    }
}