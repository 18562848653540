@use "../../global-styles/palette" as c;

.ribbon-with-data-for-trust-pilot-root {
  background: linear-gradient(c.$pure-white, c.$pure-white) padding-box,
    linear-gradient(90deg, #ededed 13%, c.$tm-green-default 30.75%, #ededed 47.43%)
      border-box;
  border: 1px solid transparent;
  border-top: 1px solid #e4e4e4;
  border-right: 0;
  border-left: 0;
  .g-container {
    .ribbon-with-data-for-trust-pilot-root__container {
      padding: 28px 0;

      &__statsWrapper {
        display: grid;
        grid-template-columns: repeat(2, auto);
        row-gap: 20px;

        &__stats {
          width: 100%;
          div {
            p:first-child {
              width: fit-content;
              color: c.$dark-90;
              text-align: center;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              margin-inline: auto;
            }

            p:last-child {
              color: c.$dark-90;
              width: fit-content;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              margin-inline: auto;
            }
          }
        }
      }

      &__evaluationWrapper {
        display: grid;
        grid-auto-flow: column;
        border-radius: 8px;
        border: 1px solid #e4e4e4;
        margin: 30px auto 0;
        width: fit-content;
        padding: 6px 12px;

        p:first-child {
          width: fit-content;
          font-size: 14px;
          font-weight: 600;
          line-height: 150%;
        }

        p:nth-child(2) {
          width: fit-content;
          margin-inline: 12px;
          font-size: 14px;
          font-weight: 600;
          line-height: 150%;
        }

        img {
          width: fit-content;
        }
      }
    }
  }
}

.medium-view {
  .ribbon-with-data-for-trust-pilot-root {
    .g-container {
      .ribbon-with-data-for-trust-pilot-root__container {
        &__statsWrapper {
          grid-auto-flow: column;
          max-width: 533px;
          margin-inline: auto;
        }
      }
    }
  }
}

.large-view {
  .ribbon-with-data-for-trust-pilot-root {
    .g-container {
      .ribbon-with-data-for-trust-pilot-root__container {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;

        &__statsWrapper {
          grid-auto-flow: column;
          width: 533px;

          &__stats {
            div {
              width: fit-content;
              p:first-child {
                margin: 0;
                font-size: 25px;
              }

              p:last-child {
                margin: 0;
              }
            }
          }
        }

        &__evaluationWrapper {
          margin: 0;
          align-content: center;
        }
      }
    }
  }
}
