@use "../../global-styles/palette" as c;

.breadcrumbs {
    &--light {
        &__item {
            color: c.$dark-60;

            svg {
                path {
                    fill: c.$dark-20;
                }
            }
        }
    }

    span {
        display: flex;

        .breadcrumbs__home-link {
            display: flex;
            align-items: center;

            svg {
                path {
                    fill: c.$dark-default;
                }
            }
        }

        .breadcrumbs__item {
            display: flex;
            align-items: center;
            color: c.$dark-40;
            font-size: 12px;

            svg {
                padding: 4px;

                path {
                    fill: c.$dark-20;
                }
            }

            &--link {
                display: flex;
                align-items: center;
                color: c.$dark-default;
                font-size: 12px;

                svg {
                    padding: 4px;

                    path {
                        fill: c.$dark-20;
                    }
                }
            }
        }
    }
}

.medium-view,.small-view{
    .breadcrumbs{
        display: none;
    }
}