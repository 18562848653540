@use "../../global-styles/palette" as c;
@use "./TimeLine/TopTimeline";
@use "./TimeLine/BottomTimeline";

.timeline-with-text-wrapper {
  background-color: c.$dark-default;
  h4 {
    margin-bottom: 24px;
    padding-inline: 24px;
    color: c.$pure-white;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  .timeline-with-text-wrapper__container {
    overflow-x: hidden;
    
    &__topLineWrapper {
      padding: 0 30px 4px;
      display: flex;
      column-gap: 40px;
      align-items: end;
      width: fit-content;
      border-bottom: 4px solid c.$dark-80;
    }
    &__bottomLineWrapper {
      @extend .timeline-with-text-wrapper__container__topLineWrapper;
      padding: 0 0 0 160px;
      align-items: baseline;
      border: none;
    }
  }
}

.small-view {
  .timeline-with-text-wrapper {
    .timeline-with-text-wrapper__container {
      overflow-x: scroll;
    }
  }
}

.medium-view {
  .timeline-with-text-wrapper {
    h4 {
      padding-inline: 72px;
    }
    .timeline-with-text-wrapper__container {
      overflow-x: scroll;

      &__topLineWrapper {
        padding: 0 72px 4px;
      }
      &__bottomLineWrapper {
        padding: 0 0 0 190px;
      }
    }
  }
}

.large-view {
  .timeline-with-text-wrapper {
    h4 {
      padding-inline: 156px;
    }
    .timeline-with-text-wrapper__container {
      &__topLineWrapper {
        padding: 0 0px 4px 156px;
      }
      &__bottomLineWrapper {
        padding: 0 0 0 290px;
      }
    }
  }
}
