@use "../../global-styles/palette" as c;

.rich-text-description-cta-and-image-banner {
  &__image-container {
    margin-top: 15px;
    video {
      width: 100%;
      border-radius: 6px 6px 0px 0px;
    }
  }

  &__top-end-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__button-and-description-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  &__title-container {
    flex: 4;
    text-align: center;
  }

  &__button-and-description-container {
    flex: 3;
  }
}

.large-view {
  .rich-text-description-cta-and-image-banner {
    &__top-end-container {
      display: flex;
      flex-direction: row;
      gap: 40px;
    }

    &__image-container {
      margin-top: 10px;
      margin-bottom: -4px;

      video {
        width: 100%;
        border-radius: 20px 20px 0px 0px;
      }
    }

    &__button-and-description-container {
      text-align: left;

      .h5-semi-bold {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
      }
    }

    &__title-container {
      text-align: left;
    }

    .button-v2-wrapper {
      justify-content: left;
    }
  }
}

.medium-view {
  .rich-text-description-cta-and-image-banner {
    &__top-end-container {
      display: flex;
      flex-direction: row;
    }

    &__image-container {
      margin-top: 13px;

      video {
        width: 100%;
        border-radius: 12px 12px 0px 0px;
      }
    }

    &__button-and-description-container {
      text-align: left;
    }

    &__title-container {
      text-align: left;
    }

    .button-v2-wrapper {
      justify-content: left;
    }
  }
}