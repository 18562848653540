@use "../../../global-styles/palette" as c;

.other-card_root {
  border: 1px solid c.$dark-10;
  border-radius: 16px;
  padding: 24px;

  h5 {
    color: c.$dark-default;
    margin: 24px 0 8px;
  }

  .p-regular{
    color: var(--content-text-color);
  }
}

.large-view {
  .other-card_root {
    display: flex;
    flex-direction: column;

    img {
      width: fit-content;
    }

    p {
      width: 262px;
    }
  }

  .other-accounts-variation-cards-deck_cardsWrapper-2-columns {
    .other-card_root {
      border: 0px !important;

      p {
        width: auto !important;
      }
    }
  }
}