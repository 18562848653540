@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.navigation {
  &__widgets {
    display: flex;
  }

  &__widget {
    color: c.$dark-40;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 5px;
    &--lang{
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      color: var(--main-nav-foreground-color);
      padding: 12px;
      border-radius: 48px;
      line-height: 16px;
      cursor: pointer;
      &:hover{
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(12px);
      }
    }
  }

  &__search {
    color: c.$dark-80;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid;
    width: 0;
    padding: 0;
    overflow: hidden;
    transition: v.$transition;
    font-size: 16px;
    font-weight: 400;
    outline: 0;

    &.active {
      width: 150px;
      margin-left: 8px;
    }
  }
}

.medium-view {
  .navigation {
    &__widgets {
      right: 48px;
      max-width: 375px;
    }
  }
}

.small-view {
  .navigation {
    &__widgets {
      position: fixed;
      top: 0px;
      right: -100%;
      background-color: c.$pure-white;
      width: 100%;
      transition: v.$transition;
      flex-direction: row-reverse;
      box-sizing: border-box;

      &__inputWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        width: 100%;
        margin-inline: auto;
        &--mobile{
          padding: 0;
        }

        svg {
          cursor: pointer;
        }

        /* clears the 'X' from Internet Explorer */
        input[type="search"]::-ms-clear {
          display: none;
          width: 0;
          height: 0;
        }
        input[type="search"]::-ms-reveal {
          display: none;
          width: 0;
          height: 0;
        }

        /* clears the 'X' from Chrome */
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
          display: none;
        }
      }
    }

    &__search {
      border-radius: 8px;
      border: none;
      background-color: c.$neutral-accent-background;
      height: 40px;
      width: 80%;
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 40px;
      box-sizing: border-box;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='jmizk30oca' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg clip-path='url(%23k0omiwei5b)' fill='%236E7783'%3E%3Cpath d='M10.4 5.6a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6zm0-1.6a6.4 6.4 0 1 1 0 12.8 6.4 6.4 0 0 1 0-12.8z'/%3E%3Cpath d='M13.834 14.966a.8.8 0 0 1 1.132-1.132l4.8 4.8a.8.8 0 0 1-1.132 1.132l-4.8-4.8z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='k0omiwei5b'%3E%3Cpath fill='%23fff' transform='translate(4 4)' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }

    &__widget--search {
      display: none;
    }
  }

  .mobile-nav-active .navigation__widgets {
    right: 0;
    max-width: 100%;
  }
}

.medium-view,
.large-view {
  .navigation__widgets {
    &__inputWrapper {
      &--mobile{
        display: none;
      }
      svg {
        display: none;
      }
    }
  }
  .navigation__widget--lang{
    svg{
      margin-top: 0;
    }
  }
}