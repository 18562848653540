@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/typography";

.card-with-icon {
    background-color: c.$pure-white;
    max-width: v.$max-column-width-3columns;
    min-width: v.$card-standard-width;
    min-height: 292px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    text-align: center;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 32px;

    &__title {
        @extend %card-title--size-h4;

        margin-bottom: 8px;
        color: c.$blue-lagoon-default;
    }

    &__text-container {
        max-width: 312px;
    }

    &__text-container p {
        color: c.$dark-80;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
    }
}

.small-view,
.medium-view {
    .card-with-icon {
        background-color: c.$pure-white;
        max-width: 327px;
        min-width: 327px;
        min-height: 216px;
        padding-top: 24px;
        text-align: center;

        &__img-container img {
            width: 80px;
        }

        &__text-container {
            max-width: 279px;
        }

        &__title {
            margin-bottom: 16px;
        }
    }
}
