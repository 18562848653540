@use "../../../../global-styles/palette" as c;

.person-root {
    display: none;
    justify-items: center;
    border-radius: 4.934px;
    background-color: rgba(255, 255, 255, 0.80);

    img {
        border-radius: 4.934px;
    }

    p.person-root__name {
        margin: 7px 0 2px 0;
        color: c.$dark-default;
        font-size: 13.156px;
        font-weight: 600;
        line-height: normal;
        width: 115px;
        color: c.$dark-default;
    }

    .person-root__position {
        color: c.$dark-default;
        font-size: 9.867px;
        font-weight: 500;
        line-height: normal;
        width: 115px;
        color: c.$dark-default;
        margin-bottom: 7px;
    }
}

.medium-view {
    .person-root {
        display: grid;
    }
}

.large-view {
    .person-root {
        display: grid;

        p.person-root__name {
            font-size: 15.549px;
            width: 137px;
        }

        .person-root__position {
            font-size: 11.662px;
            width: 135px;
            margin-bottom: 10px;
        }
    }
}