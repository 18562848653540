@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.trending-videos-v2 {
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;

    &__list-item-text-link a {
        text-decoration: none;
        color: inherit;
    }

    &__main-img {
        text-decoration: none;
        color: inherit;
    }

    .trending-videos-v2__coming-soon.trending-videos-v2__auto-cursor {
        cursor: default;
    }

    h2 {
        color: c.$dark-default;
        text-align: center;
    }

    &__container {
        display: flex;
        gap: 24px;
    }

    &__main-img-img {
        width: 100%;
        border-radius: 32px;
    }

    &__main-img-img-side {
        height: 126px;
        border-radius: 12px;
    }

    &__main {
        background-color: c.$neutral-accent-background;
        border-radius: 32px;
        display: flex;
        flex-direction: column;
        gap: 28px;
        position: relative;
        flex: 1;

        .youtube-video-iframe {
            border-radius: 32px;
            height: 404px;
        }

        .simplelightbox {
            height: 404px;
            position: unset;
        }

        .simplelightbox--on {
            height: 100%;
            position: fixed;

            .youtube-video-iframe {
                border-radius: unset;
            }
        }
    }

    &__main {
        background-color: c.$neutral-accent-background;
    }

    &__lightbox-trigger {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
    }

    &__main-text {
        max-width: 472px;
        margin-left: 40px;
    }

    &__main-text {

        margin-bottom: 20px;
    }

    &__upload-time {
        color: c.$dark-80;

        p {
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;

            span {
                margin-right: 4px;
                display: flex;

                svg {
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }

    &__upload-title {
        color: c.$dark-default;
        @extend %clamped-text-base;
        -webkit-line-clamp: 2;

        h4 {
            font-weight: 600;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__list-item {
        display: flex;
        max-height: 126px;
        background-color: c.$neutral-accent-background;
        border-radius: 12px;
        gap: 24px;
        width: 552px;
        position: relative;
    }

    &__list-item {
        background-color: c.$neutral-accent-background;
    }

    &__list-item-text,
    &__list-item-text-link {
        padding: 24px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-decoration: none;
    }

    &__list-item-img {

        .simplelightbox {
            height: unset;
            position: unset;

            &__content-container {
                position: unset;
                width: 168px;
                height: 126px;
            }
        }

        .simplelightbox--on {
            height: 100%;
            position: fixed;

            .simplelightbox__content-container {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .youtube-video-iframe {
                border-radius: unset;
            }
        }

        .youtube-video-iframe {
            border-radius: 12px;
        }
    }

    &__list-title {
        max-width: 336px;
        @extend %clamped-text-base;
        -webkit-line-clamp: 2;
    }

    &__list-upload-time {
        color: c.$dark-80;

        p {
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;

            span {
                margin-right: 4px;
                display: flex;

                svg {
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }
}

.medium-view {
    .trending-videos-v2 {
        &__container {
            flex-direction: column;
            align-items: center;
        }

        &__main {
            height: unset;
            width: 600px;
            border-bottom-right-radius: 24px;
            border-bottom-left-radius: 24px;
        }

        &__main-img {
            img {
                width: 100%;
            }
        }

        &__main-text {
            max-width: 520px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__list-upload-time {

            p {
                font-size: 12px;
                line-height: 16px;

                span {
                    margin-right: 4px;
                }
            }
        }

        &__upload-title {
            margin-bottom: 32px;
        }

        &__list-item {
            width: 600px;
        }
    }
}

.small-view {
    .trending-videos-v2 {
        &__container {
            flex-direction: column;
        }

        &__main {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
            height: unset;
        }

        &__main-img {
            img {
                width: 100%;
            }
        }

        &__main-text {
            margin-left: unset;
            max-width: 279px;
            margin: 0px 24px 32px 24px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__list-item {
            width: unset;
        }

        &__list-item-img {

            .simplelightbox__content-container {
                height: 104px;
            }

            .simplelightbox__content-container--youtube iframe {
                width: 0.1px;
            }

            .simplelightbox--on {
                .simplelightbox__content-container--youtube iframe {
                    width: calc(100vw - 2 * 30px);
                }

                .simplelightbox__content-container {
                    height: unset;
                }
            }
        }

        &__list-item-text {
            padding: 16px 0px;
        }

        &__list-item-text-link {
            padding: 16px 0px;
        }

        &__list-title {
            max-width: 279px;
        }

        &__list-upload-time {

            p {
                font-size: 12px;
                line-height: 16px;

                span {
                    margin-right: 4px;
                }
            }
        }
    }
}