@use "../../global-styles/palette" as c;

.markets-and-instruments {

    .g-container {
        overflow: unset;
    }

    &__container {
        display: flex;
        justify-content: center;
        gap: 64px;
    }

    &__left-wrapper, &__right-wrapper {
        width: 512px;
    }

    &__left-wrapper {
        position: -webkit-sticky;
        position: sticky;
        top: 178px;
        height: 600px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__title-wrapper {
            color: c.$dark-default;
        }

        &__desc-wrapper {
            color: c.$dark-80;
        }

        &__img-wrapper {
            img {
                width: 512px;
            }
        }

    }

    &__right-wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;

        &__el {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__el-title {
            margin-bottom: 8px;
            color: c.$dark-default;
        }

        &__el-desc {
            color: c.$dark-80;
        }
    }

}

.medium-view {
    .markets-and-instruments {
    
        &__container {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 600px;
        }

        &__left-wrapper {
            position: unset;
            top: unset;
            height: unset;
            width: 600px;

            &__img-wrapper {
                display: flex;
                justify-content: center;
    
                img {
                    width: 600px;
                }
            }
        }

        &__right-wrapper {
            width: 600px;
        }
    }
}

.small-view {
    .markets-and-instruments {
        &__container {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 327px;
        }

        &__left-wrapper {
            position: unset;
            top: unset;
            height: unset;
            width: 327px;

            &__img-wrapper {

                img {
                    width: 312px;
                }
            }
        }

        &__right-wrapper {
            width: unset;
        }
    }
}
