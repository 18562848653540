@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";
@use "../../global-styles/typography";

.trading-scenarios__title {
    padding-bottom: 28px;
}

.trading-scenarios {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    

   
    &__summary strong{
        @extend %h5;
    }
    
    &__summary {
        display: grid;
        padding: 30px 40px 20px 15px;
        gap: 25px;
    }

    &__radio {
        padding: 10px;
        border-radius: 24px;
    }

    &__radio-item {
        padding: 8px 16px;
        border-radius: 16px;
    }

    &__radio-item:hover {
        cursor: pointer;
    }

    &__radio-selector {
        display: flex;
        flex-direction: column;
    }

    &__radio-container {
        margin-bottom: 24px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;
    }

    &__scenario {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__scenario-div {
        border-radius: 16px;
        border: solid 1px #D3E2E2;
        padding: 16px 24px;

    }

    &__two-scenarios-div {
        display: flex;
        flex-grow: 1;

    }

    &__two-scenarios-div>* {
        flex: 1;
    }

}

.large-view {
    .trading-scenarios {
        border-radius: 32px;
        padding: 40px 55px;

        &__summary {
            grid-template-columns: 1fr;
            padding: 0px 40px 20px 14px;
            gap: 35px;
        }
    }
}

.medium-view {
    .trading-scenarios {

        border-radius: 40px;
        padding: 40px 40px 56px 40px;

        &__summary-container {
            align-self: flex-start;
        }

        &__scenario {
            margin-bottom: 28px;
        }

        &__two-scenarios-div {
            flex-direction: column;
        }
    }
}

.small-view {
    .trading-scenarios__title {
        padding-bottom: 22px;
    }

    .trading-scenarios {
        border-radius: 24px;
        padding: 25px 6px 40px 24px;
        flex-direction: column;

        &__radio-container {
            margin-bottom: 8px;
            align-items: center;

        }

        &__radio-selector {
            margin-right: 17px;
        }

        &__summary {
            grid-template-columns: 1fr 1fr;
            gap: 25px;
            padding: 10px;

            &> :last-child {
                grid-column: span 2;
            }
        }

        &__scenario-div {
            padding: 15px 15px;
        }

        &__two-scenarios-div {
            flex-direction: column;
        }

        &__scenario {
            margin-top: 24px;
        }
    }
}