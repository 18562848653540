@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.trending-videos {
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;

    h2 {
        color: c.$dark-default;
        text-align: center;
    }

    &__container {
        display: flex;
        gap: 24px;
    }

    &__main {
        background-color: c.$neutral-accent-background;
        height: 552px;
        border-radius: 32px;
        display: flex;
        flex-direction: column;
        gap: 28px;
        position: relative;
        flex: 1;

        .youtube-video-iframe {
            border-radius: 32px;
            height: 404px;
        }

        .simplelightbox {
            height: 404px;
            position: unset;
        }
    
        .simplelightbox--on {
            height: 100%;
            position: fixed;

            .youtube-video-iframe {
                border-radius: unset;
            }
        }
    }

    &__main:hover {
        background-color: c.$neutral-accent-default;
        cursor: pointer;
    }

    &__lightbox-trigger {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
    }

    &__main-icon:hover {
        cursor: pointer;
    }

    &__main-text {
        max-width: 472px;
        margin-left: 40px;
    }

    &__upload-time {
        color: c.$dark-80;

        p {
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;   

            span {
                margin-right: 4px;
                display: flex;

                svg{
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }

    &__upload-title {
        color: c.$dark-default;
        @extend %clamped-text-base;
        -webkit-line-clamp: 2;

        h4 {
            font-weight: 600;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__list-item-0, &__list-item-1, &__list-item-2, &__list-item-3 {
        display: flex;
        max-height: 126px;
        background-color: c.$neutral-accent-background;
        border-radius: 12px;
        gap: 24px;
        width: 552px;
        position: relative;

        .trending-videos__list-lightbox-trigger-0, .trending-videos__list-lightbox-trigger-1, .trending-videos__list-lightbox-trigger-2, .trending-videos__list-lightbox-trigger-3 {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            cursor: pointer;
        }
    }

    &__list-item-0:hover, &__list-item-1:hover, &__list-item-2:hover, &__list-item-3:hover {
        background-color: c.$neutral-accent-default;
        cursor: pointer;
    }

    &__list-item-text {
        padding: 24px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__list-item-img {

        .simplelightbox {
            height: unset;
            position: unset;
            &__content-container {
                position: unset;
                width: 168px;
                height: 126px;
            }
        }

        .simplelightbox--on {
            height: 100%;
            position: fixed;

            .simplelightbox__content-container {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .youtube-video-iframe {
                border-radius: unset;
            }
        }

        .youtube-video-iframe {
            border-radius: 12px;
        }
    }

    &__list-title {
        max-width: 336px;
        @extend %clamped-text-base;
        -webkit-line-clamp: 2;
    }

    &__list-upload-time {
        color: c.$dark-80;

        p {
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;   

            span {
                margin-right: 4px;
                display: flex;

                svg{
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }
}

.medium-view {
    .trending-videos {
        &__container {
            flex-direction: column;
            align-items: center;
        }

        &__main {
            height: unset;
            width: 600px;
            border-bottom-right-radius: 24px;
            border-bottom-left-radius: 24px;
        }

        &__main-img {
            img {
                width: 100%;
            }
        }

        &__main-text {
            max-width: 520px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__list-upload-time {
    
            p {
                font-size: 12px;
                line-height: 16px;
    
                span {
                    margin-right: 4px;
                }
            }
        }

        &__upload-title {
            margin-bottom: 32px;
        }

        &__list-item-0, &__list-item-1, &__list-item-2, &__list-item-3 {
            width: 600px;
        }
    }
}
.small-view {
    .trending-videos {
        &__container {
            flex-direction: column;
        }

        &__main {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
            height: unset;
        }

        &__main-img {
            img {
                width: 100%;
            }
        }

        &__main-text {
            margin-left: unset;
            max-width: 279px;
            margin: 0px 24px 32px 24px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__list-item-0, &__list-item-1, &__list-item-2, &__list-item-3  {
            width: unset;
        }

        &__list-item-img {
            
            .simplelightbox__content-container {
                height: 104px;
            }

            .simplelightbox__content-container--youtube iframe {
                width: 0.1px;
            }

            .simplelightbox--on { 
                .simplelightbox__content-container--youtube iframe {
                    width: calc(100vw - 2 * 30px);
                }

                .simplelightbox__content-container {
                    height: unset;
                }
            }
        }

        &__list-item-text {
            padding: 16px 0px;
            margin-left: -167px;
        }

        &__list-title {
            max-width: 279px;
        }

        &__list-upload-time {
    
            p {
                font-size: 12px;
                line-height: 16px;
    
                span {
                    margin-right: 4px;
                }
            }
        }
    }
}