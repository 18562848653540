@use "../../../global-styles/palette" as c;

.basic-banner {
    .breadcrumbs {
        .breadcrumbs__home-link {
          svg {
            path {
              fill: c.$dark-default;
            }
          }
        }
    }

    .addMarginAuto {
        margin: 0px auto;
    }

    h1.basic-banner__title {
        font-size: 54px;
        line-height: 64px;
        margin-bottom: 8px;
    }

    p.basic-banner__subtitle {
        max-width: 744px;
    }
}

.medium-view {
    .basic-banner {
        h1.basic-banner__title {
            font-size: 45px;
            line-height: 56px;
        }

        p.basic-banner__subtitle {
            max-width: 600px;
            font-size: 18px;
            font-weight: 500;
        }
    }
}

.small-view {
    .basic-banner {
        h1.basic-banner__title {
            font-size: 36px;
            line-height: 48px;
        }

        p.basic-banner__subtitle {
            max-width: 327px;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
        }
    }
}