@use "../../../global-styles/palette" as c;

.HeroBannerWithImagesWrapper {
  background-color: c.$dark-default;
  padding-inline: 24px;

  .heroRootWrapperGridContainer {
    padding: 0;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-content: start;
    grid-template-areas:
      "titles"
      "buttons"
      "image"
      "sliderButtons";
  }

  .titleWrapper {
    display: grid;
    grid-area: titles;
    grid-template-columns: 1fr;
    place-items: center;
    width: 100%;
    height: fit-content;

    &__mainTitle {
      color: c.$pure-white;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      font-size: 42px;
    }

    &__mainSubTitle {
      color: c.$dark-20;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      width: 300px;
      height: 70px;
    }
  }

  .imageWrapper {
    grid-area: image;
    width: 327px;
    height: 341px;
    margin-top: 4.1vh;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .buttonsWrapper {
    display: grid;
    grid-area: buttons;
    grid-template-columns: 1fr;
    align-content: space-between;
    place-items: center;
    margin-top: 4.6vh;
    gap: 16px;
    white-space: nowrap;

    .squaredButton {
      background-color: c.$tm-green-default;
      border: none;
      border-radius: 38px;
      text-decoration: none;
      color: c.$dark-default;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      padding: 8px 30.5px;

      &:hover {
        background-color: c.$tm-green-40;
      }
    }

    a {
      color: c.$pure-white;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-decoration-line: underline;

      width: fit-content;
    }
  }

  .statsWrapper {
    grid-area: stats;
    display: none;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
  }

  .sliderButtonsWrapper {
    grid-area: sliderButtons;
    display: grid;
    grid-auto-flow: column;
    width: 32vw;
    justify-content: space-between;
    margin: 4.6vh 0 3.5vh 0;
    gap: 8px;

    span {
      display: grid;
    }

    &__sliderButtonsItemContainer {
      background-color: c.$dark-60;
      border-radius: 4px;
    }

    &__sliderButtonsItem {
      width: 40px;
      height: 8px;
      border-radius: 4px;
      display: inline-block;
      background-color: c.$dark-60;
    }

    .sliderButtonsWrapper__active {
      position: relative;
      max-width: 40px;
      border-radius: 4px;

      .sliderButtonsWrapper__progres-scale {
        position: absolute;
        background-color: c.$tm-green-default;
        width: 0px;
        max-width: 40px;
        height: 8px;
        border-radius: 4px;
        animation: progres 5s infinite linear; 
      }
      
      @keyframes progres {
        0%{
          width: 0%;
        }
        5%{
          width: 5%;
        }
        10%{
          width: 10%;
        }
        15%{
          width: 15%;
        }
        20%{
          width: 20%;
        }
        25%{
          width: 25%;
        }
        30%{
          width: 30%;
        }
        35%{
          width: 35%;
        }
        40%{
          width: 40%;
        }
        45%{
          width: 45%;
        }
        50%{
          width: 50%;
        }
        55%{
          width: 55%;
        }
        60%{
          width: 60%;
        }
        65%{
          width: 65%;
        }
        70%{
          width: 70%;
        }
        75%{
          width: 75%;
        }
        80%{
          width: 80%;
        }
        85%{
          width: 85%;
        }
        90%{
          width: 90%;
        }
        95%{
          width: 95%;
        }
        100%{
          width: 100%;
        }
      };
    }
    

    &:hover {
      cursor: pointer;
    }
  }
}

.small-view {
	.HeroBannerWithImagesWrapper {
		.heroRootWrapperGridContainer {
      grid-template-rows: min-content;
      align-content: stretch;
		}

    .titleWrapper {
      height: 220px;
      overflow: hidden;

      &__mainTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
      }

      &__mainSubTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }

    .imageWrapper {
      width: auto;
      height: 250px;
      max-width: 100%;
    }

    .buttonsWrapper {
      height: auto;
      margin-top: 2vh;
    }

    .sliderButtonsWrapper {
      grid-auto-columns: 1fr;
      
      &__sliderButtonsItem {
        width: auto;
        max-width: none;
      }
    }
	}
}

.medium-view {
  .HeroBannerWithImagesWrapper {
    padding-inline: 72px;
    .heroRootWrapperGridContainer {
      max-width: unset;
      grid-template-areas:
        "titles"
        "image"
        "buttons"
        "stats"
        "sliderButtons";

      .titleWrapper {

        &__mainTitle {
          width: 70vw;
          font-size: 52px;
          line-height: 64px;
          font-weight: 700;
        }
        &__mainSubTitle {
          margin-top: 1vh;
        }
      }

      .imageWrapper {
        margin-top: 3vh;
        width: 428px;
        height: 446px;
      }

      .buttonsWrapper {
        grid-auto-flow: column;
        height: fit-content;
        width: 26vw;
        grid-template-columns: unset;
        justify-content: space-between;
        margin-top: 4vh;
        gap: 24px;
      }

      .statsWrapper {
        color: c.$pure-white;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 4vh;
        gap: 6.3vw;
        width: 100%;

        span {
          border-left: 1px solid c.$dark-80;
          padding-left: 16px;
          width: 100%;

          &:last-child {
            width: fit-content;
            place-self: end;
          }
        }

        &__statTitle {
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          margin: 0;
        }

        &__statSubTitle {
          color: c.$dark-20;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          white-space: nowrap;
          margin: 0;
        }
      }
      .sliderButtonsWrapper {
        width: 13vw;
        margin: 3vh 0 4vh 0;
      }
    }
  }
}


.large-view[dir="rtl"] {
  .HeroBannerWithImagesWrapper {
    .titleWrapper {
      .titleWrapper__mainTitle,
      .titleWrapper__mainSubTitle {
        text-align: right;
      }
    }
  }
}

.large-view {
  .HeroBannerWithImagesWrapper {
    display: grid;
    justify-content: center;

    .heroRootWrapperGridContainer {
      min-height: unset;
      max-width: 1128px;
      justify-items: unset;
      grid-template-columns: 512px 80px 552px;
      grid-template-areas:
        "titles . image"
        "buttons . image"
        "stats . image"
        ". sliderButtons .";
    }
    .titleWrapper {
      width: fit-content;
      margin-top: 40px;
      place-items: start;

      &__mainTitle {
        width: 520px;
        font-size: 54px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        text-align: left;
      }
      &__mainSubTitle {
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        width: fit-content;
        margin-top: 8px;
      }
    }

    .buttonsWrapper {
      margin-top: 45px;
      width: fit-content;
      grid-template-columns: unset;
      height: fit-content;
      gap: 24px;
      place-items: baseline;
    }

    .imageWrapper {
      margin-top: 40px;
      width: 552px;
      height: 575px;
    }

    .statsWrapper {
      margin-top: 70px;
      color: c.$pure-white;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(4, 1fr);
      gap: 37px;
      width: 469px;
      height: fit-content;

      span {
        border-left: 1px solid c.$dark-80;
        padding-left: 16px;
        width: 100%;
      }

      &__statTitle {
        margin: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 48px;
      }

      &__statSubTitle {
        color: c.$dark-20;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        margin: 0;
      }
    }

    .sliderButtonsWrapper {
      margin: 32px 0 40px 0;
      width: 136px;
    }
  }
}

.elementToFadeIn {
  display: inline;
  animation: fadein 0.5s linear forwards;
  opacity: 0;
}

.elementToFadeOut {
  display: inline;
  animation: fadeOut 0s linear forwards;
  opacity: 1;
}

@keyframes fadein {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
