@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/variables" as v;

.dark-numbered-card {
    box-sizing: border-box;
    padding: 32px;
    border-radius: 16px;
    color: c.$pure-white;
    background-color: c.$dark-default;

    .dark-numbered-card__title {
        line-height: 1;
    }

    &__index {
        margin-right: 16px;
        color: c.$blue-lagoon-default;
    }
}
.large-view {
    .dark-numbered-card {
        height: 264px;
        min-width: v.$card-standard-width;

        &__index {
            font-size: 61px;
        }
        &__title {
            font-weight: bold;
            padding-bottom: 29px;
        }
        &__content {
            overflow: hidden;
            max-height: 115px;
        }
    }
}
.small-view,
.medium-view {
    .dark-numbered-card {
        height: 216px;
        padding-top: 24px;

        &__index {
            font-size: 48px;
        }
        &__title {
            font-weight: bold;
            padding-bottom: 12px;
        }
        &__content {
            overflow: hidden;
            max-height: 100px;
        }
    }
}
