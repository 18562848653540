@use "../../global-styles/palette" as c;

.experience-new-way-trade {
    background-color: c.$pure-white;
    .g-container {
        h2 {
            text-align: center;
            margin-bottom: 40px;
        }

        .common-images-style {
            margin-inline: auto;
            width: 65vw;
        }

        .experience-new-way-trade__container {
            border-radius: 20px;
            overflow: clip;
            background: linear-gradient(180deg, #0D1620 18.5%, rgba(13, 22, 32, 0.00) 74%, rgba(13, 22, 32, 0.30) 100%), linear-gradient(270deg, #0D1620 0%, #0E4281 38.95%, #0A651E 68.79%, #0D1620 100%);

            .logos-wrapper {
                box-sizing: border-box;
                margin-bottom: 10px;
                justify-content: space-between;
                width: 100%;
                max-width: 800px;
                margin: 0;
                margin-inline: auto;
                padding: 43px 43px 0 43px;

                .first-images-wrapper {
                    margin-bottom: 5px;
                    padding-top: 38px;


                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .second-images-wrapper {

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .experience-new-way-trade__subtitle {
                color: c.$neutral-accent-light-grey-font;
                box-sizing: border-box;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
                margin-bottom: 24px;
                padding-inline: 5px;
            }

            .experience-new-way-trade__imageContainer {
                transform: translate(10%, 10%);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }
}

.medium-view {
    .experience-new-way-trade {
        .g-container {
            .common-images-style {
                width: 30vw;
            }

            .experience-new-way-trade__container {
                .logos-wrapper {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    max-width: 480px;
                    margin: 0;
                    margin-inline: auto;
                    padding: 23px 23px 0 23px;
                    gap: 50px;

                    .first-images-wrapper {
                        padding: 0;
                    }
                }

                .experience-new-way-trade__subtitle {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 140%;
                    width: 100%;
                    padding: 0 30px;
                    margin-inline: auto;
                }

                .experience-new-way-trade__imageContainer {
                    transform: unset;
                    margin-inline: auto;
                    width: 70vw;
                    margin-top: 35px;
                }
            }
        }
    }
}

.large-view {
    .experience-new-way-trade {
        .g-container {
            .common-images-style {
                width: 20vw;
            }

            .experience-new-way-trade__container {
                .logos-wrapper {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    max-width: 800px;
                    margin: 0;
                    margin-inline: auto;
                    padding: 43px 43px 0 43px;

                    .first-images-wrapper {
                        padding: 0;
                    }
                }

                .experience-new-way-trade__subtitle {
                    max-width: 800px;
                    margin: 13px auto 26px;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 140%;
                }

                .experience-new-way-trade__imageContainer {
                    transform: unset;
                    width: 60vw;
                    margin-inline: auto;
                    margin-top: 33px;
                    min-width: 800px;
                    max-width: 1000px;
                }
            }
        }
    }
}

.small-view{
    .experience-new-way-trade{
        .g-container{
            .experience-new-way-trade__container{
                .logos-wrapper{
                    padding: 0 50px;
                    max-width: 400px;
                }
            }
        }
    }
}