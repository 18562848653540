@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/palette" as c;

@use "./FavoriteETFCardItem/FavoriteETFCardItem";

.favorite-etf-card {
    background-color: c.$pure-white;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 360px;
    min-height: 352px;
    justify-content: space-between;

    &__title {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }

    &__content {
        min-height: 216px;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 32px;
        margin-right: 36px;
    }
    &__link a {
        display: flex;
        text-decoration: none;
        color: c.$blue-lagoon-default;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
    }
}

.medium-view, .small-view {
    .favorite-etf-card {
        width: 327px;
        min-height: 288px;
        padding-top: 24px;

        &__link {
            margin-bottom: 24px;
            margin-right: 28px;
        }
    }
}