@use "../../../global-styles/palette" as c;

.ribbonBanner2 {
    box-sizing: border-box;
    background-color: c.$blue-lagoon-default;
    min-height: 80px;
    padding: 12px 0;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__text {
        display: flex;
        align-items: center;
        h3 {
            color: c.$pure-white;
            margin-right: 60px;
        }

        p {
            color: c.$pure-white;
            max-width: 406px;
        }
    }

    &__cta {
        background-color: c.$tm-green-default;
        color: c.$pure-white;
        padding: 10px 30px;
        max-height: 36px;
        border-radius: 20px;
        border: none;
        cursor: pointer;
        text-decoration: none;
      }
    
      &__cta:hover {
        background-color: c.$tm-green-120;
      }
}

.small-view, .medium-view {
    .ribbonBanner2 {
        padding: 32px 0;

        &__inner {
            flex-direction: column;
            justify-content: unset;
        }

        &__text {
            flex-direction: column;

            h3 {
                margin: 0 0 16px 0;
            }

            p {
                margin-bottom: 24px;
                text-align: center;
            }
        }
    }
}