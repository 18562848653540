@use "../../global-styles/palette" as c;

.categories-with-icon-swiper-root {
  padding: 20px 0;
  &__card>a {
    padding: 12px 10px 8px 10px;
    min-width: 60px;
  }

  &__card>a:hover {

    border-radius: 8px;
    background-color: rgba(211, 226, 226, 0.05);
  }

  .g-container {
    .categories-with-icon-swiper-root__titleWrapper {
      margin-bottom: 16px;
      &.leftTitle{
        text-align: left;
      }
      &.centerTitle{
        text-align: center;
      }

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
      }
    }

    .leftTitle {
      @extend .categories-with-icon-swiper-root__titleWrapper;

      p {
        text-align: left;
      }
    }

    .categories-with-icon-swiper-root__swiperWrapper {
      .g-container {
        margin: 0;

        .swiper {
          width: 100%;
          .swiper-wrapper {
            width: 100%;
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;

            .swiper-slide {
              width: fit-content;
              padding: 15px 0;
              margin-inline: 17px;

              .categories-with-icon-swiper-card {
                display: grid;
                justify-items: center;
                align-content: end;
                color: inherit;
                text-decoration: inherit;
                text-decoration: none;

                img {
                  width: 24px;
                  height: 24px;
                }

                p {
                  margin-top: 8px;
                  text-align: center;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;
                  height: fit-content;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .categories-with-icon-swiper-root {
    .g-container {
      .categories-with-icon-swiper-root__swiperWrapper {
        .g-container {
          .swiper {
            .swiper-wrapper {
              .swiper-slide {
                margin-inline: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.large-view {
  .categories-with-icon-swiper-root {
    .g-container {
      .categories-with-icon-swiper-root__swiperWrapper {
        .g-container {
          .swiper {
            .swiper-wrapper {
              .swiper-slide {
                margin-inline: 15px;
              }
            }
          }
        }
      }
    }
  }
}

html[dir="rtl"] {
  .categories-with-icon-swiper-root {
    .g-container {
      .categories-with-icon-swiper-root__titleWrapper {
        &.leftTitle p{
          text-align: right;
        }
      }
    }
  }
}