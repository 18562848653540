@use "../../../global-styles/palette" as c;
@use "./VideoCard/VideoCard";

.videoCardDeck {
  &__titleAndLink {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      color: c.$dark-default;
    }

    a {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      color: c.$blue-lagoon-default;
      text-decoration: none;
      display: flex;
    }
  }

  &__pagination-container {
    display: none;
  }

  .swiper-slide {
    width: fit-content;
    margin-right: 24px;
  }
}

.large-view {
  .videoCardDeck {
    .swiper-slide {
      @extend %swiper-slide;
    }
  }
}

.medium-view,
.small-view {
  .videoCardDeck {
    &__titleAndLink {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;

      h2 {
        margin-bottom: 8px;
      }
    }

    .videoCard {
      max-width: 100%;
    }

    &__slider {
      .g-container {
        margin-right: 0px;
      }

      .videoCard {
        max-width: 280px;
      }
    }

    &__pagination-container {
      margin: 24px 0;
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 32px;
      -webkit-tap-highlight-color: transparent;
    }

    &__pagination-image {
      width: 40px;
      height: 40px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: c.$blue-lagoon-default;
    }

    .swiper-button-disabled {
      color: c.$dark-60;
    }

    .swiper-slide {
      margin-right: 16px;
    }
  }
}