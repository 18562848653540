@use "../../global-styles/palette" as c;
@use "../../global-styles/typography";

.careerTabsSlider {
  --nav-item-border-radius: 8px;

  h2 {
    margin-bottom: 32px;
    text-align: center;
  }

  &__sliderWrap {
    max-width: 1128px;
    margin: 0 auto;
  }

  &__swiper {
    background-color: c.$pure-white;
    border-bottom-left-radius: var(--nav-item-border-radius);
    border-bottom-right-radius: var(--nav-item-border-radius);
    border-top-right-radius: var(--nav-item-border-radius);
  }

  .swiper-slide {
    background-color: c.$pure-white;
    display: flex;
    height: auto;
  }

  &__card {
    box-sizing: border-box;
    position: relative;
    min-height: 232px; 
    padding: 32px 32px 32px 384px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      position: absolute;
      left: 100px;
      top: 50%;
      transform: translateY(-50%);
    }

    h3 {
      color: c.$dark-default;
      margin-bottom: 16px;
    }

    p {
      color: c.$dark-80;
      max-width: 552px;
    }
  }

  &__customPagination {
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }

  &__customPagination::-webkit-scrollbar { 
    display: none;  
  }

  &__bullets {
    @extend %slider-tab-text;
    
    box-sizing: border-box;
    min-width: 200px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: c.$dark-20;
    border-top-right-radius: var(--nav-item-border-radius);  
    border-top-left-radius: var(--nav-item-border-radius); 
    cursor: pointer; 
    color: c.$dark-80;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    -webkit-tap-highlight-color: transparent;
  }

  &__bullets:not(:last-child) {
    margin-inline-end: 16px;
  }

  .swiper-pagination-bullet-active {
    background-color: c.$pure-white;
  }
}

.small-view, .medium-view {
  .careerTabsSlider {
    &__swiper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &__card {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;

      img {
        position: static;
        width: 80px;
        height: 80px;
        margin: 32px 0 10px 0;
        transform: translateY(0);
      }

      h3 {
        margin-bottom: 16px;
      }
    }
  }
}