@use "../../global-styles/palette" as c;

.trading-glossary {
    display: flex;
    background-color: c.$pure-white;
    gap: 40px;

    &__main {
        display: flex;
        flex-basis: 60%;
    }

    &__side-bar {
        display: flex;
        flex-direction: column;
        flex-basis: 40%;
        margin-right: auto;
        max-width: 350px;
    }
}

.small-view, .medium-view {
    .trading-glossary {
        flex-direction: column;
        &__side-bar{
            padding: 32px 24px 0px 24px;
            max-width: 100%;
        }
    }
}