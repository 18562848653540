@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;
@use "./AccordionMenu/AccordionMenu";

.main-footer {
    &__container {
        padding: 24px 0 15px 0;
    }
}

.large-view {
    .main-footer {
        .g-container {
            width: calc(100% - 2 * v.$gutter-large);
            min-width: auto;
            max-width: v.$min-content-width-large;
        }
    }
}

.small-view,
.medium-view {
    .main-footer {
        .accordion-menu__menu__block-links:last-child{
            border-bottom: 1px solid c.$dark-10;
        }
        &__container {
            padding: 0px 0 15px 0;
        }

        &__menu {
            display: none;
            margin: 0px;
            padding: 0px;
        }
    }
}
