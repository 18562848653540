@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;
@use "../AcademyArticleCardDeck/AcademyArticleCard/AcademyArticleCard";

.information-card-dark-deck {
    &__title {
        text-align: center;
        max-width: 744px;
    }

    &__title-container {
        max-width: 940px;
        margin: 0px auto 24px auto;
        display: grid;

        h2:dir(rtl) {
            line-height: 70px;
        }

        h5 {
            color: c.$dark-80;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-top: 8px;
        }
    }

    &__bottom-container {
        margin-top: 25px;
    }

    &__title-and-description-container {
        margin-left: -23px;
        padding-inline: 30px;
    }

    &__title-and-description-container>h5 {
        margin-top: 7px;
    }

    .swiper-slide {
        height: auto !important;
        margin-right: 20px;

        &:last-child{
            padding-right: 24px;
        }
    }

    &__content {
        img{
            width: 100%;
            border-radius: 16px;
        }
        border-radius: 16px;
        height: 100%;
        overflow: hidden;
        &:hover{
            background-color: c.$neutral-accent-default;
        }
    }

    &__card-text {
        margin: 15px 15px 20px 15px;
        max-width: 310px;
    }

    .button {
        padding: 0px 24px;
    }
}

.small-view,
.medium-view {
    .information-card-dark-deck {

        &__card-image {
            width: 280px;
        }

        &__bottom-container {
            margin-left: -23px;
        }
    }
}

.medium-view {
    .information-card-dark-deck {
        &__title-container {
            max-width: 600px;
            margin: 0px auto 24px auto;
            display: grid;

            h2 {
                font-size: 40px;
            }

            h2:dir(rtl) {
                line-height: 60px;
            }

            h5 {
                color: c.$dark-80;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                margin-top: 8px;
            }
        }
    }
}

.small-view {
    .information-card-dark-deck {
        &__title-container {
            max-width: 340px;
            margin: 0px auto 24px auto;
            display: grid;

            &__card-text {
                margin: 13px 20px 0px 13px;
            }

            h2 {
                font-size: 32px;
            }

            h2:dir(rtl) {
                line-height: 45px;
            }

            h5 {
                margin-top: 8px;
                color: c.$dark-80;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                width: 300px;
                margin-inline: auto;
            }
        }
    }
}

.large-view{
    .information-card-dark-deck {
        &__title-and-description-container {
            max-width: 750px;
        }
        .g-container{
            .information-card-dark-deck__container{
                .swiper-wrapper{
                    display: flex;
                    flex-flow: unset;  
                    gap: 0; 
                    width: fit-content;                 
                }
            }
        }
    }
}

html[dir="rtl"] {
    .information-card-dark-deck {
        .swiper-slide {
            margin-right: auto;
            margin-left: 20px;
            
            &:last-child {
                padding-right: 0;
                padding-left: 24px;
            }
        }
    }
}