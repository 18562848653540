@use "../../global-styles/palette" as c;

.cards-deck-with-title-and-sub-v2-root * {
  box-sizing: border-box;
}

.cards-deck-with-title-and-sub-v2-root {

  &__card {
    width: 200px;
    padding: 32px;
    border-radius: 16px;
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: space-between;
    background-position: bottom right;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    border:solid 1px #CBE1E1;

    .cards-deck-with-title-and-sub-v2-root__description-container {
      margin-bottom: 30px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: c.$dark-default;
    }

    .h4-semi-bold {
      margin-bottom: 10px;
      color: c.$dark-default;
    }
  }

  &__card-darkbg {
    .h4-semi-bold {
      color: c.$pure-white;
    }
    border: none;
  }

  .cards-deck-with-title-and-sub-v2-root__first-card-container {
    .cards-deck-with-title-and-sub-v2-root__card {
      .cards-deck-with-title-and-sub-v2-root__description-container {
        color: #CFD2D6;
      }
    }
  }

  &__link {
    text-decoration: none;
    color: c.$dark-60;
  }

  &__link:hover {
    color: c.$dark-default;
  }

  &__first-card-container {
    border-radius: 16px;
    color: c.$pure-white;
  }

  &__wrapper-right {
    .cards-deck-with-title-and-sub-v2-root__card {
      background-color: c.$neutral-accent-light-grey;
    }
  }

  &__bullet-container {
    display: flex;
    gap: 7px;
    margin-top: 10px;
  }

  &__cards-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  &__wrapper-right {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .button-v2-wrapper {
    margin: 0px;
    justify-content: unset;
  }
}

.medium-view {
  .cards-deck-with-title-and-sub-v2-root {
    &__cards-wrapper {
      display: grid;
      grid-template-columns: 1fr;
    }

    &__wrapper-right {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  }
}

.large-view {
  .cards-deck-with-title-and-sub-v2-root {
    &__cards-wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }

    &__wrapper-right {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  }
}