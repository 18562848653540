@use "./variants/variants";
@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/variables" as v;

.numbered-swipe-card {
    $block-padding: 24px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $block-padding 0 0;
    background-color: c.$neutral-accent-background;
    border-radius: 32px;
    text-align: center;
    font-family: inherit;

    &__number,
    h2,
    p {
        padding-right: $block-padding;
        padding-left: $block-padding;
    }

    &__number {
        color: c.$tm-green-default;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.3;
    }

    &__number,
    h2 {
        margin-bottom: 8px;
    }


    h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.3;
    }

    p {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;

        a {
            color: c.$dark-default;
            font-weight: 700;
            text-decoration: underline;
        }
    }

    &__image {
        width: 100%;
        margin-top: auto;
    }
}