@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";
@use "../../../src/Components/global/Grid/Col/_Col.scss" as cols;

.jobBoard {
  background-color: c.$pure-white;
  box-sizing: border-box;

  &__content {
    h2 {
      color: c.$dark-default;
      text-align: center;
      margin-bottom: 32px;
    }
  }

  &__searchFields {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  &__fieldItem {
    label {
      display: inline-block;
      color: c.$dark-80;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 8px;
    }
    input,
    select {
      box-sizing: border-box;
      outline: none;
      width: 100%;
      height: 48px;
      border-radius: 8px;
      border: solid 1px c.$dark-20;
      font-size: 18px;
      font-weight: 500;
      padding: 10px;
      color: c.$dark-60;
      background-color: c.$neutral-accent-background;
    }

    select {
      appearance: none;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(%23clip0_15417_12101)"><path d="M8.7179 12.28C8.57738 12.4207 8.38674 12.4998 8.1879 12.5L7.8079 12.5C7.60948 12.4977 7.41962 12.4189 7.2779 12.28L2.1479 7.14C2.05324 7.04612 2 6.91832 2 6.785C2 6.65168 2.05324 6.52388 2.1479 6.43L2.8579 5.72C2.95006 5.62594 3.07621 5.57293 3.2079 5.57293C3.33959 5.57293 3.46574 5.62594 3.5579 5.72L7.9979 10.17L12.4379 5.72C12.5318 5.62534 12.6596 5.5721 12.7929 5.5721C12.9262 5.5721 13.054 5.62534 13.1479 5.72L13.8479 6.43C13.9426 6.52388 13.9958 6.65168 13.9958 6.785C13.9958 6.91832 13.9426 7.04612 13.8479 7.14L8.7179 12.28Z" fill="%233E4A5A"/></g><defs><clipPath id="clip0_15417_12101"><rect width="16" height="16" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>');
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 44px 24px;
    }

    input.jobBoard__fieldItem__search {
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 40px;
      box-sizing: border-box;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='jmizk30oca' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg clip-path='url(%23k0omiwei5b)' fill='black'%3E%3Cpath d='M10.4 5.6a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6zm0-1.6a6.4 6.4 0 1 1 0 12.8 6.4 6.4 0 0 1 0-12.8z'/%3E%3Cpath d='M13.834 14.966a.8.8 0 0 1 1.132-1.132l4.8 4.8a.8.8 0 0 1-1.132 1.132l-4.8-4.8z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='k0omiwei5b'%3E%3Cpath fill='%23fff' transform='translate(4 4)' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }

    &:nth-child(2) {
      margin: 16px 0;
    }
  }

  .g-col {
    width: 100%;
  }

  &__card {
    padding: 24px;
    background-color: c.$neutral-accent-background;
    box-sizing: border-box;
    border-radius: 16px;

    &:hover {
      cursor: pointer;
    }

    h6 {
      @extend %clamped-text-base;

      -webkit-line-clamp: 1;
      color: c.$dark-default;
      margin: 5px 0 16px 0;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    p {
      color: c.$dark-80;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__detail {
    display: flex;
    align-items: center;
    gap:6px;
  }

  &__pagination {
    margin: 40px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__paginationBtns {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__paginationBtn {
    height: 40px;
    border: none;
    border-radius: 8px;
    background-color: transparent;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: inherit;
    text-decoration: none;

    &--active {
      background-color: c.$neutral-accent-default;
      color: c.$dark-default;
      font-weight: 700;
    }
  }

  &__paginationArrow {
    width: 32px;
    height: 32px;
    border: none;
    color: c.$dark-default;
    font-size: 32px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    display: grid;
    align-content: center;
    justify-content: center;

    svg {
      transform: rotate(90deg);
      width: 32px;
      height: 32px;
    }

    &--disable {
      color: c.$dark-60;
      pointer-events: none;
    }
  }

  &__nextPaginationArrow {
    @extend .jobBoard__paginationArrow;

    svg {
      transform: rotate(-90deg);
    }
  }

  &__positions {
    color: c.$dark-80;
    margin-top: 10px;
  }

  &__noMatch,
  &__error {
    text-align: center;
    color: c.$dark-default;
    font-weight: 500;
    line-height: 1.52;
  }
}

.medium-view {
  .jobBoard {
    .g-container {
      margin: 0 72px;
      .jobBoard__content {
        h2 {
          font-size: 40px;
          font-weight: 600;
          line-height: 48px;
        }
      }

      .jobBoard__searchFields {
        display: grid;
        margin-bottom: 24px;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 24px;
      }
      .jobBoard__list {
        .g-row.g-row--2-columns {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px 24px;
        }
      }

      .jobBoard__fieldItem {
        &:nth-child(1) {
          grid-column: span 2;
        }
        &:nth-child(2) {
          margin: 0;
        }
      }
    }
  }
}

.large-view {
  .jobBoard {
    .jobBoard__list .g-row--2-columns .g-col {
      @extend .g-col--horiz-space;
      width: 100%;
    }

    .g-container {
      .jobBoard__content {
        h2 {
          font-weight: 600;
          text-align: center;
          font-size: 48px;
          font-style: normal;
          line-height: 56px;
        }
      }

      .jobBoard__searchFields {
        display: grid;

        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        align-items: end;
      }
      .jobBoard__list {
        .g-row.g-row--2-columns {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px 24px;
          width: 100%;
        }
      }

      .jobBoard__fieldItem {
        &:nth-child(2) {
          margin: 0px;
        }
      }

      .jobBoard__info {
        flex-direction: row;
        gap: 56px;
      }
    }
  }
}

[dir="rtl"] {
  .jobBoard__content .jobBoard__fieldItem select {
      padding-right: 35px;
  }
}