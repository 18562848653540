@use "../../global-styles/palette" as c;

.text-and-image-lp {
    display: flex;
    gap: 40px;

    &__text-container {
        max-width: 544px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
            color: c.$dark-90;
            font-weight: 700;
            line-height: 110%;
        }
    }

    &__text-description {
        color: c.$dark-80;
        max-width: 498px;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
    }

    &__image-container {
        background-color: #F2F6F6;
        border-radius: 29px;

        img {
            width: 544px;
        }
    }
}

.medium-view {
    .text-and-image-lp {
        flex-direction: column-reverse;

        &__image-container {
            display: flex;
            justify-content: center;
            margin: 0px auto;
            width: 664px;
        }

        &__text-container {
            max-width: unset;

            h2 {
                font-size: 34px;
            }
        }

        &__text-description {
            max-width: 665px;
            font-size: 16px;
        }
    }
}

.small-view {
    .text-and-image-lp {
        flex-direction: column-reverse;

        &__image-container {
            
            img {
                width: 335px;
            }
        }

        &__text-container {

            h2 {
                font-size: 34px;
            }
        }

        &__text-description {
            font-size: 16px;
            font-weight: 400;
        }
    }
}

.large-view {
    .image-right {
        flex-direction: row;
    }

    .image-left {
        flex-direction: row-reverse;
        gap: 60px;
    }
}