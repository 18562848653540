@use "../../../global-styles/palette" as c;

.license-root {
    display: flex;
    flex-direction: column;
    align-items: center;

    .h3-bold-small {
        color: c.$dark-default;
        margin-block: 10px 4px;
        line-height: 20px;
    }

    p.license-root__description {
        color: c.$gray-font-color;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.4px;
    }
}

.medium-view {
    .license-root {
        flex-direction: row;
        column-gap: 10px;

        .h3-bold-small {
            line-height: 24px;
            margin: 0;
        }

        p.license-root__description {
            text-align: start;
        }
    }
}

.large-view {
    .license-root {
        max-width: 270px;
        flex-direction: row;
        column-gap: 10px;

        .h3-bold-small {
            margin: 0;
            font-size: 24px;
            line-height: 24px;
        }

        p.license-root__description {
            text-align: start;
        }
    }
}