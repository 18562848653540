@use "../../global-styles/palette" as c;

.login-tabs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    margin: 40px 0px 113px 0px;
    
    .active-button {
        background-color: c.$dark-default;
        border: 1px solid c.$dark-default;
        color: c.$pure-white;
        svg {
            stroke: c.$pure-white;
        }
    }

    .active-button:hover {
        background-color: c.$dark-default;
        border: 1px solid c.$dark-default;
        color: c.$pure-white;
        cursor: pointer;
    }

    &__button-box {
        width: 309px;
        height: 90px;
        padding: 12px 24px;
        border-radius: 8px;
        border: 1px solid c.$neutral-accent-default;
        background-color: c.$neutral-accent-background;
        color: c.$dark-80;
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        font-family: 'Figtree', sans-serif;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        img {
            margin-right: 12px;
            max-width: 32px;
        }

        svg {
            position: absolute;
            stroke: c.$dark-80;
            right: 24px;
        }
    }

    &__button-box:dir(rtl) {
        justify-content: flex-end;
        gap: 12px;
    }

    &__button-box:hover {
        border: 1px solid c.$pure-white;
        background-color: c.$pure-white;
        color: c.$dark-80;
        cursor: pointer;
    }

    &__tabs-wrapper {
        position: absolute;
        top: 0%;
        right: 0%;
        padding: 40px;
        border-radius: 8px;
        border: 1px solid c.$neutral-accent-default;
        width: 56%; // ~ 630px
    }

    &__tabs-wrapper:dir(rtl) {
        left: 0%;
        right: unset;
    }

    &__desktop-tabs {
        margin-bottom: 40px;
    }

    &__desktop-tabs--list {
        display: flex;
        flex-direction: column;
        gap: 17px;
    }

    &__desktop-tabs--title,
    &__mobile-tabs--title {
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
        color: c.$dark-60;
        margin-bottom: 20px;
    }

    &__desktop-tabs--item {
        display: flex;
        gap: 20px;
    }

    &__mobile-tabs--list {
        display: flex;
        flex-direction: column;
        gap: 17px;
    }

    &__mobile-tabs--item {
        display: flex;
        gap: 20px;
    }
}

.large-view {
    .login-tabs-container {
        &__desktop-tabs--title,
        &__mobile-tabs--title {
            font-size: 14px;
            font-weight: 700;
            line-height: 32px;
        }

        &__desktop-tabs--item,
        &__mobile-tabs--item {
            a {
                font-size: 20px;
                font-weight: 500;
                line-height: 120%;
                text-decoration: none;
                color: c.$pure-black;
            }
        }
    }
}

.medium-view {
    .login-tabs-container {
        margin: 40px 0px 113px 0px;

        &__tabs-wrapper {
            width: 50%;
        }

        &__desktop-tabs--title,
        &__mobile-tabs--title {
            font-size: 14px;
            font-weight: 700;
            line-height: 32px;
        }

        &__desktop-tabs--item,
        &__mobile-tabs--item {
            a {
                font-size: 20px;
                text-decoration: none;
                color: c.$pure-black;
            }
        }
    }
}

@media (max-width: 880px) {
    .medium-view {
        .login-tabs-container {
            &__tabs-wrapper {
                width: 37%;
            }

            &__button-box {
                width: 44%;
            }
        }
    }
}

.small-view {
    .login-tabs-container {
        margin: 40px 0px 0px 0px;

        &__tabs-wrapper {
            position: unset;
            width: unset;
            padding: 20px 0px 20px 20px;
        }

        &__button-box {
            width: 100%;
            height: 61px;
            margin-bottom: 12px;
            font-size: 22px;

            svg {
                transform: rotate(90deg);
            }
        }

        &__desktop-tabs--title,
        &__mobile-tabs--title {
            font-size: 14px;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 17px;
        }

        &__desktop-tabs--item,
        &__mobile-tabs--item {

            a {
                font-size: 18px;
                text-decoration: none;
                color: c.$pure-black;
            }
        }
    }
}