@use "../../global-styles/palette" as c;

.instrument-header {
    display: flex;
    flex-wrap: wrap;

    > * {
        width: 100%;
    }

    &__instrument {
        align-self: flex-start;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 12px;
        row-gap: 12px;

        &-name,
        &-name:is(h1) {
            font-size: 28px;
            font-weight: 700;
            color: c.$dark-default;
            line-height: 1.5;
        }

        &-code {
            font-size: 16px;
            font-weight: 500;
            color: c.$dark-80;
            line-height: normal;
        }

        &-currency {
            width: 100%;
            color: c.$dark-60;

            img {
                vertical-align: middle;
            }
        }
    }

    &__price {
        display: flex;
        gap: 12px;
        margin-top: 32px;
        font-weight: 700;

        &-current {
            font-size: 42px;
            line-height: 1;
            color: c.$dark-default;
        }

        &-change {
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 20px;
            line-height: 1.2;
            color: var(--price-change-color);

            &.instrument-header_change--positive {
                --price-change-color: #{c.$support-up};

                &::after {
                    border-bottom: 4px solid var(--price-change-color);
                }
            }

            &.instrument-header_change--negative {
                --price-change-color: #{c.$support-down};

                &::after {
                    border-top: 4px solid var(--price-change-color);
                }
            }

            &::after {
                content: "";
                display: block;
                width: 0; 
                height: 0; 
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }
        }
    }

    &__price-history {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        margin-top: 24px;

        &-range {
            --range-bar-width: 85px;

            display: grid;
            grid-template-columns: auto min-content min-content min-content;
            align-items: center;
            column-gap: 8px;
            row-gap: 12px;

            &-title {
                color: c.$dark-60;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.3;
            }

            &-bar {
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                height: 8px;

                &::before {
                    content: "";
                    display: block;
                    width: var(--range-bar-width);
                    height: 2px;
                    background-color: c.$dark-10;
                }

                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    inset: 0;
                    width: 2px;
                    height: 8px;
                    margin: auto;
                    margin-inline-start: var(--offset-from-start);
                    background-color: #000;
                }

                &--disabled::after {
                    content: none;
                }

                &--fullbar::before {
                    background-color: #000;
                }

                &--fullbar::after {
                    content: none;
                }
            }

            &-value {
                color: c.$dark-default;
                font-weight: 700;
                line-height: 1.7;
                text-align: end;
            }
        }

        &-openclose {
            display: flex;
            justify-content: space-between;

            > :first-child {
                color: c.$dark-60;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.3;
            }

            > :nth-child(2) {
                color: c.$dark-default;
                font-weight: 700;
                line-height: 1.7;
                text-align: end;
            }
        }
    }

    &__quotes {
        $border-radius: 8px;

        display: flex;
        column-gap: 2px;
        margin-top: 12px;
        color: c.$pure-white;

        > * {
            flex: 1 1 0;
            min-width: 120px;
            padding: 8px 12px;
        }

        > :first-child {
            text-align: start;
            border-start-start-radius: $border-radius;
            border-end-start-radius: $border-radius;
        }

        > :last-child {
            text-align: end;
            border-start-end-radius: $border-radius;
            border-end-end-radius: $border-radius;
        }

        > * > :first-child {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            text-transform: uppercase;
        }
        
        > * > :last-child {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
        }

        &-quote {
            --price-change-color: #{c.$support-up};

            background-color: var(--price-change-color);

            &.instrument-header_change--positive {
                --price-change-color: #{c.$support-up};
            }
    
            &.instrument-header_change--negative {
                --price-change-color: #{c.$support-down};
            }
        }
    }

}

.small-view,
.medium-view,
.large-view {
    .instrument-header__instrument-name {
        @extend .instrument-header__instrument-name;
    }
}

.medium-view,
.large-view {
    .instrument-header {
        &__price-history {
            --inter-item-spacing: 10px;

            flex-direction: row;
            flex-wrap: wrap;

            &-range {
                grid-template-columns: repeat(6, min-content);
                gap: 0;
    
                & > :first-child {
                    grid-column: 1 / 4;
                    grid-row: 1 / 2;
                }
    
                & > :nth-child(5) {
                    grid-column: 4 / -1;
                    grid-row: 1 / 2;
                }

                > :nth-child(1),
                > :nth-child(4) {
                    padding-inline-end: var(--inter-item-spacing);
                }

                > :nth-child(5),
                > :nth-child(8) {
                    padding-inline-end: var(--inter-item-spacing);
                }

                > :nth-child(5),
                > :nth-child(6) {
                    border-inline-start: 1px solid c.$dark-10;
                    padding-inline-start: var(--inter-item-spacing);
                }

                &-value {
                    text-align: start;
                }

                &-bar {
                    margin-inline: 8px;
                }
            }
    
            &-openclose {
                flex-direction: column;
                border-inline-start: 1px solid c.$dark-10;
                padding-inline: var(--inter-item-spacing);

                & + .instrument-header__price-history-openclose {
                    padding-inline-end: 0;
                }

                > :nth-child(2) {
                    text-align: start;
                }

            }
        }

        &__quotes {
            width: auto;
        }
    }
}

.medium-view {
    .instrument-header {
        &-container {
            max-width: 600px;
            margin-inline: auto !important;
        }
        
        &__price-history-range {
            --range-bar-width: 75px;
        }
    }
}

.large-view {
    .instrument-header {
        &__price-history-range {
            --range-bar-width: 100px;
        }
    }
}

.large-view {
    .instrument-header {
        justify-content: space-between;
        align-items: flex-start;

        &__price-history {
            width: auto;
        }
    }
}