@use "../../../global-styles/palette" as c;

.cards-deck-info-box {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;

    &__inner {
        display: flex;
        justify-content: stretch;
        align-self: stretch;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        padding: 20px;
        margin-inline: auto;
        border-radius: 8px;
        border: 1px solid c.$dark-20;
        color: c.$dark-default;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;


        &::before {
            flex-shrink: 0;
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><g clip-path="url(%23clip0_491_24270)"><path d="M10 5.8334V10.8334" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 14.1751L10.0083 14.1658" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.99999 18.3334C14.6023 18.3334 18.3333 14.6024 18.3333 10.0001C18.3333 5.39768 14.6023 1.66673 9.99999 1.66673C5.39761 1.66673 1.66666 5.39768 1.66666 10.0001C1.66666 14.6024 5.39761 18.3334 9.99999 18.3334Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_491_24270"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>');
        }
    }



    &__content &__title {
        font-weight: 600;
        font-size: inherit;
        color: inherit;
        line-height: inherit;
    }

    &__content &__text {
        p + p {
            margin-top: 10px;
        }

        a {
            color: c.$tm-green-120;
            text-decoration-line: underline;
        }
    }
}

.medium-view,
.large-view {
    .cards-deck-info-box {
        max-width: 900px;
        width: 80%;

        &--full-width {
            width: 100%;

            .cards-deck-info-box__inner {
                margin-inline: 0;
            }
        }

        &--width-auto {
            width: auto;
            max-width: none;

            .cards-deck-info-box__inner {
                width: auto;
                margin-inline: 0;
            }
        }
    }
}