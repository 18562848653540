@use "./AlphabetsSearchCard/AlphabetsSearchCard";
@use "../../global-styles/palette" as c;


.alphabets-search {

    &__title-container {
        margin-bottom: 24px;

        p {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            color: c.$dark-default;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 8px;
    }

}