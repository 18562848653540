@use "../../../global-styles/palette" as v;

.footer-banner {
    background-color: v.$dark-10;
    display: none;
    justify-content: center;
}

.small-view, .medium-view {
    .footer-banner {
        padding: 18px 0px;
    }
}