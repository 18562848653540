@use "../../global-styles/palette" as c;

.card-with-animation-root {
  .g-container {
    h2 {
      color: c.$dark-default;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 24px;
    }

    p {
      color: c.$dark-80;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 24px;
    }
  }
}

.medium-view {
  .card-with-animation-root {
    .g-container {
      h2 {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
}

.large-view {
  .card-with-animation-root {
    .g-container {
      display: grid;
      grid-template-areas:
        "titleArea animationArea"
        "contentArea animationArea";

      h2 {
        grid-area: titleArea;
        width: 400px;
        font-size: 48px;
        line-height: 56px;
        text-align: left;
        margin: 0 0 16px 0;
      }

      .card-with-animation-root__animation {
        grid-area: animationArea;
      }

      p {
        grid-area: contentArea;
        text-align: left;
        width: 560px;
        margin: 0 100px 0 0;
      }
    }
  }
}
