@use "../../../global-styles/palette" as v;

.articleBanner.staticBanner{
  height: auto;
  .staticBanner__breadcrumbs{
    margin-bottom: 32px;
  }
}
.articleBanner__categories{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px auto 32px;
  a{
    background-color: v.$neutral-accent-default;
    padding: 4px 16px;
    border-radius: 100px;
    color: v.$dark-80;
    text-decoration: none;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.articleBanner{
  &__tag{
    display: inline-block;
    border-radius: 4px;
    padding: 0 6px;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    font-weight: 400;
    margin-bottom: 16px;
    &--red{
      color: rgba(75, 180, 72, 1);
      background-color: rgba(75, 180, 72, 0.1);
    }
    &--yellow{
      color: rgba(252, 139, 5, 1);
      background-color: rgba(252, 139, 5, 0.1);
    }
  }
}

.medium-view,.small-view{
  .articleBanner.staticBanner{
    padding: 24px 0;
  }
}