@use "../../global-styles/palette" as c;

.custom-p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    text-decoration: none;
    font-weight: bold;
}

.contact-form-banner {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    background-color: c.$dark-90;
    border-radius: 20px;
    padding: 32px;

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 472px;
        padding: 20px;
        color: c.$pure-white;
    }

    &__form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 448px;
        padding: 32px;
        border-radius: 12px;
        background: c.$pure-white;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    }

    &__field {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 24px;
    
        &--checkbox {
          flex-direction: row;
          align-items: center;
          justify-content: center;
    
          .contact-form__required-error {
            margin-left: 15px;
          }
        }
    
        input,
        textarea,
        select {
          font-size: 16px;
          padding: 16px;
          border: 1px solid #F4F5F5;
          border-radius: 8px;
          font-weight: 500;
          color: c.$dark-60;
          background-color: #F4F5F5;
        }
    
        .selected-file {
          margin-top: 7px;
          font-size: 16px;
          padding: 16px;
          border: 1px solid c.$dark-20;
          border-radius: 4px;
          font-weight: 500;
          color: c.$dark-60;
          background-color: c.$dark-10;
          position: relative;
        }
    
        .delete-icon {
          position: absolute;
          top: 55%;
          right: 15px;
          transform: translateY(-50%);
          cursor: pointer;
        }
    
        textarea {
          resize: none;
        }
    
        select {
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.29 11.29a.996.996 0 0 0-1.41 0L12 15.17l-3.88-3.88a.997.997 0 1 0-1.41 1.41l4.59 4.59a.994.994 0 0 0 1.41 0l4.59-4.59c.38-.38.38-1.02-.01-1.41z' fill='%236e7783'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: 95%;
          background-color: #F4F5F5;
          border: 1px solid #F4F5F5;
          border-radius: 8px;
        }
    
        input[type="checkbox"] {
          opacity: 0.5;
          width: 20px;
          margin: 0 8px 0 8px;
        }
    
        input[type="number"] {
          appearance: textfield;
          -moz-appearance: textfield;
        }
    }
    &__label {
        @extend .custom-p;
        margin-bottom: 8px;
        &--checkbox {
          color: c.$dark-default;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
        }
    
        &--required {
          position: relative;
          align-self: flex-start;
        }
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0em;
        text-align: left;
    }

    &__button {
        .button {
            display: flex;
            margin: unset;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
        }

        .button--primary {
            color: c.$dark-default;
        }
    }

}

.large-view {
    .contact-form-banner {
        h3 {
            font-size: 38px;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 12px;
        }

        &__text-wrapper {
            &__subtitle {
                font-size: 18px;
                font-weight: 400;
                line-height: 150%;
                margin-bottom: 70px;
            }
        }
    }
}

.small-view {
    .contact-form-banner {
        flex-direction: column;
        gap: unset;
        padding: 40px 12px 12px 12px;

        h3 {
            font-size: 26px;
            margin-bottom: 4px;
            text-align: center;
        }

        &__text-wrapper {
            margin: 0px auto;
            padding: 0px 0px 20px 0px;
            &__subtitle {
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
                text-align: center;
                // margin-bottom: 70px;
            }
        }

        &__form-wrapper {
            width: unset;
            padding: 24px;
        }
    }
}
.medium-view {
    .contact-form-banner {
        flex-direction: column;
        gap: unset;

        h3 {
            font-size: 26px;
            margin-bottom: 8px;
            text-align: center;
        }

        &__text-wrapper {
            margin: 0px auto;
            &__subtitle {
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
                text-align: center;
                // margin-bottom: 70px;
            }
        }

        &__form-wrapper {
            width: unset;
        }
    }
}