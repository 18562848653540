@use "../../../global-styles/palette" as c;

.podcast-episode-banner {
    &__image-and-content-container {
        display: flex;
        gap: 24px;

        svg {
            transform: translateY(4px);
        }

    & > div {
      & > div {
        & > ul {
          list-style: disc;
          padding-inline-start: 20px;

          [dir="rtl"] & {
            padding-inline-start: 25px;
          }
        }
      }
    }
  }

    &__image-and-content-container>* {
        flex: 1;
    }

    img {
        border-radius: 30px;
    }

    h3,
    h4 {
        color: c.$dark-default;
    }

    &__responsive-image {
        max-width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.small-view,
.medium-view {
    .podcast-episode-banner {
        &__title {
            display: flex;
            place-content: center;
            place-items: center;
        }

        h3,
        h4 {
            text-align: center;
        }

        img {
            border-radius: 20px;
        }

        &__image-and-content-container {
            flex-direction: column;
            gap: 16px;
        }
    }
}