@use "../../../global-styles/palette" as c;

.instrument-category-deck {
  &__card-link {
    text-decoration: none;
    color: inherit;
  }

  &__title-containr {
    padding-bottom: 16px;

    h5 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__title{
    margin-bottom:8px ;
  }
  &__card:hover {
    background-image: var(--instrument-category-deck-icon-nest-hover);
  }

  &__card {
    background-image: var(--instrument-category-deck-icon-nest);
    transition: background-image 0.2s;
    flex-direction: column;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 140px;
    height: 100px;
    margin-top: 20px;
  }

  &__cards-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &__icon-vertical-container {
    display: flex;
  }

  &__icon-img-container {
    width: 30px;
  }

  &__icon-img-container>img {
    transform: translateY(-22px);
    transition: transform 0.2s;
  }

  &__card-title-container {
    margin-bottom: 13px;
  }

  &__card:hover .instrument-category-deck__icon-img-container>img {
    transform: translateY(-26px);
    transition: transform 0.2s;
  }

  &__second-component-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: c.$neutral-accent-background;
    padding: 16px 30px;
    border-radius: 24px;
    margin: 24px 10px 0px 10px;

    h5 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  }

  &__second-component-button {

    a {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 8px 20px;
      border: 1px solid c.$dark-default;
      width: 184px;
      height: 31px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      background-color: transparent;
      text-decoration: none;
      color: c.$dark-default;

      img {
        margin-right: 8px;
        [dir="rtl"] & {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }

    a:hover {
      cursor: pointer;
      color: c.$pure-white;;
      background-color: c.$dark-default;
    }
  }

}

.medium-view {
  .instrument-category-deck {
    &__title-containr {
      max-width: 600px;
      margin: 0 auto;
    }
    &__second-component-container {
      flex-direction: column;
      padding: 32px 40px;
      gap: 32px;
      max-width: 520px;
      margin: 32px auto;
    }

    &__second-component-title {
      max-width: 520px;
      margin: 0 auto;
    }
  }
}

.small-view {
  .instrument-category-deck {
    &__second-component-container {
      flex-direction: column;
      margin: 32px 0 0 0;
      gap: 32px;
      padding: 24px;
    }
    &__second-component-title {

      h5 {
        font-size: 22px;
      }
    }
  }
}