@use "../../global-styles/palette" as c;
@use "../../global-styles/typography";

.demoAccountTabsSlider {
  &__title {
    margin: 0px auto 32px auto;
    max-width: 744px;
  }

  &__bullets {
    @extend %slider-tab-text;
  }

  > p{
    text-align: center;
    margin: 16px auto 24px auto;
    max-width: 744px;
  }
  .careerTabsSlider__card-text{
    display: flex;
    flex-direction: column;
  }
  .careerTabsSlider__card{
    padding: 40px 44px;
    flex-direction: row;
    p{
      text-align: left;
      margin-top: 8px;
    }
  }
  img{
    position: static;
    transform: none;
    align-self: baseline;
    width: 264px;
    border-radius: 16px;
  }
  a {
    margin-top: auto;
    text-decoration: none;
    color: c.$blue-lagoon-default;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}
.large-view{
  .demoAccountTabsSlider {
    .careerTabsSlider__card-text{
      padding-left: 80px;
    }
  }
}
.small-view,.medium-view{
  .demoAccountTabsSlider {
    h4{
      text-align: center;
      padding: 16px 0;
    }
    .careerTabsSlider__card{
      flex-direction: column;
      height: 100%;
    }
    .careerTabsSlider__card-text{
      height: 100%;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
}