@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";


.popular-instruments {
    .centered-container {
        flex-direction: column;

        .button-v2 {
            margin-bottom: 14px;
        }
    }

    &__grid {
        display: grid;
        margin-top: 40px;
        margin-bottom: 40px;
    }

 

    &__title-container {
        width: 100%;

        h2 {
            text-align: center;
        }
    }

    &__bottom-link {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
    }

    &__instrument-container {
        display: grid;
        grid-template-columns: 30px 1fr;
        background-color: c.$neutral-accent-background;
        grid-template-rows: 1fr 1fr;
        gap: 3px;
        padding: 9px 12px 3px 6px;
        border-radius: 8px;
    }

    &__arrow-icon {
        grid-column: 3/4;
        grid-row: 2/3;
    }

    &__arrow-icon svg {
        transform: translateY(-15px);
    }


    &__instrument-container:hover &__description {
        visibility: visible;
        opacity: 1;
    }

    &__instrument-container:hover &__arrow-icon {
        visibility: visible;
        opacity: 1;
    }

    &__description {
        grid-column: 2 / 3;
    }


}

.large-view {
    .popular-instruments {
        &__grid {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 12px;
            &--three-cols{
                grid-template-columns: 264px 264px 264px;
                justify-content: space-between;
            }
        }
        &__grid-item{
            max-width: 264px;
        }

        &__description,
        &__arrow-icon {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &__instrument-container:not(:hover) {

            background-color: c.$pure-white;
        }
    }
}

.medium-view {
    .popular-instruments {
        &__grid {
            grid-template-columns: 1fr 1fr;
            gap: 12px;
        }
    }
}

.small-view {
    .popular-instruments {
        &__grid {
            grid-template-columns: 1fr;
            gap: 12px;
            margin-top: 28px;
        }

        &__title-container h2 {
            max-width: 95vw;
        }
    }
}