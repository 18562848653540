@use "../../../../../../global-styles/palette" as c;

.numbered-swipe-card--app-download {
    $inset: 24px;

    position: absolute;
    right: $inset;
    bottom: $inset;
    left: $inset;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: c.$pure-white;
    border-radius: 16px;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.08), 0px -17px 17px 0px rgba(0, 0, 0, 0.07), 0px -39px 24px 0px rgba(0, 0, 0, 0.04), 0px -70px 28px 0px rgba(0, 0, 0, 0.01), 0px -109px 30px 0px rgba(0, 0, 0, 0.00);

    p {
        margin-top: 0;
        margin-bottom: 0;
    }

    a {
        display: block;
    }

    img {
        display: block;
        max-width: 100%;
    }

    .numbered-swipe-card--app-download__desktop,
    .numbered-swipe-card--app-download__mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .numbered-swipe-card--app-download__desktop {
        img {
            width: 72px;
            height: 72px;
        }
    }

    .numbered-swipe-card--app-download__mobile {
        gap: 16px;

        a img {
            width: 152px;
            height: 48px;
        }
    }

    @media(pointer: coarse) {
        .numbered-swipe-card--app-download__desktop {
            display: none;
        }
    }

    @media(pointer: fine) {
        .numbered-swipe-card--app-download__mobile {
            display: none;
        }
    }

}