@use "../../../global-styles/palette" as c;

.market-card-root {
    background-image: var(--background-image-url-mobile);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    max-width: 165px;
    width: 100%;
    height: 206px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    .h4-v2dot6{
        font-size: 22px;
    }

    p.caption-risk-warning-bold {
        color: c.$tm-card-text-color-light;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        display: none;

        border-radius: 8px;
        background: rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(4px);
    }

    &__title-wrapper {
        padding: 0 12px 20px;
        margin-top: auto;

        h4 {
            color: c.$pure-white;
            margin-bottom: 4px;
        }

        p.market-card-root__title-wrapper__subtitle {
            color: rgba(255, 255, 255, 0.80);
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
        }
    }


    .button-v2-wrapper {
        width: 100%;

        .market-card-root__button {
            display: flex;
            text-decoration: none;
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            width: 100%;
            background: rgba(255, 255, 255, 0.20);
            backdrop-filter: blur(6px);
            border-radius: 18px;
            justify-content: center;
            margin-inline: 12px;
            padding-block: 12px;
            color: c.$pure-white;
        }
    }
}

.medium-view {
    .market-card-root {
        background-image: var(--background-image-url-desktop);
        width: 50%;
        max-width: 400px;
        height: 206px;
        background-size: 100% 100%;
        
        .h4-v2dot6{
            font-size: 24px;
        }
        &__title-wrapper {
            padding: 0 16px 20px;

            h4.h4-semi-bold {
                font-size: 22px;
            }
        }
    }
}
.small-view {
    .market-card-root {
        background-size: cover;
        text-decoration: none;
        .button-v2-wrapper{
            display: none;
        }
        &__title-wrapper {
            h4.h4-semi-bold {
                font-size: 18px;
            }
        }
    }
}

.large-view {
    .market-card-root {
        background-image: var(--background-image-url-desktop);
        background-size: auto 105%;
        max-width: 320px;
        height: 100%;
        box-sizing: border-box;
        padding: 12px 20px 20px 12px;
        transition: .5s ease;
        .h4-v2dot6{
            font-size: 24px;
        }
        .button-v2-wrapper{
            max-height: 0;
            opacity: 0;
            transition: .5s ease;
        }
        &:hover{
            background-size: auto 100%;
            transition: .5s ease;
            .button-v2-wrapper{
                max-height: 38px;
                opacity: 1;
                transition: .5s ease;
            }
        }

        p.caption-risk-warning-bold {
            display: flex;
            border-radius: 8px;
            backdrop-filter: blur(4px);
            width: max-content;
            padding: 8px 10px;
        }

        &__title-wrapper {
            padding: 0 0 0 12px;
            margin-bottom: 16px;

            h4.h4-semi-bold {
                line-height: 28.8px;
            }
        }
    }
}