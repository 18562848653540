@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/layout";


.vps-provider-card {
  display: grid;
  gap: 32px 24px;
  line-height: 24px;

  &__header {
    grid-column: 1/2;
  }

  &__specifications {
    grid-row: 1/3;
    grid-column: 2/3;
  }

  &__footer {
    grid-column: 1/2;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px 24px;

    img {
      display: block;
      height: 48px;
      max-width: 100%;
    }
  }

  &__key-features {
    display: grid;
    justify-content: center;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, auto);
    column-gap: 80px;

    padding: 8px 32px;
    border-style: solid none;
    border-width: 1px;
    border-color: #D3E2E2;

    &-name {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      color: c.$dark-60;
    }

    &-value {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__specifications:not(:empty) {
    padding: 0 v.$gutter-large;
  }

  &__specifications ul li {
    display: flex;
    align-items: center;
    gap: 12px;
    color: c.$dark-default;
    font-size: 18px;
    font-weight: 500;

    & + li {
      margin-top: 24px;
    }
  }

  &__specifications ul li:before {
    flex-shrink: 0;
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_9148_1258)"><path d="M7.07692 0H16.9231C20.8246 0 24 3.17538 24 7.07692V16.9231C24 20.8246 20.8246 24 16.9231 24H7.07692C3.17538 24 0 20.8246 0 16.9231V7.07692C0 3.17538 3.17538 0 7.07692 0Z" fill="%230E1D31"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18.8462 7.2021C19.0476 7.40364 19.1608 7.67694 19.1608 7.96192C19.1608 8.24689 19.0476 8.5202 18.8462 8.72173L10.7909 16.777C10.6844 16.8835 10.5581 16.9679 10.419 17.0256C10.2799 17.0832 10.1308 17.1129 9.98021 17.1129C9.82965 17.1129 9.68056 17.0832 9.54146 17.0256C9.40236 16.9679 9.27598 16.8835 9.16953 16.777L5.16733 12.7755C5.06468 12.6764 4.98281 12.5578 4.92649 12.4267C4.87016 12.2956 4.84051 12.1545 4.83927 12.0118C4.83803 11.8691 4.86523 11.7276 4.91926 11.5955C4.9733 11.4635 5.0531 11.3435 5.15401 11.2426C5.25491 11.1417 5.37491 11.0619 5.50698 11.0078C5.63906 10.9538 5.78058 10.9266 5.92328 10.9278C6.06597 10.9291 6.207 10.9587 6.33812 11.015C6.46923 11.0714 6.58782 11.1532 6.68696 11.2559L9.97985 14.5488L17.3258 7.2021C17.4256 7.10223 17.5441 7.02301 17.6746 6.96896C17.805 6.91491 17.9448 6.88708 18.086 6.88708C18.2272 6.88708 18.367 6.91491 18.4974 6.96896C18.6279 7.02301 18.7464 7.10223 18.8462 7.2021Z" fill="%235EE15A"/></g><defs><clipPath id="clip0_9148_1258"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>')
  }

  &__warning {
    margin: v.$gutter-large 0 8px;
    font-size: 12px;
    color: c.$dark-80;

    strong,
    b {
      font-weight: 700;
    }

    a {
      color: c.$dark-default;
    }
  }
}

.small-view,
.medium-view {
  .vps-provider-card {
    &__header,
    &__specifications,
    &__footer {
      grid-row: auto;
      grid-column: auto;
    }

    &__specifications:not(:empty) {
      padding: 0;
    }
  }
}

.small-view {
  .vps-provider-card {
    &__logo {
      img {
        height: 32px;
      }
    }
  }
}