@use "../../../../global-styles/palette" as c;

.simplelightbox--on .video-card-deck-slider-card__text-container {
    display: none!important;
}

.video-card-deck-slider-card {
    &__slide-upload-time {
        display: flex;
        gap: 4px;
    }
}