@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;
@use "./AccountCard/AccountCard";

.account-card__deck{
    .information-card__deck-cards{
        display: flex;
        max-width: 934px;
        overflow-x: auto;
        margin: 0 auto;
    }
    .account-card{
        flex: 1;
        min-width: 284px;
    }
    .information-card__legend{
        margin-top: 40px;
        color: c.$dark-default;
    }
}

.information-card__deck.account-card__deck {
    padding-bottom: 0px;
}

.small-view,.medium-view{
    .account-card__deck{
        .information-card__legend{
            margin-top: 32px;
        }
    }
}