@use "../../global-styles/palette" as c;

.announcements {
    display: flex;
    justify-content: center;

    &__container {
        display: flex;
        flex-direction: column;
        width: 800px;  
    }

    &__text {
        margin-bottom: 40px;
    }

    &__main-title {
        h1 {
            color: c.$dark-default;
            font-size: 38px;
            font-style: normal;
            font-weight: 600;
            line-height: 48px;
        }
    }

    &__main-subtitle {
        h5 {
            color: c.$dark-80;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }

    &__last-announcement {
        display: flex;
        flex-direction: row;
    }

    &__last-announcement-date {
        width: 88px;
        margin: 19.5px 20px 0px 0px;

        p, span {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
        }

        p {
            color: c.$dark-60;
            white-space: nowrap;
        }

        span {
            color: c.$tm-green-120;
        }
    }

    &__last-announcement-text {
        width: 361px;
        margin: 15px 32px 0px 0px;

        a {
            text-decoration: none;

            h3 {
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 35px;
                margin-bottom: 8px;
                color: c.$dark-default;
            }
    
            p {
                color: c.$dark-80;
            }
        }
    }

    &__last-announcement-text-image {
        display: flex;
    }

    &__last-announcement-image {
        img {
            width: 300px;
            height: 200px;
            border-radius: 16px;
        }
    }

    &__list-announcements-item {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #E7E8EA;
    }

    &__list-announcements-item-date {
        width: 88px;

        p {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            color: c.$dark-60;
            white-space: nowrap;
        }
    }

    &__list-announcements-item-title {
        
        a {
            text-decoration: none;
        }

        h4 {
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            color: c.$dark-default;
        }
    }

    &__list-announcements-item-title:hover {
        cursor: pointer;
    }

    &__button-container {
        margin-top: 40px;
    }
}

.medium-view {
    .announcements {
        &__container {
            display: flex;
            flex-direction: column;
            width: 600px;
        }

        &__last-announcement {
            gap: 20px;
        }

        &__last-announcement-text-image {
            flex-direction: column-reverse;
        }

        &__last-announcement-image {
            img {
                width: 492px;
                height: 328px;
            }
        }

        &__last-announcement-text {
            width: 493px;

            a {
                text-decoration: none;
    
                h3 {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px;
                    margin-bottom: 8px;
                    color: c.$dark-default;
                }
        
                p {
                    color: c.$dark-80;
                }
            }
        }

        &__list-announcements-item-title {
             width: 492px;

            a {
                text-decoration: none;
            }
    
            h4 {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                color: c.$dark-default;
            }
        }

        &__button-container {
            margin-top: 32px;
        }
    }
}

.small-view {
    .announcements {
        &__container {
            display: flex;
            flex-direction: column;
            width: 327px;
        }

        &__text {
            margin-bottom: 32px;
        }

        &__last-announcement { 
            display: flex;
            flex-direction: column;
        }

        &__last-announcement-date {
            display: flex;
            margin: unset;
            margin-bottom: 10px;

            p {
                margin-right: 8px;
            }
        }

        &__last-announcement-text-image {
            display: flex;
            flex-direction: column-reverse;
        }

        &__last-announcement-image img {
            width: 327px;
            height: 218px;
        }

        &__last-announcement-text {
            width: 327px;
            margin: unset;
            margin-top: 20px;

            a {
                h3 {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26px;
                }
            }
        }

        &__list-announcements-item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: unset;
        }

        &__list-announcements-item-title h4 {
            font-size: 18px;
            line-height: 24px;
        }

        &__button-container {
            .button-v2-wrapper {
                a {
                    width: 327px;
                }
            }
        }

        &__button-container {
            margin-top: 32px;
        }
        
    }
}