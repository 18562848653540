@use "../../../../global-styles/variables" as v;

.g-col {
    overflow: hidden;
}

.large-view {
    .g-row--2-columns {
        .g-col {
            max-width: v.$max-column-width-2columns;

            &.g-col--horiz-space {
                width: calc(v.$max-column-width-2columns - v.$gutter-large);
                flex-grow: 1;
            }
        }
    }
    .g-row--3-columns {
        .g-col {
            max-width: v.$max-column-width-3columns;
        }
    }
    .g-row--4-columns {
        .g-col {
            max-width: v.$max-column-width-4columns;
        }
    }
    .g-row--5-columns {
        .g-col {
            max-width: v.$max-column-width-5columns;
        }
    }
    .g-row--6-columns {
        .g-col {
            max-width: v.$max-column-width-6columns;
        }
    }
}
