@use "../../global-styles/palette" as c;

.traders-gym {
  background-color: c.$dark-default;

  &__desktop {
    display: contents;
  }

  &__mobile {
    display: none;
  }

  &__inner {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
  }

  &__content-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }

  .button {
    margin: 0;
  }

  h1 {
    color: c.$pure-white;
  }

  p {
    color: c.$pure-white;
    width: 552px;
    height: 112px;
  }


  img {
    width: 1128px;
    height: 492px;
    object-fit: contain;
  }
}

.medium-view,
.small-view {
  .traders-gym {

    &__desktop {
      display: none;
    }

    &__mobile {
      display: contents;
    }

    &__inner {
      margin-top: 64px;
      margin-bottom: 64px;
      align-items: center;
      gap: 32px;
    }

    p {
      width: 327px;
      height: 168px;
    }

    img {
      width: 327px;
      height: 143px;
      justify-self: center;
    }
  }
}