@use "../../global-styles/palette" as c;

.animated-view-with-title-root {
  .g-container {
    .animated-view-with-title-root__contentWrapper {
      display: grid;
      grid-template-areas:
        "animationArea"
        "textsArea";

      & > div:first-child {
        grid-area: animationArea;
      }

      &__textWrapper {
        grid-area: textsArea;
        margin-top: 40px;

        h2 {
          color: c.$dark-90;
          text-align: center;
          margin-bottom: 20px;
        }

        p {
          color: c.$dark-80;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
  }
}

.medium-view {
  .animated-view-with-title-root {
    .g-container {
      margin-inline: 40px;

      .animated-view-with-title-root__contentWrapper {
        display: grid;
        grid-template-areas: "textsArea animationArea";
        align-items: center;

        & > div:first-child {
          min-width: 300px;
          min-height: 300px;
          margin-inline-start: 40px;
        }

        &__textWrapper {
          margin: 0;

          h2 {
            margin-bottom: 23px;
            width: 320px;
            text-align: start;
          }

          p {
            text-align: start;
          }
        }
      }
    }
  }
}

.large-view {
  .animated-view-with-title-root {
    .g-container {
      .animated-view-with-title-root__contentWrapper {
        display: grid;
        grid-template-areas: "textsArea animationArea";
        align-items: center;

        & > div:first-child {
          min-width: 550px;
          min-height: 550px;
          margin-inline-start: 40px;
        }

        &__textWrapper {
          margin: 0;

          h2 {
            margin-bottom: 23px;
            text-align: start;
          }

          p {
            text-align: start;
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
          }
        }
      }
    }
  }
}
