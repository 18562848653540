@use "../../global-styles/palette" as c;
@use "../../global-styles/variables" as v;

@use "./SearchInput/SearchInput";
@use "./SearchResults/SearchResults";
@use "./ContactUs/ContactUs";

.global-search__resultsWrapper {
  .g-container {
    overflow: unset;

    .global-search__results {
      display: flex;
      padding-top: 20px;

      .lds-spinner {
        display: block;
        margin: 20px auto;
        filter: brightness(0.5);
        width: 100%;
        transform: translateX(25%) scale(0.65);
      }

      .contact-us-root {
        display: none;
      }
    }
  }
}

.large-view {
  .global-search__resultsWrapper {
    .g-container {
      .global-search__results {
        .contact-us-root {
          display: grid;
          margin-left: auto;
          row-gap: 24px;
        }
      }
    }
  }
}
