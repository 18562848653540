@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.faq-accordion-list {
    a {
        color: c.$dark-default;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-decoration-line: underline;
        margin: 0 8px 0 8px;
    }

    &__faq-title {
        text-align: left;
    }

    &__flex-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__title-flex {
        gap: 10px;
    }

    &__icon-image {
        width: 100%;
    }

    &__image-container {
        width: 38px;
        height: 38px;
    }

    &__faq-card {

        padding: 20px 0px 10px 0px;
        border-bottom: solid 1px c.$neutral-accent-default;
    }

    &__faq-card:last-child {

        border-bottom: unset;
    }

    &__minus {
        display: none;
    }

    &__minus-stub {
        visibility: hidden;
        height: 0;
    }

    &__plus-minus-text {
        gap: 10px;
    }

    &-list__plus {
        display: inline-block;
    }

    &__faq-card.active {
        .faq-accordion-list__minus {
            display: inline-block;
        }

        .faq-accordion-list__plus {
            display: none;
        }

        .faq-accordion-list__faq-card-answer {
            padding: 6px 0px 0 0;
            transition: .2s ease-in;
            max-height: 100%;
            color: c.$dark-default;
            max-width: 500px;

            ul, ol > li {
                list-style-type: disc;
                list-style-position: inside;
            }

            &:dir(rtl) {
                line-height: 25px;
            }
        }

        svg {
            transform: rotateX(-180deg);
        }
    }

    &__faq-card-question {
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 0;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        color: c.$dark-default;
    }

    &__faq-card-answer {
        color: c.$dark-60;
        max-height: 0;
        transition: .2s ease-out;
        overflow: hidden;
        width: 70vw;

        a {
            margin: 0;
            text-decoration: none;
            color: c.$blue-lagoon-default;
            font-weight: bold;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

.large-view {
    .faq-accordion-list {

        &__flex-container {
            flex-direction: row;
            gap: 20px;
        }

        &__flex-container>* {
            flex: 1;
        }

        &__title-container {
            margin-top: 25px;
        }

        &__faq-card {
            width: 565px;
        }

        &__faq-card-answer {
            width: 512px;
        }
    }
}