@use "../../global-styles/palette" as c;
@use "../../global-styles/typography";
@use "../../global-styles/custom-scrollbar" as scrollbar;

.contract-specs-tabs {
  background-color: c.$pure-white;

  .careerTabsSlider__sliderWrap {
    max-width: 100%;
  }

  .swiper-slide:not(.swiper-slide-active) {
    visibility: hidden;

    .contract-specs-tab__no-results-container svg {
      visibility: visible;
    }
  }

  .spinner-wrapper {
    display: flex;
    place-content: center;
    padding: 40px;
  }

  .lds-spinner {
    --spinner-color: #000;
  }

  .pagination-container {
    background-color: c.$dark-default;
  }

  .careerTabsSlider__bullets {
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: auto;
    min-width: auto;
    padding: 16px 24px 16px 16px;
    background-color: c.$dark-default;
    color: c.$pure-white;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: c.$dark-80;

    &::before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background-image: var(--icon-idle-url);
      background-repeat: no-repeat;
      background-position: center;
    }

    & > * {
      margin: 0;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      line-height: 1.2;
    }

    h2 {
      position: relative;
      font-weight: 700;
      color: transparent;

      &::after {
        content: attr(data-label);
        position: absolute;
        inset: 0;
        font-weight: 400;
        color: c.$pure-white;
      }
    }
  }

  &__error {
    padding: 24px;
  }

  .swiper-pagination-bullet-active {
    background-color: c.$pure-white;
    border-color: c.$pure-white;
    
    &::before {
      background-image: var(--icon-active-url);
    }

    h2 {
      &::after {
        font-weight: 700;
        color: c.$dark-default;
      }
    }
  }
}

.contract-specs-tab {
  width: 100%;
  padding: 40px 0;

  &__no-results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;

    svg + * {
      margin-top: 16px;
    }
  }

  &__important-note {
    margin-bottom: 32px;
  }
}

.small-view,
.medium-view {
  .contract-specs-tabs {
    .pagination-container {
      .g-container {
        margin: 0 auto;
      }
    }
  }
}

.small-view,
.medium-view,
.large-view {
  .price-feed-cs {
      &__table-container {
        --cell-padding-horizontal: 18px;
        --cell-head-padding-vertical: 20px;
        --offset: var(--cell-padding-horizontal);
        --instrument-image-height: 24px;
        --text-distance-from-instrument-image: 8px;

        @include  scrollbar.custom-scrollbar(c.$dark-40, c.$dark-10, 16px);

        max-width: 100%;
        overflow-x: auto;
        border: var(--contract-specs_table-border);
        border-radius: 0 0 var(--contract-specs_table-border-radius) var(--contract-specs_table-border-radius);

        table {
          table-layout: fixed;
          border-collapse: collapse;
          box-sizing: border-box;
          min-width: 100%;
        }

        thead {
          @extend .caption-bold;
          
          color: c.$dark-60;
      
          th {

            & > div {
              padding: var(--cell-head-padding-vertical) var(--cell-padding-horizontal);
              text-align: center;
              text-transform: uppercase;
            }

            &:first-child > div {
              margin-inline-start: calc(-1 * var(--offset));
              padding-inline-start: var(--offset);
              text-align: start;
            }

            &:last-child {
              overflow-x: hidden;
            }

            &:last-child > div {
              margin-inline-end: calc(-1 * var(--offset));
              text-align: end;
            }
          }

          td {
            &::before {
              content: "";
              display: block;
              border-bottom: var(--contract-specs_table-border);
            }

            &:last-child {
              overflow-x: hidden;
            }

            &:first-child::before {
              margin-inline-start: calc(-1 * var(--offset));
            }

            &:last-child::before {
              margin-inline-end: calc(-1 * var(--offset));
            }
          }
        }

        tr {
          border-style: none solid;
          border-width: var(--cell-padding-horizontal);
          border-color: transparent;
        }

        tbody tr {
          @extend .p-regular;
      
          cursor: auto;
          min-height: 75px;
          color: c.$dark-default;
      
          td {
            padding: var(--cell-padding-horizontal);
            text-align: center;
            vertical-align: middle;
            box-sizing: border-box;
            a{
              text-decoration: none;
              color: inherit;
            }
          }
      
          td:first-child {
            white-space: nowrap;
            padding-inline-start: 0;
            text-align: start;
          }
      
          td:last-child {
            padding-inline-end: 0;
            text-align: end;
          }
      
          &:not(:first-child) {
            border-top: var(--contract-specs_table-border);
          }
        }

        td img {
          height: var(--instrument-image-height);
          margin-inline-end: var(--text-distance-from-instrument-image);
          vertical-align: middle;
        }

        td[data-field-name="LastDealingDay"],
        td[data-field-name="Description"],
        td[data-field-name="Ric"],
        th[data-field-name="LastDealingDay"] > div,
        th[data-field-name="Description"] > div,
        th[data-field-name="Ric"] > div {
          text-align: start;
        }

        td[data-field-name="LastDealingDay"] {
          min-width: 300px;
        }

        td[data-field-name="Description"] {
          width: 200px;
        }

        td[data-field-name="TradingHours"] {
          width: 180px;
        }

        td[data-field-name="MaxLeverageProfMT4AndMT5"],
        td[data-field-name="MaxLeverageProfThinkTrader"] {
          width: 140px;
        }

        .price-feed-cs__table--Commodities {
          td[data-field-name="TradingHours"] {
            min-width: 200px;
          }
        }

        .price-feed-cs__table--Futures {
          td[data-field-name="Instrument"] {
            --instrument-image-width: 24px;
            --pseudo-text-indent-around-image: calc(var(--instrument-image-width) + var(--text-distance-from-instrument-image));

            padding-inline-start: var(--pseudo-text-indent-around-image);

            img {
              margin-inline-start: calc(var(--pseudo-text-indent-around-image) * -1);
            }

            img.hide-alt-text + .price-feed-cs__table-instrument-name {
              margin-inline-start: calc(var(--pseudo-text-indent-around-image) * -1);
            }
          }
        }

        td[data-field-name="Instrument"] {
          .price-feed-cs__table-instrument {
            display: flex;
            min-width: 120px;
          }
  
          img {    
            &.hide-alt-text {
              display: none;
            }
          }
        }

      }

      &__cellTitle {
        word-wrap: break-word;
        padding: 4px 0;
      }

      &__headerCell {
        text-align: start;

        &:last-child {
          text-align: end;
        }
      }
    }
}

.small-view,
.medium-view {
  .price-feed-cs__cellContainer {
    &:last-child {
      display: block;
    }
  }

  .price-feed-cs__headerCell {
    &:last-child {
      display: block;
    }
  }
}
