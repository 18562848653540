@use "../../../../global-styles/palette" as c;

a.caption-risk-warning-bold {
    display: flex;
    align-items: center;
    column-gap: 12px;
    text-decoration: none;
    width: max-content;
    padding: 4px;
    padding-inline-start: 16px;
    border-radius: 38px;
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(4px);

    p.button-title {
        color: c.$dark-20;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
    }


    img {
        border-radius: 24px;
        background: c.$light-gray-bg;
        padding: 6px;
    }
}

.large-view {
    a.caption-risk-warning-bold {
        margin: 0;
        line-height: 19.6px;
    }
}