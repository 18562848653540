@use "../../../../global-styles/palette" as c;

.elementToFadeIn {
  display: inline;
  animation: fadein ease-in forwards;
  opacity: 0;
}

.elementToFadeOut {
  display: none!important;
}

@keyframes fadein {
  100% {
    opacity: 1;
  }
}

.phonesGridContainerWrapper {
  display: grid;
  justify-items: center;
  padding: 32px 0;

  &__upperTitle {
    color: c.$dark-80;
    text-align: center;
  }

  &__mainTitle {
    text-align: center;
    color: c.$dark-default;
    margin: 8px 0;
  }
  &__mainSubTItle {
    text-align: center;
    color: c.$dark-80;
    width: 279px;
  }

  &__phoneImagesWrapper {
    margin-top: 32px;
    width: 184px;
    height: 376px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.large-view {
  .phonesGridContainerWrapper {
    width: fit-content;
    padding: 32px 0 40px;
    margin-inline: auto;

    &__mainSubTItle {
      width: fit-content;
    }

    &__phoneImagesWrapper {
      width: 245px;
      height: 500px;
    }
  }
}
