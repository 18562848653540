@use "../../../global-styles/palette" as v;

.optional-button-banner {
  &__flex-middle {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }

  &__title-container {
    h2 {
      color: v.$dark-default;
    }
  }

  &__description-container {
    color: v.$dark-80;
  }

  .button {
    padding: 0px 24px;
    font-weight: 500;
  }

  .button--primary {
    color: v.$dark-default;
  }

  &__link-wrapper {
    color: v.$dark-default;
    font-weight: 700;
  }

  &__flex-outer {
    display: flex;
    gap: 5%;
  }

  &__image-container-left:not(:empty),
  &__image-container-right:not(:empty) {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    flex-direction: column;
    gap: 24px;

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--big-image &__image-container-left:not(:empty),
  &--big-image &__image-container-right:not(:empty) {
    width: 60%;
  }

  &__image-container-mobile {
    margin-top: 48px;

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--big-image &__image-container-mobile {
    order: 4;
  }

  &__button-container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.large-view {
  .optional-button-banner {
    &__button-container {
      align-self: flex-start;
    }

    &__title-container {
      align-self: flex-start;
    }
    &__description-container {
      min-width: 540px;
    }
  }
  .optional-button-banner__image-container-right.hide-small{
    position: relative;
    right: -500px;
    transition: 2s linear;
  }
  .optional-button-banner--animated{
    .optional-button-banner__image-container-right.hide-small{
      right: 0;
    }
  }
  .option-button-banner__container{
    .g-container:not(.g-container--fullwidth){
      overflow: visible;
    }
  }
}

.medium-view,
.small-view {
  .optional-button-banner {
    &__title-container {
      text-align: center;
    }

    &__button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -23px;
    }
  }
}