@use "../../global-styles/palette" as c;

.instant-swiper-root {
  --first-arrow-rotate: rotate(180deg);
  --second-arrow-rotate: none;

  .g-container--tablet-margins-landing-pages {
    border-radius: 20px;
  }

  &__titles-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  &__title {
    max-width: 335px;

    h2 {
      text-align: center;
      font-size: 34px;
      font-weight: 700;
      line-height: 110%;
    }
  }

  &__subtitle {
    max-width: 335px;
    text-align: center;
    margin-bottom: 40px;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
    }
  }

  .g-container {
    background-color: c.$light-green-bg;

    .instant-swiper-root__activeButtonsWrapper {
      grid-area: activeButtonsArea;
      display: flex;
      padding-block: 30px 20px;
      padding-inline: 30px;

      span {
        background-color: c.$light-green-button-bg;
        width: 30px;
        height: 6px;
        display: block;
        border-radius: 10px;
        margin-inline-end: 10px;
      }

      .active-button {
        background-color: c.$tm-green-default;
      }
    }

    .instant-swiper-root__textsWrapper {
      grid-area: textsWrapperArea;
      padding-inline: 30px;
      margin-bottom: 20px;
      max-width: 275px;

      h3 {
        color: c.$dark-90;
        font-size: 26px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 12px;
        height: 60px;
      }

      p {
        color: c.$dark-90;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .instant-swiper-root__buttonsWrapper {
      grid-area: buttonsWrapperArea;
      width: fit-content;
      margin-inline-start: 30px;
      span {
        svg {
          background-color: c.$pure-white;
          padding: 12px;
          border-radius: 50%;

          &:hover {
            cursor: pointer;
            background-color: c.$tm-green-default;
          }
        }
      }

      span:first-child {
        svg {
          transform: var(--first-arrow-rotate);
          margin-inline-end: 16px;
        }
      }

      span:nth-child(2) {
        svg {
          transform: var(--second-arrow-rotate);
        }
      }
    }

    .instant-swiper-root__imageWrapper {
      grid-area: imageWrapper;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.medium-view {
  .instant-swiper-root {
    &__title {
      max-width: 664px;
    }

    &__subtitle {
      max-width: 664px;
  
      p {
        font-size: 18px;
      }
    }

    .g-container {
      display: grid;
      grid-template-areas:
        "activeButtonsArea imageWrapper"
        "textsWrapperArea imageWrapper"
        "buttonsWrapperArea imageWrapper";

      .instant-swiper-root__activeButtonsWrapper {
        width: fit-content;
        padding-block: 40px 30px;
        padding-inline: 0 40px;
      }

      .instant-swiper-root__textsWrapper {
        padding-inline: 40px;
        margin-bottom: 57px;
        max-width: 334px;

        h3 {
          width: fit-content;
        }

        p {
          width: 340px;
        }
      }

      .instant-swiper-root__buttonsWrapper {
        height: fit-content;
      }
    }
  }
}

.large-view {
  .instant-swiper-root {

    &__title {
      max-width: 996px;

      h2 {
        font-size: 48px;
      }
    }

    &__subtitle {
      max-width: 960px;

      p {
        font-size: 18px;
      }
    }

    .g-container {
      display: grid;
      grid-template-areas:
        "activeButtonsArea imageWrapper"
        "textsWrapperArea imageWrapper"
        "buttonsWrapperArea imageWrapper"
        ". imageWrapper";

      .instant-swiper-root__activeButtonsWrapper {
        padding-inline: 80px 0;
        padding-block: 60px 70px;
        height: fit-content;
      }

      .instant-swiper-root__textsWrapper {
        padding-inline: 80px;
        height: fit-content;
        max-width: 518px;

        h3 {
          width: fit-content;
          height: fit-content;
          font-size: 38px;
          font-weight: 600;
          line-height: 120%;
        }

        p {
          height: fit-content;
          font-size: 18px;
          font-weight: 500;
          line-height: 150%;
        }
      }

      .instant-swiper-root__buttonsWrapper {
        margin-inline-start: 80px;
        height: fit-content;
      }

      .instant-swiper-root__imageWrapper {
        width: 450px;
        height: auto;
      }
    }
  }
}

html[dir="rtl"] {
  .instant-swiper-root {
    --first-arrow-rotate: none;
    --second-arrow-rotate: rotate(180deg);
  }
}
