@use "../../global-styles/palette" as c;

.ExpandableCardsContainer {
  padding-inline: 24px;

  .ComponentHeader {
    h2 {
      color: c.$dark-default;
      font-style: normal;
      text-align: center;
    }
    p {
      color: c.$dark-80;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      width: 330px;
      margin-inline: auto;
    }
  }

  &__cardsWrapper {
    display: grid;
    gap: 24px;
    margin: 32px 0;

    .ExpandableCardWrapperClosed {
      height: 135px;
    }
  }
}

.medium-view {
  .ExpandableCardsContainer {
    padding-inline: 72px;

    .ComponentHeader {
      p {
        width: 100%;
      }
    }

    &__cardsWrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;

      .ExpandableCardWrapper {
        &__visibleHeader {
          &__topCardInfo {
            &__image {
              width: 56px;
              height: 56px;
            }

            &__signsWrapper {
              svg {
                display: none;
              }
            }
          }
        }
      }

      .ExpandableCardWrapperClosed {
        height: 100%;
      }
    }
  }
}

.large-view {
  .ExpandableCardsContainer {
    max-width: 1128px;
    margin-inline: auto;

    .ComponentHeader {
      p {
        width: 100%;
      }
    }

    &__cardsWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;

      .ExpandableCardWrapper {
        &__visibleHeader {
          &__topCardInfo {
            &__image {
              width: 56px;
              height: 56px;
            }

            &__signsWrapper {
              svg {
                display: none;
              }
            }
          }
        }
      }

      .ExpandableCardWrapperClosed {
        height: 100%;
      }
    }
  }
}
