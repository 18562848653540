@use "../../../global-styles/palette" as c;


.wide-card-with-button {
    --min-card-height: 352px;

    box-sizing: border-box;
    display: grid;
    justify-items: stretch;
    grid-template-rows: repeat(3, min-content) 1fr;
    gap: 12px;
    width: 100%;
    min-width: min-content;
    min-height: var(--min-card-height);
    padding: 40px;
    border-radius: 32px;

    h4 {
        width: fit-content;
    }

    p {
        width: fit-content;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &__image {
        width: 64px;
        height: 64px;
    }

    &__button {
        justify-content: flex-start;
        align-self: end;
        margin: 0;
    }
}

.small-view{
    .wide-card-with-button{
        .button-v2{
            width: 100%;
        }
    }
}
.medium-view,
.large-view {
    .wide-card-with-button {
        --min-card-height: 224px;

        grid-template-columns: 1fr min-content;
        grid-template-rows: repeat(2, min-content) 1fr;
        grid-template-areas:
            "title image"
            "text image"
            "button image";
        overflow: visible;

        &__image {
            grid-area: image;
            margin-block-start: 12px;
        }

        h4 {
            grid-area: title;
        }

        p {
            grid-area: text;
        }

        &__button {
            grid-area: button;
        }
    }
}