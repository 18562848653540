@use "../../../global-styles/palette" as v;
@use "./HeroBanner/HeroBanner";

.hero-banner-slider{
    &__navigation{
        background-color: v.$dark-default;
        color: v.$pure-white;
        text-align: center;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-image{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
        }
    }
        .g-container{
            position: relative;
            overflow: unset;
        }
}
.small-view, .medium-view {
    .hero-banner-slider__navigation-title{
        font-size: 19px;
        text-align: left;
        padding-left: 90px;
    }
    .hero-banner-slider__navigation-image{
        max-width: 84px;
    }
}