@use "../../global-styles/palette" as c;

.subscribeRibbon{
    &__options-container{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__option-text{
        color: c.$pure-white;
        margin-right: 16px;
    }
    &__options{
        display: flex;
    }
    &__option{
        color: c.$blue-lagoon-40;
        margin-right: 24px;
        cursor: pointer;
    }
    &__trigger{
        height: 100%;
        width: 48px;
        position: absolute;
        left: 0;
        display: none;
        &.active{
            display: block;
        }
    }
    &__widget--search{
        position: relative;
    }
}
.small-view{
    .subscribeRibbon{
        &__option-text{
            width: 100%;
            margin-bottom: 8px;
        }
    }
}