@use "../../../global-styles/palette" as c;

.TradeWithThinkCopy-root {
  .g-container {
    .TradeWithThinkCopyWrapper {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 32px;
      align-content: start;

      &__sectionWrapper__section>img {
        width: 24px;
        padding-top: 4px;
        padding-right: 2px;
      }

      grid-template-areas: 
      "mainImage"
      "mainTitle"
      "subtitle"
      "sectionWrapper"
      "button";

      &__mainTitle {
        color: c.$dark-default;
        grid-area: mainTitle;
        height: fit-content;
        margin-top: 24px;
        font-style: normal;
        text-align: center;
      }

      &__mainSubTitle {
        grid-area: subtitle;
        margin: 24px 0 24px 0;
        font-style: normal;
        color: c.$dark-80;
      }

      &__image {
        grid-area: mainImage;
        width: 100%;
        height: auto;
      }

      &__sectionWrapper {
        grid-area: sectionWrapper;
        width: 100%;

        &__section {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: min-content auto;
          margin-top: 16px;

          &:first-child {
            margin: 0;
          }

          &__titleWrapper {
            width: fit-content;
            margin-left: 8px;

            &__title {
              color: c.$dark-default;
              font-style: normal;
            }

            &__subTitle {
              margin-top: 8px;
              font-style: normal;
              color: c.$dark-80;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }

      &__button-container {
        display: flex;
      }

      &__squaredButton {
        margin: 32px auto 0px auto;
        background-color: c.$tm-green-default;
        color: c.$dark-default;
        border: none;
      }

      &__squaredButton:hover {
        background-color: c.$tm-green-120;
      }
    }

    .TradeWithThinkCopyWrapperRight {
      @extend .TradeWithThinkCopyWrapper;
    }
  }
}

.medium-view {
  .TradeWithThinkCopy-root {
    .g-container {
      .TradeWithThinkCopyWrapper {
        column-gap: 32px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          "mainImage mainImage"
          "mainTitle sectionWrapper"
          "subtitle sectionWrapper"
          "button sectionWrapper"
          ". sectionWrapper";

        &__mainTitle {
          margin-top: 32px;
          text-align: left;
        }

        &__mainSubTitle {
          margin: 16px 0 32px 0;
        }

        &__image {
          width: 552px;
          height: 552px;
          margin-inline: auto;
          object-fit: contain;
        }

        &__sectionWrapper {
          margin-top: 32px;

          &__section {
            margin-top: 16px;

            &:first-child {
              margin: 0;
            }

            &__titleWrapper {
              &__subTitle {
                margin: 0;
              }
            }
          }
        }

        &__squaredButton {
          margin-top: 0px;
        }
      }
    }
  }
}

.large-view {
  .TradeWithThinkCopy-root {
    .g-container {
      .TradeWithThinkCopyWrapper {
        padding: 0;
        grid-template-columns: 1fr 1fr;
        column-gap: 64px;
        align-items: center;

        &__sectionWrapper__section>img {
          width: 32px;
          padding-top: 4px;
        }

        grid-template-areas: 
        "mainImage mainTitle"
        "mainImage subtitle"
        "mainImage sectionWrapper"
        "mainImage button";

        &__mainTitle {
          margin: 0;
          text-align: left;
        }

        &__mainSubTitle {
          margin: 16px 0;
          font-style: normal;
        }

        &__image {
          width: 552px;
          height: 552px;
          object-fit: contain;
        }

        &__sectionWrapper {
          &__section {
            margin-top: 24px;
            justify-content: start;

            &:first-child {
              margin: 0;
            }

            &__titleWrapper {
              &__subTitle {
                margin: 0;
              }
            }
          }
        }

        &__button-container {
          display: unset;
        }

        &__squaredButton {
          margin-top: 32px;
        }
      }

      .TradeWithThinkCopyWrapperRight {
        @extend .TradeWithThinkCopyWrapper;
        grid-template-areas:
          "mainTitle mainImage"
          "subtitle mainImage"
          "sectionWrapper mainImage"
          "button mainImage";
      }
    }
  }
}