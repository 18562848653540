@use "../../global-styles/palette" as c;

.two-section-with-title-new {
    display: flex;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        h5 {
            max-width: 744px;
            text-align: center;
            font-weight: 500;
            color: c.$dark-80;
        }
    }

    &__cards-wrapper {
        display: flex;
        gap: 24px;
    }

    .two-section-with-title-new-card {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        min-width: 500px;
        box-shadow: 0px 1.5px 4px -1px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        border: 1px solid c.$dark-10;
        background: c.$pure-white;

        &__title-wrapper {
            display: flex;
            gap: 12px;

            h4 {
                color: c.$dark-default;
            }
        }

        &__list-wrapper {
            list-style-type: disc;
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__item {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: c.$dark-default;
        }
    }
}

.small-view {
    .two-section-with-title-new {
        &__title-wrapper {
            align-items: unset;

            h5 {
                text-align: left;
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;

            }
        }

        &__cards-wrapper {
            flex-direction: column;
        }

        .two-section-with-title-new-card {
            min-width: unset;

            &__title-wrapper {
                h4 {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 120%;
                }
            }

            &__list-wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            &__item {
                max-width: 258px;
            }
        }
    }
}

.medium-view {
    .two-section-with-title-new {
        justify-content: center;

        &__container {
            gap: 40px;
        }

        &__title-wrapper {
            align-items: unset;
            h5 {
                font-size: 16px;
                font-weight: 400;
                max-width: 600px;
                text-align: unset;
            }
        }

        &__cards-wrapper {
            flex-direction: column;
        }

        .two-section-with-title-new-card {
            min-width: 552px;

            &__title-wrapper {
                h4 {
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 120%;
                }
            }
        }
    }
}

.large-view {
    .two-section-with-title-new {
        &__title-wrapper {
            h5 {
                font-weight: 500;
            }
        }
    }
}