@use "../../global-styles/palette" as c;

.still-have-questions-items-root {
  .g-container {
    .still-have-questions-items-root__titleWrapper {
      h2 {
        color: c.$dark-default;
        text-align: center;
        font-style: normal;
        margin-bottom: 8px;
        font-size: 22px;
        line-height: 28px;
      }

      h5 {
        color: c.$dark-80;
        text-align: center;
        font-style: normal;
        margin-bottom: 32px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .still-have-questions-items-root__itemContainer {
      display: grid;
      justify-content: center;
      row-gap: 24px;

      .still-have-questions-item {
        background-color: c.$neutral-accent-background;
        display: grid;
        width: 240px;
        border-radius: 16px;
        justify-content: center;
        align-content: center;
        justify-items: center;
        cursor: pointer;
        padding: 16px 0;

        h5 {
          height: fit-content;
        }
      }
    }
  }
}

.medium-view {
  .still-have-questions-items-root {
    .g-container {
      margin-inline: 72px;
      .still-have-questions-items-root__titleWrapper {
        h2 {
          font-size: 26px;
          line-height: 32px;
        }
      }

      .still-have-questions-items-root__itemContainer {
        gap: 24px;
        grid-template-columns: repeat(2, auto);
        justify-items: center;
      }
    }
  }
}

.large-view {
  .still-have-questions-items-root {
    .g-container {
      .still-have-questions-items-root__titleWrapper {
        h2 {
          font-size: 32px;
          line-height: 40px;
        }

        h5 {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .still-have-questions-items-root__itemContainer {
        grid-auto-flow: column;
        gap: 0 24px;
      }
    }
  }
}

/* Styles for mobile devices (coarse pointer and no hover) */
@media (pointer: coarse) and (hover: none) {
  .still-have-questions-item--non-mobile {
    display: none !important;
  }
}

/* Styles for non-mobile devices (fine pointer or hover) */
@media (pointer: fine) {
  .still-have-questions-item--mobile {
    display: none !important;
  }
}
@media (hover: hover) {
  .still-have-questions-item--mobile {
    display: none !important;
  }
}