@use "../../global-styles/palette" as c;

.simple-header-banner__root {
    h1 {
        color: c.$pure-white;
        text-align: center;
        margin-bottom: 8px;
    }

    p {
        text-align: center;
        color: c.$dark-10;
    }
}

.large-view {
    .simple-header-banner__root {
        display: grid;
        justify-content: center;

        h1 {
            max-width: 682px;
            margin-inline: auto;
        }

        p {
            max-width: 682px;
            margin-inline: auto;
        }
    }
}