@use "../../global-styles/palette" as c;

.title-with-two-cards-root {
  --list-item-image: url("data:image/svg+xml, %3Csvg height='24' width='24' fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.6045 5.96624C20.8577 6.21954 21 6.56304 21 6.92121C21 7.27938 20.8577 7.62288 20.6045 7.87618L10.4803 18.0004C10.3465 18.1342 10.1877 18.2404 10.0128 18.3128C9.83802 18.3852 9.65064 18.4225 9.46141 18.4225C9.27217 18.4225 9.08479 18.3852 8.90997 18.3128C8.73514 18.2404 8.57629 18.1342 8.4425 18.0004L3.41236 12.9712C3.28335 12.8466 3.18045 12.6975 3.10966 12.5327C3.03887 12.3679 3.00161 12.1907 3.00005 12.0113C2.99849 11.832 3.03267 11.6541 3.10058 11.4881C3.1685 11.3221 3.2688 11.1713 3.39562 11.0445C3.52245 10.9176 3.67326 10.8174 3.83926 10.7494C4.00526 10.6815 4.18312 10.6473 4.36247 10.6489C4.54182 10.6505 4.71907 10.6877 4.88386 10.7585C5.04866 10.8293 5.1977 10.9322 5.3223 11.0612L9.46096 15.1999L18.6937 5.96624C18.8191 5.84072 18.9681 5.74115 19.132 5.67321C19.2959 5.60528 19.4716 5.57031 19.6491 5.57031C19.8266 5.57031 20.0023 5.60528 20.1662 5.67321C20.3301 5.74115 20.4791 5.84072 20.6045 5.96624Z' fill='%235EE15A' fillRule='evenodd' /%3E%3C/svg%3E");

  .g-container {
    h2 {
      margin-bottom: 32px;
      text-align: center;
    }

    .title-with-two-cards-root__cardsWrapper {
      .title-with-card-root {
        display: grid;
        justify-items: center;

        &:first-child {
          margin-bottom: 40px;
        }

        &__title-and-image {
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          margin-bottom: 16px;

          img {
            margin-right: 16px;
          }
        }

        &__card-content-wrapper {
          border-radius: 32px;
          padding: 24px;
          background-color: c.$pure-white;
          max-width: 472px;

          ul {
            padding: 16px;

            li {
              display: flex;
              align-items: flex-start;
              gap: 10px;

              &::before {
                flex-shrink: 0;
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                background-image: var(--list-item-image);
              }

              & + li {
                margin-top: 24px;
              }
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .title-with-two-cards-root {
    .g-container {
      margin-inline: 72px;
    }
  }
}

.large-view {
  .title-with-two-cards-root {
    .g-container {
      .title-with-two-cards-root__cardsWrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .title-with-card-root {
          &:first-child {
            margin: 0;
          }

          &__card-content-wrapper {
            min-height: 168px;
          }
        }
      }
    }
  }
}