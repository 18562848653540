@use "../../../global-styles/palette" as c;

.contact-us-root {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  height: fit-content;
  z-index: 1;

  .contacts {
    border-radius: 16px;
    background: c.$neutral-accent-background;
    padding: 32px;
    display: grid;
    row-gap: 8px;

    p {
      color: c.$dark-80;
      width: 290px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .contacts__options {
      display: grid;
      row-gap: 8px;

      div {
        display: flex;
        align-items: center;

        p,
        a {
          color: c.$dark-default;
          margin-left: 8px;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          text-decoration-line: underline;
        }

        a:hover {
          cursor: pointer;
        }
      }
    }
  }

  .create-account {
    padding: 32px;
    border-radius: 16px;
    background: c.$neutral-accent-background;

    h4 {
      width: 250px;
      text-align: center;
      margin: 0 auto 16px;
    }
  }
}
