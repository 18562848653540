@use "../../../global-styles/palette" as c;

.trade-with-component-3-items {
  &__main-flex {
    display: flex;
    gap: 32px;
    align-items: flex-start;
  }

  &__section {
    flex: 1;
    min-width: 0;
    width: 100%;
  }

  &__center-content {
    display: flex;
    place-content: center;
    text-align: center;

  }

  .flex2 {
    display: flex;
    gap: 20px;
  }

  .flex3 {
    display: flex;
    gap: 8px;
    height: 44px;
    margin-top: 10px;
  }

  &__section-heading {
    margin-bottom: 4px;
    margin-top: 28px;
  }

  &__main-title {
    margin-bottom: 32px;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    transition: none;
  }

  .text-link {
    color: #0E1D31 !important;
  }

  &__section-subtitle {
    margin: 20 auto 4 auto;
  }

}

.medium-view {
  .trade-with-component-3-items {
    &__main-flex {
      flex-direction: column;
    }

    &__center-content {
      place-content: baseline;
      text-align: start;
    }
  }
}

.small-view {
  .trade-with-component-3-items {
    &__main-flex {
      flex-direction: column;
    }

    &__center-content {
      place-content: center;
    }
  }
}