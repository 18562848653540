@use "../../global-styles/palette" as c;

.why-spread-bet-with-us {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__cards-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    &__tag {
        display: flex;
        padding: 8px 14px 8px 10px;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: c.$pure-white;
    }

    &__tags-container {
        display: flex;
        gap: 8px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        background-color: c.$light-green-bg;
        border-radius: 20px;
        justify-content: space-between;
    }

    &__card-content-upper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 35px;
    }

    &__card .h5-lp {
        color: c.$dark-90;
    }

    &__icon.icon {
        width: 42px;
    }

    &__img {
        width: 100%;
        border-radius: 20px;
        margin-top: -25px;
    }


    &__custom-disclaimer-font {
        color: c.$dark-80;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
    }

    &__card-content-upper {
        padding: 28px 30px 49px 30px;
    }
}

.small-view {
    .why-spread-bet-with-us {
        &__cards-grid {
            grid-template-columns: 1fr;
        }

        &__card-content-upper {
            padding: 28px 30px 38px 30px;
        }

        &__img {
            margin-top: -12px;
        }

        &__card .h5-lp {
            margin-right: 15px;
        }

        &__card .copy-lp {
            margin-right: 15px;
        }
    }
}

.medium-view {
    .why-spread-bet-with-us {
        &__card-content-upper {
            padding: 28px 30px 48px 30px;
        }

        &__card .h5-lp {
            margin-right: 33px;
        }

        &__card .copy-lp {
            margin-right: 33px;
        }
    }
}