@use "../../../../global-styles/palette" as c;

.icon-title-squared-card {
    display: flex;
    align-items: center;
    width: 130px;
    height: 117px;
    padding: 16px 10px;
    flex-direction: column;
    gap: 10px;
    border-radius: 12px;
    box-shadow: 0px 7px 16.3px 0px #eee;
    background-color: #fff;
    border: 1px solid transparent;
    animation: initialCardStatus 0.7s ease backwards;
    text-decoration: none;

    &:hover {
        animation: hoveredCardStatus 0.7s ease forwards;
        cursor: pointer;
    }

    p.icon-title-squared-card__title {
        color: c.$pure-black;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
    }

    p.icon-title-squared-card__subTitle {
        color: c.$dark-80;
        text-align: center;
        font-size: 8px;
        font-weight: 400;
        line-height: 10.248px;
    }
}

@keyframes initialCardStatus {
    from {
        border: 1px solid c.$dark-20;
        box-shadow: unset
    }

    to {
        border: 1px solid transparent;
        box-shadow: 0px 7px 16.3px 0px #eee;
    }
}


@keyframes hoveredCardStatus {
    from {
        border: 1px solid transparent;
        box-shadow: 0px 7px 16.3px 0px #eee;
    }

    to {
        border: 1px solid c.$dark-20;
        box-shadow: unset;
    }
}