@use "../../../global-styles/palette" as c;
@use "../../../global-styles/layout";

.trustpilot-card{
    display: flex;
    flex-direction: column;
    max-width: 360px;
    padding: 24px;
    box-sizing: border-box;
    margin: 0 auto;
    &__title{
        font-weight: bold;
        margin-bottom: 8px;
    }
    &__rating{
        display: flex;
        gap: 8px;
        margin-bottom: 8px;
    }
    &__author{
        color: c.$dark-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 16px;
    }

    &__date{
        align-self: flex-end;
        color: c.$dark-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
    &__review{
        font-weight: 500;
        @extend %clamped-text-base;
        -webkit-line-clamp: 4;
    }
}

.small-view {
    .trustpilot-card {

        &__title{
            min-height: 48px;
        }
    }
}
