@use "../../../global-styles/palette" as c;

.card-deck-with-texts-buttons-root {
  .g-container {
    border-radius: 32px;
    padding: 40px 0px;
    background-color: c.$neutral-accent-background;

    .card-deck-with-texts-buttons-root {
      &__textsWrapper {
        h3 {
          text-align: center;
          width: 60vw;
          margin: 0 auto 8px;
        }

        p {
          color: c.$dark-default;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          max-width: 70vw;
          margin-inline: auto;

          strong {
            font-weight: 600;
          }
        }
      }

      &__cardsWrapper {
        margin: 32px 0;
        display: grid;
        justify-content: center;
        row-gap: 32px;

        .card-with-texts {
          border: 40px;

          p:first-child {
            border: 1px solid c.$tm-green-default;
            width: 36px;
            height: 36px;
            align-items: center;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            margin: 0 auto 16px;
            font-size: 18px;
            font-weight: 600;
            line-height: 100%;
          }

          p:last-child {
            width: 74vw;
            text-align: center;
            font-weight: 500;
          }
        }
      }

      &__buttonsWrapper {
        width: 74vw;
        margin-inline: auto;
        display: grid;
        justify-items: center;
        row-gap: 16px;

        .button-v2-wrapper,
        .cta-large-wbg,
        .ghost-large-wbg {
          width: 100%;
        }
      }
    }
  }
}

.medium-view {
  .card-deck-with-texts-buttons-root {
    .g-container {
      .card-deck-with-texts-buttons-root {
        &__textsWrapper {
          h3 {
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 35px;
          }
        }
        &__cardsWrapper {
          .card-with-texts {
            p:last-child {
              width: 73vw;
            }
          }
        }

        &__buttonsWrapper {
          width: fit-content;
          grid-auto-flow: column;
          align-items: center;
          column-gap: 16px;

          .button-v2-wrapper,
          .cta-large-wbg,
          .ghost-large-wbg {
            width: fit-content;
          }
        }
      }
    }
  }
}

.large-view {
  .card-deck-with-texts-buttons-root {
    .g-container {
      padding: 64px 0;
      .card-deck-with-texts-buttons-root {

        &__textsWrapper {
          h3 {
            width: fit-content;
          }
        }

        &__cardsWrapper {
          grid-auto-flow: column;
          column-gap: 32px;
          width: fit-content;
          margin-inline: auto;

          .card-with-texts {
            p:last-child {
              width: 315px;
            }
          }
        }

        &__buttonsWrapper {
          grid-auto-flow: column;
          align-items: center;
          width: fit-content;
          column-gap: 16px;

          .button-v2-wrapper,
          .cta-large-wbg,
          .ghost-large-wbg {
            width: fit-content;
          }
        }
      }
    }
  }
}
