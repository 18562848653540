@use "../../../global-styles/palette" as c;

.card-with-icon-texts-link {
    --container-padding: 32px;
    --y-margin-big: 20px;
    --y-margin-small: 8px;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    padding: var(--container-padding);
    border-radius: 16px;
    border: 1px solid #e6e6e6;
    background: var(--background-color, #fff);
    box-shadow: 0px 1.5px 4px -1px rgba(0, 0, 0, 0.07);
    color: var(--content-text-color, #{c.$dark-default});

    &__icon {
        width: min-content;
        max-width: 100%;
        margin-bottom: var(--y-margin-big);
    }

    &__title-link {
        &,
        &:hover,
        &:visited,
        &:link {
            color: inherit;
            text-decoration: none;
        }
    }

    &__title:not(:empty):not(:empty) {
        color: inherit;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.2;
    }

    &__subtitle {
        margin-bottom: var(--y-margin-small);
        color: inherit;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
    }

    &__link {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        min-height: 38px;
        margin-top: auto;

        a,
        a:hover,
        a:visited,
        a:link {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            margin-top: var(--y-margin-big);
            border-radius: 48px;
            border: 1px solid #e2e8f0;
            color: c.$dark-default;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            text-align: center;
            text-decoration: none;

            &:hover {
                border-color: #94a3b8;
            }
        }
    }
}
