@use "../../global-styles/palette" as c;
@use "./MiniComponentsGridContainer/PhonesGridContainer/PhonesGridContainer";
@use "./MiniComponentsGridContainer/ChartsGridContainer/ChartsGridContainer";
@use "./MiniComponentsGridContainer/NotificationsGridContainer/NotificationsGridContainer";

.animatedBoxGridWrapper {
  .g-container {
    margin-bottom: 64px;
    display: grid;
    row-gap: 24px;
    background-color: c.$pure-white;

    .animatedBoxGridWrapper__titlesWrapper__mainTitle {
      color: c.$dark-default;
      text-align: center;
    }

    .animatedBoxGridWrapper__titlesWrapper__mainSubTitle {
      color: c.$dark-80;
      text-align: center;
      font-weight: 500;
      margin-top: 8px;
    }

    .animatedBoxGridWrapper__phoneGridWrapper {
      background-color: c.$neutral-accent-background;
      border-radius: 32px;
      margin-top: 8px;
    }

    .animatedBoxGridWrapper__chartsGridWrapper {
      background-color: c.$neutral-accent-background;
      border-radius: 32px;
    }

    .animatedBoxGridWrapper__notificationsGridWrapper {
      background-color: c.$neutral-accent-background;
      border-radius: 32px;
    }
  }
}

.medium-view {
  .animatedBoxGridWrapper {
    .g-container {
      margin-inline: 72px;
    }
  }
}

.large-view {
  .animatedBoxGridWrapper {
    .g-container {
      grid-template-areas:
        "titlesWrapper titlesWrapper"
        "phoneWrapper chartWrapper"
        "phoneWrapper notificationWrapper";
      gap: 32px;

      span.animatedBoxGridWrapper__titlesWrapper {
        grid-area: titlesWrapper;
      }
      .animatedBoxGridWrapper__titlesWrapper__mainTitle {
        width: 600px;
        margin-inline: auto;
      }

      .animatedBoxGridWrapper__phoneGridWrapper {
        margin: 0;
        grid-area: phoneWrapper;
      }

      .animatedBoxGridWrapper__chartsGridWrapper {
        grid-area: chartWrapper;
      }

      .animatedBoxGridWrapper__notificationsGridWrapper {
        grid-area: notificationWrapper;
      }
    }
  }
}
