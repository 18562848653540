.ribbon-banner3 {
    padding: 17px;
    gap: 20px;
    font-style: normal;

    &__main-text {
        text-align: start;
        font-style: normal;
        line-height: 120%;
        font-size: 14px;
        font-weight: 600;
    }

    a {
        text-decoration-line: underline;
        white-space: nowrap;
    }

    img {
        height: 26px;
    }
}

.large-view,
.medium-view {
    .ribbon-banner3 {
        padding: 16px;

        &__main-text {
            font-size: 16px;
            line-height: 100%;
        }
    }
}