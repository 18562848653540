@use "../../global-styles/palette" as c;

.contact-us-banner-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h2 {
            color: c.$dark-default;
        }
    }

    &__description {
        margin: 16px 0px 32px 0px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: c.$dark-80;
    }

    &__image-container {

        img {
            width: 512px;
        }
    }
}

.medium-view {
    .contact-us-banner-with-button {
        flex-direction: column;
        padding: 40px 0px;
    }
}

.small-view {
    .contact-us-banner-with-button {
        flex-direction: column;
        gap: 40px;

        &__image-container {

            img {
                width: 327px;
            }
        }
    }
}