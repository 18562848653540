@use "../../../../global-styles/palette" as c;

.dropdown-filter {
  &,
  & * {
    box-sizing: border-box;    
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__select {
    select {
      width: 260px;
      height: 48px;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: c.$neutral-accent-background;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: c.$dark-default;
      border: 1px solid c.$neutral-accent-background;
      outline: 0;
      appearance: none;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="%233e4a5a"><g clip-path="url(%23clip0_15417_12101)"><path d="M8.7179 12.28C8.57738 12.4207 8.38674 12.4998 8.1879 12.5L7.8079 12.5C7.60948 12.4977 7.41962 12.4189 7.2779 12.28L2.1479 7.14C2.05324 7.04612 2 6.91832 2 6.785C2 6.65168 2.05324 6.52388 2.1479 6.43L2.8579 5.72C2.95006 5.62594 3.07621 5.57293 3.2079 5.57293C3.33959 5.57293 3.46574 5.62594 3.5579 5.72L7.9979 10.17L12.4379 5.72C12.5318 5.62534 12.6596 5.5721 12.7929 5.5721C12.9262 5.5721 13.054 5.62534 13.1479 5.72L13.8479 6.43C13.9426 6.52388 13.9958 6.65168 13.9958 6.785C13.9958 6.91832 13.9426 7.04612 13.8479 7.14L8.7179 12.28Z" fill="inherit"/></g><defs><clipPath id="clip0_15417_12101"><rect width="16" height="16" fill="inherit" transform="translate(0 0.5)"/></clipPath></defs></svg>');
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 44px 16px;
    }
  }
}

html[dir="rtl"] .dropdown-filter {
  &__select {
    select,
    select.dropdown-wrapper__dropdown {
      padding-right: 16px;
      padding-left: 32px;
      background-position: left center;
    }
  }
}