@use "../../global-styles/palette" as c;
@use "../../global-styles/custom-scrollbar" as scrollbar;

.filters {
  --padding: 24px;

  background-color: c.$pure-white;
  max-height: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  overflow: hidden;
  display: flex;
  transition: .3s ease;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  border-radius: 16px;
  top: 50%;
  transform: translateY(-50%);

  & > .g-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding);
  }

  &__close {
    cursor: pointer;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__container {
    @include scrollbar.custom-scrollbar();

    padding: 32px 0 0;
    column-gap: 0;
    flex: 1;
    padding: 0 var(--padding);
    overflow: auto;
  }

  &__field {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;

    & + & {
      margin-top: 32px;
    }

    &-title {
      width: 100%;
      margin-bottom: 16px;
      color: c.$dark-80;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        text-transform: none;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-decoration: underline;
        color: c.$dark-80;
      }
    }

    &-checkboxes,
    &-radios {
      gap: 16px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding);
    border-top: 1px solid c.$dark-10;

    .button-v2-wrapper {
      margin: 0 0 0 auto;
    }
  }

  &__reset {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: c.$dark-default;
    text-decoration: underline;
  }
}

.medium-view,
.small-view {
  .filters {
    &__field {
      width: 100%;

      &-checkboxes,
      &-radios {
        flex-direction: column;
      }
    }

    &__container {
      flex-flow: column;
      overflow-y: scroll;
    }

    &__actions {
      text-align: center;
    }
  }
}

.small-view {
  .filters {
    &__field-checkboxes,
    &__field-radios {
      grid-template-columns: none;
    }
  }
}

.active-filters {
  .filters {
    position: fixed;
    transition: .3s ease-out;
    opacity: 1;
    z-index: 99;
    max-height: 700px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.medium-view,
.small-view {
  .active-filters {
    .filters {
      padding: 0;
    }
  }
}

.small-view {
  .filters {
    top: 0;
    bottom: 0;
    transform: translateY(-50%);
    transform-origin: center;
    transition: max-height 0.3s ease, transform 0.3s ease;;
    border-radius: 0;
    max-height: 0;
    margin: auto;
  }

  .active-filters {
    .filters {
      height: 100%;
      max-height: 100%;
      transform: translateY(0);
    }
  }
}