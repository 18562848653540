@use "../../../global-styles/palette" as c;

.two-cards-with-images-root {
  .g-container {
    h2 {
      color: c.$dark-default;
      text-align: center;
    }

    .two-cards-with-images-root__cardsWrapper {
      margin: 32px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      .card-with-image {
        background-color: c.$pure-white;
        border-radius: 24px;
        padding: 24px;

        &__imageContainer {
          margin-bottom: 32px;

          img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
        }

        h4 {
          text-align: center;
          color: c.$dark-default;
          margin-bottom: 16px;
          font-size: 24px;
        }

        p {
          color: c.$dark-80;
          width: 73vw;
        }
      }
    }
  }
}

.medium-view {
  .two-cards-with-images-root {
    .g-container {
      h2 {
        font-size: 40px;
      }

      .two-cards-with-images-root__cardsWrapper {
        justify-content: center;

        .card-with-image {

          p {
            width: fit-content;
          }
        }
      }
    }
  }
}

.large-view {
  .two-cards-with-images-root {
    .g-container {
      h2 {
        font-size: 40px;
        width: 500px;
        margin-inline: auto;
      }

      .two-cards-with-images-root__cardsWrapper {
        justify-content: center;

        .card-with-image {
          padding: 40px;

          &__imageContainer {
            margin-bottom: 28px;
            width: 450px;
          }

          h4 {
            width: fit-content;
          }

          p {
            width: 450px;
          }
        }
      }
    }
  }
}
