@use "../../../global-styles/palette" as c;

.tab-with-instruments-and-table {
  --instrument-container-v-margin: 32px;

  &__instruments-container {
    h3:not(:empty) {
      margin-bottom: 12px;
      color: c.$dark-60;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.3;
      text-transform: uppercase;
    }
  }

  &__instruments-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    margin-bottom: var(--instrument-container-v-margin);
  }

  &__tabledata-container {
    display: flex;
    justify-content: center;
  }

  &__tabledata {
    --table-x-padding: 0px;
    --table-y-padding: 16px;
    --table-x-gap: 24px;

    overflow: hidden;
    background: c.$neutral-accent-dark-bg-5;
    border: 1px solid c.$dark-10;
    border-radius: 16px;

    table {
      border-collapse: separate;
      border-spacing: 0;
      border: var(--table-x-gap) solid transparent;
      width: 100% !important;
      background-color: c.$pure-white;
      table-layout: fixed;
    }

    tr > * {
      text-align: center;
    }

    tr > *:first-child {
      text-align: start;
    }

    tr > *:last-child {
      text-align: end;
    }

    td {
      padding: 0;
      padding-top: var(--table-y-padding);
      padding-bottom: var(--table-y-padding);
    }

    th {
      font-weight: bold;
      padding-bottom: 8px;
      border-bottom: 1px solid c.$dark-10;
    }

    thead {
      color: c.$dark-60;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.3;
      text-transform: uppercase;
    }

    thead th,
    thead td {
      padding-inline: var(--table-x-padding);
      padding-top: 0;
      vertical-align: bottom;
    }

    thead th:first-child,
    thead td:first-child,
    thead th:last-child,
    thead td:last-child {
      padding-bottom: 0;
    }

    thead th:first-child::after,
    thead td:first-child::after,
    thead th:last-child::after,
    thead td:last-child::after {
      position: relative;
      content: "";
      display: block;
      width: 100%;
      margin-top: 8px;
      margin-bottom: -1px;
      border-bottom: 1px solid c.$dark-10;
    }

    thead th:first-child::after,
    thead td:first-child::after {
      margin-inline-start: calc((var(--table-x-gap) + var(--table-x-padding)) * -1);
    }

    thead th:last-child::after,
    thead td:last-child::after {
      margin-inline-start: calc(var(--table-x-gap) + var(--table-x-padding));
    }

    tbody tr:not(:first-child) td {
      border-top: 1px solid c.$dark-10;
    }

    tbody td {
      color: c.$dark-default;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.7;
    }

    tbody tr:last-child td {
      padding-bottom: 0;
    }

  }
}

.medium-view {
  .tab-with-instruments-and-table {
    --instrument-container-v-margin: 32px;
  }
}

.large-view {
  .tab-with-instruments-and-table {
    --instrument-container-v-margin: 40px;

    &__instruments-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__instruments-list {
      justify-content: center;
    }
  }
}