@use "../../../global-styles/palette" as c;

.items__root {

    img {
        margin-bottom: 16px;
    }

    p {
        color: c.$dark-80;
    }
}