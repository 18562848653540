@use "../../global-styles/palette" as c;

.single-award-banner {
    --container-padding: 32px;
    --adjusted-poster-top-offset: calc(var(--award-poster-top-offset) + calc(var(--container-padding) * -1));
    --top-text-padding: max(var(--container-padding, 0px), var(--award-poster-top-offset, 0px));

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    max-width: none;
    padding: 0;
    margin: calc(var(--award-poster-top-offset, 0) * -1) 0 0 0;
    border-radius: 30px;
    border: 1px solid #ccc;
    background: c.$pure-white;

    &__text-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 8px;
        padding: var(--container-padding) var(--container-padding) 0;
        text-align: center;
    }

    &__title  {
        color: c.$pure-black;
        word-break: break-word;
    }

    &__subtitle,
    &__year {
        color: c.$pure-black;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.40;
        word-break: break-word;
    }

    &__year {
        padding-top: 12px;
    }

    &__poster {
        box-sizing: border-box;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        max-width: 400px;
        width: 100%;
        padding: 0 67px 30px;
        margin-top: var(--award-poster-top-offset, 0);
        background: var(--background-image-url) bottom center no-repeat;
        background-size: 100% auto;

        img {
            max-width: 100%;
            border-radius: 20px;
            box-shadow: -10px 14px 25px 0px rgba(0, 0, 0, 0.25),
                0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }
}

.medium-view,
.large-view {
    .single-award-banner {
        flex-direction: row;
        row-gap: 64px;

        &__text-content {
            margin-inline-end: auto;
            align-items: flex-start;
            padding-block: var(--container-padding, 0);
            padding-inline: var(--container-padding, 0) 20px;
            
            & > * {
                text-align: start;
            }
        }

        &__poster {
            align-self: flex-start;
            background-size: 100% auto;
        }
    }
}


.medium-view {
    .single-award-banner {
        --container-padding: 40px;
        --award-poster-top-offset: -16px;

        &__poster {
            max-width: 324px;
        }
    }
}

.large-view {
    .single-award-banner {
        --container-padding: 60px;
        --award-poster-top-offset: -40px;

        padding-inline-end: 40px;

        &__poster {
            max-width: 400px;
        }
    }
}
