@use "../../global-styles/palette" as c;

.custom-p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  text-decoration: none;
  font-weight: bold;
}

.contact-form {

  &__container,
  &__contacts {
    display: flex;
    background-color: c.$blue-lagoon-20;
    border-radius: 40px;
  }

  &__general-error-label {
    color: c.$error;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    margin-top: 1em;
    font-size: small;
    font-weight: bold;
    width: 30px;
  }

  &__semi-transparent {
    opacity: 0.4;
    pointer-events: none;
  }

  &__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  &__disabled {
    pointer-events: none;
    background-color: c.$dark-40;
    border-color: c.$dark-40;
  }

  &__loading-popup {
    background-color: c.$dark-20;
    padding: 10px;
    padding-left: 55px;
    padding-right: 55px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  }

  &__loading-popup-msg-container {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px;
  }

  &__loading-overlay span {
    color: c.$dark-default;
    font-size: 1em;
    font-weight: bold;
  }

  &__general-success-label {
    color: c.$dark-default;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    margin-top: 1em;
    font-size: small;
    font-weight: bold;
  }

  &__contacts {
    padding: 40px 48px 0;
    flex-direction: column;
  }

  &__required-star {
    color: c.$error;
  }

  &__required-error {
    color: c.$error;
    font-size: small;
    font-weight: bold;
  }

  &__required-error-bottom {
    color: c.$error;
    font-size: small;
    font-weight: bold;
  }

  &__spinner-container {
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
  }

  &__contact {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
      color: c.$dark-80;
      display: block;
    }

    p {
      width: 100%;
      margin-top: 16px;
      color: c.$dark-80;
      font-weight: bold;
    }

    a {
      font-weight: bold;
      color: c.$dark-80;
    }

    h5 {
      font-weight: bold;
    }

    img {
      margin-right: 8px;
    }
  }

  &__form {
    padding: 40px 96px;
    background-color: c.$pure-white;
    border-radius: 24px;
    position: relative;
  }

  &__form-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 32px;
  }

  &__field {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;

    &--half {
      min-width: 279px;
    }

    &--checkbox {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .contact-form__required-error {
        margin-left: 15px;
      }
    }

    input,
    textarea,
    select {
      font-size: 16px;
      padding: 16px;
      border: 1px solid c.$dark-light-input-bg;
      border-radius: 4px;
      font-weight: 500;
      color: c.$dark-default;
    }

    option:first-of-type {
        color: c.$dark-default;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: c.$dark-60;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: c.$dark-60;
      opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: c.$dark-60;
      opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: c.$dark-60;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: c.$dark-60;
    }
    
    ::placeholder { /* Most modern browsers support this now. */
      color: c.$dark-60;
    }

    .selected-file {
      margin-top: 7px;
      font-size: 16px;
      padding: 16px;
      border: 1px solid c.$dark-20;
      border-radius: 4px;
      font-weight: 500;
      color: c.$dark-60;
      background-color: c.$dark-10;
      position: relative;
    }

    .delete-icon {
      position: absolute;
      top: 55%;
      right: 15px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    textarea {
      resize: none;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.29 11.29a.996.996 0 0 0-1.41 0L12 15.17l-3.88-3.88a.997.997 0 1 0-1.41 1.41l4.59 4.59a.994.994 0 0 0 1.41 0l4.59-4.59c.38-.38.38-1.02-.01-1.41z' fill='%236e7783'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 95%;
      background-color: c.$pure-white;
    }

    input[type="checkbox"] {
      opacity: 0.5;
      width: 20px;
      margin: 0 8px 0 8px;
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  &__label {
    @extend .custom-p;
    margin-bottom: 8px;
    &--checkbox {
      color: c.$dark-default;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
    }

    &--required {
      position: relative;
      align-self: flex-start;
    }
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__file {
    @extend .custom-p;
    padding: 9px;
    color: c.$blue-lagoon-default;
    border: 1px solid c.$dark-20;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    display: flex;
    width: 100%;
  }
}
.checkbox-wrapper {
  display: inherit;
}

.captcha-input {
  width: fit-content;
  margin: 20px auto 0;
}

.medium-view,
.small-view {
  .contact-form {
    &__container {
      flex-direction: column;
      background-color: unset;
    }

    &__contacts,
    &__form {
      padding: 40px 24px;
    }

    &__form-container {
      flex-direction: column;
    }

    &__field {
      width: unset;
    }
  }
}

.small-view {
  .contact-form {
    &__form-container {
      flex-direction: column;
      align-items: center;
    }

    &__field {
      min-width: 85.5%;
    }

    &__field--half {
      max-width: 85.5%;
    }
  }
}

.contact-form__container-root {
  background-color: c.$pure-white;
  .g-container {
    .contact-form__container {
      .contact-form__form {
        background-color: c.$neutral-accent-background;

        h4 {
          text-align: center;
        }

        .contact-form__form-container {
          .contact-form__button {
            .button--primary {
              color: c.$dark-default;
            }
          }
        }
      }
    }
  }
}

.large-view {
  .contact-form__field {

    &--half {
      min-width: 340px;
    }
  }

  .contact-form__form {
    border-radius: 32px;
  }

  .contact-form__container-root {
    .g-container {
      .contact-form__container {

        .contact-form__form {
          margin-inline: auto;
          width: 100%;
          padding-inline: 80px;

          .contact-form__form-container {
            display: grid;
            grid-auto-flow: column;
            justify-content: normal;
            column-gap: 40px;
            grid-template-areas:
              "firstName lastName"
              "region aboutQuestion"
              "email mobilePhone"
              "message captcha"
              "checkbox checkbox"
              "button button";
              &--partnership{
                grid-template-areas:
                  "firstName lastName"
                  "region email"
                  "mobilePhone aboutQuestion"
                  "message captcha"
                  "button button";
              }
          }

          h4 {
            text-align: center;
            grid-area: titles;
          }

          .contact-form__field.contact-form__field--half.region-input {
            grid-area: region;
            width: 100%;
          }

          .contact-form__field.contact-form__field--half.firstName-input {
            grid-area: firstName;
            width: 100%;
          }
          .contact-form__field.contact-form__field--half.lastName-input {
            grid-area: lastName;
            width: 100%;
          }

          .contact-form__field.contact-form__field--half.email-input {
            grid-area: email;
            width: 100%;
          }

          .contact-form__field.contact-form__field--half.mobilePhone-input {
            grid-area: mobilePhone;
            width: 100%;
          }

          .contact-form__field.contact-form__field--half.aboutQuestion-input {
            grid-area: aboutQuestion;
            width: 100%;
          }

          .contact-form__field.message-input {
            grid-area: message;
            width: 100%;
          }

          .contact-form__field.contact-form__field--checkbox.checkbox-input {
            grid-area: checkbox;
          }
          .contact-form__field.captcha-input {
            grid-area: captcha;
            margin-top: 0;
          }
          .contact-form__button {
            grid-area: button;
          }
        }
      }
    }
  }
}
