@use "../../global-styles/palette" as c;
@use "./Categories/Category";

.mt5-download-section__root {
    background-color: c.$pure-white;

    --h-header: 20px;
    scroll-margin-top: calc(var(--h-header) + 7em);
    scroll-behavior: smooth;

    .g-container {
        h2 {
            margin-bottom: 20px;
        }

        .mt5-download-section__categoriesWrapper {
            .category__root {
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}

.medium-view {
    .mt5-download-section__root {
        .g-container {
            h2 {
                margin-bottom: 32px;
            }

            .mt5-download-section__categoriesWrapper {
                display: flex;
                column-gap: 20px;
            }
        }
    }
}

.large-view {
    .mt5-download-section__root {
        .g-container {
            h2 {
                margin-bottom: 32px;
            }

            .mt5-download-section__categoriesWrapper {
                display: flex;
                column-gap: 20px;

                .category__root {
                    &:nth-child(2) {
                        display: block;
                    }
                }
            }
        }
    }
}