@use "../../global-styles/palette" as c;
@use "./TrustpilotCard/TrustpilotCard";

.trustpilot-slider-wrapper {
  background-color: c.$pure-white;
  .trustpilot-slider {

    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1288px;
    margin: 0 auto;
    padding: 56px 0;

    .trustpilot-card {
      padding: 0;
      margin: 0;

      &__title{
        margin-bottom: 16px;
      }

      &__author {
        color: c.$dark-60;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-align: start;
      }

      &__review {
        color: c.$dark-80;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: justify;
        width: 65vw;
        white-space: normal;
      }
    }

    .sliderTitle {
      font-size: 24px;
      border-bottom: 1px solid c.$dark-20;
      padding-bottom: 16px;
    }

    .trustpilot-prev,
    .trustpilot-next {
      svg {
        color: c.$dark-default;
        width: 25px;
        height: 25px;
      }
      &.swiper-button-disabled {
        svg {
          color: c.$dark-40;
        }
      }
    }

    .trustpilot-prev {
      transform: rotate(90deg);
    }

    .trustpilot-prev:hover, .trustpilot-next:hover {
      cursor: pointer;
    }

    .trustpilot-next {
      transform: rotate(-90deg);
    }
  }
}
.trustPilotEvaluation {
  background-color: c.$neutral-accent-background;
  border-radius: 12px; 
  display: grid;
  max-width: 90vw;
  padding: 15px 0;

  grid-template-columns: repeat(auto-fill, 125px);
  justify-content: center;
  justify-items: center;
  align-items: center;
  row-gap: 16px;
  margin-inline: auto;

  &__starsImage {
    justify-self: start;
  }

  &__trustpilotImage {
    justify-self: baseline;
  }
}

.reviews-global-traders-box {
  display: flex;
  justify-content: center;
  gap: 8px;
  color: c.$dark-80;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 24px;
}

.small-view {
  .trustpilot-slider-wrapper {
    .trustpilot-slider {
  
      .trustpilot-card {
        margin-top: 24px;
      }
    }
    .trustPilotEvaluation {
      padding: 16px 0;
      max-width: 327px;
    }
  }
}

.medium-view {
  .g-container.g-container--fullwidth {   
    .trustpilot-slider {
      width: 100%;
      padding-bottom: 24px;

      .trustpilot-card {
        margin: 24px 20px 0 0;

        div {
          display: grid;
        }

        &__title {
          min-width: 165px;
          margin: 0 0 16px 0;
          white-space: unset;
        }

        &__author {
          width: 200px;
          margin: 4px 0 0;
        }

        &__review {
          min-width: 190px;
          width: 100%;
          text-align: left;
          min-height: 140px;
        }
      }
    }
  }
  .trustPilotEvaluation {
    max-width: 92vw;
    grid-template-columns: repeat(4, 105px);
    white-space: nowrap;

    &__starsImage {
      justify-self: unset;
    }
  }
}

.large-view {
  .trustpilot-slider-wrapper {
  .g-container.g-container--fullwidth {
    .trustpilot-slider {
      max-width: 1128px;
      width: 100%;

      .trustpilot-card {
        margin-top: 24px;
      
        &__title {
            white-space: unset;
          }

        &__review {
          text-align: justify;
          width: 312px;
        }
        }
      }
    }
    .trustPilotEvaluation {
      max-width: 1128px;
      grid-template-columns: repeat(4, 105px);

      &__starsImage {
        justify-self: unset;
      }
    }
  }
}
