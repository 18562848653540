@use "../../../global-styles/palette" as c;

.cards-deck-with-slider-on-mobile {

  .swiper-pagination {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .swiper-slide {
    display: flex;
    align-items: stretch;
    height: auto;
  }

  .swiper-pagination-bullet {
    border-radius: 8px;
    width: 8px;
    height: 8px;
    background-color: c.$dark-10;
    display: inline-block;
    margin-top: 9px;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: c.$dark-40;
  }

  &__main-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    grid-auto-rows: 1fr
  }

  .h3-semi-bold {
    margin-bottom: 18px;
  }

  .p-regular {
    color: c.$dark-80
  }

  .h5-bold,
  .h3-semi-bold {
    color: c.$dark-default;
  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 16px;
    padding: 32px;
  }
}

.large-view {
  .cards-deck-with-slider-on-mobile {
    &__main-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.medium-view {
  .cards-deck-with-slider-on-mobile {
    &__main-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}