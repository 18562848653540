@use "./CardsWithIconTextCard/CardsWithIconTextCard";
@use "../../../global-styles/palette" as c;

.cards-with-icon-text {
    display: flex;
    flex-direction: column;
    gap: 42px;

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        h2 {
            font-weight: 700;
            line-height: 110%;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
        }
    }

    &__cards {
        display: flex;
        gap: 20px;
    }
}

.medium-view {
    .cards-with-icon-text {
        max-width: 664px;
        margin: 0px auto;

        &__text {
            gap: 10px;
    
            h2 {
                font-size: 34px;
            }
    
            p {
                font-size: 16px;
            }
        }

        &__cards {
            flex-wrap: wrap;
        }
    }
}

.small-view {
    .cards-with-icon-text {
        &__text {
            gap: 20px;
    
            h2 {
                font-size: 34px;
                font-weight: 600;
                text-align: center;
            }
    
            p {
                font-size: 16px;
                text-align: center;
            }
        }

        &__cards {
            flex-wrap: wrap;
        }
    }
}

