@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";



.instruments-buy-sell-cards-bar {
  .swiper-slide {
    width: auto !important;
  }

  &__mini-title {
    margin-bottom: 7px;
    display: flex;
    justify-content: space-between;
    color: c.$dark-80;
  }

  &__mini-title>span {
    gap: 4px
  }

  &__card-outline {
    border-radius: 8px;
    border: 1px solid c.$dark-10;
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    width: 148px;
    box-sizing: border-box;

    &:hover {
      background-color: c.$neutral-accent-background;
    }
  }

  &__card-inner {
    display: flex;
    flex-direction: column;
    gap: 10px;

  }

  &__card-link {
    text-decoration: none;
    color: inherit;
  }

  &__percent-bar-main {
    background-color: c.$error;
    height: 3px;
    width: 100%;
  }

  &__percent-bar-green {
    background-color: c.$tm-green-120;
    height: 3px;
  }

  &__percent {
    justify-content: space-between;
  }
}

.small-view {
  .instruments-buy-sell-cards-bar {
    &__mini-title {
      flex-direction: column;
    }

    .instruments-buy-sell-cards-bar__mini-title {
      margin-bottom: 12px;
      gap: 9px;
    }
  }
}