@use "../../global-styles/palette" as c;
@use "../../global-styles/button-styled-as-link";

.contractspecs-filters {
  box-sizing: border-box;
  
  &__title {
    margin-bottom: 24px;
  }

  .filters__checkbox [type=radio]:not(:checked) + label:before {
    border-color: c.$dark-default;
  }

  .filters__checkbox [type="radio"]:checked + label:before,
  .filters__checkbox [type="radio"]:not(:checked) + label:before,
  .filters__checkbox [type="radio"]:checked + label:after,
  .filters__checkbox [type="radio"]:not(:checked) + label:after {
    border-radius: 50%;
  }

  .filters__checkbox [type="radio"]:checked + label,
  .filters__checkbox [type="radio"]:not(:checked) + label {
    color: c.$dark-default;

    &.active {
      color: c.$blue-lagoon-default;
    }
  }

  &__filters {
    align-items: center;
  }

  &__filter-bar-container {
    margin-bottom: 16px;
  }

  &__filter-bar {
    --gap: 22px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: var(--gap);
    width: 100%;
    margin-bottom: 16px;
  }

  .filters__checkbox {
    align-items: center;
  }

  &__instruments {
    width: 100%;
    display: flex;
  }

  &__select-fields {
    display: flex;
  }

  &__radio {
    margin-left: auto;
  }

  &__search {
    padding: var(--contract-specs_table-horizontal-padding);
    border: var(--contract-specs_table-border);
    border-bottom: none;
    border-radius: var(--contract-specs_table-border-radius) var(--contract-specs_table-border-radius) 0 0;

    .search-banner__input {
      width: 100%;
      max-width: 360px;
      padding-inline-start: 42px;
      padding-inline-end: 8px;
      background-color: c.$neutral-accent-background;
      border-color: c.$neutral-accent-background;
      background-size: 16px 16px;
      background-origin: border-box;
      background-position: 16px center;

      &::placeholder {
        color: c.$dark-60;
      }
    }
  }

  &__clear-filters {
    &-button {
      @extend %button-styled-as-link-min-size;

      display: block;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: c.$dark-default;
      text-decoration: underline;
    }
  }

  .toggle-expand-collapse-button {
    --expand-button-icon-url: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 11C7.5 11.5523 7.94772 12 8.5 12H10.5C11.0523 12 11.5 11.5523 11.5 11C11.5 10.4477 11.0523 10 10.5 10H8.5C7.94772 10 7.5 10.4477 7.5 11ZM1.5 0C0.947716 0 0.5 0.447715 0.5 1C0.5 1.55228 0.947715 2 1.5 2H17.5C18.0523 2 18.5 1.55228 18.5 1C18.5 0.447715 18.0523 0 17.5 0H1.5ZM3.5 6C3.5 6.55228 3.94772 7 4.5 7H14.5C15.0523 7 15.5 6.55228 15.5 6C15.5 5.44772 15.0523 5 14.5 5H4.5C3.94772 5 3.5 5.44772 3.5 6Z' fill='%230E1D31'/%3E%3C/svg%3E%0A");
  }
}

.small-view,
.medium-view {
  .contractspecs-filters {
    &__filter-bar {
      margin-bottom: 16px;
    }

    &__select-fields {
      display: none;
    }

    .filters__field-checkboxes {
      height: 100%;
      margin: 10px 0 0 0;
    }

    &__radio {
      margin: 10px 0 0 0;
    }

    .search-banner__input {
      max-width: fit-content;
    }
  }
}

.small-view {
  .contractspecs-filters {    
    &__filter-bar {
      flex-direction: column;
      align-items: flex-start;
      
      & > * {
        max-width: none;
      }

      .dropdown-filter {
        &,
        & .dropdown-wrapper {
          &,
          & select {
            width: 100%;
          }
        }
      }
    }

    .search-banner__input {
      max-width: none;
    }
  }
}

.medium-view {
  .contractspecs-filters {
    &__filter-bar {
      flex-wrap: wrap;

      & > * {
        max-width: fit-content;
      }
    }

    .search-banner__input {
      max-width: fit-content;
    }
  }
}

html[dir="rtl"] {
  .contractspecs-filters {
    &__search {
      .search-banner__input {
        padding-inline-end: 8px;
        padding-inline-start: 42px;
        background-position: right 16px center;
      }
    }
  }
  
}