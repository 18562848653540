@use "./CardWithIcon/CardWithIcon";
@use "../../../global-styles/palette" as c;

.card-with-icon-deck {
    display: flex;
    flex-direction: column;

    &__main-title {
        max-width: 744px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 32px;
        text-align: center;
        h2 {
            color: c.$dark-default
        }
        p{
            margin-top: 16px;
            color: c.$dark-80;
        }
    }
    &__links {
        padding-top: 40px;
    }
    &__link {
        display: block;
    }
    &__primaryLink {
        padding: 17px 34px;
        font-size: 21px;
        text-decoration: none;
        border-radius: 28px;
        color: c.$pure-white;
        background-color: c.$tm-green-default;
    }
    &__primaryLink:hover {
        background-color: c.$tm-green-120;
    }
    &__secondaryLink {
        font-size: 17px;
        color: c.$blue-lagoon-default;
        text-decoration: none;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
    }

    .with-btn {
        margin: 40px 0 80px 0;
    }
    .without-btn {
        margin: 40px 0 40px 0;
    }
}
.large-view {
    .card-with-icon-deck {
        &__links {
            display: flex;
            flex-direction: row;
            gap: 40px;
            justify-content: center;
            align-items: center;
        }
    }
}

.small-view, .medium-view {
    .card-with-icon-deck {
        align-items: center;

        &__main-title {
            margin-bottom: 24px;
            max-width: 327px;
        }

        &__links {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

    }
}