@use "../../global-styles/palette" as c;

$component-root-class: section-with-awards-root;

.#{$component-root-class} {
  .g-container {
    h2 {
      color: c.$dark-90;
      text-align: center;
      font-size: 34px;
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 40px;
    }

    .#{$component-root-class}__cardsWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px 12px;

      .section-with-awards-card-root {
        width: fit-content;
        border-radius: 16px;
        padding: 24px 12px;
        border: 1px solid c.$grey-card-border;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        p {
          color: c.$dark-90;
          width: 135px;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 150%;
        }

        img {
          max-width: 80px;
        }
      }
    }
  }
}

.medium-view {
  .#{$component-root-class} {
    .g-container {
      .#{$component-root-class}__cardsWrapper {
        gap: 20px;
        max-width: 860px;
        margin-inline: auto;

        .section-with-awards-card-root {
          p {
            width: 185px;
          }
        }
      }
    }
  }
}

.large-view {
  .#{$component-root-class} {
    .g-container {
      h2 {
        font-size: 48px;
      }

      .#{$component-root-class}__cardsWrapper {
        gap: 18px;
        
        .section-with-awards-card-root {
          p {
            width: 185px;
          }
        }
      }
    }
  }
}
