@use "../../../../global-styles/variables" as v;

.simplelightbox {
    --video-spacing-x: 30px;
    --video-spacing-y: 30px;
    --close-icon-size: 44px;
    --video-ratio: 16/9;
    --video-ratio-reversed: 9/16;
    --video-width: calc(100vw - 2 * var(--video-spacing-x));
    --video-height: calc(100vh - 2 * var(--video-spacing-y) - var(--close-icon-size));
    --video-width-constraint: calc(var(--video-height) * var(--video-ratio));
    --video-height-constraint: calc(var(--video-width) * var(--video-ratio-reversed));

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__close-icon-wrapper {
        width: var(--video-width);
        max-width: var(--video-width-constraint);
    }

    &__close-icon {
        display: flex;
        justify-content: flex-end;
        margin: 10px;
        cursor: pointer;

        svg {
            width: var(--close-icon-size);
            height: var(--close-icon-size);
        }
    }

    &__content-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &--youtube {
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &--on {
        position: fixed;
        z-index: v.$lightbox-z-index;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        background-color: rgba(14, 29, 49, 0.8);
        transform: translate3d(0, 0, 0);

        .simplelightbox__content-container {
            position: static;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            height: 100%;

            &--youtube {
                iframe {
                    width: var(--video-width);
                    height: var(--video-height);
                    max-width: var(--video-width-constraint);
                    max-height: var(--video-height-constraint);
                }
            }
        }
    }
}

.large-view {
    .simplelightbox {
        --video-spacing-x: 150px;
        --video-spacing-y: 130px;
    }
}