@use "../../../global-styles/palette" as c;
@use "./VideoCardDeckSliderCard/VideoCardDeckSliderCard";

.video-card-deck-slider-g-container-fullwidth-outer-background {
  width: 100%
}

.video-card-deck-slider {
  margin-top: 80px;
  width: 100%;

  .g-container {
    margin-left: calc((100% - 1128px) / 2);
    margin-right: 0px;
  }

  &__container {

    .video-card-deck-slider__swiperWrapper>.g-container {
      margin-right: 0px;
      margin-left: 0px;
      max-width: unset !important;
    }

  }

  &__mainTitle {
    max-width: 744px;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    color: c.$dark-default;
    margin-bottom: 24px;
  }

  .swiper-slide {
    height: 376px;
    width: 360px !important;
  }

  .simplelightbox--on .simplelightbox__content-container {
    background: rgba(14, 29, 49, 0.80);
  }

  .youtube-video-iframe {
    border-radius: 16px;
    height: 264px;
  }

  .simplelightbox {
    position: relative;
    height: 376px;
    min-width: 360px;
    background-color: c.$neutral-accent-background;
    border-radius: 16px;
  }

  .simplelightbox:hover {
    background-color: c.$neutral-accent-default;
  }

  .simplelightbox--on:first-child {
    margin-left: unset !important;
  }

  .simplelightbox--on {
    height: 100% !important;
    position: fixed !important;

    .youtube-video-iframe {
      border-radius: unset !important;
    }

    .video-card-deck-slider-card__text-container {
      display: none !important;
    }
  }

  .video-card-deck-slider-card__lightbox-trigger {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }

  .video-card-deck-slider-card__slide-upload-time {
    margin: 16px 24px 8px 24px;

    p {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .video-card-deck-slider-card__slide-title {
    margin-left: 24px;
  }

  .swiper-backface-hidden .swiper-slide {
    transform: none;
  }

  .button-v2-wrapper {
    margin-top: 32px;
  }
}

.medium-view {
  .video-card-deck-slider>.g-container {
    margin-left: calc((100% - 600px) / 2);
  }

  .video-card-deck-slider {
    &__container>.g-container {
      margin-left: 0px;
    }

    &__mainTitle {
      max-width: 600px;
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
    }

    .swiper-slide {
      width: 288px !important;
      height: 315px;
    }

    .simplelightbox {
      min-width: 288px;
      height: 315px;
    }

    .youtube-video-iframe {
      height: 211px;
    }

    .swiper-backface-hidden .swiper-slide {
      transform: none;
    }
  }
}

.small-view {
  .video-card-deck-slider>.g-container {
    margin-left: calc((100% - 327px) / 2);
  }

  .video-card-deck-slider {
    &__mainTitle {
      max-width: 327px;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
    }

    .swiper-slide {
      width: 270px !important;
      height: 302px;
    }

    .simplelightbox {
      min-width: 270px;
      height: 302px;
    }

    .youtube-video-iframe {
      height: 198px;
    }
  }
}

.large-view[dir="rtl"] {
  .video-card-deck-slider {
    .g-container {
      margin-right: auto;
    }
  }
}