@use "../../global-styles/palette" as c;

.awards{
    &__title{
        text-align: center;
        max-width: 744px;
        margin: 0 auto 24px auto;
    }
    &__wrapper{
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;

        .swiper {
            width: 100%;

            .swiper-wrapper {
                .small-award-card {
                    width: 100%;
                }
            }
        }


    }

    &__bullet {
        width: 8px;
        height: 8px;
        background-color: c.$dark-20;
        margin: 0 8px;
        border-radius: 50%;
        display: none;

        &.swiper-pagination-bullet-active{
            background-color: c.$dark-40;
        }
    }
    &__pagination{
        display: flex;
        justify-content: center;
        padding: 16px 0;
    }
    .swiper-button-disabled{
        filter: grayscale(1);
    }
    .swiper-button-prev,.swiper-button-next{
        svg{
            color: c.$blue-lagoon-default;       
        }
    }
}

.large-view{
    .awards{
        &__title{
            margin-bottom: 32px;
        }
        .swiper-button-prev,.swiper-button-next,.awards__pagination{
            display: none;
        }
    }
}