@use "../../global-styles/palette" as v;

.profile-section{
    display: flex;
    gap: 56px;
    align-items: flex-start;
    padding: 40px 0 20px;
    img{
        width: 160px;
        height: 160px;
    }
    &__content{
        display: flex;
        flex-direction: column;

    }
    .h4-bold,.p-regular{
        color: v.$dark-80;
        margin-top: 8px;
    }
    .p-regular{
        p{
            margin-bottom: 20px;
        }
    }
}

.large-view{
    .profile-section{
        padding: 60px 0;
        img{
            width: 240px;
            height: 240px;
        }
    }
}

.medium-view{
    .profile-section{
        gap: 24px;
        img{
            width: 180px;
            height: 180px;
        }
    }
}

.small-view{
    .profile-section{
        flex-direction: column;
        justify-self: center;
        align-items: center;
        text-align: center;
        gap: 24px;
    }
}
