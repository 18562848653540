.g-row {
    display: flex;
    overflow: hidden;
}
.large-view {
    .g-row {
        gap: 24px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}
.small-view, .medium-view {
    .g-row {
        gap: 16px;
        flex-direction: column;
    }
}