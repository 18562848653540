@use "../../global-styles/palette" as c;

.broker-location-map {
  color: var(--text-color);

  &__title {
    color: inherit;
    text-align: center;
    white-space: pre-line;
  }

  &__subtitle {
    color: inherit;
    text-align: center;
    white-space: pre-line;
    margin: 24px 0;
  }

  &__mapImage {
      width: 100%;
  }
}