@use "../../global-styles/palette" as c;

.wide-card-with-list-v2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__top-level-flex {
    display: flex;
    gap: 40px;
    >div{
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__link-container{
    margin-top: auto;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__richtext1 {
    margin-bottom: 24px;
  }

  &__richtext2 {
    margin-bottom: 32px;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  .centered-container {
    display: flex;
    text-align: start !important;
  }

  &__gap-item1 {
    gap: 18px;
  }

  &__gap-item2 {
    gap: 8px;
  }

  &__list {
    li {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    margin-bottom: 32px;
  }

  .button {
    border-radius: 38px;
    text-decoration: none;
    border: solid 1px c.$dark-20;
  }
}

.small-view,
.medium-view {
  .wide-card-with-list-v2 {
    &__top-level-flex {
      flex-direction: column;
    }

    &__title {
      margin-bottom: 20px;
    }
  }
}