@use "../../../global-styles/palette" as c;

.basic-banner-with-changing-text-position {
    .breadcrumbs__home-link {
        svg{
            path {
                fill: c.$dark-default;
            }
        }
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 64px;
    }

    &__wrapper {
        max-width: 744px;
    }

    &__title-container {
        text-align: center;
        margin-bottom: 8px;

        h1 {
            font-size: 38px;
            line-height: 48px;
        }
    }

    &__subtitle-container {
        text-align: center;

        h5 {
            font-weight: 500;
        }
    }
}


.medium-view {
    .basic-banner-with-changing-text-position {

        &__text-container {
            margin-top: 32px;
            margin-bottom: 48px;
        }

        &__title-container {
    
            h1 {
                font-size: 38px;
                line-height: 38px;
            }
        }

        &__wrapper {
            max-width: 600px;
        }

    }
}

.small-view {
    .basic-banner-with-changing-text-position {

        &__text-container {
            margin-top: 16px;
            margin-bottom: 64px;
        }

        &__wrapper {
            max-width: 327px;
        }

        &__title-container {
            margin-bottom: 16px;

            h1 {
                font-size: 26px;
                line-height: 32px;
            }
        }
    }
}