@use "./ListItem/ListItem";

.section-with-list-versioned__root {
    &__textsWrapper {
        display: grid;
        row-gap: 24px;

        h3 {
            margin-bottom: 8px;
        }

        .button-v2-wrapper {
            margin-block: 8px 32px;
            width: 100%;

            .cta-large-wbg {
                margin-right: auto;
            }
            
            &:dir(rtl){
                .cta-large-wbg {
                    margin: 0 0 0 auto;
                }
            }
        }
    }

    &__imageWrapper {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.large-view {
    .section-with-list-versioned__root {
        display: flex;

        &__textsWrapper {
            h3 {
                font-size: 48px;
                font-weight: 600;
                line-height: 56px;
            }

            .button-v2-wrapper {
                margin: 8px 0 0 0;
            }
        }
    }
}