@use "../../global-styles/palette" as c;
@use "./License/License";

.license-slider-root {
    position: relative;
    isolation: isolate;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 50px;
        height: 100%;
        z-index: 2;
    }

    &::before {
        left: 0;
        background: linear-gradient(to right, var(--gradient-start-color, #FFF), rgba(255, 255, 255, 0));
    }

    &::after {
        right: 0;
        background: linear-gradient(to left, var(--gradient-start-color, #FFF), rgba(255, 255, 255, 0));
    }

    p.text-nav {
        text-align: center;
        margin-bottom: 20px;
        color: c.$dark-default;
    }

    .swiper-wrapper {
        transition-timing-function : linear;
    }
}

.small-view,
.medium-view,
.large-view {
    .license-slider-root__container {
        &.g-container {
            margin-inline: auto;
        }
    }
}

.medium-view {
    .license-slider-root {
        p.text-nav {
            line-height: 16px;
            margin-bottom: 32px;
        }

        &__license-wrapper {
            .swiper {
                .swiper-wrapper {
                    display: flex;
                    align-items: center;

                    .swiper-slide {
                        width: 256px !important;
                    }
                }
            }

        }
    }
}

.large-view {
    .license-slider-root {
        .swiper-slide{
            width: 270px;
        }
        &::before,
        &::after {
            width: 100px;
        }

        p.text-nav {
            line-height: 16px;
            margin-bottom: 32px;
        }

        &__license-wrapper {
            .swiper {
                .swiper-wrapper {
                    align-items: center;
                }
            }
        }
    }
}

.small-view{
    .license-slider-root{
        .swiper-slide{
            width: 150px;
        }
    }
}