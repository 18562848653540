@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;
@use "../../../global-styles/typography";
@use "./SearchResultItem/SearchResultItem";

.search-results {
  width: 100%;

  .global-search__input {
    .global-search__input-container {
      .search-input {
        align-items: baseline;
        input {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='jmizk30oca' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg clip-path='url(%23k0omiwei5b)' fill='%236E7783'%3E%3Cpath d='M10.4 5.6a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6zm0-1.6a6.4 6.4 0 1 1 0 12.8 6.4 6.4 0 0 1 0-12.8z'/%3E%3Cpath d='M13.834 14.966a.8.8 0 0 1 1.132-1.132l4.8 4.8a.8.8 0 0 1-1.132 1.132l-4.8-4.8z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='k0omiwei5b'%3E%3Cpath fill='%23fff' transform='translate(4 4)' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: 10px center;
          padding-left: 40px;
          box-sizing: border-box;
          margin: 32px 0 40px 0;
          border: none;
          background-color: c.$neutral-accent-background;
          max-width: v.$card-standard-width;
        }
      }
    }
  }

  h1.search-results__title {
    color: c.$dark-default;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    width: fit-content;
  }

  &__subtitle,
  p.search-results__subtitle {
    @extend %typography--desktop-caption;

    color: c.$dark-default;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__container {
    background-color: c.$pure-white;

    .search-results__list {
      display: grid;
      row-gap: 30px;
      margin: 32px 0;

      .no-results {
        display: grid;
        justify-items: center;

        svg {
          margin-bottom: 12px;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px auto 0 auto;

    &-text {
      margin-top: 8px;
    }
  }

  .jobBoard__paginationArrow,
  .jobBoard__paginationBtn:not(.jobBoard__paginationBtn--active) {
    background-color: transparent;
  }
}

.medium-view {
  .search-results {
    .global-search__input {
      .global-search__input-container {
        .search-input {
          input {
            max-width: v.$card-standard-width;
          }
        }
      }
    }
  }
}

.large-view {
  .search-results {
    width: fit-content;
    &__container {
      margin: 0;

      .search-results__list {
        row-gap: 0px;
        max-width: 704px;

        .no-results {
          min-width: 650px;
        }
      }
    }

    &__result + .search-results__result {
      margin-top: 32px;
    }

    .search-results {
      .global-search__input {
        .global-search__input-container {
          .search-input {
            input {
              max-width: v.$card-standard-width;
            }
          }
        }
      }
    }
  }
}
