@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.cards-grid-image-and-description {
  &__cards-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 16px;
  }

  &__card-container {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background-color: c.$neutral-accent-dark-bg-15;
    text-decoration: none;
    color: c.$pure-white;
  }

  &__card-container:hover {
    background-color: c.$neutral-accent-dark-bg-10;
  }

  &__card-image-container {
    width: 150px;
    height: 78px;
  }

  &__card-image {
    width: 120px;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
}

.large-view {
  .cards-grid-image-and-description {
    &__cards-grid {

      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}