@use "../../../global-styles/palette" as c;
@use "./SellBuyPriceCard/SellBuyPriceCard";

.sell-buy-price-cards-deck {
    display: flex;
    flex-direction: column;

    &__text-wrapper {
        max-width: 700px;
        margin: 0px auto 40px auto;

        h2 {
            color: c.$dark-90;
            font-size: 48px;
            font-weight: 700;
            line-height: 110%;
            text-align: center;
            margin-bottom: 20px;
        }

        p.sell-buy-price-cards-deck__text-subtitle {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            text-align: center;
            color: c.$dark-80;
        }
    }

    &__cards-wrapper {
        display: flex;
        justify-content: center;
        gap: 12px;
    }

    &__free-node {
        p {
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            color: c.$dark-60;
        }
    }
}

.large-view {
    .sell-buy-price-cards-deck {
        &__free-node {
            p {
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                color: c.$dark-60;
            }
        }
    }
}

.medium-view {
    .sell-buy-price-cards-deck {
        &__text-wrapper {
            max-width: 665px;

            h2 {
                font-size: 34px;
            }

            p.sell-buy-price-cards-deck__text-subtitle {
                font-size: 16px;
            }
        }

        &__free-node {
            p {
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                color: c.$dark-60;
            }
        }
    }
}

.small-view {
    .sell-buy-price-cards-deck {
        margin-bottom: 20px;
        
        &__text-wrapper {
            max-width: 335px;
        }

        h2 {
            font-size: 34px;
        }

        p.sell-buy-price-cards-deck__text-subtitle {
            font-size: 16px;
        }

        &__cards-wrapper {
            flex-wrap: wrap;
        }

        &__free-node {
            p {
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                color: c.$dark-60;
                margin-top: 20px;
            }
        }
    }
}