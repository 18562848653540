@use "../../global-styles/palette" as c;

.profile-articles{
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }
    section{
        max-width: unset;
    }
    .academyArticleCardDeck__title-and-radio, .mobile-div, .desktop-div{
        display: none;
    }
    .newsCardDeck{
        padding: 0;
    }
}
.medium-view,.small-view{
    .profile-articles{
        .g-container{
            margin: 0;
            min-width: 100%;
        }
    }
}
.small-view{
    .profile-articles{
        &__header{
            flex-direction: column-reverse;
            margin-bottom: 20px;
            width: 100%;
            h4{
                width: 100%;
            }
            .academyArticleCardDeck__radio{
                width: 100%;
                margin-bottom: 32px;
                div{
                    width: 50%;
                }
            }
        }
        .g-container.g-container--fullwidth{
            min-width: 100%;
        }
    }
}
