@use "../../../../global-styles/palette" as c;

.mobile-small-award-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;

    img {
        width: 71px;
        height: 48px;
        margin-bottom: 12px;
    }

    .mobile-small-award-card__title {
        color: c.$dark-default;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.4px;
        text-transform: capitalize;
    }

    p.mobile-small-award-card__subtitle {
        color: c.$dark-default;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        margin-block: 12px 8px;
    }

    p.mobile-small-award-card__date {
        color: c.$dark-60;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
    }
}