@use "../../../../global-styles/palette" as c;

.small-award-card {
    width: 215px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__award-wrapper {
        display: flex;
        align-items: center;
        &:dir(rtl){
            flex-direction: row-reverse;
        }

        p.small-award-card__award-wrapper__title {
            color: c.$dark-default;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 22.4px;
            text-transform: capitalize;
            width: 140px;
        }
    }

    p.small-award-card__subtitle {
        color: c.$dark-default;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        margin-block: 12px 8px;
    }

    p.small-award-card__date {
        color: c.$dark-60;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
    }
}