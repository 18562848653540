@use "../../../../global-styles/palette" as c;

.link-pill {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 340px;
    max-width: 100%;
    min-height: 48px;
    padding: 12px 24px;
    text-decoration: none;
    color: c.$pure-white;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;

    &--regular-button {
        border-radius: 38px;
        background-color: #1D4ED8;
        color: c.$pure-white;

        &:hover {
            background-color: #2563EB;
        }
    }

    &--mobile-app-button {
        border-radius: 48px;
        background-color: #ECEDEE;
        color: c.$dark-default;

        &:hover {
            background-color: #E2E8F0;
        }
    }
}

.medium-view,
.large-view {
    .link-pill {
        width: auto;

        &--mobile-app-button {
            background-color: c.$pure-white;

            &:hover {
                background-color: #E2E8F0;
            }
        }
    }
}