@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;

.download-button-deck{
    &__container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
    }
    h3{
        margin-bottom: 32px;
    }
}
.medium-view{
    .download-button-deck{
        &__container{
            grid-template-columns: 1fr 1fr;
        }
    }
}

.small-view{
    .download-button-deck{
        &__container{
            grid-template-columns: 1fr;
        }
    }
}