@use "../../global-styles/palette" as c;

.SectionWithList.TradeWithComponentWrapper{
  display: flex;
  padding: 16px 0;
  column-gap: 24px;
  >div{
    flex: 1;
  }
  & > div:first-child {
    h2 {
      margin-bottom: 16px;
    }    
  }

  .TradeWithComponentWrapper__sectionWrapper{
    margin-top: 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .TradeWithComponentWrapper__sectionWrapper__section{
    justify-content: flex-start;
  }
}
.medium-view{
  .SectionWithList{
    .TradeWithComponentWrapper__mainTitle{
      margin-top: 0;
    }
  }
}
.small-view{
  .SectionWithList.TradeWithComponentWrapper{
    padding: 0;
    flex-direction: column;
    .TradeWithComponentWrapper__sectionWrapper{
      margin-top: 24px;
    }
  }
}