@use "../../../global-styles/palette" as c;

.alphabets-search-card {
    text-decoration: none;

    &__title {
        background-color: c.$neutral-accent-background;
        width: 34px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 8px;
        color: c.$pure-black;
        text-align: center;
    }
    svg{
        path {
            fill: #000;
        }
    }
    span{
        display: flex;
    }

    &__title:hover,&__title--active {
        background-color: c.$neutral-accent-default;
    }
}