@use "../../../global-styles/palette" as c;
@use "./SingleCard/SingleCard";


.cards-deck-with-title-sub-swiper__root {
    h3 {
        margin-bottom: 32px;
        text-align: center;
    }

    &__swiper-view {
        .swiper-pagination {
            display: none;
            justify-content: center;
            margin-top: 17px;

            .cards-deck-with-title-sub-swipe__bullet {
                display: block;
                width: 8px;
                height: 8px;
                background-color: c.$dark-10;
                margin: 0;
                border-radius: 50%;

                &.swiper-pagination-bullet-active {
                    background-color: c.$dark-40;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.small-view,
.medium-view {
    .cards-deck-with-title-sub-swiper__root {
        h3 {
            text-align: start;
        }

        &__swiper-view {
            .swiper-pagination {
                display: flex;

                .cards-deck-with-title-sub-swipe__bullet {
                    &:nth-child(even) {
                        margin-inline: 8px;
                    }
                }
            }
        }
    }
}