@use "../../../../global-styles/palette" as c;

.simple-icon-and-text-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    img {
        margin-bottom: 20px;
    }

    &__title {
        color: c.$dark-default;
        text-align: center;
    }

    &__text {
        color: c.$dark-60;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
    }
}

.large-view {
    .simple-icon-and-text-card {
        &__title {
            font-size: 24px;
        }

        &__text {
            font-size: 16px;
        }
    }
}