@use "../../../global-styles/palette" as c;

.small-card-with-animated-icon {
    border-radius: 16px;
    border: 1px solid #E6E6E6;
    background: c.$pure-white;
    box-shadow: 0px 1.5px 4px -1px rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        gap: 8px;
        color: c.$dark-default;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
    }
    svg {
        max-height: 64px;
        max-width: 64px;
    }
}

.large-view {
    .small-card-with-animated-icon {
        min-width: 221.72px;
        min-height: 134px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1566px){
    .large-view {
        .small-card-with-animated-icon {
            min-width: 221.01px;
            min-height: 138.01px;
        }
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1440px){
    .large-view {
        .small-card-with-animated-icon {
            min-width: 210.01px;
            min-height: 138.01px;
        }
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1365px){
    .large-view {
        .small-card-with-animated-icon {
            min-width: 202.01px;
            min-height: 128.01px;
        }
    }
}

.medium-view {
    .small-card-with-animated-icon {
        min-width: 147.29px;
        min-height: 130.01px;
    }
}

@media only screen and (max-width: 768px){
    .medium-view, .small-view {
        .small-card-with-animated-icon {
            min-width: 138.01px;
            min-height: 130.01px;
        }
    }
}