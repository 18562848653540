@use "../../global-styles/palette" as c;

.ribbon-without-stat-line-root {
  &--scheme-green {
    --border-color: #{c.$tm-light-border-green};
    --bkgr-color: #{c.$light-green-bg};
    --text-color: #{c.$tm-green-130};  
  }

  &--scheme-lightpurple {
    --border-color: #DDD8F8;
    --bkgr-color: #{c.$background-gray};
    --text-color: #484365;  
  }


  .g-container {
    border-radius: 20px;
    border: 1px solid var(--border-color);
    background-color: var(--bkgr-color);

    .ribbon-without-stat-line-root__contentWrapper {
      display: grid;
      padding: 24px 24px 24px 32px;

      &__textWrapper {
        h4>span {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          margin-bottom: 4px;
        }
      }

      &__subtitle {
        color: var(--text-color);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: start;
        margin-bottom: 20px;
      }

      &__buttonsWrapper {
        display: flex;
        gap: 6px;

        a.ribbon-without-stat-line-root__contentWrapper__link {
          white-space: nowrap;
          padding: 24px;
          font-weight: 700;
          color: c.$dark-default;
        }
      }

      .button-v2-wrapper {
        width: 100%;

        a {
          margin-right: auto;
        }
      }

      .button-v2-wrapper:dir(rtl) {
        a {
          margin: 0 0 0 auto;
        }
      }

    }
  }
}

.small-view {
  .ribbon-without-stat-line-root {
    .g-container {
      .ribbon-without-stat-line-root__contentWrapper {
        &__textWrapper {
          h4 {
            max-width: 272px;
          }
        }

        &__subtitle {
          max-width: 272px;
        }

        &__buttonsWrapper {
          flex-direction: column;
          gap: 12px;
  
          a.ribbon-without-stat-line-root__contentWrapper__link {
            padding: 8px 20px;
          }
        }
      }
    }
  }
}

.medium-view {
  .ribbon-without-stat-line-root {
    .g-container {
      .ribbon-without-stat-line-root__contentWrapper {
        grid-auto-flow: column;
        align-items: center;
        column-gap: 20px;

        &__textWrapper {
          h4 {
            max-width: 352px;
          }

          h4>span {
            margin: 0;
            font-size: 24px;
          }
        }

        &__subtitle {
          margin: 0;
        }

        &__buttonsWrapper {
          flex-direction: column;
          align-items: center;
          gap: unset;

          a.ribbon-without-stat-line-root__contentWrapper__link {
            padding: 8px 20px;
          }
        }
      }
    }
  }
}

.large-view {
  .ribbon-without-stat-line-root {
    .g-container {
      .ribbon-without-stat-line-root__contentWrapper {
        grid-auto-flow: column;

        &__subtitle {
          margin: 0;
        }

        .button-v2-wrapper {
          width: 100%;

          a {
            margin-right: unset;
            margin-left: auto;
          }
        }
      }

      .ribbon-without-stat-line-root__contentWrapper:dir(rtl) {
        justify-content: space-between;
      }
    }
  }
}