@use "./FeaturesList/FeaturesList";
@use "./CheckOutButton/CheckOutButton";

.stats-line-ribbon-banner__root {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;

    &__features {
        display: flex;
        justify-content: space-between;
        column-gap: 12px;
    }
}

.large-view {
    .stats-line-ribbon-banner__root {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        &__features {
            column-gap: 32px;
        }
    }
}