@use "../../global-styles/palette" as c;
@use "../../global-styles/typography";

.signalCentreTabSlider {

  h2 {
    margin-bottom: 32px;
    text-align: center;
  }

  &__sliderWrap {
    max-width: 1128px;
    margin: 0 auto;
  }

  &__swiper {
    background-color: c.$pure-white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
  }

  .swiper-slide {
    background-color: c.$pure-white;
    display: flex;
    height: auto;
  }

  &__card {
    box-sizing: border-box;
    min-height: 232px; 
    padding: 40px 48px;
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    margin-bottom: 40px;
    color: c.$dark-80;
  }

  &__steps {
    display: flex;
  }

  &__step {
    p {
        color: c.$dark-80;
    }
  }

  .step-border {
    border-right: 1px solid c.$dark-20;
    border-left: 1px solid c.$dark-20;
  }

  .p-l {
    padding-left: 47px;
    margin-right: 47px;
    margin-left: 47px;
    padding-right: 47px;
  }

  &__step-title {
    margin-bottom: 16px;
  }

  &__step-title-step {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: c.$dark-40;
    text-transform: uppercase;
    margin-right: 9px;
  }

  &__step-title-number {
    font-size: 61px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    color: c.$blue-lagoon-60;
  }

  &__description {
    max-width: 280px;

    a {
        color: c.$blue-lagoon-default;
        text-decoration: none;
        font-weight: bold;
    }

    strong {
        font-weight: bold;
        color: c.$dark-80;
    }
  }

  &__btns {
    margin-top: 24px;
  }

  &__btn {
    margin-right: 16px;
  }

  &__link {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-decoration: none;
    color: c.$blue-lagoon-default;
  }

  &__customPagination {
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }

  &__customPagination::-webkit-scrollbar { 
    display: none;  
  }

  &__bullets {
    @extend %slider-tab-text;

    height: 56px;
    display: flex;
    align-items: center;
    background-color: c.$dark-20;
    border-top-right-radius: 12px;  
    border-top-left-radius: 12px; 
    cursor: pointer; 
    color: c.$dark-default;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    -webkit-tap-highlight-color: transparent;
    padding: 0 24px;
  }

  &__bullets:not(:last-child) {
    margin-right: 16px;
  }

  .swiper-pagination-bullet-active {
    background-color: c.$pure-white;
  }
}

.small-view, .medium-view {
  .signalCentreTabSlider {

    &__title {
      width: 90%;
      margin: 0px auto 24px auto;
    }

    &__swiper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &__bullets {
        font-size: 16px;
        line-height: 1.5;
        justify-content: unset;
        padding: 0 24px;
        min-width: unset;
    }

    &__card {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 24px;
    }

    &__subtitle {
        margin-bottom: 24px;
    }

    &__steps {
        flex-direction: column;
    }

    &__step {
        padding-right: unset;
        width: 98%;
    }

    &__step-title-step {
        font-size: 28px;
    }
    
    &__step-title-number {
        font-size: 48px;
    }

    &__description {
        max-width: unset;
    }

    .step-border {
        border-right: unset;
        border-left: unset;
        border-top: 1px solid #CFD2D6;
        border-bottom: 1px solid #CFD2D6;
        margin: 24px 0;
    }

    .p-l {
        padding-left: unset;
        padding: 24px 0;
    }
    
  }
}