@use "../../global-styles/palette" as c;

.bubbleTitleDescButtons {
    background-color: c.$pure-white;

    a {
        color: c.$dark-default;
        font-size: 16px;
        font-style: normal;
        line-height: 24px
    }

    &__main-container {
        padding: 32px 0 32px 0;
        background-color: c.$neutral-accent-background;
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 552px;
        margin: 0px auto;
        gap: 8px;
        text-align: center;
        padding-inline: 32px;
        p {
            max-width: 279px;
        }
    }

    &__buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__main-button {
        padding: 8px 24px;
        border-radius: 4px;
        background-color: c.$tm-green-default;
        text-decoration: none;
        font-weight: 500;
    }

    &__demo-link {
        font-weight: 700;
        padding: 8px 24px;
    }

}

.small-view {
    .bubbleTitleDescButtons {
        &__with-arrow::after {
            content: '>';
            display: inline-block;
            font-size: 1.2em;
            margin-left: 2px;
            line-height: 1;
            transform: translateY(1px);
        }
    }
}

.medium-view {
    .bubbleTitleDescButtons {

        &__main-container {
            padding: 40px 0px;
            margin: 0px auto;
            margin-bottom: 48px;
        }

        &__text-container {
            p {
                max-width: 520px;
            }
        }

        &__buttons-container {
            flex-direction: row;
            justify-content: center;
        }
    }
}

.large-view {

    .bubbleTitleDescButtons {

        &__main-container {
            padding: 64px 0px;
        }

        &__text-container {
            p {
                max-width: 552px;
            }
        }

        &__buttons-container {
            flex-direction: row;
            justify-content: center;
        }
    }
}