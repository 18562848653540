@use "../../global-styles/palette" as c;

.hero-banner-instruments {
  background-color: #fff;
  box-sizing: border-box;

  &__desktop-title{
    display: block;
    margin-bottom: 16px;
  }
  &__mobile-title{
    display: none;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content{
    color: c.$dark-80;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0;
    text-decoration: none;
    margin-bottom: 40px;
    ul{
      list-style-type: disc;
      padding-left: 10px;
      margin-left: 10px;
    }
    li{
      margin-top: 16px;
    }
  }
  &__text {
    max-width: 552px;
    
    h2 {
      margin-bottom: 20px;
      color: c.$dark-default;
    }
  }
  &__image{
    max-width: 50%;
  }
    &__cta {
      display: inline-block;
      margin-top: 30px;
      padding: 10.5px 25px;
      border-radius: 8px;
      background-color: c.$tm-green-default;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: c.$dark-default;
      cursor: pointer;
      outline: none;
      border: none;
      text-decoration: none;
      min-width: 168px;
      text-align: center;
      white-space: nowrap;
      box-sizing: border-box;
    }
}
.large-view{
  .hero-banner-instruments{
    .button-v2-wrapper{
      justify-content: flex-start;
    }
  }
}
.small-view {
  .hero-banner-instruments {
    &__desktop-title{
      margin: 16px 0;
    }
    &__inner{
      flex-direction: column-reverse;
    }
    &__image{
      max-width: 100%;
    }
    &__text {
      width: 100%;
      display: flex;
      margin: 0;
      flex-direction: column;
      align-items: center;
      max-width: unset;

      h2 {
        text-align: center;
      }

      p {
        max-width: 456px;
        margin-top: 25px;
      }
    }

    &__content{
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
}