@use "../../global-styles/palette" as c;

$component-root-class: access-think-portal-today-img-toggle;

.#{$component-root-class} {
    &__inner {
        display: flex;
        gap: 40px;
    }

    &__options-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    &__options {
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > * {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px 32px;
            padding: 16px 24px;

            border-radius: 16px;
            border: 1px solid #EDEDED;
        }

        h4 {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 20px;

            img {
                width: 32px;
            }
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    &__option-desktop-app {
        flex-direction: column;

        a {
            color: c.$dark-default;
            text-decoration: none;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            border-radius: 8px;
            border: 1px solid c.$dark-default;
            padding: 16px 32px;
        }
    }

    &__option-mobile-app {
        .#{$component-root-class}__option-links {
            flex-direction: row;
        }
    }

    &__option-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-inline-end: auto;

        p {
            text-align: center;
            color: #6B7B90;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
        }
    }

    &__option-links {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__img-block {
        flex-shrink: 0;
    }

    &__img-container {
        width: 656px;
        aspect-ratio: 1.7859;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__qr-code {
        width: 80px;
        height: 80px;
    }
}

.large-view,
.medium-view,
.small-view {
    .#{$component-root-class} {
        &__options {
            h4 {
                font-size: 20px;
            }
        }
    }
}

.medium-view,
.small-view {
    .#{$component-root-class} {
        &__inner {
            flex-direction: column;
        }

        &__options-container {
            order: 2;
        }

        &__img-block {
            order: 1;
        }
    }
}

.small-view {
    .#{$component-root-class} {

        &__option-mobile-app,
        &__option-web,
        &__option-desktop-app {
            flex-direction: column;

            .#{$component-root-class}__option-info {
                display: flex;
                justify-content: center;
                margin-inline-end: 0;
            }
        }
    }

    .access-think-portal-today-img-toggle__option-web {
        flex-direction: row;
    }
}

@media (pointer: coarse) {
    .#{$component-root-class} {
        &--desktop-view {
            display: none;
        }
    }
}

@media (pointer: fine) {

    .large-view,
    .medium-view,
    .small-view {
        .#{$component-root-class} {
            &--mobile-view {
                display: none;
            }
        }
    }
}