@use "../../../global-styles/palette" as c;
@use "./SmallAwardCard/SmallAwardCard";
@use "./MobileSmallAwardCard/MobileSmallAwardCard";

.small-award-cards-deck-root {
    display: flex;
    justify-content: center;

    .g-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px 20px;
        max-width: 450px;

        .small-award-cards-deck-root__title {
            width: 86%;

            h3 {
                text-align: center;
            }
        }
    }
}

.medium-view {
    .small-award-cards-deck-root {
        .g-container {
            max-width: 100%;

            .small-award-cards-deck-root__title {
                width: 92%;
            }
        }
    }
}

.large-view {
    .small-award-cards-deck-root {
        .g-container {

            .small-award-cards-deck-root__title {
                width: 80%;
            }
        }
    }
}