@use "../../global-styles/palette" as c;

.trading-view-small-button {
    display: flex;
    width: 143px;
    height: 40px;
    margin-top: 20px;
}

.medium-view {
    .trading-view-small-button {
        margin-bottom: 16px;
    }
}

.small-view {
    .trading-view-small-button {
        margin: 20px auto 16px auto;
    }
}