@use "../../global-styles/palette" as c;

.innovation-block--demo{
  .innovation-block__item-checkmark{
      margin: 4px;
  }
  .innovation-block__list{
    margin-top: 16px;
  }

  .innovation-block__paragraph {
    max-width: 552px;
    margin-top: 24px;
  }
  
}
.large-view{
  .innovation-block--demo{
    .innovation-block__content{
      padding-right: 60px;
    }
    .innovation-block__image--mobile{
      display: none;
    }
  }
}
.medium-view, .small-view{
  .innovation-block__image--mobile{
    display: block;
    max-width: 100%;
    width: auto;
  }
  .innovation-block--demo {

    .innovation-block__list{
      margin-bottom: 0px;
    }
  }
}