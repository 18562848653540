@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/palette" as c;

.accordion-card {
  padding: 32px 24px;
  border-radius: 16px;
  height: auto;
  background-color: c.$pure-white;
  transition: 0s ease-in;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &__title {
    color: c.$dark-default;
    transition: 0s ease-in;
  }
  &__text {
    color: c.$dark-60;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 32px;
    margin-top: 16px;
    transition: 0s ease-in;
  }
  &__link {
    color: c.$blue-lagoon-default;
    display: flex;
    align-items: center;
    transition: 0s ease-in;
    text-decoration: none;
    margin-top: auto;
    font-weight: bold;
    svg {
      fill: c.$blue-lagoon-default;
      transition: 0s ease-in;
    }
  }
  &__icon {
    position: absolute;
    right: -24px;
    bottom: -32px;
    transition: 0s ease-in;
    z-index: 1;
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    transition: 0s ease-in;
  }
}

.large-view,
.medium-view {
  .accordion-card {
    &__trigger {
      display: none;
    }
    &:hover {
      background-color: c.$blue-lagoon-default;
      .accordion-card__title {
        color: c.$pure-white;
      }
      .accordion-card__text {
        color: c.$pure-white;
      }
      .accordion-card__link {
        color: c.$pure-white;
        svg {
          fill: c.$pure-white;
        }
      }
      .accordion-card__icon {
        filter: brightness(0) invert(1);
        opacity: 0.5;
      }
    }
  }
}

.medium-view {
  .accordion-card {
    min-height: 200px;
  }
}

.large-view {
  .accordion-card {
    height: 100%;
    min-height: 280px;
  }
}
.small-view {
  .accordion-card {
    padding: 20px 24px;
    width: 100%;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0s ease-in;

      svg {
        min-width: 32px;
        height: 32px;
      }
    }
    &__content {
      max-height: 0;
      overflow: hidden;
      .accordion-card__icon {
        display: none;
      }
    }
    &--opened {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
      .accordion-card__content {
        transition: max-height 0.5s ease-out;
        max-height: 500px;
        overflow: visible;
        .accordion-card__icon {
          display: block;
        }
      }
      .accordion-card__trigger {
        transform: rotateX(-180deg);
      }
    }
  }
}
