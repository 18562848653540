@use "../../../global-styles/palette" as c;

.trading-view-download-banner {
    display: flex;
    border-radius: 20px;
    background: c.$pure-black;

    &__text-section {
        padding: 60px 0px 60px 60px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    h2.trading-view-download-banner__text-wrapper__title-container {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        color: c.$pure-white;
    }

    .trading-view-download-banner__text-wrapper__description-container {
        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            color: c.$dark-20;
        }
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &__list-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            li {
                display: flex;
                gap: 12px;
                color: c.$pure-white;

                p {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 150%;
                }
            }
        }
    }  

    &__image-section {
        padding: 34px 0px 33px 0px;
    }

    &__link-wrapper {
        a {
            color: c.$pure-white;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%;
            text-decoration-line: underline;
            text-underline-position: under;
        }
    }
}

.small-view {
    .trading-view-download-banner {
        flex-direction: column;
        overflow: hidden;

        h2.trading-view-download-banner__text-wrapper__title-container {
            font-size: 21px;
            line-height: 28px;
        }
    
        .trading-view-download-banner__text-wrapper__description-container {
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &__text-section {
            padding: 30px 30px 0px 30px;
            gap: 24px;
        }

        &__text-wrapper {
           
            &__list-container {
               margin-top: 10px;
    
                li {
                    p {
                        font-size: 16px;
                        max-width: 239px;
                    }
                }
            }
        }

        &__image-section {
            padding: 34px 0px 0px 0px;
            display: flex;
            justify-content: flex-end;

            img {
                max-width: 285px;
            }
        }
    
    }
}

.medium-view {
    .trading-view-download-banner {
        flex-direction: column;
        max-height: 797px;
        overflow: hidden;

        h2.trading-view-download-banner__text-wrapper__title-container {
            font-size: 26px;
            line-height: 32px;
        }

        &__text-section {
            padding: 40px 40px 0px 40px;
        }

        &__image-section {
            display: flex;
            justify-content: flex-end;
        }

    }
}

.large-view {
    .trading-view-download-banner {
        &__buttons-wrapper {
            display: flex;
            gap: 12px;

            a {
                display: flex;
                gap: 12px;
                border-radius: 10px;
                border: 1px solid c.$dark-80;
                min-width: 146px;
                padding: 12px 0px 12px 12px;
                text-decoration: none;
            }
    
            &__button-text {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }
    
            &__button-title {
                color: c.$dark-40;
                font-size: 10px;
                font-weight: 500;
                line-height: 10px;
            }
    
            &__button-subTitle {
                color: c.$pure-white;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
            }
        }
    }
}


html[dir="rtl"] {

    .trading-view-download-banner {
        &__text-section {
            padding: 60px 60px 60px 0px;
        }

        &__buttons-wrapper {
            a {
                padding: 12px 12px 12px 0px;
            }
        }
    }
}
