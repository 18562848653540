@use "../../../global-styles/palette" as v;

.account-card {
    &__header-image-container {
        flex-direction: column;
        gap: 5px;

        img {
            max-height: 34px;
        }

        div {
            max-width: 246px;
        }
    }

    &__description {
        text-align: center;
    }

    &__title-upper {
        text-align: center;
        margin-bottom: 4px;
    }

    &__button-container {
        border-top: solid 2px v.$dark-10;
        padding-top: 32px;
        margin-top: 32px;
    }

    &__header-button {
        padding: 0px 24px;
        font-weight: 600;
        border-radius: 4px;
        border: 1px solid v.$dark-default;
        color: v.$dark-default;
    }

    &__account-card {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &> :first-child {
            margin-top: 20px;
        }

        &> :last-child {
            margin-bottom: 32px;
        }
    }

    &__fixed-logo {
        border-bottom: solid 2px v.$dark-10;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: v.$pure-white;
    }

    &__logo-row {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 64px;
            margin-bottom: 24px;
        }
    }

    &__logo-text h4 {
        font-size: 27px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 14px;
    }

    &__logo-dark-text {
        color: v.$dark-default;
    }

    &__logo-green-text {
        color: v.$tm-green-default;
    }

    &__list-items {
        min-width: 216px;
        padding: 32px 0 0 0;
        background-color: v.$pure-white;
    }

    &__container-scrollabe {
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: normal;
    }

    &__item:nth-child(15) {
        min-height: 48px;
    }

    &__item-title {
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: v.$dark-80;
    }

    &__item-description {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: v.$dark-default;
        margin-bottom: 3.2px;
    }

    &__bottom-title {
        margin-bottom: 36px;
    }
}

.min-height-116 {
    min-height: 116px;
}

.small-view {
    .account-card {
        &__fixed-logo {
            width: 212px;
            min-width: unset;
        }

        &__list-items {
            width: 212px;
        }
    }
}