@use "../../global-styles/palette" as v;
@import '../../global-styles/_typography';
@import '../../global-styles/asian-text-links';

.article {
  background-color: v.$pure-white;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  h2 {
    scroll-margin-top: 100px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  &__container {
    max-width: 740px;
    margin: 0 auto;
  }

  .articleBanner {
    path {
      stroke: v.$dark-default;
      fill: v.$dark-default;
    }

    &__content {
      max-width: 740px;
      margin: 0 auto;
      text-align: center;

      h1 {
        color: v.$dark-default;
      }
    }

    &__image {
      width: 100%;
      border-radius: 33px;
    }

    a.staticBanner__breadcrumb {
      color: v.$dark-default;
      white-space: nowrap;
    }

    span.staticBanner__breadcrumb {
      color: v.$dark-60;
      white-space: nowrap;
    }
  }

  .g-container {
    position: relative;
    z-index: 1;
  }

  &__content {
    background-color: v.$pure-white;
    padding: 40px 16px;
    display: flex;
    flex-direction: column;

    h2 {
      @extend %h2-small;
      margin: 40px 0px 16px 0px;
    }

    h3 {
      @extend %h3-small;
      margin: 32px 0px 8px 0px;
    }

    h4 {
      @extend %h4-small;
      margin: 32px 0px 8px 0px;
    }

    h5 {
      font-weight: bold;
      margin: 32px 0px 8px 0px;
    }

    ul {
      list-style: none;
      padding-left: 1em;
      font-size: 18px;

      &>li {
        padding-left: 1em;
        padding-bottom: .75em;

        &>ul {
          list-style: circle;

          &>li {
            padding-left: initial;

            &::before {
              content: "";
            }
          }
        }
      }

      &>li::before {
        content: "•";
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        text-align: left;
      }
    }

    ol {
      list-style: none;
      padding-left: 1em;

      &>li {
        counter-increment: list-counter;

        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;

        &>ul {
          &>li {
            list-style: circle;
            padding-left: initial;
          }

          &>li::before {
            content: "";
          }
        }
      }

      &>li::before {
        content: counter(list-counter) ". ";
        position: absolute;
        left: 0;
        top: 0;
        color: inherit;
        font-weight: bold;
        font-size: inherit;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto !important;
      margin: 24px auto 0;
      align-self: center;
    }

    a {
      text-decoration: none;
      color: v.$blue-lagoon-default;
      font-weight: bold;
    }

    strong {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-style: solid;
      margin-left: auto;
      margin-right: auto;
    }

    td,
    th {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #ddd;

      td,
      th {
        border: 1px solid #fff;
      }
    }

    tbody {
      tr {

        th {
          text-align: center;
        }

        td.width-10 {
          width: 10%;
        }

        td.width-40 {
          width: 40%;
        }

      }
    }

  }

  .faq-accordion {
    padding: 0;
  }

  .faq-accordion__deck {
    padding: 40px 96px;
  }

  .faq-accordion__title {
    color: v.$dark-default;
  }

  .faq-card {
    margin-bottom: 16px;
  }

  a.staticBanner__breadcrumb {
    text-decoration: underline;
  }

  &__sidebar {
    position: fixed;
    max-height: calc(80vh - 100px);
    left: 0;
    top: 261px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    max-width: 240px;
    transition: .4s ease-in;

    &.sidebar-closed {
      max-height: 44px;
    }

    &.fixed-sidebar-false {
      position: absolute;
      top: unset;
      bottom: 0;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
    }
  }

  .staticBanner__breadcrumb {
    font-size: 12px;
    font-weight: bold;
  }

  &__disclaimerText {
    padding-top: 40px;
    margin: 0 16px;
    border-top: 1px solid v.$neutral-accent-default;
    color: v.$dark-60;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42;
  }
}

.progressbar {
  position: fixed;
  top: 104px;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  z-index: 2;

  &__marker {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: v.$tm-green-120;
    transition: .3s ease-out;
  }
}

.contents-table {
  background-color: v.$neutral-accent-background;
  padding: 40px 56px;
  border-radius: 32px;
  max-width: 740px;
  margin: 0 auto;
  box-sizing: border-box;

  h4 {
    margin-bottom: 16px;
  }

  ul {
    list-style-type: disc;
    margin-left: 28px;
  }

  li {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    line-height: 24px;
    margin-bottom: 8px;
  }
}

.large-view {
  .article {
    .faq-accordion-list__flex-container {
      flex-direction: column;
    }

    .g-container {
      max-width: 740px;
      min-width: 740px;
    }

    &__container {

      .g-container,
      .g-container.g-container--fullwidth {
        min-width: 100% !important;
      }
    }

    &__content {
      p {
        font-size: 18px;
      }
    }
  }
}

.medium-view,
.small-view {

  .progressbar {
    top: 48px;
  }

  .article {
    &__sidebar {
      display: none;
    }

    &__content,
    .faq-accordion__deck {
      padding: 32px 24px;
    }

    .g-container {
      margin: 0;
    }

    &__content {
      h2 {
        font-size: 28px;
        line-height: 1.14;
        margin: 32px 0px 8px 0px;
      }

      h3 {
        margin: 24px 0px 8px 0px;
      }

      p {
        font-size: 18px;
      }
    }

    &__disclaimerText {
      padding-top: 20px;
      margin: 0 24px;
    }
  }
}

.small-view {

  .contents-table,
  .articleBanner__content {
    max-width: calc(100% - 16px);
  }
}

html[lang="zh-TW"],
html[lang="th-TH"] {
  .article__content {
    a {
      @include asian-text-links();
    }

    h2:not(:first-child) {
      margin-top: 45px;
      padding-top: 40px;
      border-top: 1px solid #CCCCCC;
    }

    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 18px;
    }

    strong {
      font-size: inherit;
    }

    tr.header-row {
      color: v.$pure-white;
      background-color: v.$dark-80;
    }

    thead tr:first-child th,
    thead tr:first-child td {
      border-top: none;
    }

    tr.header-row th:first-child,
    tr.header-row td:first-child,
    thead th:first-child,
    thead th:first-child {
      border-inline-start: 1px solid v.$dark-80;
    }

    thead tr th,
    tr.header-row th:last-child,
    tr.header-row td:last-child {
      color: v.$pure-white;
    }

    tr.header-row th:last-child,
    tr.header-row td:last-child,
    thead th:last-child,
    thead td:last-child {
      border-inline-end: 1px solid v.$dark-80;
    }


    table tbody tr {
      border: 1px solid #ddd;
    }

    table tbody tr:nth-child(even) {
      background-color: v.$neutral-accent-background
    }

    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
      border: 1px solid #ddd;
    }
  }
}