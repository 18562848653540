@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;

.card-deck-container-resources {
    padding: 15px;

    &__main-container-lardium {
        display: flex;
    }

    &__card-large {
        border-radius: 16px;
        padding: 20px
    }

    &__cards-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    &__flex {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 10px;
        margin-right: -5px;
    }

    &__title-container-lardium {
        margin-bottom: 40px;
    }

    .swiper-pagination {
        display: flex;
        gap: 10px;
        justify-content: center;
    }

    .swiper-pagination-bullet {
        border-radius: 8px;
        width: 8px;
        height: 8px;
        background-color: c.$dark-60;
        display: inline-block;

    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: c.$tm-green-default;
    }

    &__arrow-icon {
        margin-left: -7px;
        padding-top: 2px;
    }
}

.medium-view {
    .card-deck-container-resources {
        &__title-container-lardium {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__title-description-lardium {
            text-align: center;
        }

        &__top-image-container-medium {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__main-container-lardium {
            padding: 30px 5px 30px 5px;
        }
    }
}

.large-view {
    .card-deck-container-resources {
        &__card-large.hovered {
            background-color: c.$neutral-accent-dark-bg-15;
        }

        &__left-image-container-large {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__main-container-lardium {
            padding: 70px 20px 70px 20px;
        }
    }
}

.small-view {
    .card-deck-container-resources {
        padding-bottom: 64px;

        &__title-container-small {
            margin: 40px;
        }

        &__card-text-content-small {
            margin: 15px;
        }

        .swiper-pagination-bullets {

            .swiper-pagination-bullet {
                width: 32px;
                height: 4px;
                border-radius: 4px;
                margin-top: 9px;
            }
        }

    }
}

[dir="rtl"] {
    .card-deck-container-resources {
        &__flex {
            justify-content: unset;
        }
    }
}