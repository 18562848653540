@use "../../../global-styles/palette" as c;

.icon-and-title-card-deck-2rows {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 32px;
    margin-bottom: 10px;

    &__card-sub {
        text-align: center;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 10.248px;
    }

    &__card {
        box-sizing: border-box;
        width: 140px;
        height: 117px;
        padding: 16px 10px;
        border-radius: 12px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
        min-width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        text-align: center;
        text-decoration: none;
        color: c.$pure-black;

        p {
            transform: translateY(2px);
        }
    }

    &__card:hover {
        border: 1px solid #EEE;
        box-shadow: unset;
        cursor: pointer;
        color: c.$pure-black;
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    &__items-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
    }

    &__bottom-link {
        text-decoration-line: underline;
        font-style: normal;
        color: inherit;
    }
}

.medium-view {
    .icon-and-title-card-deck-2rows {
        &__items-container {
            gap: 18px;
        }
    }
}

.small-view {
    .icon-and-title-card-deck-2rows {
        gap: 24px;

        &__items-container {
            gap: 12px;
        }

        &__card {
            width: 157px;
        }
    }
}