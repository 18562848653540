@use "../../global-styles/palette" as c;

.title-with-image-and-countries-root {
  .g-container {
    .title-with-image-and-countries-root__contentWrapper {
      h2 {
        color: c.$dark-default;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }

      &__imageContainer {
        width: fit-content;
        height: auto;
        margin: 32px 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__culturesWrapper {
        .cultures-container-root {
          &:first-child {
            margin-bottom: 40px;
          }

          &__cultureTitle {
            color: c.$dark-60;
            width: fit-content;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 8px;
          }

          &__containerWrapper {
            display: grid;
            row-gap: 16px;
            grid-template-columns: repeat(auto-fill, 50%);

            &__cultureItem {
              display: grid;
              grid-auto-flow: column;
              justify-content: start;
              align-items: center;
              width: fit-content;
              gap:8px;

              &__cultureName {
                color: c.$dark-default;
                width: fit-content;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .title-with-image-and-countries-root {
    .g-container {
      margin: 0 72px;
      .title-with-image-and-countries-root__contentWrapper {
        &__imageContainer {
          margin-inline: auto;
        }

        &__culturesWrapper {
          .cultures-container-root {
            &:first-child {
              margin-bottom: 32px;
            }

            &__containerWrapper {
              grid-template-columns: repeat(auto-fill, 33%);
            }
          }
        }
      }
    }
  }
}

.large-view {
  .title-with-image-and-countries-root {
    .g-container {
      .title-with-image-and-countries-root__contentWrapper {
        display: grid;
        align-items: center;
        justify-content: center;

        grid-template-areas:
          "mainImage mainTitle"
          "mainImage cultures";

        h2 {
          grid-area: mainTitle;
          width: 500px;
          text-align: left;
        }

        &__imageContainer {
          grid-area: mainImage;
          margin: 0;
          height: 300px;
          margin-right: 24px;
        }

        &__culturesWrapper {
          width: 744px;

          .cultures-container-root {
            grid-area: cultures;

            &__containerWrapper {
              grid-template-columns: repeat(4, 1fr);
            }
          }
        }
      }
    }
  }
}
