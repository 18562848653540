@use "../../global-styles/palette" as c;

.largeImageSection {
  background-color: #f2f4f6;

  h2 {
    text-align: center;
    color: c.$dark-default;
    max-width: 864px;
    margin: 0 auto 16px auto;
  }

  p {
    text-align: center;
    color: c.$dark-80;
    max-width: 744px;
    margin: 0 auto;
  }

  img,
  video {
    display: block;
    width: 100%;
  }

  .video-wrapper {
    margin: 0 auto;
    max-width: 800px;
    container-type: inline-size;
    overflow: hidden;
    padding: 0 3cqi;

    .video-container {
      width: 100%;
      height: 45cqi;
      -webkit-perspective: 1000px;
      perspective: 1000px;

      video {
        transform: rotateX(30deg);
        top: 0;
        border-radius: 3cqi;
        border: 3cqi solid #1c1c1c;
        box-shadow: 0px 0px 0px 3px #1c1c1c inset;
        width: 100%;
        height: auto;
        box-sizing: border-box;
      }
    }
  }
}

.medium-view {
  .video-wrapper {
    .video-container {
      video {
        transform: rotateX(20deg);
      }
    }
  }
}
