@use "../../../global-styles/palette" as c;

.contacts-banner {
    background-color: c.$dark-default;

    &__container {
        padding: 24px 0;
    }

    &__item {
        background-color: c.$pure-white;
        border-radius: 8px;
        padding: 8px 16px;
        width: 232px;
        display: flex;
        align-items: center;

        p,a {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-decoration: none;
            color: c.$dark-default;
        }
    }

    &__item-img {
        margin-right: 8px;
    }

    &__item-text {
        position: relative;
        cursor: pointer;
    }

    &__item-additional-text {
        margin-left: 72px;
        color: c.$blue-lagoon-default;
        cursor: pointer;
    }

    .blueLagoon {
        color: c.$blue-lagoon-default;
    }

    .no-circle {
        display: none;
    }

    .circle {
        display: flex;
        background-color: c.$tm-green-default;
        width: 4px;
        height: 4px;
        border-radius: 50px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 114px;
        margin: auto 0;
        display: none;
    }
}

.small-view, .medium-view {
    .contacts-banner {
        &__container {
            display: flex;
            justify-content: center;
            padding: 40px 0;
        }

        &__item {
            p {
                line-height: normal;
            }
        }
    }
}