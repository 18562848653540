@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.scroll-to-top {
    position: fixed;
    bottom: calc(v.$live-chat-icon-height + v.$live-chat-icon-offset-bottom);
    right: 20px;
    width: 56px;
    height: 56px;
    margin-bottom: 20px;
    background-color: c.$blue-lagoon-default;
    color: c.$pure-white;
    display: none;
    flex-direction: column;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;

    svg {
        fill: c.$pure-white;
        transform: rotate(270deg);
    }
}