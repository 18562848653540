@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.section-cards-moving-in-opposite-direction {
  &__card {
    padding: 23px 23px 15px 23px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 16px;
    width: 270px;
    border-radius: 16px;
    background: c.$pure-white;
  }

  &__card-image {
    width: 32px;
  }

  &__message-container {
    height: 55px;
    width: 100%;
    text-align: left;
  }

  &__main-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__title_container {
    text-align: center;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 20px;
  }

  &__title_container>* {
    max-width: 80%;
  }

  &__card-title-bar {
    align-self: flex-start;
    justify-content: left;
    gap: 10px;
  }

  .swiper-slide {
    width: auto;
  }
}

.large-view {
  .section-cards-moving-in-opposite-direction {
    &__title_container>* {
      max-width: 65%;
    }
  }
}