@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.risk-warning {
    padding: 40px 0 30px 0;
    background-color: c.$light-grey;

    &,
    .small-view &,
    .medium-view &,
    .large-view & {
        p {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: c.$dark-60;
            
            strong {
                font-weight: 700;
                color: c.$dark-default;
            }
        }
    }
}

.large-view {
    .risk-warning {
        .g-container {
            width: calc(100% - 2 * v.$gutter-large);
            min-width: auto;
            max-width: v.$min-content-width-large;
        }
    }
}

.small-view,
.medium-view {
    .risk-warning {
        padding: 32px 0px;
    }
}
