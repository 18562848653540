@use "../../../global-styles/palette" as c;
@use "../../../global-styles/typography";

.faq-card{
    background-color: c.$pure-white;
    padding: 24px 24px 24px 24px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    svg{
        min-width: 32px;
        height: 32px;
    }
    &.active{
        .faq-card__answer{
            padding: 16px 16px 0 0;
            transition: .2s ease-in;
            max-height: 500px;
        }
        svg{
            transform: rotateX(-180deg);
        }
    }

    &__question{
        @extend %card-title--size-h5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        color: c.$dark-default;
    }

    &__answer{
        color: c.$dark-60;
        max-height: 0;
        transition: .2s ease-out;
        overflow: hidden;

        a {
            text-decoration: none;
            color: c.$blue-lagoon-default;
            font-weight: bold;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}