@use "../../global-styles/palette" as c;

.hero-banner-with-rolling-animation-video-bg {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: center;


  &__container {
    position: relative;
  }

  &__video-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background: rgba(14, 29, 49, 0.90);
    backdrop-filter: blur(8px);

    video {
      width: 100%;
    }
  }

  &__video-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(14, 29, 49, 0.90);
    backdrop-filter: blur(8px);
  }

  &__button {
    margin: 50px 0 90px 0;
  }

  h1 {
    margin: 15px 0 8px;
  }


  &__rolling-item {
    border-left: solid 2px #3E4A5A;
    padding: 10px 55px 3px 20px;
  }

  &__rolling-item-upper {
    justify-content: flex-start;
    gap: 4px;
  }

  &__rolling-items-container {
    display: none;

  }

}

.large-view {
  .hero-banner-with-rolling-animation-video-bg {
    h1,p{
      max-width: 744px;
      margin: 0 auto;
    }
    padding: 20px 0 48px 0px;

    &__rolling-items-container {
      display: flex;
      justify-content: center;
    }
  }
}

.medium-view,
.small-view {
  .hero-banner-with-rolling-animation-video-bg {
    max-width: 600px;
    margin: 0 auto;
  }
}



.small-view {
  .hero-banner-with-rolling-animation-video-bg {
    h1 {
      margin-top: 16px;
    }

    &__button {
      margin: 54px 0 54px 0;
    }

    &__video-container {
      video {
        height: 100%;
        width: auto;
      }
    }
  }
}