@use "../../global-styles/palette" as c;

.swiper-with-video-bg-root {
  &__container {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .g-container {
    overflow: visible;
    margin: 0;
    .swiper-with-video-bg-root__wrapper {
      display: grid;
      grid-template-areas:
        "logoArea"
        "swiperArea"
        "titlesArea"
        "buttonArea";

      &__logoContainer {
        width: fit-content;
        margin-inline: auto;
        grid-area: logoArea;
        margin: 20px 0px 60px 0px;

        svg{
          color: c.$dark-default;
        }
      }

      &__disclaimer{
        margin:20px 40px 20px 40px;
      }
      
      &__titlesWrapper {
        grid-area: titlesArea;

        h1 {
          color: c.$dark-90;
          text-align: center;
          font-size: 48px;
          font-weight: 700;
          line-height: 110%;
          margin-bottom: 12px;
        }
      }

      &__rich-text {
        color: c.$dark-80;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 20px;


        img {
          margin: 0px 8px 22px 0px;
        }

        p {
          display: flex;
          font-size: 18px;
          font-weight: 600;
          line-height: 120%;
        }

        p:first-child {
          margin-top: 20px;
        }
      }

      &__evaluationWrapper {
        display: flex;
        gap: 12px;
        padding: 6px 12px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid c.$button-border-color;
        max-width: 230px;
        margin-top: 40px;

        p {
          font-size: 14px;
          font-weight: 500;
        }
      }

      &__buttonWrapper {
        grid-area: buttonArea;
        display: flex;
        gap: 12px;

        a {
          font-size: 16px;
        }

        .secondary-large-wbg {
          background-color: c.$dark-90;
          color: c.$pure-white;
        }
      }

      &__swiperContainer {
        grid-area: swiperArea;

        &__swiper {
          position: relative;

          .swiper-vertical {

            &:first-child {
              .swiper-slide {
                width: 100%;
                display: grid;

                &:not(.swiper-slide-next) {
                  opacity: 0.75;
                  animation: fadeIn 5s ease-out forwards;
                }
                &.swiper-slide-active {
                  animation: fadeOut 5s ease-out forwards;
                  opacity: 1;
                }
                &.swiper-slide-next {
                  opacity: 1;
                }
              }
            }
          }

          .swiper-slide {
            width: 100%;
            display: grid;
            height: auto!important;

            &:not(.swiper-slide-next) {
              animation: fadeOut 5s ease-out forwards;
              opacity: 1;
            }
            &.swiper-slide-active {
              animation: fadeIn 5s ease-out forwards;
            }
            &.swiper-slide-next {
              opacity: 1;
            }
          }

          .video-bg-component-card {
            display: flex;
            align-items: center;
            border-radius: 16px;
            padding: 20px;
            width: 100%;
            margin-inline: auto;
            background-color: c.$pure-white;

            &__cardTitlesWrapper {
              display: grid;
              grid-auto-flow: column;
              justify-content: space-between;
              width: 100%;

              &__leftTitles {
                width: fit-content;

                p:first-child {
                  color: c.$dark-90;
                  line-height: 120%;
                }

                p:last-child {
                  color: c.$dark-80;
                  font-size: 10px;
                  font-weight: 300;
                  line-height: 120%;
                }
              }

              &__rightTitles {
                width: fit-content;
                p:first-child {
                  color: c.$dark-90;
                  line-height: 120%;
                  text-align: end;
                  font-size: 12px;
                  font-weight: 700;
                }

                p:last-child {
                  color: c.$dark-80;
                  text-align: end;
                  font-size: 10px;
                  font-weight: 300;
                  line-height: 120%;
                }
              }
            }
          }

          img {
            max-width: 327px;
          }
        }

        .video-bg-component {
          &__video-mask {
            &__video {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.2;
  }
}

.medium-view {
  .swiper-with-video-bg-root {
    padding-inline: 20px;
    background-color: c.$pure-white;
    &__container{
      max-width: 250px;
      margin: 0 auto;
    }
    .g-container {
      .swiper-with-video-bg-root__wrapper {
        grid-template-areas:
          "logoArea swiperArea"
          "titlesArea swiperArea"
          "buttonArea swiperArea"
          ". swiperArea";

        &__logoContainer {
          margin: 40px 0 32px 0;
        }

        &__titlesWrapper {
          height: fit-content;

          h1 {
            text-align: start;
            width: fit-content;
          }

          p {
            text-align: start;
            width: 35vw;
          }
        }

        &__rich-text {
          max-width: 363px;
        }

        &__buttonWrapper {
          width: fit-content;
          height: fit-content;
        }

        &__swiperContainer {
          display: flex;
          align-items: center;
          &__swiper {
            .swiper-vertical {
              height: 220px;
              min-height: 230px;
            }

            .video-bg-component-card {
              width: 100%;
              max-width: 250px;
              box-sizing: border-box;
              height: fit-content;

              img {
                margin-inline-end: 10px;
              }

              &__cardTitlesWrapper {
                min-width: 40vw;
              }
            }

            img {
              max-width: 311px;
            }
          }

          .video-bg-component {
            &__video-mask {
              width: 50vw;
            }
          }
        }
      }
    }
  }
}

.large-view {
  .swiper-with-video-bg-root {
    .g-container {
      margin-inline: auto;

      .swiper-with-video-bg-root__wrapper {
        grid-template-areas:
          "logoArea swiperArea"
          "titlesArea swiperArea"
          "buttonArea swiperArea"
          ". swiperArea";

        &__logoContainer {
          margin: 40px 0 90px 0;
        }

        &__titlesWrapper {
          height: fit-content;

          h1 {
            text-align: start;
            font-size: 62px;
          }

          p {
            text-align: start;
          }
        }

        &__rich-text {
          max-width: 448px;
        }

        &__buttonWrapper {
          width: fit-content;
          height: fit-content;
        }

        &__swiperContainer {
          display: grid;

          &__swiper {
            display: grid;
            grid-auto-flow: column;
            align-items: center;

            .swiper-vertical {
              height: 278px;
              min-height: 230px;
              position: unset;
              width: 250px;
              margin: 0;
            }

            .video-bg-component-card {
              width: 100%;
              box-sizing: border-box;
              height: fit-content;

              img {
                margin-inline-end: 10px;
              }

              &__cardTitlesWrapper {
                min-width: unset;
                width: 100%;
                height: 26px;

                &__rightTitles {
                  width: fit-content;
                }
              }
            }

            img {
              max-width: 600px;
              margin-top: unset;
            }
          }

          .video-bg-component {
            &__video-mask {
              &__video {
                width: 50vw;
                max-width: 680px;
              }
            }
          }
        }
      }
    }
  }
}
.small-view {
  .swiper-with-video-bg-root__container {

    .swiper-with-video-bg-root__lottie-wrapper {
      display: flex;
      width: 300px;  
    }

    .swiper-vertical{
      height: 250px;
      width: 250px;
    }

    .video-bg-component {
      margin-bottom: 40px;
    }

    .video-bg-component-card{
      width: 100%;
      box-sizing: border-box;
    }
  }

  .swiper-with-video-bg-root__wrapper__swiperContainer {
    margin: 20px 0px 40px 0px;
  }

  .swiper-with-video-bg-root__wrapper {
    justify-items: center;

    &__titlesWrapper {
      max-width: 335px;
      margin: 0px auto;
    }

    &__buttonWrapper {
      justify-content: center;
      flex-direction: column;

      .button-v2-wrapper {
        margin: unset;
      }
    }

    &__rich-text {
      text-align: center;
    }
  }
}