@use "../../../global-styles/palette" as c;

.search-banner {
    background-color: c.$dark-80;
    padding: 16px 0;

    &__container {
        display: flex;
        align-items: center;
        width: fit-content;
    }

    &__input {
        box-sizing: border-box;
        height: 44px;
        width: 344px;
        border-radius: 8px;
        border: 0;
        padding-left: 52px;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='jmizk30oca' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg clip-path='url(%23k0omiwei5b)' fill='%236E7783'%3E%3Cpath d='M10.4 5.6a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6zm0-1.6a6.4 6.4 0 1 1 0 12.8 6.4 6.4 0 0 1 0-12.8z'/%3E%3Cpath d='M13.834 14.966a.8.8 0 0 1 1.132-1.132l4.8 4.8a.8.8 0 0 1-1.132 1.132l-4.8-4.8z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='k0omiwei5b'%3E%3Cpath fill='%23fff' transform='translate(4 4)' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 16px center;
        outline: none;
    }

    &__title {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: c.$pure-white;
        margin-left: 24px;
    }

    &__items {
        ul {
            display: flex;

            li {
                margin-left: 16px;

                a {
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                    color: c.$blue-lagoon-40;
                    text-decoration: none;
                }
            }
        }
    }
}

.small-view, .medium-view {
    .search-banner {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        &__input-container,
        &__input {
            width: 100%;
        }

        &__title {
            margin: 16px 0 8px 0;
        }

        &__items {
            ul {
                display: flex;
    
                li {
                    margin: 0 32px 0 0;
                    white-space: nowrap;
                }
            }
        }
    }
}



// color: c.$blue-lagoon-40;
// color: c.$pure-white;