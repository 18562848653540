@use "../../global-styles/palette" as c;

.video-section-root {
    background-color: c.$pure-white;

    .g-container {
        .video-section__textsWrapper {
            h2 {
                text-align: center;
                margin-bottom: 12px;
            }

            p {
                text-align: center;
                margin-bottom: 24px;
                color: c.$dark-80;
            }

            .button-v2-wrapper {
                display: none;
            }
        }

        .video-section__ImageWrapper {
            a {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.large-view {
    .video-section-root {
        .g-container {
            display: flex;
            flex-direction: row-reverse;
            column-gap: 56px;
            align-items: center;

            .video-section__textsWrapper {
                h2 {
                    text-align: start;
                    max-width: 770px;
                }

                p {
                    text-align: start;
                    margin-bottom: 32px;
                    max-width: 590px;
                }

                .button-v2-wrapper {
                    display: block;
                    width: min-content;
                    margin: 0;
                }
            }
        }
    }
}