@use "../../../global-styles/palette" as c;

.instrument-slider-card {
  width: 208px;
  height: 110px;
  background: #fff;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: -1px 0px 38px -10px rgba(125,125,125,0.3);
  -webkit-box-shadow: -1px 0px 38px -10px rgba(125,125,125,0.3);
  -moz-box-shadow: -1px 0px 38px -10px rgba(125,125,125,0.3);

  &__header{
    display: flex;
    justify-content: space-between;
  }
  &__description{
    color: c.$dark-60;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__img {
    height: 40px;
  }
  &__footer{
    display: flex;
    justify-content: space-between;
  }
  &__price{
    text-decoration: none;
    line-height: 1.5;
    box-sizing: border-box;
    width: 84px;
    height: 26px;
    padding: 4px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: end;
    border-radius: 4px;

    .highlight{
      font-weight: 700;
      font-size: 16px;
    }
  }
  .price-feed__negative{
    background-color: rgba(217, 48, 44, 0.1);
    color: c.$support-down;
  }
  .price-feed__positive{
    background-color: rgba(20, 131, 91, .1);
    color: c.$support-up;
  }
}

.medium-view,
.small-view {
  .instrument-slider-card {
  }
}
.small-view{
  .instrument-slider-card{
    width: 96px;
    height: 50px;
    padding: 6px 8px;

    &__title{
      font-size: 7px;
    }
    &__description{
      font-size: 5px;
      max-width: 58px;
    }
    &__price{
      font-size: 6px;
      width: 38px;
      height: 12px;
      line-height: .5;
    }
    &__img {
      height: 20px;
    }
  }
}