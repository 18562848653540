@use "../../global-styles/palette" as c;

.thinkTraderQr {
    box-sizing: border-box;

    &__inner {
        display: flex;
    }
    &__green-title {
        color: c.$tm-green-default;
    }
    &__title {
        margin-bottom: 32px;
    }
    &__text2 {
        margin-bottom: 32px;
    }
    &__text2-link {
        color: c.$blue-lagoon-default;
    }
    &__text3 {
        margin-bottom: 16px;
    }
    &__qr-content {
        display: flex;
        align-items: center;
    }
    &__qr-text {
        margin-left: 16px;
    }
    &__mobile-downloads {
        display: flex;
    }
    &__mobile-download-buttons {
        width: 160px;
        height: 49px; 
    }
    &__content-break {
        border: 1px solid c.$dark-40;
        margin: 32px 0;
    }
    &__mt-buttons {
        display: flex;
        margin: 0 auto;
    }
    &__mt-button {
        width: 198px;
        height: 56px;
        flex-grow: 0;
        margin: 24px 16px 0 0;
        border-radius: 20px;
        border: solid 1px c.$dark-20;
        background-color: c.$pure-white;
    }
    &__mt-img {
        height: 38px;
    }
    &__chart-img {
        margin-top: -40px;
    }
    .mobile{
        display: none;
    }
}

.small-view, .medium-view {
    .thinkTraderQr {
    
        &__inner {
            display: block;
        }
        &__title {
            text-align: center;
        }
        &__chart-img {
            width: 327px;
            height: 385px;
            margin-bottom: 24px;
        }
        &__mt-img {
            height: 32px;
        }
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
    }
}