@use "../../../global-styles/palette" as c;

.alphabet-list-item {
    margin-left: 26px;
    margin-bottom: 40px;

    h5 {
        padding-bottom: 7px;
        margin-bottom: 8px;
        border-bottom: 1px solid c.$dark-10;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.52;
        letter-spacing: normal;
        color: c.$dark-default;
    }

    p {
        color: c.$dark-80;
        font-weight: 500;
    }
}

.small-view,.medium-view {
    .alphabet-list-item {
        margin-left: unset;
        margin-bottom: 40px;
    }
}