@use "../../../../global-styles/palette" as c;

.notificationsGridContainerWrapper {
  padding: 32px 24px 10px;

  &__textsWrapper {
    &__mainTitle {
      color: c.$dark-default;
      text-align: center;
    }

    h5.notificationsGridContainerWrapper__textsWrapper__subTitle {
      text-align: center;
      margin: 8px 0 32px;
      font-weight: 500;
      color: c.$dark-80;
    }
  }

  &__notificationsWrapper {
    .swiper-vertical {
      height: 130px;
    }

    .swiper-slide {
      width: 100%;
      margin-bottom: 15px;

      &:not(.swiper-slide-active):not(.swiper-slide-next):not(.swiper-slide-prev) {
        opacity: 0;
      }
    }

    &__notificationCard {
      background-color: c.$pure-white;
      padding: 7.5px;
      border-radius: 12.4px;
      display: grid;
      justify-content: start;
      grid-auto-flow: column;
      grid-template-columns: 1fr 85%;
      align-items: center;

      &__imageContainer {
        overflow: hidden;
        border-radius: 10px;
        margin-right: 10px;
        width: 29.45px;
        height: 29.45px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__notificationsTexts {
        &__headerWrapper {
          display: grid;
          grid-auto-flow: column;
          justify-content: space-between;

          p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper__notification-mainTitle {
            color: c.$dark-default;
            font-size: 11.625px;
            font-style: normal;
            font-weight: 600;
            line-height: 15.5px;
            letter-spacing: -0.387px;
          }

          p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper__notification-elapsedTime {
            color: c.$dark-40;
            font-size: 10.075px;
            font-style: normal;
            font-weight: 400;
            line-height: 13.95px;
            letter-spacing: -0.06px;
          }
        }
        p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__notification-subTitle {
          color: c.$dark-60;
          font-size: 10.075px;
          font-style: normal;
          font-weight: 400;
          line-height: 13.95px;
          letter-spacing: -0.06px;
        }
      }
    }
  }
}

.medium-view {
  .notificationsGridContainerWrapper {
    &__notificationsWrapper {
      .swiper-vertical {
        height: 150px;
      }

      .swiper-slide {
        margin-bottom: 15px;
        width: 80%;
        margin-inline: auto;
      }

      &__notificationCard {
        grid-template-columns: 1fr 90%;

        &__imageContainer {
          width: 38px;
          height: 38px;
        }

        &__notificationsTexts {
          &__headerWrapper {
            p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper__notification-mainTitle {
              font-size: 15px;
              line-height: 20px;
              letter-spacing: -0.5px;
            }

            p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper__notification-elapsedTime {
              font-size: 13px;
              line-height: 18px;
              letter-spacing: -0.078px;
            }
          }
          p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__notification-subTitle {
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.078px;
          }
        }
      }
    }
  }
}

.large-view {
  .notificationsGridContainerWrapper {
    max-width: 608px;
    margin-inline: auto;

    &__textsWrapper {
      h5.notificationsGridContainerWrapper__textsWrapper__subTitle {
        width: 420px;
        margin-inline: auto;
      }
    }

    &__notificationsWrapper {
      .swiper-vertical {
        height: 150px;
      }

      .swiper-slide {
        margin-bottom: 20px;
        max-width: 360px;
        margin-inline: auto;
      }

      &__notificationCard {
        grid-template-columns: 1fr 85%;

        &__imageContainer {
          width: 38px;
          height: 38px;
        }

        &__notificationsTexts {
          &__headerWrapper {
            p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper__notification-mainTitle {
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }

            p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__headerWrapper__notification-elapsedTime {
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }
          p.notificationsGridContainerWrapper__notificationsWrapper__notificationCard__notificationsTexts__notification-subTitle {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.078px;
          }
        }
      }
    }
  }
}
