@use "../../global-styles/palette" as c;

.two-instrument-sliders{
  display: flex;
  gap: 16px;
  max-height: 500px;
  padding: 0 16px;

  &__note-under-prices {
    color: c.$dark-60;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  &__note-under-prices-container {
    transform: translateY(10px);
    left: calc(50% + 120px);
    display: flex;
    position: absolute;
    place-items: center;
  }

  .swiper{
    overflow: unset;
  }
  .swiper-slide{
    height: auto!important;
    width: auto!important;
  }
}
.medium-view,.small-view{
  .two-instrument-sliders{
    position: relative;
    width: 100%;
    padding: 16px 0;
    overflow: hidden;
    gap: 8px;
    &::before,&::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: rgba(255,255,255,0.5);
      box-shadow:  24px 1px 51px -1px rgba(255,255,255,1);
      z-index: 2;
    }
    &::after{
      left: unset;
      right: 0;
      box-shadow: -24px 1px 51px -1px rgba(255,255,255,1);
    }
    flex-direction: column;
  }
}

.large-view {
  .two-instrument-sliders {
    .awardsSli1derDark__swiper::before,
    .awardsSli1derDark__swiper::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 30px;
      z-index: 2;
      pointer-events: none;
    }

    .awardsSli1derDark__swiper::before {
      top: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }

    .awardsSli1derDark__swiper::after {
      bottom: 0;
      background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }
  }
}

.medium-view {
  .two-instrument-sliders {
    &__note-under-prices-container {
      left: calc(210px);
    }
  }
}

.small-view {
  .two-instrument-sliders {
    &__note-under-prices-container {
      left: calc(1% + 53px);
      width: 80%;
      transform: translateY(-9px);
    }
  }
}