@use "../../../global-styles/palette" as c;


.video-banner-lp {
    display: flex;
    flex-direction: column;

    &__text-container {
        display: flex;
        flex-direction: row;

        &__description-wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-top: 6px;

            &__button-box {
                display: flex;
                flex-direction: row;
                gap: 20px;
                align-items: center;
            }
        }
    }

    &__video-container {
        margin-bottom: -4px;
    }

    &__video {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}

.large-view {
    .video-banner-lp {
        &__text-container {
            margin: 56px 0px;

            &__title-wrapper {
                max-width: 450px;

                h1 {
                    font-size: 62px;
                    font-weight: 700;
                    line-height: 110%;
                    color: c.$dark-default;
                } 
            }

            &__description-wrapper {
                &__text-box {
                    max-width: 615px;
                    display: flex;
                    flex-direction: row;
                    gap: 20px;

                    p {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24px;
                        color: c.$dark-80;
                    }
                }

                &__button-box {
                    .button-v2-wrapper {
                        margin: unset;
                    }

                    p {
                        max-width: 228px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 150%;
                        color: c.$dark-40;
                    }
                }
            }
        }

        &__video {
            max-width: 1128px;
        }
    }
}

.medium-view {
    .video-banner-lp {
        &__text-container {
            margin: 40px 0px;
            justify-content: center;
            gap: 24px;

            &__title-wrapper {
                max-width: 347px;

                h1 {
                    font-size: 48px;
                }
            }

            &__description-wrapper {
                padding-top: 10px;
                
                &__button-box {
                    margin-top: 24px;

                    .button-v2-wrapper {
                        margin: unset;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 150%;
                        color: c.$dark-40;
                        max-width: 171px;
                    }
                }

                &__text-box {    
                    p {
                        max-width: 293px;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
            }
        }

        &__video-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__video {
            max-width: 664px;
        }
    }
}

.small-view {
    .video-banner-lp {
        &__text-container {
            flex-direction: column;
            align-items: center;

            &__title-wrapper {
                max-width: 347px;

                h1 {
                    font-size: 47px;
                    font-weight: 700;
                    line-height: 110%;
                    text-align: center;
                }
            }

            &__description-wrapper {
                padding-top: 12px;

                &__button-box {
                    flex-direction: column;

                    p {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 150%;
                        color: c.$dark-40;
                        text-align: center;
                        max-width: 80%;
                    }
                }

                &__text-box {
                    display: flex;
                    justify-content: center;

                    p {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 150%;
                        color: c.$dark-80;
                        max-width: 99%;
                        text-align: center;
                    }
                }
            }
        }

        &__video-container {
            margin-top: 32px;
        }

        &__video {
            max-width: 100%;
        }

        .button-v2-wrapper {
            margin: unset;
            width: 335px;

            a {
                width: 78%;
            }
        }
    }
}