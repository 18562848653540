@use "../../../global-styles/palette" as c;

.academyBanner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  .button-v2-wrapper{
    margin: 0;
    .secondary-large-wbg{
      background-color: c.$pure-white;
      &:hover{
        background-color: c.$dark-default;
      }
    }
  }
  &__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: c.$pure-white;
    max-width: 552px;
    h5{
      margin-top: 8px;
      margin-bottom: 32px;
      color: c.$dark-20;
    }
  }
  &__image{
    max-width: 430px;
    max-height: 300px;
    img{
      width: 100%;
    }
  }
}
.medium-view,.small-view{
  .academyBanner{
    flex-direction: column;
    justify-content: center;
    &__content{
      align-items: center;
      text-align: center;
    }
    &__image{
      margin-top: 24px;
    }
  }
}