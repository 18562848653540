.footer-banner-v2dot6 {
    --x-padding: 20px;
    --y-padding: 40px;

    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: start;
    padding: var(--y-padding) var(--x-padding);
    color: var(--text-color, #fff);

    & > * {
        max-width: 100%;
    }

    &__subtitle {
        padding-bottom: 12px;
        color: #cbd5e1;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4;
        color: #cbd5e1;
    }

    &__top-image {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
    }

    &__text-and-button {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 20px;
        padding: 20px 0;

        & > * {
            text-align: center;
        }
    }

    &__left-image,
    &__right-image {
        display: none;
    }
}

.medium-view {
    .footer-banner-v2dot6 {
        --x-padding: 40px;

        .button-v2 {
            box-sizing: border-box;
            max-width: 260px;
        }
    }
}

@mixin footer-banner-v2dot6-large-view {
    column-gap: 20px;

    &__top-image {
        display: none;
    }

    &__text-and-button {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
    }

    &__left-image {
        display: block;
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
    }

    &__right-image {
        display: block;
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
    }
}

.large-view {
    .footer-banner-v2dot6 {
        --x-padding: 40px;

        @include footer-banner-v2dot6-large-view;

        .button-v2 {
            box-sizing: border-box;
            max-width: 180px;
        }
    }
}

@media (min-width: 800px) and (orientation: landscape) {
    .footer-banner-v2dot6 {
        @include footer-banner-v2dot6-large-view;
    }
}
