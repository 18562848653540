@use "../../global-styles/palette" as c;

.trade-with-expert-insights-root {
  .button-v2-wrapper{
    margin-top: 24px;
  }
  .g-container {
    .trade-with-expert-insights-root__textContainer {
      margin-bottom: 52px;

      h2 {
        color: c.$dark-90;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        line-height: 110%;
        margin-bottom: 20px;
      }

      p {
        color: c.$dark-80;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .trade-with-expert-insights-root__image-container {
      overflow: hidden;

      &__imageWrapper {

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__cardsInfoWrapper {
        margin-bottom: 40px;

        .card-info-root {
          display: flex;
          border-radius: 16px;
          padding: 16px 24px;
          margin: 10px 0 10px 0;

          img {
            height: fit-content;
            margin-inline-end: 8px;
          }

          p {
            width: fit-content;
            color: c.$dark-default;
            font-size: 22px;
            font-weight: 600;
            line-height: 32px;
            cursor: default;
          }
        }
      }

      &__buttonWrapper {
        display: flex;
        margin-top: 25px;
      }
    }
  }
}

.medium-view {
  .trade-with-expert-insights-root {
    .g-container {
      .trade-with-expert-insights-root__image-container {
        &__cardsInfoWrapper {

          margin-bottom: unset;

          .card-info-root {
            p {
              font-size: 24px;
            }
          }
        }

        &__buttonWrapper {
          
          margin-top: unset;
        }
      }
    }
  }
}

.large-view {
  .trade-with-expert-insights-root {
    .g-container {
      .trade-with-expert-insights-root__textContainer {
        h2 {
          text-align: start;
        }

        p {
          text-align: start;
        }
      }

      .trade-with-expert-insights-root__image-container {
        display: flex;
        flex-direction: row-reverse;
        gap: 48px;

        &__imageWrapper {
          max-width: 631.729px;
        }

        &__cardsInfoWrapper {
          .button-v2-wrapper {

            a {
              margin-inline-end: auto;
            }
          }
          .card-info-root {
            img {
              margin-inline-end: 20px;
            }
          }
        }
      }
    }
  }
}

.small-view {
  .trade-with-expert-insights-root {
    .g-container {
      .trade-with-expert-insights-root__image-container {
        &__cardsInfoWrapper {
          margin-bottom: unset;
        }

        &__buttonWrapper {
          margin-top: unset;
        }
      }
    }
  }
}