@use "../../global-styles/palette" as c;
@use "./SmallCardWithAnimatedIcon/SmallCardWithAnimatedIcon";

.small-cards-slider {
    .swiper-slide {
        width: unset;
    }

    .swiper-wrapper {
        flex-wrap: nowrap;
        justify-content: flex-start;
        gap: 12px;
    }
}

.large-view .small-cards-slider .g-container.g-container--fullwidth .g-container {
    max-width: unset!important;
}

.large-view {
    .small-cards-slider {
        .g-container--new, .g-container {
            margin: 0px;
            display: flex;
            justify-content: center;
        }
        
        .g-container--fullwidth {
            margin: 0px;
        }
    }
}

.medium-view {
    .small-cards-slider {
        .g-container--new, .g-container {
            margin: 0 0 0 32px;
        }
        .swiper-wrapper {
            gap: 8px;
        }
    }
}

.small-view {
    .small-cards-slider {
        .g-container--new, .g-container {
            margin: 0 0 0 20px;
        }
        .swiper-wrapper {
            gap: 8px;
        }
    }
}