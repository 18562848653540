@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/typography";

.search-result-item {
    h4.search-result-item__title {
        width: 100%;
        color: c.$dark-default;
        border-bottom: 1px solid c.$neutral-accent-default;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-decoration-line: underline;
    }

    &__url {
        color: inherit;
        text-decoration: none;
    }

    .search-result-item__summary {
        color: c.$dark-default;
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}