@use "../../../../global-styles/palette" as c;

.broker-regulation-card {
  background-color: c.$dark-80;
  box-sizing: border-box;
  padding: 8px 24px;
  border-radius: 8px;
  max-width: 264px;
  text-align: center;

  &__title {
    color: c.$pure-white;
    font-weight: bold;
  }

  & > p {
    font-size: 16px;
    color: c.$dark-20;
  }
}

.small-view, .medium-view {
  .broker-regulation-card {
    max-width: 228px;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;

    & > p {
      font-size: 15px;
    }
  }
}