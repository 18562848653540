@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.popular-instruments-by-groups-root {
  background-color:  c.$pure-white;

  .g-container {
    .popular-instruments-by-groups-root__title {
      font-style: normal;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 24px;
    }

    .popular-instruments-by-groups-root__link {
      margin-top: 32px;
      display: block;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: c.$dark-default;
    }

    .popular-instruments-by-groups-root__cards-container {
      display: grid;
      row-gap: 24px;

      &__cardItem {
        .cardItem-instrument-by-groups {
          &__groupName {
            color: c.$dark-80;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
          }

          &__itemWrapper {
            .cardItem-instrument-by-groups__link-container {
              background-color: c.$neutral-accent-background;
              display: grid;
              grid-template-columns: 1fr 90%;
              padding: 8px 16px;
              margin: 24px 0;
              text-decoration: none;
              border-radius: 8px;
              color: c.$dark-default;

              &:first-child {
                margin-top: 8px;
              }

              &:last-child {
                margin-bottom: 0px;
              }

              img {
                margin-right: 12px;
              }

              &__texts-wrapper {
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                justify-content: space-between;

                &__name {
                  font-style: normal;
                  font-weight: 500;
                }

                &__description {
                  @extend %clamped-text-base;

                  -webkit-line-clamp: 1;

                  color: c.$dark-80;
                  font-size: 12px;
                  font-style: normal;
                  line-height: 16px;
                }

                svg {
                  transform: rotate(-90deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .popular-instruments-by-groups-root {
    .g-container {
      margin-inline: 72px;

      .popular-instruments-by-groups-root__title {
        line-height: 48px;
        font-size: 40px;
      }

      .popular-instruments-by-groups-root__cards-container {
        &__cardItem {
          .cardItem-instrument-by-groups {
            &__itemWrapper {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 16px 24px;
              .cardItem-instrument-by-groups__link-container {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.large-view {
  .popular-instruments-by-groups-root {
    .g-container {
      .popular-instruments-by-groups-root__title{
        font-size: 48px;
      }
      .popular-instruments-by-groups-root__cards-container {        
        display: grid;
        grid-template-columns: repeat(
          auto-fit,
          minmax(min(100%/3, max(64px, 100%/5)), 1fr)
        );
        column-gap: 10px;

        &__cardItem {
          .cardItem-instrument-by-groups {
            &__itemWrapper {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
              gap: 8px 24px;
              .cardItem-instrument-by-groups__link-container {
                margin: 0;
                grid-template-columns: 1fr 90%;
                background-color: transparent;

                &__texts-wrapper {
                  &__description {
                    opacity: 0;
                  }

                  svg {
                    opacity: 0;
                  }
                }

                &:hover {
                  animation: appearingBg 1s ease forwards;

                  .cardItem-instrument-by-groups__link-container__texts-wrapper__description {
                    animation: appearing 1s ease forwards;
                  }

                  svg {
                    animation: appearing 1s ease forwards;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes appearing {
  100% {
    opacity: 1;
  }
}

@keyframes appearingBg {
  100% {
    background-color: c.$neutral-accent-background;
  }
}
