@use "../../../global-styles/palette" as c;

.tier-instrument {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    border-radius: 60px;
    border: 1px solid c.$dark-10;

    img {
        width: 16px;
        height: 16px;
    }

    &__name {
        color: c.$dark-default;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
    }
}