@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/palette" as c;

.information-card--platform{
    height: 100%;
    
    .information-card__content{
        padding: 32px 32px 24px;
    }
    .information-card__link{
        margin-top: auto;
        padding: 0 32px 32px;
        display: flex;
    }
    .information-card__text{
        color: c.$dark-80;
    }
}