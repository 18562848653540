@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/palette" as c;

.informationCardDark {
    opacity: 0.9;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &__img {
        position: absolute;
        border-radius: 16px;
    }

    &__text {
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: c.$pure-white;
        margin: 32px 32px 16px 32px;
        z-index: 2;
    }

    &__description {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: c.$pure-white;
        margin: 0px 32px 0px 32px;
        z-index: 2;
    }

    &__link--text {
        margin: 0 0 32px 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;
    }

    &__link--text a {
        color: c.$pure-white;
        text-decoration: none;
        z-index: 2;
    }

    &__link--icon {
        z-index: 2;
        display: flex;
        align-items: center;
    }
}

.large-view {
    
    .informationCardDark {
        width: 360px;
        height: 300px;

        &__img {
            width: 360px;
            height: 300px;
        }
    }
}

.medium-view,
.small-view {

    .informationCardDark {
        width: 280px;
        height: 260px;

        &__img {
            width: 280px;
            height: 260px;
        }

        &__title {
            @extend .informationCardDark__title;

            font-size: 28px;
            line-height: 1.14;
            margin: 24px 24px 8px 24px;
        }
    
        &__description {
            font-size: 16px;
            line-height: 1.5;
            margin: 0px 24px 0px 24px;
        }

        &__link--text {
            margin: 0 0 28px 28px;
        }
    }
}
