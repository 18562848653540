@use "../../../global-styles/palette" as c;

.card-deck-with-different-size {
    &__title-container {
        margin: 20px 10px 20px 10px;
    }

    &__flex-container {
        flex-direction: column;
    }

    &__two-cards {
        flex-direction: column;
    }

    &__small-card {
        height: 250px;
        width: 100%;
        padding: 30px 30px 30px 30px;
    }

    &__big-card {
        height: 380px;
        width: 100%;
        padding: 30px 30px 30px 30px;
    }

    &__one-card {
        width: 100%;
    }

    &__card {
        border-radius: 32px;
        box-sizing: border-box;
    }

    &__title-and-description {
        text-align: left;
    }

    &__title-and-description-inner-container p {
        margin-top: 10px;
    }

    &__gap {
        gap: 24px;
    }
}

.small-view {

    .card-deck-with-different-size__small-card {
        min-height: 250px;
        background-color: c.$neutral-accent-background;
        border-radius: 32px;
    }

    .card-deck-with-different-size__one-card {
        background-color: c.$neutral-accent-background;
        border-radius: 32px;
    }
}

.medium-view {
    .card-deck-with-different-size {

        &__gap {
            gap: 32px;
        }

        &__small-card {
            height: 260px;
            padding: 24px 15px 30px 24px;
        }

        &__big-card {
            height: 400px;
            padding: 40px 30px 40px 30px;
        }

        &__title-and-description {
            text-align: unset;
            display: flex;
            justify-content: center;
        }

        &__title-and-description-inner-container {

            max-width: 500px;
        }

        &__two-cards {
            flex-direction: row;
        }
    }
}

.large-view {
    .card-deck-with-different-size {

        &__gap {
            gap: 32px;
        }

        &__small-card {
            height: 300px;
            padding: 40px 90px 40px 90px;
            text-align: center;
        }

        &__title-and-description-small-card {
            text-align: center;
        }

        &__title-and-description {
            display: flex;
            justify-content: left;
        }

        &__title-and-description.card-deck-with-different-size__align-right {
            justify-content: right;
        }

        &__big-card {
            height: 300px;
            width: 100%;
            padding: 100px 90px 100px 90px;
            text-align: left;
        }

        &__one-card {
            width: 100%;
        }


        &__align-right {
            text-align: right;
            float: right;
        }

        &__title-and-description-inner-container {
            
            max-width: 360px;  
        }

        .card-deck-with-different-size__big-card {
            h4, p {
                text-align: left;
            }
        }

        &__two-cards {
            flex-direction: row;
        }
    }
}