@use "../../global-styles/palette" as c;

.managePortfolioWithEase-root {
  position: relative;
  .g-container {
    border-radius: 32px;
    .managePortfolioWithEaseWrapper {
      background-color: c.$neutral-accent-background;
      height: 563px;
      display: grid;
      justify-items: center;
      position: relative;
      align-content: baseline;

      &__textsWrapper {
        padding: 32px 25px;
        margin-right: auto;
        height: fit-content;

        h2 {
          color: c.$dark-default;
        }

        h5 {
          margin-top: 8px;
          color: c.$dark-80;
          font-weight: 500;
        }
      }

      &__links-wrapper {
        display: grid;
        width: fit-content;
        justify-self: start;
        padding-left: 25px;

        &__webLinkWrapper, &__mobileLinkWrapper {
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          justify-content: start;
        }

        span {
          text-decoration: underline;
        }
        span:hover {
          cursor: pointer;
        }

        svg {
          margin-right: 8px;
        }

        span:first-child {
          margin-bottom: 16px;
        }

        a {
          color: c.$dark-default;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }

      img {
        width: 390px;
        height: auto;
        position: absolute;
        transform: scale(2) translate(0vw, 80px);
        transition: 3s linear;
      }
      &--animated {
        img {
          transform: scale(0.53) translate(0, 370px);
        }
      }

      &__modal {
        position: fixed;
        left: 0;
        top: 0;
        display: grid;
        align-content: center;
        justify-items: center;
        height: 100vh;
        width: 100%;
        background: rgba(14, 29, 49, 0.8);
        backdrop-filter: blur(10px);
        z-index: 99;

        &__videoWrapper {
          display: grid;
          max-width: 1128px;

          span.iconWrapper {
            margin: 0 0 16px auto;

            &:hover {
              cursor: pointer;
            }

            svg {
              color: c.$pure-white;
            }
          }

          .youtube-video-iframe {
            height: 60vw;
            max-height: 500px;
            max-width: 1128px;
            margin-inline: auto;
            width: 90vw;
          }
        }
      }
    }
  }
}

.medium-view {
  .managePortfolioWithEase-root {
    .g-container {
      margin-inline: 72px;

      .managePortfolioWithEaseWrapper {
        justify-items: center;
        height: 808px;

        &__textsWrapper {
          padding: 56px 56px 32px;
        }

        &__links-wrapper {
          padding-left: 56px;
        }

        img {
          margin-top: 10px;
          width: 390px;
          height: auto;
          transform: scale(2) translate(0vw, 325px);
        }

        &--animated {
          img {
            transform: scale(1) translate(0vw, 340px);
          }
        }
      }
    }
  }
}

.large-view {
  .managePortfolioWithEase-root {
    .g-container {
      .managePortfolioWithEaseWrapper {
        height: 520px;
        grid-template-areas:
          "textWrapper mainImage"
          "linkWrapper mainImage"
          ". mainImage";

        &__textsWrapper {
          grid-area: textWrapper;
          width: 415px;
          padding: 114px 0 56px 96px;
        }

        &__links-wrapper {
          grid-area: linkWrapper;
          padding-left: 96px;
        }

        img {
          grid-area: mainImage;
          width: 370px;
          margin: 46px 30px 0;
          transform: scale(1.25) translate(0vw, 45px);
        }

        &--animated {
          img {
            transform: scale(1) translate(-5vw, 0px);
          }
        }
      }
    }
  }
}
