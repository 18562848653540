@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";
@use "../../global-styles/typography";



.trading-scenarios-multiple__title {
    padding-bottom: 28px;
    flex-direction: column;
    gap: 8px;
    h5{
        color: c.$dark-80;
        max-width: 744px;
    }
}

.trading-scenarios-multiple {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__summary strong {
        @extend %h5;
    }

    &__summary {
        display: grid;
        padding: 30px 40px 20px 15px;
        gap: 25px;
    }

    &__radio {
        padding: 8px;
        border-radius: 24px;
    }

    &__radio-item {
        padding: 8px 16px;
        border-radius: 16px;
    }

    &__radio-item:hover {
        cursor: pointer;
    }

    &__radio-selector {
        display: flex;
        flex-direction: column;
    }

    &__radio-container {
        margin-bottom: 24px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;
    }

    &__radio-title {
        text-align: left;
    }

    &__scenario {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 28px;
    }

    &__scenario-div {
        border-radius: 16px;
        border: solid 1px #D3E2E2;
        padding: 16px 24px;

    }

    &__two-scenarios-div {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }

    &__two-scenarios-div>* {
        flex: 1;
    }

    &__two-samples {
        flex-direction: column;
    }

    &__dropdown-selector-container {
        width: 100%;
        padding: 16px 0 15px 0;
    }

    &__dropdown-selector-container select {
        width: 100%;
    }

    &__dropdown-selector-container .dropdown-wrapper {

        margin-right: 21px;
    }
}

.large-view {
    .trading-scenarios-multiple {
        border-radius: 32px;
        padding: 40px 55px;

        &__radio-selector-container {
            width: 65%;
        }

        &__two-scenarios-div {
            width: 700px;
        }

        &__two-samples {
            flex-direction: row;
        }

        &__summary {
            grid-template-columns: 1fr;
            padding: 60px 40px 20px 0px;
            gap: 35px;
            width: 200px;
        }
    }

    .trading-scenarios-multiple__two-samples>* {
        min-width: 50%;
        margin-right: 20px;
    }
}

.medium-view {
    .trading-scenarios-multiple {

        align-items: center;
        border-radius: 40px;
        padding: 40px 40px 56px 40px;

        &__scenarios-container {
            width: 60vw;
        }

        &__summary-container {
            align-self: flex-start;
        }

        &__summary {
            width: 200px;
        }

        &__two-scenarios-div {
            flex-direction: column;
        }
    }
}

.small-view {
    .trading-scenarios-multiple {
        align-items: center;

        .trading-scenarios__title {
            padding-bottom: 22px;
        }

        border-radius: 24px;
        padding: 25px 6px 40px 24px;
        flex-direction: column;

        &__radio-container {
            margin-bottom: 8px;
            align-items: center;

        }

        &__radio-selector {
            margin-right: 17px;
        }

        &__summary {
            margin-top: 6px;
            grid-template-columns: 1fr 1fr;
            gap: 25px;
            padding: 0px;
        }

        &__summary-container {
            width: 100%;
            margin-bottom: 8px;
        }

        &__scenario-div {
            padding: 15px 15px;
        }

        &__two-scenarios-div {
            flex-direction: column;
        }

        &__scenario {
            margin-top: 24px;
        }
    }
}