@use "../../global-styles/palette" as c;

.header-with-title-subTitle-button {
    display: grid;
    justify-items: center;
    gap: 10px;

    h2 {
        text-align: center;
    }

    p.copy-lp {
        text-align: center;
        line-height: 1.5;
    }

    .button-v2-wrapper {
        .secondary-large-dbg {
            background-color: c.$dark-default;
            color: c.$pure-white;
        }
    }
}

.medium-view {
    .header-with-title-subTitle-button {
        p.copy-lp {
            width: 80%;
        }
    }
}

.large-view {
    .header-with-title-subTitle-button {
        p.copy-lp {
            width: 72%;
            font-size: 16px;
            margin-bottom: 28px;
        }
    }
}