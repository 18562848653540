@use "../../../global-styles/palette" as c;

.trading-view-banner {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    
    &__text-and-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        h1 {
          color: c.$dark-default;  
        }

        h5 {
            max-width: 744px;
            margin: 0px auto;
            text-align: center;
            font-weight: 500;
            color: c.$dark-80;
        }
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 465px;
    }

    p.trading-view-banner__button-title{
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
        color: c.$dark-default;
        font-weight: 600;
    }

    &__button-or{
        color: c.$dark-40;
        font-weight: 600;
        font-size: 16px;
    }

    &__image-container {
        display: flex;
        justify-content: center;

        img {
            max-width: 800px;
        }
    }
}

.large-view {
    .trading-view-banner {
        &__text-wrapper {
            h5 {
                font-weight: 500;
            }
        }
    }
}

.small-view {
    .trading-view-banner {
        &__button-wrapper {
            flex-direction: column;
            width: 100%;
            .button-v2-wrapper{
                width: 100%;
                a{
                    width: 100%;
                }
            }
        }
        &__button-or{
            margin: 12px 0;
        }

        flex-direction: column;
        gap: 64px;

        &__text-and-button-container {
            gap: 40px;
        }

        &__image-container {
            img {
                max-width: 324px;
            }
        }

        &__text-wrapper {
            h1 {
                text-align: center;
            }
            h5 {
                font-weight: 500;
            }
        }
    }
}

.medium-view {
    .trading-view-banner {

        flex-direction: column;
        gap: 40px;

        &__image-container {
            img {
                max-width: 594px;
            }
        }

        &__text-wrapper {
    
            h5 {
                max-width: 600px;
                font-weight: 500;
            }
        }
    }
}