@use "../../../../global-styles/palette" as c;

.academyArticleCard:hover {
    background-color: c.$neutral-accent-60;
}

.academyArticleCard {
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
    max-width: 360px;
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    background-color: c.$neutral-accent-background;
    height: 100%;

    &__img {
        width: 100%;
        max-height: 240px;
        min-height: 200px;
        margin-bottom: 24px;
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    &__title-tag {
        margin-bottom: 27px;
    }

    h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: c.$dark-default;
        margin: 0 24px;
        font-size: 20px;
    }

    &__tags {
        margin: -5px 24px 16px 24px;
        gap: 8px;
        flex-wrap: wrap;
    }

    &__tag {
        border-radius: 100px;
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        background-color: c.$neutral-accent-default;
    }

    &__tag:hover {
        background-color: c.$dark-default;
        color: c.$pure-white;
    }

    p {
        display: none;
    }

    &__details {
        margin: auto 24px 0 24px;
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &__details-level {
        display: flex;
        padding: 0px 6px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        margin-left: auto;
        background: rgba(100, 100, 100, 0.10);
    }

    &__details-level-beginner {
        color: c.$tm-green-120;
        background: rgba(75, 180, 72, 0.10);
    }

    &__details-level-advanced {
        color: #FC8B05;
        background: rgba(252, 139, 5, 0.10);
    }

    &__details>* {
        gap: 5px;
    }

    &--list {
        height: 230px;
        max-width: 1128px;
        position: relative;
        padding: 32px 96px 24px 480px;
        max-height: 240px;

        .academyArticleCard__tags {
            margin: -5px 24px 16px 0px;
            gap: 8px;
        }

        .academyArticleCard__img {
            height: unset;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 418px;
            margin: 0;
        }

        h4 {
            margin: 0 0 8px 0;
        }

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            color: c.$dark-80;
            display: flex;
            max-width: 552px;
        }

        .academyArticleCard__details {
            margin: auto 0 0 0;
        }
    }

}

.large-view {
    .academyArticleCard {
        height: 100%;

        &__img {
            min-height: 240px;
        }

        &__height-with-tags {
            min-height: 400px;
        }
    }
}

.small-view,
.medium-view {
    .academyArticleCard {

        h4 {
            font-size: 18px;
        }

        &__details {
            margin: 15px 24px 0 24px;
        }

        max-width: unset;
        width: 100%;
    }

}