@use "../../global-styles/palette" as c;

.hero-banner-with-title-subtitle-image-button {
  margin: 30px 0 45px 0;
  text-align: center;

  &__main-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title-button-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 18px;
  }

  &__title-button-group>h5 {
    margin-bottom: 15px;
  }

  &__image {
    flex: 1;
    margin-top: 30px;
  }

  .button-v2-wrapper {
    margin: 0;
  }
}

.large-view {
  .hero-banner-with-title-subtitle-image-button {
    text-align: left;

    &__main-flex {
      flex-direction: row;
    }

    &__title-button-group {
      align-items: flex-start;
    }
  }
}