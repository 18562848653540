@use "../../../global-styles/palette" as c;

.hero-banner-animation {
    .g-container--new {
        .hero-banner-animation__bg-image {
            img {
                width: 100%;
                height: 100%;
            }
        }

        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            margin-bottom: 8px;
            color: c.$pure-white;
        }

        h5 {
            color: c.$dark-20;
            margin-bottom: 32px;
        }
    }
}

.large-view {
    .hero-banner-animation {
        .g-container--new {
            position: relative;
            z-index: 1;
            box-sizing: border-box;
            min-height: 450px;

            &::before {
                content: "";
                height: 100%;
                width: 230px;
                position: absolute;
                left: 0;
                background: linear-gradient(270deg, rgba(14, 29, 49, 0.00) 0%, #0E1D31 100%);
            }

            .hero-banner-animation__bg-image {
                position: absolute;
                z-index: -1;
                width: 100%;
                max-width: 2000px;
                inset: 0;
                top: -40px;

                img {
                    object-fit: cover;
                }
            }

            h1 {
                margin-top: 60px;
            }
        }
    }
}