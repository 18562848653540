@use "../../../../global-styles/palette" as c;

.card-swiper-root {
    display: grid;
    border-radius: 16px;
    padding: 24px;
    max-width: 327px;
    max-height: 236px;
    border: 1px solid c.$dark-10;
    background-color: c.$pure-white;

    p.h5-bold {
        color: c.$dark-default;
        margin-top: 24px;
    }

    p.p-regular {
        color: c.$dark-80;
        margin-block: 8px;
    }

    a.text-nav {
        color: c.$dark-default;
    }
}


.medium-view {
    .card-swiper-root {
        max-width: 250px;
        max-height: 252px;
    }
}