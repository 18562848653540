@use "../../global-styles/palette" as c;

.tabs-with-instruments-and-table {
    margin: auto;
    background-color: c.$pure-white;

    .swiper-wrapper {
        padding-bottom: 1px;
    }

    &__container:not(:empty) {
        display: flex;
        justify-content: center;
        background-color: c.$pure-white;
    }

    &__tab-controls {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        margin: 0 0 32px;
        list-style: none;
        overflow: scroll;
        -ms-overflow-style: none;  
        scrollbar-width: none;  
        
        &::-webkit-scrollbar { 
            display: none;  
        }

        > * {
            box-sizing: border-box;
            flex-shrink: 0;
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            min-width: 50px;
            min-height: 37px;
            border-radius: 50px;
            color: c.$dark-default;
            background: #EAEFF5;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            cursor: pointer;

            &.swiper-pagination-bullet-active {
                border-radius: 50px;
                background-color: c.$dark-default;
                color: c.$pure-white;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }

    &__important-note {
        .free-text__content {
            color: c.$dark-80;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.7;
        }
    }
}

.medium-view,
.large-view {
	.g-container.g-container--fullwidth .g-container.tabs-with-instruments-and-table {
		max-width: 800px;
	}
}

.medium-view {
    .tabs-with-instruments-and-table {
        &__tab-controls {
            margin-bottom: 24px;
        }
    }
}

.large-view {
    .tabs-with-instruments-and-table {
        &__tab-controls {
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 40px;
        }
    }
}