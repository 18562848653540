@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.navigation {
    &__container {
        display: flex;
        flex: 1;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 16px 0;
        .button {
            margin: 16px;

            &--primary {
                margin-left: 0;
                margin-right: 0;
            }

            &--primary-blue {
                margin-right: 0;
                margin-left: 16px;
            }
        }
    }

    &__list,
    &__buttons {
        display: flex;
    }

    &__list{
        align-items: stretch;
    }

    &__item {
        display: flex;
        cursor: pointer;
        .navDropdown-hover{
            max-width: 950px;
            display: flex;
            justify-content: start;
            margin: 0 auto;
            .navDropdown__content{
                width: 100%;
            }
        }
        &:hover{
            .navDropdown-hover{
                height: calc(100vh - 104px);
            }
        }

        &:not(:first-child) {
            margin-left: 16px;
        }

        svg {
            fill: c.$dark-40;
            width: 16px;
        }
    }

    &__item--current {
        position: relative;

        &:not(.navigation__item--button){
            .navigation__link{
                border-bottom: 4px solid c.$tm-green-120;
            }
        }

        svg {
            fill: c.$tm-green-default;
            transition: v.$transition;
            transform: rotateX(-180deg);
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        color: c.$pure-white;
        width: 100%;
    }

    &__magnifying {
        display: flex;

        &:first-child {
            a {
                padding: 0 3px;
            }
        }

        &:last-child {
            svg {
                display: none;
            }
        }
    }

    &__menuTitleWrapper {
        display: none;
    }
}
.small-view,
.medium-view {
    .navigation {
        &__container {
            flex-direction: column;

            .button {
                width: 100%;
                max-width: 216px;

                svg {
                    transform: rotate(270deg);
                }

                &--primary-blue {
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 0;
                }

                &--primary {
                    margin-bottom: 0;
                    margin-top: 0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .loginAndSearchWrapper {
                display: flex;
                width: 100%;
                justify-content: center;

                svg {
                    display: none;
                }

                .loginButtonsWrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100px;
                    padding: 0 24px;
                    margin-bottom: 20px;

                    .navigation__item.navigation__item--button {
                        width: 100%;

                        a {
                            width: 100%;
                        }

                        .squaredButton--primary {
                            background-color: transparent;
                            color: c.$dark-default;
                            border: 1px solid c.$dark-default;

                            &:hover {
                                background-color: c.$dark-default;
                                color: c.$pure-white;
                            }
                        }

                        .squaredButton--secondary {
                            background-color: c.$tm-green-default;

                            &:hover {
                                background-color: c.$tm-green-120;
                                color: c.$pure-white;
                            }
                        }
                    }
                }
            }

            .logo-wrapper {
                display: none;
            }
        }

        &__list {
            flex-direction: column;
            justify-content: space-between;
            height: 85%;
            padding: 0 24px;

            .navigation__item--button:first-child,
            :not(.navigation__item--button) + .navigation__item--button {
                margin-top: auto;
            }

            .secondary-navigation {
                &__container {
                    input[type="search"]::-webkit-search-decoration {
                        -webkit-appearance: none;
                    }
                }

                &__items {
                    width: 327px;
                    justify-content: space-around;
                    border-top: 1px solid c.$neutral-accent-default;
                    border-bottom: 1px solid c.$neutral-accent-default;
                    height: 32px;
                    align-items: center;
                }
            }

            .navigation {
                &__search {
                    flex-grow: 1;
                    width: auto;
                    margin-inline-end: auto;
                }

                &__widgets {
                    &__inputWrapper {
                        padding: 0 24px;
                        &--mobile{
                          padding: 0;
                        }
                    }
                }
            }
        }

        &__item {
            margin: 0;
            padding: 16px 0px 8px;

            &--button {
                margin-top: 0;
                padding: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            span {
                color: c.$dark-default;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0em;
                text-align: left;
            }
        }

        &__item svg {
            transform: rotate(270deg);
        }

        &__buttons {
            flex-direction: column;
            justify-content: center;
            background-color: c.$dark-default;
        }

        &__magnifying {
            svg {
                display: none;
            }
        }
    }
}

.small-view {
    .loginButtonsWrapper{
        .button-v2-wrapper{
            width: 100%;
            >span{
                width: 100%;
            }
        }
    }
    .navigation {
        &__menuTitleWrapper {
            display: block;
            width: 100%;
            margin-top: 12px;
            border-bottom: 1px solid c.$neutral-accent-default;
            text-align: center;

            p {
                padding: 8px 0;
                font-weight: 700;
            }

            a {
                display: none;
            }
        }
    }
}

.medium-view {
    .g-header {
        &__navigation-container {
            height: 100vh;
            overflow: hidden;
        }
    }
    .loginButtonsWrapper{
        display: flex;
        hanging-punctuation: 8px;
    }

    .navigation {
        &__container {
            justify-content: start;

            .loginButtonsWrapper {
                height: 100px;
                margin: 0;
            }
        }

        &__list {
            height: calc(100vh - 110px);

            .secondary-navigation {
                &__items {
                    div:nth-child(2) {
                        order: 3;
                    }

                    div:nth-child(3) {
                        order: 2;

                        .navigation {
                            &__widgets {
                                display: none;
                            }

                            &__widget--lang {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &__menuTitleWrapper {
            margin-top: 17px;
            display: grid;
            grid-auto-flow: column;
            border-bottom: 1px solid c.$neutral-accent-default;

            a {
                display: grid;
                align-self: first baseline;
                justify-self: last baseline;
                cursor: pointer;
            }

            &__menuTitle {
                color: c.$dark-default;
                font-size: 15px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: right;
            }
        }
    }
}

.large-view {
    .button-v2-wrapper {
        .ghost-medium-wbg.login-button {
            border: none;
            background-color: rgba(255, 255, 255, 0.15);
            color: var(--main-nav-foreground-color);
            backdrop-filter: blur(6px);

            &:hover {
                background-color: rgba(255, 255, 255, 0.20);
            }
        }
    }

    .has-light-header {
        .ghost-medium-wbg.login-button {
            background-color: rgba(0, 0, 0, 0.15);

            &:hover {
                background: rgba(0, 0, 0, 0.20);
            }
        }
    }

    .navigation {
        &__menuWrapper {
            display: flex;
        }

        &__menuTitle {
            display: none;
        }

        &__item {
            margin: 0;
            padding: 0 16px;

            svg {
                display: none;
            }
        }

        &__menuTitleWrapper {
            display: none;
        }
    }

    .loginAndSearchWrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        .navigation__item:last-child:dir(ltr) {
            padding: 0;
        }

        svg {
            margin-top: 5px;
            color:  var(--main-nav-foreground-color);

            &:hover {
                cursor: pointer;
            }
        }
    }

    .loginButtonsWrapper {
        display: flex;
        gap: 8px;

        .navigation {
            &__magnifying {
                .squaredButton--primary {
                    background-color: transparent;
                    color: c.$pure-white;
                    border-color: transparent;

                    &:hover {
                        background-color: c.$dark-default;
                        color: c.$pure-white;
                    }
                }

                .squaredButton--secondary {
                    margin-left: 16px;
                    color: c.$pure-white;
                }
            }
        }
    }

    .navDropdown {
        top: var(--large-view-dropdown-top-offset);
    }
    // .has-risk-warning{
    //     .navDropdown{
    //         top: 150px;
    //     }
    // }
    .search-active {
        .navDropdown__content {
            .sectionWrapper {
                width: 350px;
                position: absolute;
                left: 750px;

                .searchWrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    margin-inline: auto;

                    .search-input {
                        input {
                            display: inline;
                            background-color: c.$neutral-accent-background;
                            width: 100%;

                            &:focus {
                                outline: none;
                            }
                        }
                    }

                    svg {
                        cursor: pointer;
                        padding-left: 10px;
                    }

                    &__search {
                        border-radius: 4px;
                        border: none;
                        background-color: c.$neutral-accent-background;
                        height: 48px;
                        width: 80%;
                        background-repeat: no-repeat;
                        background-position: 10px center;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }

    .navDropdown__content {
        background-color: transparent;
    }

    .g-container .g-container--fullwidth {
        .g-container {
            background-color: transparent;
        }
    }
}

.g-header {
    --background-color-opacity: 1;
}

body.has-light-header .g-header {
    --main-nav-foreground-color: #{c.$dark-default};
    --sec-nav-foreground-color: #{c.$dark-80};
}

body.has-light-header.has-header-height-scrolled .g-header {
    --background-color-opacity: 0.6;
}

body.has-dark-header .g-header,
body.has-dark-header.landing-page {
    --main-nav-foreground-color: #{c.$pure-white};
    --sec-nav-foreground-color: #{c.$dark-20};
    --main-nav-background-color: rgba(14, 29, 49, var(--background-color-opacity));
    --light-logo-display: visible;
    --dark-logo-display: hidden;
}

body.has-dark-header.has-header-height-scrolled .g-header {
    --background-color-opacity: 0.9;
}

.g-header,
body.has-light-header .g-header, 
body.has-light-header.landing-page {
    --main-nav-background-color: rgba(255, 255, 255, var(--background-color-opacity));
    --sec-nav-mobile-foreground-color: #{c.$dark-default};
    --light-logo-display: hidden;
    --dark-logo-display: visible;
}

.g-header {
    background-color: var(--main-nav-background-color);
    
    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        backdrop-filter: blur(10px);
    }

    .navigation {
        &__link {
            color: var(--main-nav-foreground-color);
        }
    }

    .loginButtonsWrapper .navigation__magnifying .squaredButton--primary,
    .loginButtonsWrapper .navigation__magnifying .squaredButton--primary:hover {
      background-color: transparent;
      color: var(--main-nav-foreground-color);
    }

    .loginButtonsWrapper .navigation__magnifying .squaredButton--secondary,
    .loginButtonsWrapper .navigation__magnifying .squaredButton--secondary:hover {
        background-color: c.$tm-green-default;
        color: c.$dark-default;
    }

    .icon-magnifying-glass {
        color: var(--main-nav-foreground-color);
    }
}

@media (orientation: landscape) {
    .medium-view {
        .g-header__navigation-container {
            overflow: scroll;
        }

        .mobile-nav-active
            .g-header__navigation
            .navigation__container
            .loginAndSearchWrapper {
            padding-bottom: 0;
        }

        .mobile-nav-active.menu-active
            .g-header__navigation
            .navigation__container {
            height: auto;
        }
    }
}

.search-trigger{
    padding: 6px 11px;
    border-radius: 50px;
    color: var(--main-nav-foreground-color);
    cursor: pointer;
    &:hover{
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(12px);
    }
}
