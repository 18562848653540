@use "../../global-styles/palette" as c;
@use "../../global-styles/variables" as v;

.HeroBannerWithVideo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0 80px;

  &__description {
    max-width: 750px;
    text-align: center;
  }

  img {
    max-width: 936px;
  }

  &__img-top.img-top {
    max-width: 140px;
    margin: 20px;
  }

  h5 {
    margin-top: 8px;
    font-weight: 500;
  }

  .button {
    margin: 32px 0 20px 0;
    border-radius: 8px;
  }

  &__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 32px;

    span {
      text-decoration: underline;
      margin-left: 8px;
    }
  }

  &__link2 {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 0px;

    span {
      margin-left: 8px;
    }
  }
}

.fullscreen-popover {
  background: rgba(14, 29, 49, 0.8);
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: v.$modal-z-index;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;

  &__close {
    margin-bottom: 16px;
    cursor: pointer;
  }

  &__content {
    position: relative;
    max-width: 1128px;
    width: 100%;
    max-height: 705px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  &.open {
    display: flex;
  }
}

.medium-view,
.small-view {
  .HeroBannerWithVideo {
    padding-bottom: 64px;

    &__img-top.img-top {
      max-width: 150px;
    }

    &__link {
      display: none;
    }
  }
}

.small-view {
  .HeroBannerWithVideo {
    padding-top: 16px;
    padding-bottom: 45px !important;
  }
}

.large-view {
  .HeroBannerWithVideo {
    &__img-top.img-top {
      margin-top: 30px;
    }
  }
}