@use "../../../global-styles/palette" as c;

.dark-mode-text-color {
  color: c.$pure-white;
}

.light-mode-text-color {
  color: c.$dark-90;
}

.trade-with-think-trader {
  border-radius: 20px;

  &__img-container img {
    width: 100%;
  }

  &__img-container {
    flex: 1;
    margin: 0 0 40px 60px;
  }

  &__main-container {
    display: flex;
    gap: 25px;
  }

  &__text-content-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 30px;
    margin: 48px 60px 60px 60px;
  }

  &__cards-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  &__card {
    display: flex;
    flex-direction: row;
    gap: 27px;

    strong {
      font-weight: 700;
    }

    strong:dir(rtl) {
      line-height: 30px;
    }
  }

  &__card>img {
    width: 40px;
    align-self: baseline;

    &:dir(rtl) {
      align-self: self-end;
    }
  }

  .button-v2-wrapper {
    justify-content: flex-start;
  }

  .h2-lp {
    font-weight: 600;
  }

  .h5-lp {
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
  }

  .button-v2-wrapper .cta-large-wbg {
    padding: 16px 42px;
  }

  .cta-large-dbg {
    background-color: c.$dark-90;
    color: c.$pure-white;
  }

  .cta-large-dbg:hover {
    background-color: c.$dark-90;
    color: c.$pure-white;
  }
}

.small-view,
.medium-view {
  .trade-with-think-trader {
    &__main-container {
      flex-direction: column;
    }

    &__card {
      flex-direction: row;
      gap: 14px;
    }
  }
}

.small-view {
  .trade-with-think-trader {
    &__main-container {
      gap: 25px;
    }

    &__text-content-container {
      margin: 40px 20px;
    }

    &__img-container {
      margin: 0 0 20px 20px;
    }

  }
}

.medium-view {
  .trade-with-think-trader {

    &__main-container {
      flex-direction: column;
      gap: 40px;
    }

    &__cards-container {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    &__text-content-container {
      margin: 40px;
    }

    &__img-container {
      flex: 1;
    }
  }
}

.large-view {
  .trade-with-think-trader {
    &__text-content-container {
      margin: 45px 1px 55px 60px;
    }

    &__img-container {
      margin: 0 0 0 20px;
    }
  }
}

.large-view[dir="rtl"] {
  .trade-with-think-trader {
    &__text-content-container {
      margin: 45px 50px 55px 4px;
    }
  }
}