@use "../../global-styles/palette" as c;

.our-people-image-gallery {
    p.our-people-image-gallery__title {
        color: c.$dark-gray-font-color;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        letter-spacing: -0.64px;
    }

    .swiper {
        &:hover {
          cursor: grab;
        }

    .people-card-root {
        border-radius: 12px;
        margin-top: 40px;
        min-height: 420px;
        border: 1px solid c.$light-gray-line-color;

        &__image-container {
            img {
                border-radius: 12px 12px 0 0;
                width: 100%;
                height: 100%;
            }
        }

        &__texts-container {
            padding: 24px;

            h4.h4-lp {
                color: c.$dark-default;
                width: auto;
            }

            p.p-regular {
                color: c.$dark-default;
                margin-top: 8px;
                width: auto;
                line-height: 20px;
            }
        }
    }
}
}

.medium-view {
    .our-people-image-gallery {
        p.our-people-image-gallery__title {
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: -0.8px;
        }

    .swiper {
        .people-card-root {
            min-height: 360px;
        }
    }
}
}

.large-view {
    .our-people-image-gallery {
        p.our-people-image-gallery__title {
            font-size: 48px;
            line-height: 57.6px;
            letter-spacing: -0.96px;
        }
    .swiper {
        .people-card-root {
            &__texts-container {

                h4.h4-lp {
                    font-size: 24px;
                }
            }
        }
    }
}
}