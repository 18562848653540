@use "./CardWithImageAndLabels/CardWithImage";

.card-deck-with-images__root {
    display: grid;
    gap: 12px
}

.medium-view {
    .card-deck-with-images__root {
        gap: 24px
    }
}

.large-view {
    .card-deck-with-images__root {
        grid-template-columns: repeat(2, auto);
        gap: 24px;
    }
}