@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.maintenance-cards-strip {
    &__card {
        padding: 18px;
        box-sizing: border-box;
        border: solid 1px c.$dark-10;
        border-radius: 8px;
        justify-content: space-between;
        text-align: left;
        flex-direction: column;
        gap: 10px;
        min-width: 230px;
    }

    .caption2-regular {
        font-weight: 500;
    }

    &__card-title-bar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__main-strip {
        width: 100%;
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr;
    }

    &__message-container {
        height: 40px;
        text-align: left;
        width: 100%;
    }

    &__message-container>div {
        width: 100%;
    }
}

.medium-view {
    .maintenance-cards-strip {
        &__main-strip {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.large-view {
    .maintenance-cards-strip {
        &__main-strip {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}