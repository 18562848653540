@use "../../../global-styles/palette" as c;

.link-pills-pair {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    max-width: 100%;
}

.medium-view,
.large-view {
    .link-pills-pair {
        flex-direction: row;
        gap: 8px;
        padding: 8px;
        background-color: c.$dark-default;
        border-radius: 40px;
    }
}