@use "../../global-styles/palette" as c;

.dynamic-leverage-pricing-table-root {
  .g-container {
    background-color: c.$dark-default;
    margin: 0;
    padding-inline: 24px;
    .dynamic-leverage-pricing-table-root__animationContainer {
      display: none;
    }

    .instrument-slider--new {
      &__items {
        display: flex;
        background-color: c.$dark-80;
        overflow-x: auto;
        column-gap: 17px;
      }
      &__item {
        background-color: transparent;
        padding-inline: 13px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: c.$pure-white;

        &:hover {
          background-color: c.$pure-white;
          color: c.$dark-default;
          font-weight: 700;
        }
      }

      &__highlighted {
        background-color: c.$pure-white;
        color: c.$dark-default;
      }
    }

    .price-feed {
      &__title {
        text-align: center;
        margin-bottom: 32px;
        color: c.$pure-white;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }

      &__table {
        padding: 24px;
        border-radius: 16px;
        border: 1px solid c.$dark-20;
        background-color: c.$dark-80;
      }

      &__header {
        gap: 0;
        padding: 0;
        border-bottom: 1px solid c.$neutral-accent-default;
        background-color: transparent;

        &:hover {
          cursor: pointer;
        }
      }

      &__row {
        padding: 0;
        height: 50px;
      }

      &__headerCell {
        color: c.$dark-20;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }

      &__headerCell:nth-child(2) {
        display: inline;
        width: 80px;
        text-align: center;
      }

      &__cellContainer {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: c.$pure-white;

        .price-feed__cellTitle {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          white-space: nowrap;
        }
      }

      &__cellContainer:nth-child(2) {
        width: fit-content;
        display: inline;
      }

      &__headerCell:last-child {
        display: inline;
        width: 50px;
      }

      &__cellContainer:last-child {
        display: block;
        width: fit-content;
        .price-feed__cellTitle {
          color: c.$pure-white;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      &__highlighted {
        background-color: unset; 
      }
    
    }

    .price-feed__row {
      &:hover {
        background-color: unset;
        .price-feed__cellContainer {
          .price-feed__cellTitle {
            font-weight: bolder;
          }
        }

        .price-feed__cellContainer:last-child {
          font-weight: bolder;
        }
      }
    }

    .price-feed__headerCell {
      width: 30px;
      text-align: start;
    }
  }
}

.medium-view {
  .dynamic-leverage-pricing-table-root {
    .g-container {
      padding-inline: 72px;
      .instrument-slider--new {
        &__items {
          width: fit-content;
          margin-inline: auto;
        }
      }

      .price-feed {
        &__headerCell {
          width: fit-content;
        }

        &__headerCell:nth-child(2) {
          width: 85px;
          white-space: nowrap;
        }
      }
    }
  }
}
.large-view {
  .dynamic-leverage-pricing-table-root {
    background-color: c.$dark-default;
    .g-container {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      justify-content: space-evenly;
      margin-inline: auto;
      padding: 0;

      .dynamic-leverage-pricing-table-root__animationContainer {
        display: inline-block;
        width: 354px;
        height: 301px;
      }
      .instrument-slider--new {
        &__items {
          width: fit-content;
        }
      }

      .price-feed {
        &__tableWrapper {
          max-width: 600px;
        }

        &__title {
          width: fit-content;
        }

        &__headerCell {
          width: fit-content;
        }

        &__headerCell:nth-child(2) {
          width: 50px;
          white-space: nowrap;
        }
      }
    }
  }
}
