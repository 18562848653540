@use "../../../global-styles/palette" as c;
@use "./datepicker.css";

.filters{
    &__field-tabs{
      display: flex;
      border: 1px solid c.$dark-20;
      border-radius: 8px;
      padding: 8px;
      gap: 10px;
    }
    &__tab{
      border-radius: 8px;
      padding: 4px 16px;
      color: c.$dark-80;
      line-height: 24px;
      cursor: pointer;
      transition: .3s ease-out;
      &--active, &:hover{
        background-color: c.$dark-default;
        color: c.$pure-white;
      }
    }
    &__tabs-container{
      display: flex;
    }

    &__checkbox,
    &__radio {
      line-height: 28px;
      display: flex;
      margin-bottom: 16px;  
      font-size: 18px;
      font-weight: 500;
      [type="checkbox"]:checked,[type="radio"]:checked,
      [type="checkbox"]:not(:checked),[type="radio"]:not(:checked){
          position: absolute;
          left: -9999px;
      }
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label,
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label
      {
          position: relative;
          padding-left: 35px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #fff;
      }

      [type="checkbox"]:checked + label:before,
      [type="checkbox"]:not(:checked) + label:before,
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before
      {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border-width: 1px;
        border-style: solid;
        background: #fff;
        box-sizing: border-box;
      }

      [type="checkbox"]:checked + label:before,
      [type="checkbox"]:not(:checked) + label:before
      {
        border-radius: 2px;
        border-color: c.$dark-40;
      }

      [type="checkbox"]:checked + label:before {
        background-color: c.$dark-default;
      }

      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before
      {
        border-radius: 20px;
      }

      [type="radio"]:not(:checked) + label:before {
        border-color: c.$dark-40;
      }

      [type="radio"]:checked + label:before {
        border-color: c.$dark-default;
      }

      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after,
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after
      {
        content: "";
        position: absolute;
        border: 10px;
        transition: all .2s ease;
      }

      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after
      {
        top: 4px;
        left: 9px;
        width: 5px;
        height: 12px;
        transform: rotate(45deg);
        border-bottom: 2px solid #5ee15a;
        border-right: 2px solid #5ee15a;
      }

      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after
      {
        top: 6px;
        left: 6px;
        width: 12px;
        height: 12px;
        border-radius: 18px;
        background-color: c.$tm-green-default;
      }

      [type="checkbox"]:not(:checked) + label:after,
      [type="radio"]:not(:checked) + label:after
      {
          opacity: 0;
      }
      [type="checkbox"]:checked + label:after,
      [type="radio"]:checked + label:after
      {
          opacity: 1;
      }
  }
    &__checkbox.inline{
        width: auto;
        margin-right: 24px;
    }
}

.datepicker-box {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.datepickers {
  width: 100%;
  display: flex;
  gap: 12px;
  margin: 8px 0;

  input{
    height: 48px;
    width: 264px;
    border: 1px solid c.$dark-20;
    border-radius: 8px;
    color: c.$dark-60;
    font-size: 18px;
    line-height: 28px;
    text-indent: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.181 26.847H6.821c-.76 0-1.38-.62-1.38-1.38V9.227c0-.76.62-1.38 1.38-1.38h18.36c.76 0 1.38.62 1.38 1.38v16.24c0 .76-.62 1.38-1.38 1.38zM6.821 8.787c-.246 0-.446.2-.446.446v16.24c0 .247.2.447.446.447h18.36c.247 0 .447-.2.447-.447V9.227c0-.247-.2-.447-.447-.447H6.821v.007z' fill='%233E4A5A'/%3E%3Cpath d='M11.521 11.34a.464.464 0 0 1-.466-.467V5.62c0-.26.206-.467.466-.467s.467.207.467.467v5.253c0 .26-.207.467-.467.467zM20.373 11.34a.464.464 0 0 1-.467-.467V5.62c0-.26.207-.467.467-.467s.467.207.467.467v5.253c0 .26-.207.467-.467.467zM26.095 15.34H5.908a.464.464 0 0 1-.467-.467c0-.26.207-.466.467-.466h20.187c.26 0 .466.206.466.466s-.206.467-.466.467zM11.4 17.347v1.773H9.629v-1.773H11.4zm0-.667H9.629c-.367 0-.667.3-.667.667v1.773c0 .367.3.667.667.667H11.4c.367 0 .667-.3.667-.667v-1.773c0-.367-.3-.667-.667-.667zM16.89 17.347v1.773h-1.774v-1.773h1.773zm0-.667h-1.774c-.367 0-.667.3-.667.667v1.773c0 .367.3.667.667.667h1.773c.367 0 .667-.3.667-.667v-1.773c0-.367-.3-.667-.667-.667zM22.374 17.347v1.773H20.6v-1.773h1.774zm0-.667H20.6c-.366 0-.666.3-.666.667v1.773c0 .367.3.667.666.667h1.774c.366 0 .666-.3.666-.667v-1.773c0-.367-.3-.667-.666-.667zM11.4 22.307v1.773H9.629v-1.773H11.4zm0-.667H9.629c-.367 0-.667.3-.667.667v1.773c0 .367.3.667.667.667H11.4c.367 0 .667-.3.667-.667v-1.773c0-.367-.3-.667-.667-.667zM16.89 22.307v1.773h-1.774v-1.773h1.773zm0-.667h-1.774c-.367 0-.667.3-.667.667v1.773c0 .367.3.667.667.667h1.773c.367 0 .667-.3.667-.667v-1.773c0-.367-.3-.667-.667-.667zM22.374 22.307v1.773H20.6v-1.773h1.774zm0-.667H20.6c-.366 0-.666.3-.666.667v1.773c0 .367.3.667.666.667h1.774c.366 0 .666-.3.666-.667v-1.773c0-.367-.3-.667-.666-.667z' fill='%233E4A5A'/%3E%3C/svg%3E");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px);
  }
}

.small-view {
  .filters {
    &__checkbox {
      width: 100%;
    }

    .datepickers {
      flex-direction: column;
    }
  }
}
