@use "../../../global-styles/palette" as c;
@use "../../../global-styles/typography";

.custom-p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  text-decoration: none;
  font-weight: bold;
}

.main-error-container {
  display: flex;
  justify-content: center;

  flex-direction: column;

  &__error-items-container {
    margin-top: 13vh;
    margin-bottom: 13vh;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  p {
    color: c.$dark-60;

    margin-top: -10px;
    margin-bottom: -10px;
  }

  .text-jumbo {
    color: c.$dark-60;
  }

  h5 {
    color: c.$pure-white;
    max-width: 360px;
    font-weight: bold;
  }

  &__return-button {
    margin-top: 35px;
    margin-bottom: 10px;
  }

  &__help-pages-link {
    color: c.$pure-white;
    a {
      text-decoration: none;
      color: c.$blue-lagoon-default;
      font-weight: bold;
    }
  }
}

.medium-view,
.small-view {
  .main-error-container {
    &__error-items-container {
      display: flex;
      margin-top: 5vh;
      margin-bottom: 5vh;
      flex-direction: column;
      gap: 10px
    }
  }
}