@use "../../global-styles/palette" as c;

.price-feed {
  background-color: c.$pure-white;

  &__table {
    overflow-x: auto;
  }

  &__header {
    height: 40px;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: c.$light-grey;
  }

  &__headerCell {
    font-size: 12px;
    width: 90px;
    text-align: left;
  }

  &__instrumentIcon {
    max-height: 24px;
    margin: 0;
    margin-inline-end: 5px;
  }

  &__upDownIcon {
    display: none;
  }

  &__row {
    cursor: pointer;
    height: 75px;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid c.$light-grey;
  }

  &__row:hover {
    font-weight: bold;
    background-color: c.$blue-lagoon-20;

    .price-feed__upDownIcon {
      display: block;
    }
  }

  &__row:hover::after {
    opacity: 0.5;
  }

  &__highlighted {
    font-weight: bold;
    background-color: c.$blue-lagoon-20;

    .price-feed__upDownIcon {
      display: block;
    }
  }

  &__highlighted::after {
    opacity: 0.5;
  }

  &__cellContainer {
    display: flex;
    flex-direction: row;
    width: 90px;
    justify-content: flex-start;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
    }
  }

  &__cellTitle {
    font-size: 16px;
  }

  &__footerText {
    margin-top: 10px;
    font-size: 12px;
    color: c.$dark-60;
    font-weight: 500;

    &:dir(rtl) {
      line-height: 20px;
    }
  }
}

.small-view {
  .price-feed {

    &__headerCell {
      font-size: 11px;
      width: 35px;
      flex-shrink: 0;
      text-align: center;

      &:last-child {
        display: none;
      }
    }

    &__cellContainer {
      width: 35px;
      flex-shrink: 0;

      &:last-child {
        display: none;
      }
    }

    &__cellTitle {
      font-size: 14px;
    }

    &__instrumentIcon {
      display: none;
    }
  }
}


.small-view {

  .price-feed {
  
    &__header {
      height: 40px;
      padding: 0 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: unset;
    }
  }
}

.medium-view {
  .g-container {
    .price-feed__headerCell {
      width: 70px;
      white-space: nowrap;
    }

    .price-feed__headerCell:first-child {
      min-width: 180px;
    }

    .price-feed__cellContainer:first-child {
      min-width: 180px;
    }

    .price-feed__headerCell:nth-child(2) {
      margin-left: -15px;
    }
  }
}
.large-view {
  .g-container {
    .price-feed__headerCell:first-child {
      min-width: 180px;
      margin-left: 15px;
    }

    .price-feed__headerCell:nth-child(2) {
      margin-left: -15px;
    }

    .price-feed__headerCell:nth-child(3) {
      margin-left: -14px;
    }

    .price-feed__headerCell:nth-child(4) {
      margin-left: -10px;
    }

    .price-feed__cellContainer:first-child {
      min-width: 180px;
    }
  }
}

.large-view[dir="rtl"] {
  .g-container {
    .price-feed__table {
      .price-feed__headerCell {
        text-align: right;

        &:nth-child(4) {
          text-align: left;
        }
        &:nth-child(3){
          text-align: center;
        }
      }
    }
  }
}