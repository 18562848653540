.mobile-div {
    display: none;
}

.desktop-div {
    display: flex;
}

.small-view, .medium-view {
    .mobile-div {
        display: flex;
    }

    .desktop-div {
        display: none;
    }
}