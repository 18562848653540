@use "../../../../global-styles/palette" as c;

.getHelpCard {
  background-color: c.$neutral-accent-background;
  border-radius: 16px;
  padding: 24px;

  &:hover {
    background-color: c.$neutral-accent-default;
  }

  &__card-icon {
    margin-bottom: 8px;
  }

  &__card-title {
    margin-bottom: 8px;
    p {
      color: c.$dark-default;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  &__card-item {
    margin-bottom: 16px;

    a {
      display: inline list-item;
      color: c.$dark-default;
      text-decoration: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-left: 5px;
    }
  }
}

.medium-view {
  .getHelpCard {
    min-height: 240px;

    &:hover {
      background-color: c.$neutral-accent-default;
    }
  }
}

.large-view {
  .getHelpCard {
    min-height: 240px;

    &:hover {
      background-color: c.$neutral-accent-default;
    }
  }
}
