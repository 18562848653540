@use "../../../global-styles/palette" as c;
@use "./Person/Persons";

.webinar-banner-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    min-height: 256px;
    box-sizing: border-box;
    border-radius: 24px;
    background-color: c.$dark-default;
    background-image: var(--background-image-url-mobile);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;

    &__textsWrapper {
        h3 {
            color: c.$pure-white;
            text-align: center;
            margin-bottom: 4px;
        }

        .webinar-banner-section__subtitle {
            color: c.$pure-white;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-bottom: 32px;
        }
    }
}

.medium-view {
    .webinar-banner-section {
        max-width: 900px;
        margin-inline: auto;
        background-image: var(--background-image-url-desktop);
        background-position: top left;
        justify-content: space-between;
        background-size: 80%;
        padding: 40px;

        &__textsWrapper {
            h3 {
                text-align: left;
                width: 95%;
                font-size: 36px;
                font-weight: 600;
                line-height: 39px;
            }

            p {
                text-align: left;
                width: 90%;
                margin-bottom: 24px;
            }

            .button-v2-wrapper {
                width: max-content;
                margin: 0;
            }
        }

        &__peopleWrapper {
            display: flex;
            width: max-content;
            column-gap: 10px;
            height: 185px;
        }
    }
}

.large-view {
    .webinar-banner-section {
        display: flex;
        background-image: var(--background-image-url-desktop);
        background-position: top left;
        align-items: center;
        justify-content: space-between;
        padding: 56px 70px;
        background-size: 640px auto;

        &__textsWrapper {

            h3 {
                text-align: left;
                font-size: 42px;
                font-weight: 700;
                line-height: 60px;
            }

            p {
                margin-bottom: 37px;
                font-size: 20px;
                text-align: left;
            }


            .button-v2-wrapper {
                width: max-content;
                margin: 0;
            }

        }

        &__peopleWrapper {
            display: flex;
            column-gap: 22px;
        }
    }
}