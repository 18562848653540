@use "../../../global-styles/palette" as c;

.card-with-image-and-title-animated {
  padding: 16px;
  border-radius: 20px;
  transition: 1s ease;
  &__image{
    max-height: 70px;
    svg{
      width: auto!important;
    }
  }

  img {
    max-height: 70px;
  }

  &:hover{
    transform: translateY(-10px);
    transition: 1s ease;
  }

  &__textWrapper {
    h5 {
      color: c.$dark-90;
      font-size: 22px;
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 12px;
      width: 57vw;
    }

    p {
      color: c.$dark-80;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
    }
  }
}

.small-view {
  .card-with-image-and-title-animated {
    padding: 20px;

    img{
      max-height: 55px;
    }
  }
}

.medium-view {
  .card-with-image-and-title-animated {
    padding: 30px;

    img{
      max-height: 55px;
    }
  }
}

.large-view {
  .card-with-image-and-title-animated {
    padding: 40px;

    &__textWrapper {
      h5 {
        width: fit-content;
      }
    }
  }
}
