@use "../../global-styles/palette" as c;

.support-section{
  background: linear-gradient(90deg, c.$pure-white 50%, #f2f4f6 50%);
  &__tab{
    display: flex;
  }
  &__content{
    background-color: c.$pure-white;
    max-width: 778px;
    flex: 1;
    padding: 40px 0 80px;
    h5 {
      margin-bottom: 24px;
      max-width: 552px;
    }
    h4 {
      color: c.$blue-lagoon-default;
      max-width: 552px;
    }

    h3 {
      margin: 7px 0 16px 0;
      max-width: 648px;
    }

    a {
      color: c.$blue-lagoon-default;
      text-decoration: none;
      font-weight: bold;
      &:last-child{
        margin-bottom: 0;
      }
    }

    strong {
      font-weight: bold;
    }

    p {
      max-width: 552px;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: c.$dark-80;
    }
    ol {
      margin-top: 16px;
      max-width: 552px;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: c.$dark-80;
    }
    ul {
      margin-top: 16px;
      max-width: 552px;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: c.$dark-80;

      li {
        margin-bottom: 5px;
        padding-left: 30px;
        display: flex;
      }

      li:last-child {
        margin-bottom: 16px;
      }
      li::before {
        content: "•"; 
        color: c.$dark-80;
        margin-right: 10px;
        margin-left: -20px;
      }
      li {
        li::before {
          content: '';
          display: inline-block;
          width: 3px;
          height: 3px;
          -moz-border-radius: 7.5px;
          -webkit-border-radius: 7.5px;
          border-radius: 7.5px;
          border: c.$dark-80 solid 1px;
          margin-right: 10px;
        }
      } 
    }

    ol {
      list-style: decimal;
      padding: revert;
      margin-top: 16px;
      max-width: 552px;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: c.$dark-80;

      li {
        margin-bottom: 5px;
        padding-left: 10px;

        li::before {
          content: '';
          display: inline-block;
          width: 3px;
          height: 3px;
          -moz-border-radius: 7.5px;
          -webkit-border-radius: 7.5px;
          border-radius: 7.5px;
          border: c.$dark-80 solid 1px;
          margin-right: 10px;
        }
      }

      li:last-child {
        margin-bottom: 16px;
      }
    }
  }
  &__navigation{
    background-color: #f2f4f6;
    padding: 40px 0 80px 40px;  
    max-width: 350px;
  }
  &__title{
    margin-bottom: 32px;
  }
  &__item{
    margin-bottom: 16px;
    .support-section__list{
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
    }
    &.active{
      svg{
        transform: rotate(-180deg);
      }
      .support-section__list{
        max-height: 540px;
      }
    }
    &-title{
      font-weight: bold;
      margin-bottom: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg{
        transition: .3s ease-in;
      }
    }
  }
  &__list-item{
    padding: 8px 20px;
    border-left: 4px solid c.$dark-10;
    a{
      color: c.$dark-80;
      text-decoration: none;
      font-weight: 500;
    }
    &.active{
      border-color: c.$blue-lagoon-default;
      a{
        color: c.$blue-lagoon-default;
        font-weight: bold;
      }
    }
  }
  &__link{
    &.faq-accordion__link{
      font-weight: bold;
      margin-bottom: 16px;
    }
    svg{
      fill: c.$blue-lagoon-default;
      transform: rotate(180deg);
    }
  }
}

.medium-view,.small-view{
  .support-section{
    background: transparent;
    &__tab{
      flex-direction: column;
    }
    &__content,&__navigation{
      padding: 32px 24px 40px;
    }
    .g-container{
      margin: 0;
    }
    &__title {
      text-align: center;
    }

    &__content {
      p {
        font-size: 16px;
        line-height: 1.5;
      }
  
      ul {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 24px;
  
        li {
          padding-left: 25px;
        }
      }
    }
  }
}

.large-view {
  .support-section {
    &__content {
      padding: 40px 20px 80px;
    }
  }
}