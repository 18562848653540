@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.animated-image-boxes-grid-simple {
  display: grid;
  gap: 30px;

  grid-template-rows: 5fr 4fr 4fr;
  grid-template-areas:
    "bigImage"
    "smallImage"
    "swiper";

  &__markets-container {
    @extend .centered-container-column;
    padding-top: 34px;
    grid-area: bigImage;
    border-radius: 32px;

    img {
      margin-top: 10px;
    }

    .animated-image-boxes-grid-simple__main-image {
      width: 100%;
    }
  }

  &__social-container {
    @extend .centered-container-column;
    justify-content: unset;
    padding-top: 34px;
    grid-area: smallImage;
    border-radius: 32px;
  }

  &__payment-container {
    padding-top: 34px;
    grid-area: swiper;
    border-radius: 32px;
  }

  &__payment-container {
    .swiper {
      margin-top: 6vw;
    }

    .animated-image-boxes-grid-simple__title-container {
      margin-bottom: calc(16vw - 22px);
    }
  }

  &__swiper>.swiper-wrapper {
    transition-timing-function: linear;
  }

  &__social-img {
    width: 80vw;
    margin-top: 2vh;
  }

  &__payment-slide-img-container {
    height: 50px;
    width: 171px;
    @extend .centered-container;
    border-radius: 6px;
  }

  &__payment-container {
    align-items: center;
    justify-content: center;
    border-radius: 32px;
  }

  .swiper-wrapper {
    max-width: 250px;
    width: 100%;
  }
}

.medium-view {
  .animated-image-boxes-grid-simple {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    grid-template-areas:
      "bigImage bigImage"
      "smallImage swiper";

    &__payment-container {

      .swiper {
        margin-top: 3vw;
        margin-bottom: 3vw;
      }

      .animated-image-boxes-grid-simple__title-container {
        margin-bottom: calc(8vw - 20px);
      }
    }

    &__social-img {
      width: 34vw;
      margin-top: calc(0.7vw + 5px);
    }

  }
}

.large-view {
  .animated-image-boxes-grid-simple {
    padding-top: 30px;
    padding-bottom: 30px;
    grid-template-columns: 14fr 8fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "bigImage smallImage"
      "bigImage swiper";

    &__payment-container {

      .swiper {
        margin-top: 30px;
      }

      .animated-image-boxes-grid-simple__title-container {
        margin-bottom: 34px;
      }
    }

    &__social-img {
      width: min(30vw, 350px);
      margin-top: 6px;
    }

  }
}