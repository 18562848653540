@use "../../global-styles/palette" as c;

.latest-market-news{
    &__last-news{
        max-width: 600px;
        width: 100%;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
    }
    &__title {
        margin-bottom: 16px;
    }

    &__container {
        display: flex;
        gap: 32px;
    }

    &__image {
        position: relative;
        border-radius: 10px;
        height: 100%;
        min-height: 392px;

        img {
            border-radius: 10px;
        }
    }

    &__text {
        position: absolute;
        z-index: 10;
        color: #fff;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0px 24px 24px 24px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(13, 29, 49, 0.00) 0.01%, rgba(0, 0, 0, 0.50) 32%, rgba(0, 0, 0, 0.75) 100%);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        a {
            text-decoration: none;
            color: c.$pure-white;
            max-width: 540px;
        }
    }

    &__news-details {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
    }

    &__left-details {
        p {
            display: flex;
            align-items: center;

            svg {
                margin: 0px 8px;
            }
        }
    }

    &__news-list-item-1 {
        margin-top: 4px;
    }

    &__item-container-1, &__item-container-2, &__item-container-3, &__item-container-4 {
        text-decoration: none;
        color: c.$dark-default;
    }

    &__news-list-item-1, &__news-list-item-2, &__news-list-item-3 {
        border-bottom: 1px solid c.$dark-10;
        margin-bottom: 16px;
    }

    &__list-category-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: c.$dark-default;
    }

    &__list-date-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: c.$dark-60;
    }

    &__item-details {
        p {
            display: flex;
            align-items: center;

            svg {
                margin: 0px 8px;
            }
        }
    }

    &__item-title {
        margin: 4px 0px 16px 0px;

         h4 {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
         }
    }
    
    &__green-text {
        font-size: 12px;
        color: c.$tm-green-120;
        line-height: 16px;
        font-weight: 700;
    }
    
    &__category-text {
        font-size: 12px;
        color: c.$pure-white;
        line-height: 16px;
        font-weight: 600;
    }
    
    &__date-text {
        font-size: 12px;
        color: c.$dark-20;
        line-height: 16px;
        font-weight: 400;
    }
    
    &__right-details {

        p {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    &__author-text {
        font-size: 12px;
        color: c.$dark-20;
        line-height: 16px;
        font-weight: 400;
    }

    &__news-title {
        margin-bottom: 4px;
        h4 {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
        }
    }

    &__news-summary {
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            font-style: normal;
            color: c.$dark-10;
        }
    }
}

.medium-view {
    .latest-market-news{
        &__title {
            h1 {
                font-size: 38px;
                font-weight: 600;
                line-height: 48px;
                max-width: 600px;
                margin: 0px auto;
            }
        }
    
        &__container {
            flex-direction: column;
            align-items: center;
        }

        &__last-news {
            width: 600px;
        }

        &__news-title{
            h4 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &__item-title {
            max-width: 600px;

            h4 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &__news-summary {
            width: 553px;
        }
    }
}

.small-view {
    .latest-market-news {
        &__image{
            min-height: unset;
        }
        &__container {
            flex-direction: column;
            align-items: center;
        }

        &__last-news {
            max-width: 327px;
            height: 320px;
            object-fit: cover;
        }

        &__text {
            max-width: 279px;
        }

        &__news-details {
            justify-content: unset;
            flex-direction: column;
        }

        &__left-details {
            margin-bottom: 8px;
        }

        &__news-title{
            h4 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &__item-title {
           
            h4 {
                font-size: 18px;
                line-height: 24px;
            }
        }

    }
}
