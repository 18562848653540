@use "../../../global-styles/palette" as v;

.optional-button-banner-with-animation {
  &__flex-middle {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }

  .button {
    padding: 0px 24px;
    font-weight: 500;
  }

  .button--primary {
    color: v.$dark-default;
  }

  &__description-container {
    text-align: center;
  }

  &__flex-outer {
    display: flex;
  }

  &__image-container-left:not(:empty),
  &__image-container-right:not(:empty) {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--big-image &__image-container-left:not(:empty),
  &--big-image &__image-container-right:not(:empty) {
    width: 60%;
  }

  &__image-container-mobile {
    margin-top: 18px;

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--big-image &__image-container-mobile {
    order: 4;
  }

  &__button-container {
    margin-top: 16px;
  }
}

.large-view {
  .optional-button-banner-with-animation {
    &__button-container {
      align-self: flex-start;
    }

    &__title-container {
      align-self: flex-start;
    }

    &__description-container {
      text-align: start;
    }

    &__flex-outer {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .optional-button-banner-with-animation__image-container-right.hide-small {
    position: relative;
    right: -500px;
    transition: 2s linear;
  }

  .optional-button-banner-with-animation--animated {
    .optional-button-banner-with-animation__image-container-right.hide-small {
      right: 0;
    }
  }

  .option-button-banner-with-animation__container {
    .g-container:not(.g-container--fullwidth) {
      overflow: visible;
    }
  }
}

.medium-view,
.small-view {
  .optional-button-banner-with-animation {
    &__title-container {
      text-align: center;
    }

    &__button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -23px;
    }

    &__flex-outer {
      display: flex;
      gap: 5%;
    }
  }
}