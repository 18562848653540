@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;

.accordion-card-deck-with-image-v2 {
    .copy-lp {
        margin-top: 18px;
        margin-bottom: 34px;
    }

    .reference-footnote {
        transform: translateY(-12px);
        padding: 32px;
    }

    &__custom-title-font {
        font-size: 18px;
        font-weight: 600;
        line-height: 120%;
        cursor: default;
    }

    &__custom-description-font {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        cursor: default;
    }

    &__tablet-card-icon-image {
        width: 22px;
    }

    &__grid-cards {
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        gap: 20px;
    }


    &__tablet-card {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }


    &__tablet-card-image {
        width: 100%;
    }

    &__tablet-card-image-container {
        display: flex;
        object-fit: cover;
        background-color: #F2F6F6;
        border-radius: 16px;

        img {
            border-radius: 8px;
        }
    }


    &__platform-image-icon {
        width: 22px;
        margin-right: 8px;
    }

    &__platform-image-icon:dir(rtl) {
        margin: 0 0 0 8px;
    }

    &__slide-container {
        background-color: c.$pure-white;
        border-radius: 16px;
        height: 100%;
    }

    &__platform-image-title-description {
        margin-bottom: 7px;
    }

    .accordion-card-with-image-container-large {
        display: flex;
        gap: 35px;
    }

    .right-menu-container-large {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .card-large {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        padding: 15px;
        min-width: 245px;
    }

    .image-large-deck {
        height: 400px;
        width: 725px;
        object-fit: cover;
        border-radius: 8px;
    }



    .left-image-container-large {
        display: flex;
        width: 725px;
        background-color: c.$neutral-accent-background;
        border-radius: 16px;
    }

    .card-title-container {
        width: 100%;
    }


    .card-expand-description {
        margin-top: 4px;
        margin-left: 32px;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity 0.3s ease-in-out;
    }

    .hovered.card-large .card-expand-description {
        opacity: 1;
        max-height: 500px;
    }

    .hovered.card-large {
        background-color: c.$neutral-accent-background;
    }

    .left-image-container-medium {
        display: flex;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .title-medium {
        margin-bottom: 12px;
    }

    .card-medium {
        display: flex;
        flex-direction: column;
        background-color:  c.$pure-white;
        border-radius: 16px;
    }

    .card-title-container-medium {
        margin: 27px;
    }

    .slider-window-medium {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}

.medium-view {
    .accordion-card-deck-with-image-v2 {
        &__grid-cards {
            grid-template-columns: 1fr 1fr;
        }

        &__title-container {
            text-align: center;
        }
    }
}

.small-view {
    .accordion-card-deck-with-image-v2 {
        &__grid-cards {
            grid-template-columns: 1fr;
            gap: 35px;
        }

        &__title-container {
            text-align: center;
        }
    }
}