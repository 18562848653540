@use "../../../global-styles/palette" as c;

.simple-title-subtitle-banner__root {
    h1 {
        color: c.$pure-white;
        text-align: center;
        margin-bottom: 8px;        
    }

    p {
        color: c.$dark-20;
        text-align: center;
    }
}
