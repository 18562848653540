@use "../../../../global-styles/palette" as v;
@use "../../../../global-styles/typography";
@use "../../../../global-styles/layout";


.hero-banner {
    position: relative;
    min-height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;

    &__inner-container {
        max-width: 450px;
    }

    &__title {
        color: v.$dark-default;
        margin-bottom: 24px;
    }

    &__text {
        @extend %card-title--size-h5;

        margin-bottom: 24px;
        color: v.$dark-default;
    }

    &__actions {
        display: flex;
        align-items: center;
    }

    &__link {
        margin-left: 16px;
        color: v.$dark-80;
    }

    &__desktop-bkgr,
    &__mobile-image {
        display: none;
    }
}

.large-view {
    .hero-banner--dark {

        .hero-banner__title,
        .hero-banner__text,
        .hero-banner__link {
            color: v.$pure-white;
        }
    }

    .hero-banner__desktop-bkgr {
        @extend %img-as-background;

        display: block;
    }
}

.small-view,
.medium-view {
    .hero-banner {
        background-size: 0;
        flex-direction: column;
    }

    .hero-banner__inner-container {
        padding: 24px;
    }

    .hero-banner__mobile-image {
        position: relative;
        display: block;
        min-height: 300px;
        height: 50vh;
        width: 100%;
        flex: 1;
    }

    .hero-banner__mobile-bkgr {
        @extend %img-as-background;

        object-position: top;
    }
}