@use "./variables" as v;

html,
body {
    text-decoration: none;
    scroll-behavior: smooth;
}


html,
body,
textarea,
input,
select {
    font-family: "Figtree", sans-serif;
    font-style: normal;
    letter-spacing: 0;
}

html[lang="vi-VN" i],
html[lang="vi-VN" i] body,
html[lang="vi-VN" i] textarea,
html[lang="vi-VN" i] input,
html[lang="vi-VN" i] select {
    font-family: "Noto Sans Vietnamese", "Figtree", sans-serif;
    font-style: normal;
    letter-spacing: 0;
}

[dir="rtl"] {
    body,
    textarea,
    input,
    select {
        font-family: "Almarai", "Figtree", sans-serif;
    }
}

.large-view {

    //BEGIN DESIGN STYLES

    //DISPLAY
    .text-jumbo {
        font-size: 64px;
        font-weight: 700;
        line-height: 80px;
    }

    //H1
    h1,
    .h1-regular,
    %h1 {
        font-size: 54px;
        font-weight: 600;
        line-height: 64px;
    }

    //H2
    h2,
    .h2-regular,
    %h2 {
        font-size: 48px;
        font-weight: 600;
        line-height: 56px;
    }

    //H3 - Semibold
    .h3-semi-bold,
    %h3-semi-bold {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
    }

    //H3 - Bold
    h3,
    .h3-bold,
    %h3 {
        font-size: 36px;
        font-weight: 700;
        line-height: 48px;
    }

    //H4 - Semibold
    .h4-semi-bold,
    %h4-semi-bold {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
    }

    //H4 - Bold
    h4,
    .h4-bold,
    %h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
    }

    //H5 - MEDIUM
    .h5-semi-bold,
    %h5-semi-bold {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }

    //H5-BOLD
    h5,
    .h5-bold,
    %h5 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    //COPY - REGULAR
    p,
    .p-regular,
    %p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    //COPY - BOLD
    .p-bold,
    %p-bold {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    //CAPTION - REGULAR
    .caption {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    //CAPTION - BOLD
    .caption-bold {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
    }

    //CAPTION 2 - REGULAR
    .caption2-regular {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    //CAPTION 2 - BOLD
    .caption2-bold {
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
    }

    //FOOTNOTE
    .footnote {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
    }

    //BEGIN SMALL

    //H1
    .h1-regular-small,
    %h1-small {
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
    }

    //H2
    .h2-regular-small,
    %h2-small {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
    }

    //H3 - Semibold
    .h3-semi-bold-small,
    %h3-semi-bold-small {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
    }

    //H3 - Bold
    .h3-bold-small,
    %h3-small {
        font-size: 28px;
        font-weight: 700;
        line-height: 35px;
    }

    //H4 - Semibold
    .h4-semi-bold-small,
    %h4-semi-bold {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
    }

    //H4 - Bold
    .h4-bold-small,
    %h4-small {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }

    //END SMALL

    //BEGIN LANDING PAGE

    //H1-LP
    .h1-lp,
    %h1-lp {
        font-size: 62px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
    }

    //H2-LP
    .h2-lp,
    %h2-lp {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
    }

    //H3-LP
    .h3-lp,
    %h3-lp {
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //H4-LP
    .h4-lp,
    %h4-lp {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //H5-LP
    .h5-lp,
    %h5-lp {
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //COPY-LP
    .copy-lp,
    %copy-lp {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    //END LANDING PAGE

    //BEGIN RISK WARNING RIBBON

    .caption-risk-warning {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .caption-risk-warning-bold {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //END RISK WARNING RIBBON

    //END DESIGN STYLES

    .text-link-caps {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: underline;
        text-transform: uppercase;
    }

    .text-link {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: underline;
    }

    sub {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0;
        text-decoration: none;
        display: block;
        text-align: left;
    }

    .text-nav {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: none;
    }

    .reference-footnote {
        padding: 0 v.$gutter-large;
    }

    // ---------------------

    %card-title--size-h3 {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %card-title--size-h4 {
        font-weight: 700;
        font-size: 27px;
        line-height: 32px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %card-title--size-h5 {
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %slider-tab-text {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        text-decoration: none;
    }

    // H1 (style v2.6)
    .h1-v2dot6,
    h1.h1-v2dot6,
    %h1-v2dot6 {
        font-size: 58px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -1.16px;
    }

    // H2 (style v2.6)
    .h2-v2dot6,
    h2.h2-v2dot6,
    %h2-v2dot6 {
        font-size: 48px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.96px;
    }

    // H3 (style v2.6)
    .h3-v2dot6,
    h3.h3-v2dot6,
    %h3-v2dot6 {
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.8px;
    }

    // H4 (style v2.6)
    .h4-v2dot6,
    h4-v2dot6,
    %h4-v2dot6 {
        font-size: 32px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: -0.64px;
    }

    // H5 (style v2.6)
    .h5-v2dot6,
    h5.h5-v2dot6,
    %h5-v2dot6 {
        font-size: 28px;
        font-weight: 600;
        line-height: 1.2;
    }

    // H6 (style v2.6)
    .h6-v2dot6,
    h6.h6-v2dot6,
    %h6-v2dot6 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.2;
    }

}


.medium-view {

    //BEGIN DESIGN STYLES

    //DISPLAY
    .text-jumbo {
        font-size: 52px;
        font-weight: 700;
        line-height: 64px;
    }

    //H1
    h1,
    .h1-regular,
    %h1 {
        font-size: 45px;
        font-weight: 600;
        line-height: 56px;
    }

    //H2
    h2,
    .h2-regular,
    %h2 {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
    }

    //H3 - Semibold
    .h3-semi-bold,
    %h3-semi-bold {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
    }

    //H3 - Bold
    h3,
    .h3-bold,
    %h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
    }

    //H4 - Semibold
    .h4-semi-bold,
    %h4-semi-bold {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
    }

    //H4 - Bold
    h4,
    .h4-bold,
    %h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
    }

    //H5 - MEDIUM
    .h5-semi-bold,
    %h5-semi-bold {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }

    //H5-BOLD
    h5,
    .h5-bold,
    %h5 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    //COPY - REGULAR
    p,
    .p-regular,
    %p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    //COPY - BOLD
    .p-bold,
    %p-bold {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    //CAPTION - REGULAR
    .caption {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    //CAPTION - BOLD
    .caption-bold {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
    }

    //CAPTION 2 - REGULAR
    .caption2-regular {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    //CAPTION 2 - BOLD
    .caption2-bold {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }

    //FOOTNOTE
    .footnote {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
    }

    //BEGIN SMALL

    //H1
    .h1-regular-small,
    %h1-small {
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
    }

    //H2
    .h2-regular-small,
    %h2-small {
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
    }

    //H3 - Semibold
    .h3-semi-bold-small,
    %h3-semi-bold-small {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
    }

    //H3 - Bold
    .h3-bold-small,
    %h3-small {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }

    //H4 - Semibold
    .h4-semi-bold-small,
    %h4-semi-bold-small {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    //H4 - Bold
    .h4-bold-small,
    %h4-small {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    //END SMALL

    //BEGIN LANDING PAGE

    //H1-LP
    .h1-lp,
    %h1-lp {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
    }

    //H2-LP
    .h2-lp,
    %h2-lp {
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
    }

    //H3-LP
    .h3-lp,
    %h3-lp {
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //H4-LP
    .h4-lp,
    %h4-lp {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //H5-LP
    .h5-lp,
    %h5-lp {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //COPY-LP
    .copy-lp,
    %copy-lp {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    //END LANDING PAGE

    //BEGIN RISK WARNING RIBBON

    .caption-risk-warning {
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .caption-risk-warning-bold {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //END RISK WARNING RIBBON

    //END DESIGN STYLES

    .text-link-caps {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: underline;
        text-transform: uppercase;
    }

    .text-link {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: underline;
    }

    sub {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0;
        text-decoration: none;
        display: block;
        text-align: left;
    }

    .text-nav {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: none;
    }

    // ------------------------
    %card-title--size-h3 {
        font-weight: 800;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %card-title--size-h4 {
        font-weight: 800;
        font-size: 23px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %card-title--size-h5 {
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %slider-tab-text {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        text-decoration: none;
    }


    // H1 (style v2.6)
    .h1-v2dot6,
    h1.h1-v2dot6,
    %h1 {
        font-size: 48px;
        letter-spacing: -0.96px;
    }

    // H2 (style v2.6)
    .h2-v2dot6,
    h2.h2-v2dot6,
    %h2-v2dot6 {
        font-size: 40px;
        letter-spacing: -0.8px;
    }

    // H3 (style v2.6)
    .h3-v2dot6,
    h3.h3-v2dot6,
    %h3-v2dot6 {
        font-size: 34px;
        letter-spacing: -0.68px;
    }

    // H4 (style v2.6)
    .h4-v2dot6,
    h4.h4-v2dot6,
    %h4-v2dot6 {
        font-size: 28px;
        letter-spacing: -0.56px;
    }

    // H5 (style v2.6)
    .h5-v2dot6,
    h5.h5-v2dot6,
    %h5-v2dot6 {
        font-size: 24px;
    }

    // H6 (style v2.6)
    .h6-v2dot6,
    h6.h6-v2dot6,
    %h6-v2dot6 {
        font-size: 20px;
    }

}




.small-view {

    //BEGIN DESIGN STYLES

    //DISPLAY
    .text-jumbo {
        font-size: 42px;
        font-weight: 700;
        line-height: 56px;
    }

    //H1
    h1,
    .h1-regular,
    %h1 {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
    }

    //H2
    h2,
    .h2-regular,
    %h2 {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
    }

    //H3 - Semibold
    .h3-semi-bold,
    %h3-semi-bold {
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
    }

    //H3 - Bold
    h3,
    .h3-bold,
    %h3 {
        font-size: 26px;
        font-weight: 700;
        line-height: 32px;
    }

    //H4 - Semibold
    .h4-semi-bold,
    %h4-semi-bold {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
    }

    //H4 - Bold
    h4,
    .h4-bold,
    %h4 {
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
    }

    //H5 - MEDIUM
    .h5-semi-bold,
    %h5-semi-bold {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
    }

    //H5-BOLD
    h5,
    .h5-bold,
    %h5 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    //COPY - REGULAR
    p,
    .p-regular,
    %p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    //COPY - BOLD
    .p-bold,
    %p-bold {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    //CAPTION - REGULAR
    .caption {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    //CAPTION - BOLD
    .caption-bold {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
    }

    //CAPTION 2 - REGULAR
    .caption2-regular {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    //CAPTION 2 - BOLD
    .caption2-bold {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }

    //FOOTNOTE
    .footnote {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
    }

    //BEGIN SMALL

    //H1
    .h1-regular-small,
    %h1-small {
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
    }

    //H2
    .h2-regular-small,
    %h2-small {
        font-size: 22px;
        font-weight: 600;
        line-height: 28px
    }

    //H3 - Semibold
    .h3-semi-bold-small,
    %h3-semi-bold-small {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }

    //H3 - Bold
    .h3-bold-small,
    %h3-small {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px
    }

    //H4 - Semibold
    .h4-semi-bold-small,
    %h4-semi-bold-small {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    //H4 - Bold
    .h4-bold-small,
    %h4-small {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    //END SMALL

    //BEGIN LANDING PAGE

    //H1-LP
    .h1-lp,
    %h1-lp {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
    }

    //H2-LP
    .h2-lp,
    %h2-lp {
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
    }

    //H3-LP
    .h3-lp,
    %h3-lp {
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //H4-LP
    .h4-lp,
    %h4-lp {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //H5-LP
    .h5-lp,
    %h5-lp {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //COPY-LP
    .copy-lp,
    %copy-lp {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    //END LANDING PAGE

    //BEGIN RISK WARNING RIBBON

    .caption-risk-warning {
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .caption-risk-warning-bold {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    //END RISK WARNING RIBBON    

    //END DESIGN STYLES

    .text-link-caps {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: underline;
        text-transform: uppercase;
    }

    .text-link {
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: underline;
    }

    sub {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0;
        text-decoration: none;
        display: block;
        text-align: left;
    }

    .text-nav {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: none;
    }

    // ------------------------
    %card-title--size-h3 {
        font-weight: 800;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %card-title--size-h4 {
        font-weight: 800;
        font-size: 23px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %card-title--size-h5 {
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 0;
        text-decoration: none;
    }

    %slider-tab-text {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        text-decoration: none;
    }

    // H1 (v2.6)
    .h1-v2dot6,
    h1.h1-v2dot6,
    %h1-v2dot6 {
        font-size: 38px;
        letter-spacing: -0.76px;
    }

    // H2 (v2.6)
    .h2-v2dot6,
    h2.h2-v2dot6,
    %h2-v2dot6 {
        font-size: 32px;
        letter-spacing: -0.64px;
    }

    // H3 (v2.6)
    .h3-v2dot6,
    h3.h3-v2dot6,
    %h3-v2dot6 {
        font-size: 26px;
        letter-spacing: -0.52px;
    }

    // H4 (v2.6)
    .h4-v2dot6,
    h4.h4-v2dot6,
    %h4-v2dot6 {
        font-size: 22px;
        letter-spacing: -0.44px;
    }


    // H5 (v2.6)
    .h5-v2dot6,
    h5.h5-v2dot6,
    %h5-v2dot6 {
        font-size: 18px;
    }

    // H6 (v2.6)
    .h6-v2dot6,
    h6.h6-v2dot6,
    %h6-v2dot6 {
        font-size: 16px;
    }

}

.text-weight-extrabold {
    font-weight: 800;
}

.text-weight-bold {
    font-weight: 700;
}

.text-weight-semibold {
    font-weight: 600;
}

.text-weight-medium {
    font-weight: 500;
}

.reference-footnote {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    padding: 0 v.$gutter-small;
}

%typography--desktop-p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
}

%typography--desktop-caption {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
}