@use "../../global-styles/palette" as c;

.get-the-best-of-both {
  background-color: c.$pure-white;

  .g-container {
    h2 {
      margin-bottom: 40px;
      line-height: 1.1;
    }

    .get-the-best-of-both__root {
      border-radius: 20px;
      padding: 40px 0px 0 20px;
      background: linear-gradient(180deg, #0D1620 18.5%, rgba(13, 22, 32, 0.00) 74%, rgba(13, 22, 32, 0.30) 100%), linear-gradient(270deg, #0D1620 0%, #0E4281 38.95%, #0A651E 68.79%, #0D1620 100%);
      overflow: hidden;

      .common-images-container-style {
        width: 238px;
      }

      .common-images-style {
        width: 100%;
        height: 100%;
      }

      .get-the-best-of-both__titleWrapper {
        .first-image-container {
          margin-bottom: 5px;
          @extend .common-images-container-style;

          img {
            @extend .common-images-style
          }
        }

        .second-image-container {
          margin-bottom: 10px;
          @extend .common-images-container-style;

          img {
            @extend .common-images-style
          }
        }

        .get-the-best-of-both__subtile {
          color: c.$pure-white;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.5;
          margin-bottom: 24px;
          width: 92%;
        }

        .get-the-best-of-both__button {
          margin-right: auto;
          margin-bottom: 43px;
          padding: 14px 40px;
        }
      }

      .get-the-best-of-both__bottom-images {
        padding-left: 14px;

        img {
          @extend .common-images-style;
        }
      }
    }
  }
}

.get-the-best-of-both:dir(rtl) {
  .g-container {

    .get-the-best-of-both__root {
      padding: 40px 20px 0px 0px;

      .get-the-best-of-both__titleWrapper {
        .get-the-best-of-both__button {
          margin-right: unset;
          margin-left: auto;
        }
      }

      .get-the-best-of-both__bottom-images {
        padding: 0;
        transform: rotateY(180deg);
      }
    }
  }
}

.medium-view {
  .get-the-best-of-both {
    .g-container {
      .get-the-best-of-both__root {
        display: flex;
        justify-content: space-between;
        padding: 13px 0 10px 6%;

        .common-images-container-style {
          width: 54%;
        }

        .get-the-best-of-both__titleWrapper {
          padding-top: 10px;

          .get-the-best-of-both__subtile {
            width: 94%;
            color: c.$neutral-accent-light-grey-font;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.4;
          }
        }

        .get-the-best-of-both__bottom-images {
          padding: 0;
          width: 100%;
          max-width: 345px;
        }
      }
    }
  }

  .get-the-best-of-both:dir(rtl) {
    .g-container {
      .get-the-best-of-both__root {
        padding: 13px 6% 0 0;
      }
    }
  }
}

.large-view {
  .get-the-best-of-both {
    .g-container {
      .get-the-best-of-both__root {
        display: flex;
        justify-content: space-between;
        padding: 13px 0 0 69.4px;

        .common-images-container-style {
          width: 316.253px;
        }

        .get-the-best-of-both__titleWrapper {
          padding-top: 10px;

          .first-image-container {
            margin: 20px 0;
          }

          .second-image-container {
            margin-bottom: 13px;
          }


          .get-the-best-of-both__subtile {
            color: c.$neutral-accent-light-grey-font;
            font-size: 28px;
            font-weight: 400;
            line-height: 1.4;
            max-width: 530px;
            margin-bottom: 26px;
          }
        }

        .get-the-best-of-both__bottom-images {
          width: 480px;
          padding: 0;
        }
      }
    }
  }

  .get-the-best-of-both:dir(rtl) {
    .g-container {
      .get-the-best-of-both__root {
        padding: 13px 69.4px 0 0;
      }
    }
  }
}