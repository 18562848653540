@use "../../global-styles/palette" as c;

.articleInsert {
  box-sizing: border-box;

  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .reverse-row-class {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .row-class {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }

  .text-position-center {
    align-items: center;
  }

  .margin-right-72 {
    margin-right: 72px;
  }

  .margin-right-0 {
    margin-right: 0px;
  }

  &__content{
    color: c.$dark-80;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-decoration: none;
    ul{
      list-style-type: disc;
      padding-left: 10px;
      margin-left: 10px;
    }
    li{
      margin-top: 16px;
    }
    a {
      color: c.$dark-80;
      font-weight: bold;
    }
  }
  &__text {
    max-width: 552px;
    
    h2 {
      margin-bottom: 20px;
      color: c.$dark-default;
    }
  }
    &__cta {
      display: inline-block;
      margin-top: 30px;
      padding: 10.5px 25px;
      border-radius: 28px;
      background-color: c.$tm-green-default;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: c.$pure-white;
      cursor: pointer;
      outline: none;
      border: none;
      text-decoration: none;
      min-width: 168px;
      text-align: center;
      white-space: nowrap;
      box-sizing: border-box;
    }
  

  &__img1 {
    display: none;
  }

  &__img2 {
    border-radius: 16px;
  }
}

.medium-view {
  .articleInsert {
    margin: 0px 48px;

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    &__cta {
      margin-top: 6px;
    }

    &__content{
      max-width: 600px;
    }
  }

  .margin-right-72 {
    margin-right: 0px;
  }
}

.small-view, .medium-view {
  .articleInsert {
    &__text {
      width: 100%;
      display: flex;
      margin: 0;
      flex-direction: column;
      align-items: center;
      max-width: unset;

      h2 {
        text-align: center;
      }

      p {
        max-width: 456px;
        margin-top: 25px;
      }
    }

    &__content{
      margin-top: 16px;
    }

    &__img1 {
      display: block;
      max-width: 100%;
      border-radius: 16px;
    }

    &__img2 {
      display: none;
    }
  }
}

html[dir="rtl"] {
  .articleInsert {
    .margin-right-72 {
      margin-right: unset;
    }
  }
}