@use "../../../global-styles/palette" as c;

.home-page-main-banner-background {
    background: c.$dark-default;

    .home-page-main-banner {
        background: c.$dark-default;
        position: relative;

        &__text-button-wrapper {
            position: absolute;
            top: 15%;
            width: 100%;
            left: 0px;
        }

        &__text-button-box {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                color: c.$pure-white;
                margin-bottom: 8px;
            }

            p {
                color: c.$dark-20;
                margin-bottom: 40px;
            }

            .squaredButton {
                background-color: c.$tm-green-default;
                border: none;
                border-radius: 8px;
                text-decoration: none;
                color: c.$dark-default;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                padding: 8px 32px;
                max-width: 200px;
                width: 136px;
                margin-bottom: 24px;
        
                &:hover {
                background-color: c.$tm-green-40;
                }
            }

            a {
                color: c.$pure-white;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                text-decoration-line: underline;
                width: fit-content;
            }
        }

        &__text-below-cta {
            max-width: 380px;
            margin-inline: auto;
            color: c.$dark-60;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;

            &:empty {
                display: none;
            }

            & a {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
            }
        }

        a.home-page-main-banner__text-link + .home-page-main-banner__text-below-cta:not(:empty) {
            margin-top: 24px;
        }
    }
}

.large-view {
    .home-page-main-banner-background {
        .home-page-main-banner {
            max-width: 1920px;
            max-height: 650px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
            overflow: hidden;

            &__text-button-box {
                max-width: 760px;
                margin: 0 auto;
                background: radial-gradient(50% 50% at 50% 50%, #0E1D31 0%, rgba(14, 29, 49, 0.5) 61.5%, rgba(14, 29, 49, 0) 100%);
                h1 {
                    font-size: 60px;
                    font-weight: 700;
                    line-height: 80px;
                    text-align: center;
                }

                h1:dir(rtl){
                    line-height: 95px;
                }

                p {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }
    }
}

.medium-view {
    .home-page-main-banner-background {
        .home-page-main-banner {
            position: unset;

            &__text-button-wrapper {
                position: unset;
                width: unset;
                padding: 40px 0px;
            }

            &__text-button-box {
                h1 {
                    font-size: 52px;
                    font-weight: 700;
                    line-height: 64px;
                    text-align: center;
                }

                p {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }
    }
}

.small-view {
    .home-page-main-banner-background {
        .home-page-main-banner {
            position: unset;

            &__text-button-wrapper {
                position: unset;
                width: unset;
                padding: 16px 0px;

                h1, p {
                    max-width: 327px;
                    text-align: center;
                }

                h1 {
                    font-size: 42px;
                    font-weight: 700;
                    line-height: 56px;
                }

                p {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }
    }
}