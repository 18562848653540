@use "../../global-styles/palette" as c;

.tabs-with-accordion{
    display: flex;
    gap: 32px;
    align-items: flex-start;
    
    .faq-accordion-list__faq-card-answer,.faq-accordion-list__faq-card.active .faq-accordion-list__faq-card-answer{
        max-width: 100%;
        width: 100%;
        padding-left: 50px;
    }
    .faq-accordion-list__column{
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
    .faq-accordion-list__title{
        display: flex;
        gap: 12px;
        align-items: center;
    }
    .faq-accordion-list__faq-card{
        padding: 20px 0;
    }
    .faq-accordion-list__faq-card-answer{
        width: 100%;
        box-sizing: border-box;
    }
    .faq-accordion-list__content, .faq-accordion-list__faq-card-answer{
        img{
            width: 100%!important;
            height: auto!important;
        }
    }
    &__header{
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 744px;
        margin-bottom: 42px;
    }
    &__sidebar{
        padding-right: 32px;
        border-right: 1px solid #DBDBDB;
        display: flex;
        flex-direction: column;
        gap: 12px;
        box-sizing: border-box;
    }
    &__sidebar-item{
        padding: 8px 24px;
        cursor: pointer;
        &:hover,&.sidebar-item-active{
            color: #7C3AED;
            background: #EDE9FE;
        }
    }
    .faq-accordion-list__faq-card {
        width: 100%;
    }
    .faq-accordion-list__plus-minus-text{
        width: 100%;
    }
    .faq-accordion-list__icon-close{
        margin-left: auto;
    }
}

.small-view{
    .tabs-with-accordion{
        flex-direction: column;
        &__header-subtitle{
            font-weight: 500;
        }
    }
}
.medium-view{
    .tabs-with-accordion{
        &__header-subtitle{
            font-weight: 500;
        }
    }
}
.large-view{
    .tabs-with-accordion{
        .faq-accordion-list__faq-card-answer,.faq-accordion-list__faq-card.active .faq-accordion-list__faq-card-answer{
            max-width: 100%;
            width: 100%;
            padding-left: 50px;
        }
        &__sidebar-item{
            width: 232px;
            box-sizing: border-box;
        }
        &__header-subtitle{
            font-weight: 500;
        }
    }
}