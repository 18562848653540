@use "../../../../global-styles/palette" as c;

.card-with-icon-text-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    border-radius: 20px;
    background: c.$neutral-accent-background;
    width: 363px;

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .h5-lp {
        font-size: 24px;
    }

    &__subtitle {
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
    }

    &__icon-wrapper {
        height: 48px;
    }
}

.medium-view {
    .card-with-icon-text-card {
        width: 242px;

        .h5-lp {
            font-size: 20px;
        }

        &__subtitle {
            font-size: 16px;
        }
    }
}

.small-view {
    .card-with-icon-text-card {
        width: unset;
        padding: 30px;

        .h5-lp {
            font-size: 20px;
        }

        &__subtitle {
            font-size: 16px;
        }
    }
}