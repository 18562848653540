@use "../../../global-styles/palette" as c;
@use "./TopTimeline";

.bottom-time-line {
  @extend .top-time-line;
  display: flex;
  flex-direction: column-reverse;

  &::after {
    top: -10px;
  }

  span {
    margin-right: auto;
  }
}
