@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;

$color: blue;

.bye {
    width: 70%;
    height: 323px;
    color: $color;
    background-color: c.$dark-10;
    background-image: url(v.$imgPath + "/reactjs.png");
    background-position-y: 40px;
    background-repeat: repeat-x;
}

.bye__name {
    color: c.$dark-80;
}

.large-view {
    .bye {
        font-size: 32px;
    }
}

.medium-view {
    .bye {
        font-size: 28px;
    }
}

.small-view {
    .bye {
        font-size: 22px;
    }
}