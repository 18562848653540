@use "../../global-styles/palette" as c;

.instrument-slider--new {
  padding: 20px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border: 1px solid c.$dark-10;
  border-bottom: 0;

  &__title {
    text-align: center;
    white-space: pre-line;
  }

  &__items {
    display: flex;
    overflow: hidden;
  }

  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    padding: 8px 16px;
    width: 100%;
    padding: 12px 20px 12px 16px;
    border-radius: 12px;
    border-right: none;
    color: c.$dark-60;

    &:last-child {
      border-right: 0;
    }
  }

  &__item:hover {
    color: c.$pure-white;
    background-color: c.$dark-default;
    font-weight: bold;
  }

  &__highlighted {
    background-color: c.$dark-10;
    color: c.$dark-default;
  }

  &__item-title {
    font-size: 14px;
    font-weight: 700;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
}

.instrument-slider-active {
  opacity: 1;
}

.small-view,
.medium-view {
  .instrument-slider {
    &__items {
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }

  }
}