@use "../../global-styles/palette" as c;

.title-with-subtitle-and-cards {
    &__title-container {
        flex-direction: column;
    }

    &__buttons-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .button-link-wrapper {
        width: 100%;
        padding: 13px 17px 12px 20px
    }

    .button-link-wrapper__button-content-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.large-view {
    .title-with-subtitle-and-cards {
        &__buttons-container {
            grid-template-columns: 1fr 1fr 1fr;
        }

    }
}

.medium-view {
    .title-with-subtitle-and-cards {
        &__buttons-container {
            grid-template-columns: 1fr 1fr;
        }

    }
}