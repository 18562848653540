@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";
@use "../../global-styles/typography";

.faq-accordion-list-with-image {

    .faq-accordion-list__faq-card-answer li {
        padding: revert;
    }

    .faq-accordion-list__faq-card-answer ul {
        list-style: disc;
        padding: revert;
    }

    .faq-accordion-list__icon-image {
        width: 47px;
        height: 47px;
    }

    .faq-accordion-list__image-container {
        margin-right: 5px;
        margin-top: -8px;
        margin-left: -4px;
        width: 38px;
        height: 38px;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px;
    }

    .faq-accordion-list {
        &__flex-container {
            flex-direction: column;
            gap: 10px;
        }
    }

    .faq-accordion-list__faq-title {
        @extend %h3-small;
    }
}


.large-view {
    .faq-accordion-list-with-image {
        .faq-accordion-list__title-container {
            margin-top: auto;
        }

        .faq-accordion-list__icon-image {
            width: 50px;
            height: 50px;
        }

        &__grid {
            grid-template-columns: 1fr 1fr;
            gap: 50px;
        }

        .g-container--fullwidth,
        .g-container {
            width: auto !important;
            min-width: auto !important;
            max-width: auto !important;
            background-color: auto !important;
        }
    }
}