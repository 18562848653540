@use "../../global-styles/palette" as c;

.cards-deck-with-title-simple {
  &__cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 25px;
  }

  &__card {
    display: flex;
    gap: 15px;
    text-align: -webkit-auto;
    flex: 1;
  }

  &__section-image {
    width: 48px;
  }

  &__title-container {
    margin-inline: 35px;
  }

  &__buttons-wrapper {
    margin-top: 30px;

    .tmx25-round-green-lightgreen {
      width: unset;
    }
  }
}

.medium-view {
  .cards-deck-with-title-simple {
    &__cards-wrapper {
      margin: auto;
      margin-top: 36px;
    }

    &__title-container {
      margin-inline: 0px;
    }

    .centered-container {
      justify-content: flex-start;
    }
  }
}

.large-view {
  .cards-deck-with-title-simple {
    &__cards-wrapper {
      flex-direction: row;
      margin: auto;
      margin-top: 36px;
      display: flex;
      justify-content: center;
    }
  }
}

.small-view {
  .cards-deck-with-title-simple {
    &__title-container {
      margin-inline: 25px;
      max-width: 290px;
    }

    .centered-container {
      justify-content: flex-start;
      text-align: left;
    }
  }
}