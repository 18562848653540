@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;

.button-link-wrapper {
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    text-decoration: none;
    background-color: c.$neutral-accent-background;

    &__button-link {
        text-decoration: none;
        color: inherit;
    }

    &__button-content-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.button-link-wrapper:hover {
    background-color: c.$neutral-accent-default;
}