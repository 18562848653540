@use "../../../global-styles/palette" as c;

.markets-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-top: 32px;

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 744px;
        text-align: center;

        h1 {
            color: c.$dark-default;
        }

        h5 {
            color: c.$dark-80;
        }
    }

    &__video-wrapper {
        max-width: 234px;
        max-height: 132px;
    }
}

.medium-view {
    .markets-banner {
        margin-top: 40px;

        &__text-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-width: 552px;
            text-align: center;
        }

        &__video-wrapper {
            max-width: 600px;
            max-height: 341px;
        }
    }
}

.large-view {
    .markets-banner {
        margin-top: 38px;

        &__video-wrapper {
            max-width: 724px;
            max-height: 410px;
        }
    }
}

.large-view, .medium-view, .small-view {
    .markets-banner {
        h5 {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
        }
    }
}

