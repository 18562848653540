@use "../../../global-styles/palette" as c;

.additionalInformation {
  width: 100%;
  margin-top: 40px;
  color: c.$dark-80;

  h3 {
    color: c.$dark-default;
    margin-bottom: 24px;
  }

  & &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;


    h4 {
      margin-bottom: 6px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.3;
      text-transform: uppercase;

      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    a {
      color: c.$dark-80;
      font-weight: 700;
    }
  }

  &__subContent {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    ul {
      list-style: none;
      padding-left: 12px;

      li {
        padding-left: 16px;
        margin-top: 8px;
      }

      li::before {
        content: "•";
        display: inline-block;
        width: 16px;
        margin-left: -16px;
        text-align: left;
      }
    }

    strong {
      font-weight: 700;
    }

    &Mini {
      width: 100%;
    }
  }
}

.small-view,
.medium-view {
  .additionalInformation {
    a {
      font-size: 16px;
      line-height: 24px;
    }

    &__content {
      flex-direction: column;
    }
  }
}