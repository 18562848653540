@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.tradeWithComponentBlackBackground {
  .g-container {
    display: grid;

    .tradeWithComponent-mainTitle {
      text-align: center;
      font-style: normal;
      color: c.$dark-default;
    }

    .tradeWithComponent-subTitle {
      text-align: center;
      width: 320px;
      margin: 8px auto 0;
      color: c.$dark-default;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    .tradeWithComponent-sectionWrapper {
      &__section {
        display: grid;
        justify-items: center;
        margin-top: 24px;

        h4 {
          color: c.$dark-default;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }

        &__image {
          width: 64px;
          height: 64px;
        }

        h4 {
          text-align: center;
          width: fit-content;
          margin: 16px 0 8px;
          text-align: center;
          font-weight: 600;
          white-space: nowrap;
        }

        &__subTitle {
          text-align: center;
          width: fit-content;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: c.$dark-80;
        }
      }
    }

    .tradeWithComponent-buttonWrapper {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      margin-top: 40px;
      width: fit-content;
      height: fit-content;
      margin-inline: auto;
      gap:24px;

      .squaredButton {
        &--primary {
          background-color: c.$tm-green-default;
          color: c.$dark-default;
          border: none;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .tradeWithComponent-buttonWrapper-linkWrapper {
        display: grid;
        grid-auto-flow: column;
        align-items: center;

        a {
          color: c.$dark-default;
          width: fit-content;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }

        svg {
          display: none;
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }

        &:hover {
          svg {
            display: inline;
            transform: rotate(-90deg);
            color: c.$dark-default;
          }
        }
      }
    }
  }
}

.tradeWithComponentBlackBackground-dark {
  @extend .tradeWithComponentBlackBackground;

  .g-container {
    .tradeWithComponent-mainTitle {
      color: c.$pure-white;
    }

    .tradeWithComponent-subTitle {
      color: c.$pure-white;
    }

    .tradeWithComponent-sectionWrapper {
      &__section {
        h4 {
          color: c.$pure-white;
        }

        &__subTitle {
          color: c.$dark-20;
        }
      }
    }

    .tradeWithComponent-buttonWrapper {
      .tradeWithComponent-buttonWrapper-linkWrapper {
        a {
          color: c.$pure-white;
        }

        &:hover {
          svg {
            color: c.$pure-white;
          }
        }
      }
    }
  }
}

.small-view {
  .breadcrumbs__container {
    & + .g-container {
      margin-top: v.$breadcrumbs-bottom-spacing--small;
    }
  }
}

.medium-view {
  .tradeWithComponentBlackBackground {
    .breadcrumbs__container {
      & + .g-container {
        margin-top: v.$breadcrumbs-bottom-spacing--medium;
      }
    }

    .g-container {
      .tradeWithComponent-mainTitle {
        text-align: center;
        font-style: normal;
      }

      .tradeWithComponent-subTitle {
        width: 100%;
      }

      .tradeWithComponent-sectionWrapper {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr);
        column-gap: 24px;
        margin-top: 24px;
        margin-inline: 24px;

        &__section {
          margin: 0;
          align-content: start;

          &__title {
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            margin: 16px 0 8px;
          }

          &__subTitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            width: 180px;
          }
        }
      }

      .tradeWithComponent-sectionWrapper--larger {
        @extend .tradeWithComponent-sectionWrapper;
        grid-template-columns: repeat(auto-fit, 270px);
        max-width: 800px;
        grid-auto-flow: row;
        justify-content: space-between;
      }
    }
  }
}

.large-view {
  .tradeWithComponentBlackBackground {
    .breadcrumbs__container {
      .g-container {
        justify-content: flex-start;
      }

      & + .g-container {
        margin-top: v.$breadcrumbs-bottom-spacing--large;
      }
    }

    .g-container {
      .tradeWithComponent-mainTitle {
        width: 650px;
        margin-inline: auto;
        font-size: 48px;
        line-height: 56px;
        font-weight: 600;
      }

      .tradeWithComponent-subTitle {
        width: 800px;
      }

      .tradeWithComponent-sectionWrapper {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr);
        align-items: start;
        column-gap: 24px;
        margin-inline: 32px;

        &__section {
          margin-top: 32px;
          width: fit-content;
          padding-inline: 16px;

          &__image {
            width: 80px;
            height: 80px;
          }

          &__subTitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
      .tradeWithComponent-sectionWrapper--larger {
        @extend .tradeWithComponent-sectionWrapper;
      }
    }
  }
}
