@use "../../global-styles/palette" as c;

.otherAccountWrapper-root {
  .g-container {
    .otherAccountWrapper {
      background-color: c.$pure-white;
      display: grid;

      &__mainTitle {
        color: c.$dark-default;
        text-align: center;
      }

      &__mainSubTitle {
        color: c.$dark-80;
        text-align: center;
      }

      &__sectionWrapper {
        margin-top: 24px;

        &__section {
          background-color: c.$neutral-accent-background;
          border-radius: 16px;
          margin: 24px 0;
          padding: 24px;

          &:first-child {
            margin: 0;
          }

          &__image {
            width: 48px;
            height: 48px;
          }

          &__title {
            font-style: normal;
            color: c.$dark-default;
            margin: 8px 0;
          }

          &__subtitle {
            color: c.$dark-80;
            font-style: normal;
            margin-bottom: 32px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }

          .squaredButton {
            background-color: c.$pure-white;
            color: c.$dark-90;
            font-size: 18px;
            padding: 5px 30px;
            border-radius: 8px;

            &:hover {
              background-color: c.$tm-green-default;
              color: c.$dark-90;
            }
          }
        }
      }

      &__buttonWrapper {
        .button-v2-wrapper {
          margin-top: 20px;
        }
      }
    }

    .otherAccountWrapperWithBlackCard {
      @extend .otherAccountWrapper;

      .otherAccountWrapper__mainTitle {
        text-align: start;
        font-size: 34px;
        font-weight: 700;
        line-height: 110%;
      }

      .otherAccountWrapper__mainSubTitle {
        text-align: start;
      }

      .otherAccountWrapper__sectionWrapper {
        .blackCard__section {
          background-color: c.$dark-90;
          position: relative;
          overflow: hidden;

          .squaredButton {
            position: relative;
            z-index: 1;
          }

          .blackCard__title {
            color: c.$pure-white;
            width: 60vw;
            font-size: 26px;
            font-weight: 600;
            line-height: 120%;
          }
          .blackCard__subtitle {
            color: c.$middle-label-grey;
            width: 65vw;
            height: 50px;
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
          }

          .otherAccountWrapper-root__bg-image {
            position: absolute;
            background: var(--background-image-url);
            background-repeat: no-repeat;
            inset: 0;
            background-position: right 135px;
          }
        }
      }
    }

    .trading-view-card {
      display: grid;
      row-gap: 4px;
      background-color: transparent;
      border: 1px solid c.$dark-20;
      padding: 67px 33px;

      &__img {
        width: 167.31px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__title {
        color: c.$dark-default;
        font-size: 24px;
        font-weight: 600;
        line-height: 28.8px;
        text-align: start;
        height: min-content;
      }

      &__icon {
        width: 117.4px;
        height: min-content;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__subtitle {
        height: min-content;

        span {
          font-size: 15.1px;
          font-weight: 300;
          line-height: 18.13px;
          text-align: left;
          color: c.$dark-60;
        }
      }
    }
  }
}

.medium-view {
  .otherAccountWrapper-root {
    .otherAccountWrapper {
      &__sectionWrapper {
        &__section {
          margin: 20px 0;
          padding: 30px;
        }
      }
    }
  }
}

.medium-view {
  .otherAccountWrapper-root {
    .g-container {
      .otherAccountWrapper {
        &__sectionWrapper {
          display: grid;
          grid-template-columns: repeat(2, auto);
          gap: 20px;

          &__section {
            margin: 0;
            min-width: 100px;
            padding: 40px;
          }
        }
      }

      .otherAccountWrapperWithBlackCard {
        padding-inline: 20px;

        .otherAccountWrapper__sectionWrapper {
          display: grid;
          grid-template-columns: repeat(2, auto);
          gap: 20px;

          .blackCard__section {
            .blackCard__title {
              width: 225px;
            }
            .blackCard__subtitle {
              width: 250px;
            }

            .otherAccountWrapper-root__bg-image {
              background-position: unset;
              top: 115px;
              left: -33px;
            }
          }
        }
      }

      .trading-view-card {
        align-content: center;
      }
    }
  }
}

.large-view {
  .otherAccountWrapper-root {
    .g-container {
      .otherAccountWrapper {
        &__sectionWrapper {
          display: grid;
          grid-template-columns: repeat(3, auto);
          gap: 20px;
          justify-content: center;

          &__section {
            margin: 0;
            padding: 24px 32px;
            width: 298px;

            &__title {
              height: fit-content;
            }

            &__subtitle {
              height: 70px;
            }

            .squaredButton {
              margin: 0;
              line-height: 1;
              width: fit-content;
            }
          }
        }
      }

      .otherAccountWrapperWithBlackCard {
        .otherAccountWrapper__sectionWrapper {
          .blackCard__section {
            .blackCard__title {
              width: fit-content;
              font-size: 18px;
              font-weight: 600;
              line-height: 120%;
            }
            .blackCard__subtitle {
              width: fit-content;
              font-size: 16px;
              font-weight: 500;
              line-height: 150%;
            }

            .otherAccountWrapper-root__bg-image {
              background-position: unset;
              top: 115px;
              left: 0px;
            }
          }
        }
      }

      .trading-view-card {
        align-content: center;
      }
    }
  }
}
