@use "../../global-styles/palette" as c;

.cards-deck-with-title-and-sub-root {
  .g-container {
    .cards-deck-with-title-and-sub-root__cardsWrapper {
      display: grid;
      row-gap: 24px;
      margin-top: 24px;

      .cards-with-title-and-sub-root {
        border-radius: 16px;
        padding: 32px;
        background-color: c.$neutral-accent-background;

        &__card-contentWrapper {
          p {
            color: c.$dark-80;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin: 16px 0;
            width: 70vw;
          }

          a {
            color: c.$dark-default;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}

.medium-view {
  .cards-deck-with-title-and-sub-root {
    .g-container {
      margin-inline: 72px;
    }
  }
}

.large-view {
  .cards-deck-with-title-and-sub-root {
    .g-container {
      .cards-deck-with-title-and-sub-root__cardsWrapper {
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: space-between;
        .cards-with-title-and-sub-root {
          width: 296px;

          &__card-contentWrapper {
            display: grid;
            align-content: space-between;
            height: 130px;

            p {
              width: 312px;
            }

            a {
              height: fit-content;
              width: fit-content;
            }
          }
        }
      }
    }
  }
}
