@use "../../../../global-styles/palette" as c;

.icon-and-title-card {

    padding: 20px;
    border-radius: 12px;
    border: 1px solid #EEE;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    p {
        color: c.$dark-default;
        font-weight: 600;
    }

    &__image {
        width: 32px;
        height: 32px;
    }
}