@use "../../global-styles/palette" as c;

.section-with-featured-lists-root {
  .g-container {
    .section-with-featured-lists-root__titleWrapper {
      margin-bottom: 40px;

      h2 {
        text-align: center;
        margin-bottom: 12px;
        color: c.$dark-default;
      }

      p {
        text-align: center;
        color: c.$dark-80;
      }
    }

    .section-with-featured-lists-root__criteriaWrapper {
      display: grid;
      gap: 40px;

      &__eligibilityTests {
        &:nth-child(even) {
          border-top: 1px c.$light-dark solid;
          padding-top: 30px;
        }

        h3 {
          margin-bottom: 12px;
          text-align: center;
          color: c.$dark-default;
        }

        &__root-aspectWrapper {
          &__aspectWrapper {
            border-radius: 10px;
            border: 1px c.$light-dark solid;
            background-color: c.$pure-white;
            display: flex;

            &__aspectTitle {
              background-color: c.$tm-green-default;
              color: c.$dark-default;
              display: flex;
              min-width: 32px;
              height: 32px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              margin: 20px 12px;
            }

            .aspects-root {
              width: 100%;
              border-left: 1px c.$light-dark solid;
              padding: 24px 0 24px 20px;

              .plus-image-wrapper {
                display: flex;
                justify-content: center;
                margin: 12px 0px;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 50%;
                  height: 1px;
                  display: block;
                  width: 100%;
                  background-color: c.$light-dark;
                }

                svg {
                  z-index: 1;
                  padding: 2px 4px;
                  border: 1px c.$light-dark solid;
                  border-radius: 4px;
                  background-color: c.$pure-white;
                }
              }

              .aspect-item-root {
                &__title {
                  font-weight: 600;
                  color: c.$dark-default;
                  margin-bottom: 8px;
                }

                &__description {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  color: c.$dark-default;
                  width: 93%;
                }
              }
            }
          }

          .orLabel {
            width: min-content;
            margin: 8px auto;
          }
        }
      }
    }

    .section-with-featured-lists-root__buttonWrapper {
      margin-top: 40px;

      p {
        margin: 16px auto;
        width: min-content;
      }

      a {
        width: 100%;
      }
    }
  }

  .withThreeButton {
    .section-with-featured-lists-root__titleWrapper {

      h2,
      p {
        text-align: left;
      }
    }

    .section-with-featured-lists-root__criteriaWrapper {
      &__eligibilityTests {
        h3 {
          text-align: left;
        }
      }
    }

    .section-with-featured-lists-root__buttonWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      div {
        width: 100%;
      }
    }
  }
}


.medium-view {
  .section-with-featured-lists-root {
    .g-container {
      .section-with-featured-lists-root__titleWrapper {
        h2 {
          text-align: left;
        }

        p {
          text-align: left;
        }
      }

      .section-with-featured-lists-root__criteriaWrapper {
        &__eligibilityTests {
          h3 {
            text-align: left;
          }

          &__root-aspectWrapper {
            &__aspectWrapper {
              align-items: baseline;

              .aspects-root {
                .plus-image-wrapper {
                  &::before {
                    width: 80%;
                  }
                }

                .aspect-item-root {
                  &__description {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .section-with-featured-lists-root__buttonWrapper {
        display: flex;
        width: min-content;
        margin-inline: auto;
        column-gap: 16px;

        a {
          width: unset;
        }
      }
    }

    .withThreeButton {
      .section-with-featured-lists-root__buttonWrapper {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        max-width: 500px;

        div {
          width: max-content;
        }
      }
    }
  }
}

.large-view {
  .section-with-featured-lists-root {
    .g-container {
      .section-with-featured-lists-root__titleWrapper {
        h2 {
          text-align: left;
        }

        p {
          text-align: left;
        }
      }

      .section-with-featured-lists-root__criteriaWrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        &__eligibilityTests {
          &:nth-child(even) {
            border-top: unset;
            padding-top: 0px;
            border-left: 1px c.$light-dark solid;
            padding-left: 30px;
          }

          h3 {
            text-align: left;
          }

          &__root-aspectWrapper {
            &__aspectWrapper {
              .aspects-root {
                .aspect-item-root {
                  max-width: 515px;
                }
              }
            }
          }
        }
      }

      .section-with-featured-lists-root__buttonWrapper {
        display: flex;
        width: min-content;
        margin-inline: auto;
        column-gap: 16px;
      }
    }

    .centeredColumn {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .section-with-featured-lists-root__titleWrapper {

        h2,
        p {
          text-align: center;
        }

        p {
          width: 591px;
        }
      }

      .section-with-featured-lists-root__criteriaWrapper {
        display: flex;

        &__eligibilityTests {

          h3 {
            text-align: center;
          }

          &__root-aspectWrapper {


            &__aspectWrapper {
              background-color: c.$pure-white;
            }
          }
        }
      }

      .section-with-featured-lists-root__buttonWrapper {
        margin: 40px 0 0 0;
      }
    }

    .withThreeButton {
      .section-with-featured-lists-root__buttonWrapper {
        width: 100%;
        flex-direction: row;
        margin-top: 24px;

        div {
          width: max-content;
          margin: 0;

          &:last-child {
            margin-left: 10%;
          }
        }
      }
    }
  }
}