@use "./variables" as v;

@mixin button-styled-as-link {
    display: inline;
    padding: 0;
    border: 0;
    font: inherit;
    text-decoration: underline;
    cursor: pointer;
    background: transparent;
    color: currentColor;
    appearance: none;
}

%button-styled-as-link,
.button-styled-as-link {
    @include button-styled-as-link;
}

%button-styled-as-link-min-size,
.button-styled-as-link-min-size {
    @extend %button-styled-as-link;

    display: inline-flex;
    place-items: center;
    min-width: v.$touch-area-min-size;
    min-height: v.$touch-area-min-size;
}