@use "./palette" as c;

@mixin faux-link-underline($underlineColor: c.$tm-green) {
    color: c.$tm-green;
    text-decoration: none;
    border-bottom: 1px solid $underlineColor;
}

@mixin asian-text-links($underlineColor: c.$tm-green, $underlineHoverColor: c.$tm-green) {
    @include faux-link-underline($underlineColor);

    &:hover {
        @include faux-link-underline($underlineHoverColor);
    }
}