@use "../../global-styles/palette" as c;

.trading-glossary-search {
  .subscribeRibbon {
    .subscribeRibbon__container {
      .subscribeRibbon__widget {
        width: 100%;
        margin-right: auto;
        input {
          margin: 0;
        }
      }
    }
  }

  .trading-glossary {
    &__main {
      .alphabet-list {
        margin: 0;

        &__letter-block {
          .alphabet-list-item {
            h5 {
              border: 0;
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .trading-glossary-search {
    .subscribeRibbon {
      .subscribeRibbon__container {
        .subscribeRibbon__widget.subscribeRibbon__widget--search {
          width: 360px;
        }
      }
    }
  }
}

.large-view{
    .trading-glossary__side-bar{
    .cards-grid-icon-and-title__cards-grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  .trading-glossary-search {
    .trading-glossary {
      &__main {
        .alphabet-list {
          &__title {
            margin-left: 0;
          }

          margin-left: auto;
        }
      }
    }
  }
}

.large-view[dir="rtl"] {
  .trading-glossary{
    flex-direction: row-reverse;
    padding-top: 16px;
    gap: 20px;
    .alphabet-list-item{
      margin-left: unset;
      margin-right: 26px;
    }
  }
}