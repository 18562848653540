@use "../../../global-styles/palette" as c;

@use "./IconAndTitleCard/IconAndTitleCard";

.icon-and-title-card-deck {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;

    &__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        h3 {
            font-size: 38px;
            font-weight: 600;
            line-height: 120%;
            color: c.$dark-90;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            color: c.$dark-80;
        }
    }

    &__items-container {
        display: flex;
        gap: 20px;
    }
}

.medium-view {
    .icon-and-title-card-deck {
        &__items-container {
            flex-wrap: wrap;
            justify-content: center;
        }

        &__text-container {
            h3 {
                font-size: 26px;
            }

            p {
                font-size: 16px;
            }
        }
    }
} 

.small-view {
    .icon-and-title-card-deck {
        &__items-container {
            flex-wrap: wrap;
            justify-content: center;
        }

        &__text-container {
            text-align: center;

            h3 {
                font-size: 26px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}