@use "../../global-styles/palette" as c;

.price-feed-new {
  $header-x-padding: 12px;

  font-family: Figtree, sans-serif;
  width: 100%;

 .price-feed__wrap--left {
    max-width: 360px;

    h2:dir(rtl) {
      line-height: 65px;
    }
  }
  .price-feed__cellContainer {
    gap: 4px;
  }

  .button-v2-wrapper {
    justify-content: flex-end;

    .button-v2 {
      font-size: 12px;
      font-weight: 600;
      line-height: 1;
      border-radius: 38px;
      backdrop-filter: blur(4px);
    }

    .secondary-medium-wbg {
      padding: 10px 16px;
      color: #0F172A;
      background-color: #F1F5F9;
    }

    .secondary-medium-wbg:hover {
      color: c.$pure-white;
      background-color: #0F172A;
    }
  }

  .price-feed__mobile-button-wrapper {
    display: none;
    margin-top: 20px;

    .button-v2-wrapper {
      width: 100%;

      .button-v2 {
        width: 100%;
      }
    }
  }

  &__wrap {
    display: flex;
    gap: 24px;
  }

  .instrument-slider {

    p {
      font-weight: 500;
      margin: 16px 0 8px;
    }
  }

  .instrument-slider--new {
    padding: 0;
    margin-bottom: 10px;
    border: none;
    border-radius: 0;

    &__item {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: #64748B;

      &:hover {
        color: #0F172A;
      }

      &.instrument-slider--new__highlighted {
        color: c.$dark-default;
        background-color: c.$dark-10;
      }

      &:not(.instrument-slider--new__highlighted) {
        background-color: transparent;
      }
    }
  }

  .instrument-slider__items {
    flex-wrap: wrap;
    display: flex;
    justify-content: unset;
    gap: 8px;
    margin-bottom: 32px;
  }

  .instrument-slider__item {
    box-sizing: border-box;
    width: 104px;
    min-width: 80px;
    height: 80px;
    margin: 0;
  }

  .instrument-slider__item-title {
    font-size: 12px;
  }

  .instrument-slider__item-image {
    height: 40px;
    width: 40px;
  }

  .instrument-slider__title {
    text-align: left;
    margin-bottom: 32px;
  }

  .price-feed__table {
    box-sizing: border-box;
    width: 100%;
    max-width: 744px;
    padding: 20px;
    border: 1px solid c.$dark-10;
    border-radius: 20px;
  }

  .price-feed__table-data {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
  }

  .price-feed__header {
    display: table-row;
    height: auto;
    background-color: transparent;
    border-radius: 0;
  }

  .price-feed__headerBorder {
    height: 11px;
    padding: 0;

    &::after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 1px solid c.$dark-10;
    }

    &:first-child {
      padding-inline-start: $header-x-padding;
    }

    &:last-child {
      padding-inline-end: $header-x-padding;
    }
  }

  .price-feed__row {
    display: table-row;
    height: auto;
    cursor: pointer;
    border-radius: 8px;
    border-bottom: 0;
    padding: 0;

    &:hover {
      font-weight: 500;
      background-color: c.$neutral-accent-background;

      .secondary-medium-wbg {
        color: c.$pure-white;
        background-color: #0F172A;
      }
    }

    .price-feed__highlighted {
      background-color: transparent;
    }
  }

  .price-feed__headerCell {
    box-sizing: border-box;
    padding: 12px 0;
    padding-inline-end: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px; 
    color: c.$color-text-tertiary;
    text-align: start;

    &:first-child {
      padding-inline-start: $header-x-padding;
    }

    &:nth-child(4) {
      text-align: end;
    }

    &:last-child {
      padding-inline-end: $header-x-padding;
    }
  }

  .price-feed__cellContainer {
    $border-radius: 8px;

    display: table-cell;
    padding: 20px 0;
    padding-inline-end: 12px;
    text-align: start;
    text-decoration: none;
    color: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;

    a {
      text-decoration: none;
      color: inherit;
    }

    &:first-child {
      padding-inline-start: 12px;
      border-start-start-radius: $border-radius;
      border-end-start-radius: $border-radius;
    }

    &:nth-child(4) {
      text-align: end;
    }

    &:last-child {
      border-start-end-radius: $border-radius;
      border-end-end-radius: $border-radius;
    }

    &--instrument,
    &--with-icon {
      white-space: nowrap;

      & > * {
        display: inline;
      }

      svg,
      img {
        display: inline;
        vertical-align: middle;
      }

      svg {
        margin-inline-start: 3px;
      }
    }

    &.price-feed__positive {
      color: c.$support-up;

      svg {
        margin-bottom: 4px;
      }
    }
  
    &.price-feed__negative {
      color: c.$support-down;

      svg {
        margin-bottom: 1px;
      }
    }
  }

  .price-feed__headerCell,
  .price-feed__cellContainer {
    &:nth-child(3) {
      text-align: center;

      .price-feed__cellTitle {
        text-align: center;
      }
    }

    &:nth-child(4) {
      text-align: end;
    }
  }

  .price-feed__headerCell:first-child,
  .price-feed__cellContainer:first-child {
    width: 27%;
  }

  .price-feed__headerCell:nth-child(3),
  .price-feed__cellContainer:nth-child(3) {
    width: 20%;
  }

  .price-feed__headerCell:nth-child(4),
  .price-feed__cellContainer:nth-child(4) {
    width: 25%;
  }

  .price-feed__mobile-button-wrapper {
    display: none;
  }

  .price-feed__footerText {
    padding: 8px;
    margin-top: 10px;
    color: c.$color-text-tertiary;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}

.price-feed__column {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;

  p{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;

    span {
      color: c.$dark-default;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; 
    }
  }
}

.medium-view {
  .price-feed__column{
    align-content: center;
    justify-content: center;
    gap: 8px;

    p {
        width: 30%;
    }
  }

  .price-feed-new {
    .g-container {
      .price-feed__headerCell:first-child,
      .price-feed__cellContainer:first-child {
        width: 27%;
      }
    
      .price-feed__headerCell:nth-child(3),
      .price-feed__cellContainer:nth-child(3) {
        width: 15%;
      }
    
      .price-feed__headerCell::nth-child(4),
      .price-feed__cellContainer::nth-child(4) {
        width: 20%;
      }
    }
  }
}

.medium-view,
.small-view {
  .price-feed-new {
    .instrument-slider--new__items{
      overflow-x: auto;
    }

    .price-feed__header {
      display: table-row;
      width: auto;
    }

    .price-feed__row {
      width: auto;
    }

    .price-feed__highlighted {
      .price-feed__upDownIcon {
        display: none;
      }
    }

    .price-feed__row:hover {
      .price-feed__upDownIcon {
        display: none;
      }
    }

    .price-feed__headerCell {
      width: auto;
    }

    &__wrap {
      flex-direction: column;
    }

    .instrument-slider__items {
      flex-wrap: nowrap;
      margin-bottom: 16px;

    }

    .instrument-slider {
      max-width: unset;
    }

    .instrument-slider__item {
      width: 80px;
      height: 64px;
    }
  }
  .price-feed {
    &__wrap--left {
      max-width: 100%;
      h2 {
        text-align: center;
      }
    }
  }
}

.small-view {

  .price-feed__column{
    p {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
  }

  .price-feed-new {
    .g-container {
      margin-inline: auto;
    }

    .price-feed {
      &__instrumentIcon {
        display: block;
      }

      &__headerCell {
        font-size: 13px;
      }

      &__headerCell,
      &__cellContainer {
        text-align: start;

        &:nth-child(3) {
          text-align: end;
        }
      }

      &__headerCell:first-child,
      &__cellContainer:first-child {
        width: 40%;
      }

      &__headerCell:nth-child(2),
      &__cellContainer:nth-child(2) {
        width: 30%;
      }

      &__headerCell:nth-child(3),
      &__cellContainer:nth-child(3) {
        width: 20%;
        border-start-end-radius: 8px;
        border-end-end-radius: 8px;
      }

      &__headerBorder:nth-child(3) {
        padding-inline-end: 12px;
      }

      &__instrumentIcon {
        display: inline;
      }

      &__mobile-button-wrapper {
        display: flex;
        justify-content: center;
      }

      &__wrap--left {
        max-width: 100%;
        h2 {
          max-width: 90%;
          text-align: center;
          margin: 0px auto;
        }
      }

      &__cellContainer:nth-child(1) {
        width: auto;
      }

      &__headerCell:last-child,
      &__cellContainer:last-child,
      &__headerBorder:last-child,
      &__headerCell:nth-child(4),
      &__cellContainer:nth-child(4),
      &__headerBorder:nth-child(4) {
        display: none;
      }

      &__headerCell {
        width: auto;
      }
    }

    .price-feed__table {
      border-right: 0;
      border-left: 0;
      margin-left: 0;
      border-radius: 0;
    }
  }
}

.medium-view {
  .price-feed-new {
    .price-feed__table {
      margin: 28px auto 0px auto;
    }
  
    .g-container {
      .price-feed__headerCell {
        width: auto;
        white-space: nowrap;
      }
  
      .price-feed__headerCell:first-child {
        min-width: 180px;
      }
  
      .price-feed__cellContainer:first-child {
        min-width: 180px;
      }
  
      .price-feed__headerCell:nth-child(2) {
        margin-left: 0;
      }
    }
  }
}

.large-view {
  .price-feed-new {
    .g-container {
      .price-feed__headerCell:first-child {
        min-width: 180px;
        margin-left: 0;
      }
  
      .price-feed__headerCell:nth-child(2) {
        margin-left: 0;
      }
  
      .price-feed__headerCell:nth-child(3) {
        margin-left: 0;
      }
  
      .price-feed__headerCell:nth-child(4) {
        margin-left: 0;
      }
  
      .price-feed__cellContainer:first-child {
        min-width: 180px;
      }
    }

    .price-feed__column{
      gap: 16px;
    }
  }
}

@media  (max-width: 799px) and (orientation: portrait) {
  .medium-view {
    .price-feed-new {
      .g-container {
        margin-inline: auto;
      }

      .price-feed__table {
        max-width: none;
        margin-inline: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 0;
        border-radius: 0;
      }
    }
  }
}
