@use "../../../global-styles/palette" as p;
@use "../../../global-styles/variables" as v;

.social-contacts {

    &__logo-and-social {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        padding: 16px 0px;
    }

    &__logo img {
        max-width: 195px;
    }

    &__social {
        display: flex;
        gap: 16px;
        max-height: 32px;
    }

    &__social__item {
        display: flex;
        justify-content: center;
        max-height: 100%;
    }

    &__logo-image {
        max-height: 100%;
    }

    &__live-chat__mobile {
        display: none;
    }

    &__live-chat {
        margin: 17px 0;
    }

    &__live-chat__text p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: start;
        color: p.$dark-80;
    }

    &__live-chat__text a {
        color: p.$dark-default;
        cursor: pointer;
        text-decoration: underline;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    &__divider-line {
        max-width: 1128px;
        height: 1px;
        margin: 0 auto;
        background-color: p.$dark-10;
    }
}

.large-view {
    .social-contacts {

        &__logo {
            padding: 14px 0px 8px 0px;
        }

        .g-container:not(.g-container--fullwidth) {
            width: calc(100% - 2 * v.$gutter-large);
            min-width: auto;
            max-width: v.$min-content-width-large;
        }
    }
}

.small-view,
.medium-view {
    .social-contacts {
        padding-top: 0px;

        &__logo-and-social {
            flex-direction: column;
            padding: 15px 0;
            border-bottom: unset;
        }

        &__logo {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 0px 24px;
            border-bottom: 1px solid p.$dark-10;
        }

        &__live-chat {
            display: none;
        }

        &__live-chat__mobile {
            display: flex;
            padding: 16px 0px;
        }

        &__live-chat__text p {
            text-align: center;
        }

        &__divider-line {
            margin: 0 24px;
        }
    }
}