@use "../../global-styles/palette" as c;
@use "../../global-styles/variables" as v;
@use "../../global-styles/layout";

.centred-title-with-subtitle {
    margin: 30px 0;

    a {
        color: inherit;
        font-weight: bold;
    }

    &__main-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.5vh;
    }

    &__title,
    &__subtitle {
        text-align: center;
    }

    &__subtitle {
        display: flex;
        justify-content: center;
    }

    &__main-container--title-left &__title,
    &__main-container--subtitle-left &__subtitle {
        text-align: start;
    }

    &__main-container--subtitle-left &__subtitle {
        justify-content: flex-start;
    }

    &__main-container--title-right &__title,
    &__main-container--subtitle-right &__subtitle {
        text-align: end;
    }

    &__main-container--subtitle-right &__subtitle {
        justify-content: flex-end;
    }

    &__image {
        width: 100%;
        object-fit: contain;
    }

    &__image-container {
        margin-top: 2vh;
    }

    &__video {
        width: 100%;
    }

    &__description {
        max-width: 744px;
    }

    &__images {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.medium-view {
    .centred-title-with-subtitle {
        margin-top: 0;

        &__description {
            max-width: 600px;
        }
    }
}

.small-view {
    .centred-title-with-subtitle {
        margin-top: 0;
        
        &__main-container {
            gap: 16px;
        }

        &__description {
            max-width: 327px;
        }
    }
}