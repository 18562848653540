.logo-wrapper {
    position: relative;
    width: 244px;
    height: 40px;
    display: flex;
  
    a {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }

    .logo-dark {
        visibility: var(--dark-logo-display);
        position: absolute;
        inset-inline-start: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        margin-block: auto;
    }
    
    .logo-light {
        visibility: var(--light-logo-display);
    }

    &.logo-wrapper--single-logo {
        .logo-light {
            --light-logo-display: visible;
        }
    }
  }