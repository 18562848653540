@use "../../global-styles/palette" as c;
@use "../../global-styles/typography";
@use "../../global-styles/layout";
@use "./FaqAccordionCard/FaqAccordionCard";

.faq-accordion {
  position: relative;
  padding: 64px 0;

  &__bkgr {
    @extend %img-as-background;

    z-index: -1;
  }

  &--no-image {
    background-color: #f2f4f6;

    .faq-accordion__title {
      color: c.$dark-default;
    }

    .faq-accordion__link svg {
      fill: c.$blue-lagoon-default;
    }

    .faq-accordion__link {
      color: c.$blue-lagoon-default;
    }
  }

  &__title {
    @extend %card-title--size-h3;

    color: c.$pure-white;
    margin-bottom: 32px;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: c.$blue-lagoon-40;
    text-decoration: none;
    font-weight: bold;

    svg {
      fill: c.$blue-lagoon-40;
      margin-left: 4px;
    }
  }

  &__deck {
    max-width: 648px;
    margin: 0 auto;
  }

  .faq-card {
    margin-bottom: 16px;
  }
}

.small-view,
.medium-view {
  .faq-accordion {
    &__bkgr {
      display: none;
    }

    &__title {
      color: c.$dark-default;
      margin-bottom: 24px;
    }

    &__link {
      color: c.$blue-lagoon-default;

      svg {
        fill: c.$blue-lagoon-default;
      }
    }
  }
}

.large-view {
  .faq-accordion {
    padding: 80px 0;
  }
}