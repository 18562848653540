@use "../../../../global-styles/palette" as c;

.card-with-hover-animation {
    background: radial-gradient(117.9% 117.9% at 50% 100%, #2C3949 0%, #112034 100%);
    padding: 30px;
    border-radius: 22px;
    min-width: 302px;

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;

        h5 {
            color: c.$pure-white;
        }

        p {
            text-align: center;
            color: c.$dark-20;
        }
    }

    &__icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__svg-lottie-wrapper {
        display: flex;
        justify-content: center;
        min-height: 105px;
    }
}

.large-view {
    .card-with-hover-animation {
        &__text-wrapper {
            max-width: 302px;

            h5 {
                font-size: 20px;
                font-weight: 600;
                line-height: 32px;
            }
        }
    }
}
.medium-view {
    .card-with-hover-animation {
        padding: 16px;
        border-radius: 13px;

        &__text-wrapper {
            max-width: 160px;

            h5 {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
            }

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                max-width: 160px;
            }
        }

        &__svg-lottie-wrapper {
            min-height: 59px;

            img {
                width: 59px;
            }
        }
    }
}

.small-view {
    .card-with-hover-animation {
        min-height: 184px;
        &__text-wrapper {
            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                max-width: 277px;
            }
        }

        &__svg-lottie-wrapper {
            min-height: 84px;

            img {
                width: 84px;
            }
        }
    }
}

.medium-view, .small-view {
    .card-with-hover-animation {
        &__text-wrapper {
            h5 {
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
            }
        }
    }
}