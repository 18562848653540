@use "../../global-styles/palette" as c;

.two-section-with-title-root {
  .g-container {
    .two-section-with-title {
      &__titleWrapper {
        text-align: center;
        font-style: normal;

        h2 {
          color: c.$pure-white;
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
        }

        h5 {
          color: c.$dark-20;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin: 8px 0 24px;
        }
      }

      &__cardsWrapper {
        .two-section-with-title-card-root {
          h4 {
            color: c.$pure-white;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 8px;
          }

          &__contentWrapper {
            background-color: c.$dark-80;
            margin-bottom: 24px;
            display: grid;
            row-gap: 16px;
            border-radius: 24px;
            padding: 24px;

            &__content {
              display: grid;
              grid-auto-flow: column;
              justify-content: start;
              align-items: center;

              svg {
                margin-right: 8px;
              }

              h5 {
                color: c.$pure-white;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                width: 63vw;
              }
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .two-section-with-title-root {
    .g-container {
      margin: 0 72px 0;

      .two-section-with-title {
        &__titleWrapper {
          h2 {
            line-height: 40px;
          }
        }

        &__cardsWrapper {
          .two-section-with-title-card-root {
            &__contentWrapper {
              padding: 32px;
              &__content {
                h5 {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.large-view {
  .two-section-with-title-root {
    .g-container {
      .two-section-with-title {
        &__titleWrapper {
          h2 {
            line-height: 56px;
          }

          h5 {
            max-width: 700px;
            margin: 8px auto 32px;
          }
        }

        &__cardsWrapper {
          display: grid;
          grid-auto-flow: column;
          justify-content: space-between;
          .two-section-with-title-card-root {
            &__contentWrapper {
              padding: 32px;
              width: 488px;
              align-content: start;
              height: 142px;

              &__content {
                h5 {
                  width: fit-content;
                }
              }
            }
          }
        }
      }
    }
  }
}
