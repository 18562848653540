@use "../../global-styles/palette" as c;

.threeImagesCardsWrapper {
  .g-container {
    h2.threeImagesCardsWrapper__mainTitle {
      color: c.$dark-default;
      margin-bottom: 8px;
      text-align: center;
    }

    p.threeImagesCardsWrapper__subTitle {
      color: c.$dark-80;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }

    .threeImagesCardsWrapper__cardsWrapper {
      max-width: 400px;
      margin-inline: auto;
      .threeImagesCardComponent {
        display: grid;
        justify-items: center;
        width: fit-content;
        margin-top: 32px;

        &__imageWrapper {
          width: 170px;
          height: 348px;

          img {
            width: 100%;
            height: auto;
          }
        }

        h4 {
          color: c.$dark-default;
          text-align: center;
          width: 150px;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          margin: 32px 0 8px;
        }

        p {
          color: c.$dark-80;
          text-align: center;
          width: 185px;
        }
      }
    }
  }
}

.medium-view {
  .threeImagesCardsWrapper {
    .g-container {
      margin: 0 72px;
      .threeImagesCardsWrapper__cardsWrapper {
        max-width: 100%;
        .threeImagesCardComponent {
          width: 100%;

          &__imageWrapper {
            width: 184px;
            height: 376px;
          }
          h4 {
            width: 100%;
          }

          p {
            width: 100%;
          }
        }
      }
    }
  }
}

.large-view {
  .threeImagesCardsWrapper {
    .g-container {
      h2.threeImagesCardsWrapper__mainTitle {
        width: 610px;
        margin-inline: auto;
      }

      p.threeImagesCardsWrapper__subTitle {
        width: fit-content;
        margin-inline: auto;
      }
      .threeImagesCardsWrapper__cardsWrapper {
        display: grid;
        grid-auto-flow: column;
        max-width: 100%;
        .threeImagesCardComponent {
          &__imageWrapper {
            width: 245px;
            height: 501px;
          }

          h4 {
            width: fit-content;
          }

          p {
            width: 320px;
          }

          transition: 4s ease;

          &:first-child {
            transform: scale(1.7) translate(-10vw, 80px);
          }

          &:nth-child(2) {
            transform: scale(1.7) translate(0, 80px);
          }

          &:last-child {
            transform: scale(1.7) translate(10vw, 80px);
          }
        }

        &--animated {
          justify-content: space-between;
          .threeImagesCardComponent {
            &:first-child,
            &:nth-child(2),
            &:last-child {
              transform: scale(1) translate(0vw, 0px);
            }
          }
        }
      }
    }
  }
}
