@use "../../../global-styles/palette" as c;
@use "./NewsCard/NewsCard";

.newsCardDeck {
    background-color: c.$pure-white;

    &__inner-wrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 32px;
        
        h3 {
            color: c.$dark-default;
            width: 100%;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 35px;
        }
    }

    &__social-feed {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 25rem;

        .widget-container {
            position: absolute;
            inset: 0;
        }

        .twitter-timeline {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            overflow: auto;
            border: 1px solid c.$dark-20;
            border-radius: 10px;
        }

        a.twitter-timeline {
            padding: 16px;
        }
    }

    &__articles {
        flex-grow: 1;
        flex-basis: 0;
    }

    &__controls {
        .g-container,
        .g-container--fullwidth {
            min-width: auto !important;
        }

        .subscribeRibbon {
            background-color: transparent;

            .search-banner__input {
                background-color: c.$neutral-accent-background;
                border: none;
            }

            &__toggle-filters {
                display: flex;
                align-items: center;
                column-gap: 8px;
                padding: 8px 20px 8px 16px;
                border-radius: 4px;
                border: 1px solid c.$dark-20;
                color: c.$dark-default;
                font-weight: 500;

                &::before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M10 17C10 17.5523 10.4477 18 11 18H13C13.5523 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16H11C10.4477 16 10 16.4477 10 17ZM4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7C21 6.44772 20.5523 6 20 6H4ZM6 12C6 12.5523 6.44772 13 7 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H7C6.44772 11 6 11.4477 6 12Z" fill="inherit"/></svg>')
                }


                svg {
                    display: none;
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }
}

.medium-view,
.small-view {
    .newsCardDeck {
        &__inner-wrapper {
            flex-direction: column;
            h3 {
                font-size: 24px;
                font-weight: 600;
                line-height: 30px; 
            }

        }

        &__social-feed {
            margin-top: 48px;
            width: 100%;
            height: 400px;
        }

        .subscribeRibbon {
            .g-container {
                margin: 0;
            }

            
            &__actions {
                width: auto;
                justify-content: unset;
                border-top: none;
                margin-top: 0;
                padding-top: 0;
            }
        }

        &__controls {
            .filters__container {
                overflow: auto;
            }
        }
    }
}

.large-view {
    .newsCardDeck {
        &__social-feed {
            min-height: 400px;
        }
    }
}

.medium-view {
    .newsCardDeck {
        .subscribeRibbon {
            &__container {
                flex-direction: row;
                align-items: flex-start;
            }

            &__input {
                margin-bottom: 0;
            }
        }
    }
}

.small-view {
    .newsCardDeck {

        &__inner-wrapper {
            h3{
            font-size: 20px;
            line-height: 26px;
            }
        }
        .subscribeRibbon {
            &__container {
                flex-direction: column;
                align-items: stretch;
            }

            &__toggle-filters {
                flex-grow: 1;
                justify-content: center;
            }
        }
    }
}