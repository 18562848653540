@use "../../global-styles/palette" as c;

.titleSubTitleCTA{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    h2{
        font-weight: 700;
        letter-spacing: -2px;
    }
    .button-v2{
        border: 1px solid #DCDCDC;
        border-radius: 38px;
        font-size: 14px;
        padding: 12px 16px;
        line-height: 14px;
    }

    .button-v2:hover{
        background-color: #E2E8F0;
        color: c.$dark-default;
    }

    .button-v2-wrapper{
        margin: 0;
        align-self: flex-end;
    }
    &__content{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__text{
        color: #475569;
    }
}
.large-view,.small-view,.medium-view{
    .titleSubTitleCTA{
        h2{
            font-weight: 700;
        }
        h2,h3,h4,h5{
            letter-spacing: -0.02em;
        }
    }
}
.medium-view,.small-view {
    .titleSubTitleCTA{
        &__centered--tm{
            justify-content: center;
            text-align: center;
        }
        &__hide-button--tm{
            .button-v2-wrapper{
                display: none;
            }
        }
        .button-v2-wrapper{
            margin-top: 20px;
        }
    }
}

.small-view {
    .titleSubTitleCTA{
        &__hide-button--mobile{
            .button-v2-wrapper{
                display: none;
            }
        }
    }
}