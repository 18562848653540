@use "../../global-styles/palette" as c;

.tradeMiniAccount-root {
  .g-container {
    background-color: c.$pure-white;
    .tradeMiniAccount {

      &__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 8px;

        h2 {
          color: c.$dark-default;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
        }

        p {
          max-width: 327px;
        }
      }

      .tradeMiniAccount-cards-wrapper {
        margin-top: 32px;
        .tradeMiniAccountCardWrapper {
          background-color: c.$neutral-accent-background;
          border-radius: 22px;

          &:nth-child(2),
          &:last-child {
            margin-top: 24px;
          }

          &__imageWrapper {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 20px;
            border-radius: 24px;
            background-color: #d3e2e2;

            img {
              width: 100%;
            }
          }

          .tradeMiniAccountCardWrapper__comment__section-wrapper {
            display: grid;
            justify-content: center;
            margin-top: 24px;

            h4 {
              color: c.$dark-default;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 32px;
              width: fit-content;
            }

            p {
              color: c.$dark-80;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }

            .squaredButton {
              color: c.$dark-default;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              background-color: unset;
              border: 1px solid c.$dark-default;
              margin: 32px auto 24px;
            }
          }
        }
      }
      .tradeMiniAccount-cards-wrapperVertical {
        @extend .tradeMiniAccount-cards-wrapper;
      }
    }
  }
}

.small-view {
  .tradeMiniAccount-root {
    .tradeMiniAccountCardWrapper__comment__section-wrapper {
      padding: 0 24px;
    }

    .g-container .tradeMiniAccount .tradeMiniAccount-cards-wrapper .tradeMiniAccountCardWrapper__imageWrapper img {
      max-width: 286px;
    }
  }
}

.medium-view {
  .tradeMiniAccount-root {
    .g-container {
      margin-inline: 72px;
      .tradeMiniAccount {

        &__text-container {
          
          h2 {
            font-size: 40px;
            line-height: 48px;
          }

          p {
            max-width: 600px;
          }
        }

        .tradeMiniAccount-cards-wrapper {
          column-gap: 24px;

          .tradeMiniAccountCardWrapper {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: min-content 1fr;
            margin: 24px 0;

            &__imageWrapper {
              width: 252px;
              min-height: 224px;
              padding: 20px 10px;
            }

            .tradeMiniAccountCardWrapper__comment__section-wrapper {
              justify-content: start;
              align-content: space-between;
              margin: 0;
              padding: 24px;

              .squaredButton {
                margin: 32px auto 0 0;
              }
            }
          }
        }
      }
    }
  }
}

.large-view {
  .tradeMiniAccount-root {
    .g-container {
      overflow: unset;
      .tradeMiniAccount {

        &__text-container { 

          h2 {
            font-size: 48px;
            line-height: 56px;
            max-width: 100%;
            margin-inline: auto;
          }

          p {
            max-width: 744px;
          }
        }

        .tradeMiniAccount-cards-wrapper {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: minmax(0, 1fr);
          column-gap: 24px;

          .tradeMiniAccountCardWrapper {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: min-content 1fr;
            margin: 0;
            align-items: unset;

            &__imageWrapper {
              width: 252px;
              height: 100%;
              padding: 20px 10px;
            }

            .tradeMiniAccountCardWrapper__comment__section-wrapper {
              justify-content: start;
              margin: 0;
              align-content: space-between;
              padding: 23px 25px;

              .squaredButton {
                margin: 32px auto 0 0;
              }
            }
          }
          .tradeMiniAccountCardWrapper {
            display: none;
          }
          .tradeMiniAccountCardWrapper:first-child,
          .tradeMiniAccountCardWrapper:nth-child(2) {
            display: grid;
          }
        }
        .tradeMiniAccount-cards-wrapperVertical {
          @extend .tradeMiniAccount-cards-wrapper;
          column-gap: 24px;
          justify-content: space-evenly;

          .tradeMiniAccountCardWrapper {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: none;

            &:nth-child(2),
            &:last-child {
              margin-top: 0;
            }

            &__imageWrapper {
              width: 360px;
              height: 320px;
            }
          }
        }
      }
    }
  }
}