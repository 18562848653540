@use "../../global-styles/palette" as c;

.HeroBannerWithVideoBG{
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  &__container{
    position: relative;
  }
  &__video-container{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    video{
      width: 100%;
    }
  }
  &__video-mask{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(14, 29, 49, 0.90);
    backdrop-filter: blur(8px);
  }
  .button{
    margin: 32px auto;
  }
  h1{
    margin: 32px 0 8px;
  }
  p{
    font-weight: 500;
    color: c.$dark-10;
    max-width: 750px;
    margin-inline: auto;
    text-align: center;
    font-size: 18px;
    font-style: normal;    
    line-height: 24px;
  }
}

.medium-view,.small-view{
  .HeroBannerWithVideoBG{
    max-width: 600px;
    margin: 0 auto;
  }
}
.small-view{
  .HeroBannerWithVideoBG{
    h1{
      margin-top: 16px;
    }
    &__video-container{
      video {
        height: 100%;
        width: auto;
      }
    } 
  }
}