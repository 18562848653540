@use "../../global-styles/palette" as c;

.mt5-hero-banner__root {
  .g-container {
    display: grid;
    justify-items: center;


    .mt5-hero-banner__imageWrapper {
      margin-bottom: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

  h1 {
    margin-bottom: 8px;
    max-width: 750px;
    text-align: center;
    color: c.$dark-default;
  }

    .mt5-hero-banner__subtitle {
      text-align: center;
      width: 93%;
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: 500;
      color: c.$dark-80;
    }

    .cta-large-wbg {
      margin-bottom: 20px;
    }

    .tmx25-round-green-lightgreen {
      width: unset;
    }

    a.p-bold {
      color: c.$dark-default;
    }
  }
}

.medium-view {
  .mt5-hero-banner__root {
    .g-container {
      .mt5-hero-banner__subtitle {
        width: 87%;
      }
    }
  }
}

.large-view {
  .mt5-hero-banner__root {
    .g-container {
      .mt5-hero-banner__subtitle {
        width: 62%;
      }
    }
  }
}