@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;

.download-button{
    padding: 0 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 64px;
    background-color: c.$neutral-accent-background;
    text-decoration: none;
    color: c.$dark-default;
    &:hover{
        background-color: c.$neutral-accent-default;
    }
    svg:first-child{
        margin-right: 8px;
    }
    svg:last-child{
        margin-left: auto;
    }
}