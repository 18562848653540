@use "../../global-styles/palette" as c;

.section-with-two-cards-animated-root {
  --grid-template-areas:
    "textArea imageArea"
    "textArea imageArea"
    "buttonArea imageArea";

  .g-container {
    h2 {
      color: c.$dark-90;
      text-align: center;
      font-size: 34px;
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 20px;
    }

    .section-with-two-cards-animated-root__cardsWrapper {
      display: grid;
      row-gap: 20px;

      .animated-card-root {
        border-radius: 20px;
        background-color: c.$dark-90;
        padding: 20px;

        &__imageWrapper {
          grid-area: imageArea;
          margin-inline: auto;
          height: auto;
          width: 35vw;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &__textWrapper {
          margin-bottom: 40px;
          grid-area: textArea;

          h4 {
            color: c.$pure-white;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
            margin-bottom: 12px;
          }

          p {
            color: c.$light-grey-font;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            min-height: 130px;
          }
        }

        .button-v2-wrapper {
          grid-area: buttonArea;
          width: 100%;

          .button-v2 {
            width: 100%;
          }
        }

        .button-v2-wrapper:dir(rtl) {
          .button-v2 {
            margin: 0 0 0 auto;
          }
        }
      }
    }
  }
}

.small-view {
  .section-with-two-cards-animated-root {
    .g-container {
      .section-with-two-cards-animated-root__bigCardWrapper {
        .animated-big-card-root {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          border-radius: 20px;
          margin-bottom: 20px;
          padding: 20px;
          background: radial-gradient(117.99% 191.68% at 75.9% -73.94%, rgba(52, 195, 47, 0.40) 3.45%, rgba(81, 81, 148, 0.40) 58.74%, rgba(0, 0, 0, 0.00) 100%), #1A2737;

          &__textWrapper {
            text-align: center;

            h4 {
              color: #FFF;
              margin-bottom: 12px;
              font-size: 24px;
              font-weight: 600;
              line-height: 120%;
            }

            p {
              color: #C3CBD5;
              margin-bottom: 40px;
              font-weight: 500;
            }

            .button-v2-wrapper {
              display: block;
            }
          }

          &__imageWrapper {
            margin-bottom: 20px;

            img {
              max-width: 253px;
              height: 150px;
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .section-with-two-cards-animated-root {
    .g-container {
      h2 {
        text-align: var(--text-alignment);
      }

      h2:dir(rtl) {
        text-align: start;
      }

      .section-with-two-cards-animated-root__bigCardWrapper {
        display: block;
        margin-bottom: 20px;

        .animated-big-card-root {
          padding-block: 40px;
          padding-inline-start: 40px;
          display: flex;
          justify-content: space-between;
          border-radius: 20px;
          overflow: hidden;
          background: radial-gradient(117.99% 191.68% at 75.9% -73.94%, rgba(52, 195, 47, 0.40) 3.45%, rgba(81, 81, 148, 0.40) 58.74%, rgba(0, 0, 0, 0.00) 100%), #1A2737;

          &__textWrapper {
            display: flex;
            flex-direction: column;

            h4 {
              color: #FFF;
              margin-bottom: 12px;
            }

            p {
              color: #C3CBD5;
              max-width: 409px;
              margin-bottom: 40px;
            }

            .button-v2-wrapper {
              margin: unset;
              justify-content: unset;
            }
          }

          &__imageWrapper {

            img {
              max-height: 227px;
            }
          }
        }
      }

      .section-with-two-cards-animated-root__cardsWrapper {
        .animated-card-root {
          padding: 40px;
          display: grid;
          grid-template-areas: var(--grid-template-areas);


          &__imageWrapper {
            width: 27vw;
            min-width: 180px;
          }

          &__textWrapper {
            h4 {
              text-align: start;
            }

            p {
              text-align: start;
              width: 52vw;
            }
          }

          .button-v2-wrapper {
            height: fit-content;

            .button-v2 {
              margin-right: auto;
              width: fit-content;
            }
          }

          .button-v2-wrapper:dir(rtl) {
            .button-v2 {
              margin: 0 0 0 auto;
            }
          }
        }
      }
    }
  }
}

.large-view {
  .section-with-two-cards-animated-root {
    .g-container {
      h2 {
        margin-bottom: 40px;
        font-size: 48px;
        text-align: start;
      }

      .section-with-two-cards-animated-root__bigCardWrapper {
        margin-bottom: 20px;

        .animated-big-card-root {
          display: flex;
          justify-content: space-between;
          border-radius: 20px;
          overflow: hidden;
          background: radial-gradient(117.99% 191.68% at 75.9% -73.94%, rgba(52, 195, 47, 0.40) 3.45%, rgba(81, 81, 148, 0.40) 58.74%, rgba(0, 0, 0, 0.00) 100%), #1A2737;

          &__textWrapper {
            display: flex;
            flex-direction: column;
            max-width: 400px;
            padding-block: 40px;
            padding-inline-start: 40px;
            gap: 12px;

            h4 {
              color: #fff;
              font-size: 32px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
            }

            p {
              color: #C3CBD5;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              margin-bottom: 18px;
            }

            .button-v2-wrapper {
              margin: unset;
              justify-content: unset;
            }
          }

          &__imageWrapper {
            display: flex;
            align-items: flex-end;

            img {
              max-width: 600px;
              max-height: 304px;
            }
          }
        }
      }

      .section-with-two-cards-animated-root__cardsWrapper {
        --gap: 32px;
        --initial-card-width: calc((100% - var(--gap)) / 2);
        display: flex;
        justify-content: space-between;
        gap: var(--gap);

        .animated-card-root {
          width: var(--initial-card-width);
          padding-block: 40px;
          padding-inline-start: 40px;
          padding-inline-end: 0;
          display: grid;
          grid-template-areas: var(--grid-template-areas);
          transition: 1s ease;
          justify-content: space-between;

          &__imageWrapper {
            width: 195px;
            height: 227px;
          }

          &__textWrapper {
            h4 {
              text-align: start;
              font-size: 32px;
            }

            p {
              text-align: start;
              font-size: 16px;
            }
          }

          .button-v2-wrapper {
            height: fit-content;
            align-self: self-end;

            .button-v2 {
              margin-inline-end: auto;
              width: fit-content;
            }
          }

          &:hover {
            width: calc(var(--initial-card-width) + 5%);
            background-color: #12263f;
          }
        }
      }
    }
  }
}