@use "./AlphabetListItem/AlphabetListItem";
@use "../../global-styles/palette" as c;


.alphabet-list {
    width: 100%;
    padding: 40px 0 80px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 748px;
    margin-left: auto;
    box-sizing: border-box;
    &__noresults{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }
    &__title{
        margin-bottom: 32px;
        width: 100%;
        max-width: 552px;
        margin-left: 66px;
        color: c.$dark-default;
        &.search-title{
            max-width: 700px;
            word-break: break-word;
        }
    }
    &__content {
        display: flex;
    }

    &__letter {
        h3 {
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.11;
            letter-spacing: normal;
            color: c.$dark-default;
            width: 40px;
            text-align: center;
        }
    }

    // .is-sticky {
    //     position: fixed;
    //     top: 40px;
    //     left: 15%;
    //     z-index: 9;
    //     animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    // }
}
.search-title{
    &__container{
        width: 100%;
        text-align: left;
        a{
            font-weight: bold;
            color: c.$blue-lagoon-default;
            text-decoration: none;
        }
    }
}

.small-view,.medium-view {
    .alphabet-list {
        padding: 32px 24px 0px 24px;
        justify-content: center;

        &__title{
            margin-bottom: 16px;
            margin-left: 0;
        }
        &__content {
            display: flex;
            flex-direction: column;
        }

        &__letter {
            margin-bottom: 24px;

            h3 {
                width: unset;
                text-align: left;
            }
        }
    }
}