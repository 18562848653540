@use "../../../global-styles/palette" as c;

.range{
    margin:30px 0 20px 0;
    appearance: none;
    background-color: c.$dark-10;
    height: 8px;
    width: 100%;
    margin: 10px auto;
    border-radius: 4px;
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        label[for="range"] {
            position: absolute;
            width: 20px;
            top: -16px;
            left: 8px;
            color: c.$dark-default;
            pointer-events: none;
        }
        
        label[for="range"] span {
            position: absolute;
            top: 8px;
            left: 50%;
            text-align: center;
            font-size: 0.9em;
            font-weight: bold;
            transform: translateX(-50%);
            z-index: 5;
        }
        sub{
            color: c.$dark-60;
            font-weight: 500;
        }
    }
    &__options{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
  }
  .range:focus{
    outline:none;
  }
  .range::-webkit-slider-thumb{
    appearance: none;
    width: 19px;
    height: 19px;
    background: c.$dark-default;
    border-radius: 50%;
    border: 3px solid #fff;
    cursor: -moz-grab;
    cursor: -webkit-grab; 
  }
  .output {
    border: 1px solid c.$blue-lagoon-default;
    color: #333;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    padding: .4em .6em;
    border-radius: 3px;
  }