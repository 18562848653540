@use "../../../global-styles/palette" as c;
@use "./Cards/CardSwiper";

.address-card-deck-root {
    h3 {
        margin-bottom: 24px;
        color: c.$dark-default;
    }

    &__cardsWrapper {
        .swiper-pagination {
            display: flex;
            justify-content: center;
            margin-top: 16px;
            align-items: center;

            .address-card-deck__bullet {
                display: block;
                width: 8px;
                height: 8px;
                background-color: c.$dark-10;
                margin: 0;
                border-radius: 50%;

                &.swiper-pagination-bullet-active {
                    background-color: c.$dark-40;
                    width: 10px;
                    height: 10px;
                }

                &:hover {
                    cursor: pointer;
                }

                &:nth-child(even) {
                    margin-inline: 8px;
                }
            }
        }
    }
}


.medium-view {
    .address-card-deck-root {
        h3 {
            margin-bottom: 20px;
        }

        &__cardsWrapper {
            .swiper-wrapper {
                display: grid;
                grid-template-columns: repeat(2, auto);
                gap: 12px;
                max-width: 600px;

                .swiper-slide,
                .swiper-slide-active {
                    width: unset !important;
                }
            }

            .swiper-pagination {
                display: none;
            }
        }
    }
}

.large-view {
    .address-card-deck-root {
        h3 {
            margin-bottom: 20px;
        }

        &__cardsWrapper {
            .swiper-pagination {
                display: none;
            }
        }
    }
}