@use "../../../global-styles/palette" as c;
@use "./AcademyArticleCard/AcademyArticleCard";

.academyArticleCardDeck {
    &__gap {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 24px;
    }

    &__display {
        color: c.$dark-80;
        margin-top: 8px;
        text-align: center;
    }

    .g-col {
        width: 100%;
    }

    &__cards-grid {
        display: grid;
        gap: 20px;
    }

    &__title-and-radio {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 24px;
        margin-top: 24px;
    }

    &__radio {
        display: flex;
        padding: 8px;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid var(--Dark-20, #CFD2D6);

    }

    &__radio-option {
        display: flex;
        padding: 4px 16px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 8px;

    }

    &__radio-option.active {
        color: c.$pure-white;
    }

    &__radio-option:hover {
        cursor: pointer;
    }
}

.small-view {
    .academyArticleCardDeck {
        &__cards-grid {
            grid-template-columns: 1fr;
        }
    }
}

.medium-view {
    .academyArticleCardDeck {
        &__cards-grid {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.large-view {
    .academyArticleCardDeck {
        &__cards-grid {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}