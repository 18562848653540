@use "../../global-styles/palette" as c;

.contact-form__container-contact-us__root {
  .contact-form__container {
    .contact-form__form {
      background-color: c.$dark-light-bg;
      padding-inline: 0;

      h4 {
        color: c.$pure-white;
        text-align: center;
      }

      .contact-form__form-container {
        .contact-form__label {
          color: c.$dark-20;
        }

        .checkbox-input {
          .contact-form__label--checkbox {
            .privacy-policy {
              color: c.$dark-20;

              a {
                color: c.$dark-20;

              }
            }
          }
        }

        select {
          background-image: url('data:image/svg+xml,<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_35168_5084)"><path d="M9.2179 11.78C9.07738 11.9207 8.88674 11.9998 8.6879 12L8.3079 12C8.10948 11.9977 7.91962 11.9189 7.7779 11.78L2.6479 6.64C2.55324 6.54612 2.5 6.41832 2.5 6.285C2.5 6.15168 2.55324 6.02388 2.6479 5.93L3.3579 5.22C3.45006 5.12594 3.57621 5.07293 3.7079 5.07293C3.83959 5.07293 3.96574 5.12594 4.0579 5.22L8.4979 9.67L12.9379 5.22C13.0318 5.12534 13.1596 5.0721 13.2929 5.0721C13.4262 5.0721 13.554 5.12534 13.6479 5.22L14.3479 5.93C14.4426 6.02388 14.4958 6.15168 14.4958 6.285C14.4958 6.41832 14.4426 6.54612 14.3479 6.64L9.2179 11.78Z" fill="%23CFD2D6"/></g><defs><clipPath id="clip0_35168_5084"><rect width="16" height="16" fill="white" transform="translate(0.5)"/></clipPath></defs></svg>');
        }

        select,
        input,
        textarea {
          background-color: c.$dark-light-input-bg;
          color: c.$dark-10;
        }

        option:first-of-type {
          color: c.$dark-10;
        }

      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: c.$dark-10;
      }

      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: c.$dark-10;
        opacity:  1;
      }

      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: c.$dark-10;
        opacity:  1;
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: c.$dark-10;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: c.$dark-10;
      }
      
      ::placeholder { /* Most modern browsers support this now. */
        color: c.$dark-10;
      }

      .contact-form__button {
          .button--primary {
            border-radius: 48px;
            color: c.$dark-default;
          }
        }
      }
    }
  }
}

.small-view {
  .contact-form__container-contact-us__root {
    .contact-form__container {
      .contact-form__form {
        .contact-form__form-container {
          select {
            option {
              max-width: 85.5%;
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .contact-form__container-contact-us__root {
    .contact-form__container {
      .contact-form__form {
        padding-inline: 72px;
      }
    }
  }
}

.large-view {
  .contact-form__container-contact-us__root {
    .contact-form__container {
      .contact-form__form {
        padding-inline: 50px;
        width: 100%;

        .contact-form__form-container {
          display: grid;
          grid-template-areas:
            "regionName inquiry"
            "firstName message"
            "lastName message"
            "email message"
            "phone captcha"
            "checkBox checkBox"
            "button button";
            column-gap: 56px;

          .region-input {
            grid-area: regionName;
            width: 100%;
          }

          .firstName-input {
            grid-area: firstName;
            width: 100%;
          }

          .lastName-input {
            width: 100%;
            grid-area: lastName;
          }

          .email-input {
            width: 100%;
            grid-area: email;
          }

          .mobilePhone-input {
            width: 100%;
            grid-area: phone;
          }

          .aboutQuestion-input {
            width: 100%;
            grid-area: inquiry;
          }

          .message-input {
            width: 100%;
            grid-area: message;

            textArea {
              height: 100%;
              min-width: 308px;
            }
          }

          .checkbox-input {
            width: 100%;
            grid-area: checkBox;
          }

          .captcha-input {
            grid-area: captcha;
          }

          .contact-form__button {
            grid-area: button;
          }
        }
      }
    }
  }
}