@use "../../global-styles/palette" as c;

.market-news {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__title-container {
        h2 {
            color: c.$dark-default;
            font-size: 48px;
            font-weight: 600;
            line-height: 56px; 
        }
    }

    &__news-container {
        display: flex;
        gap: 48px;
    }

    &__main-news {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__main-news-item {
        display: flex;
        gap: 16px;
        text-decoration: none;
    }

    &__main-text {
        width: 428px;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__main-image-container {
        img {
            display: block;
            width: 156px;
            aspect-ratio: 3 / 2;
            object-fit: cover;
            border-radius: 8px;
        }
    }

    &__main-news-title {
        h4:not(:empty) {
            color: c.$dark-default;
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
        }
    }

    &__main-news-author {
        p:not(:empty) {
            font-size: 12px;
            color: c.$dark-60;
        }
    }

    &__calendar {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 400px;
    }

    &__main-news-info {

        p {
            display: flex;
            align-items: center;

            span {
                font-size: 12px;
                line-height: 16px;
            }

            svg {
                margin: 0px 8px;
            }
        }
    }

    &__category-text {
        font-weight: 400;
    }

    &__green-text {
        font-weight: 700;
        color: c.$tm-green-120;
    }

    &__category-text {
        font-weight: 400;
        color: c.$dark-default;
    }

    &__date-text {
        font-weight: 400;
        color: c.$dark-60;
    }

    &__more-button {
        .button-v2-wrapper {
            justify-content: flex-start;
        }
    }
}

.medium-view {
    .market-news{
        max-width: 600px;
        margin: 0px auto;

        &__news-container {
            flex-direction: column;
        }

        &__main-news-title {
            h4 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &__main-news-author {
            p {
                font-size: 12px;
                line-height: 16px;
            }
        }

        &__calendar {
            width: 100%;
        }
    }
}

.small-view {
    .market-news {

        &__news-container {
            flex-direction: column;
            align-items: center;
        }

        &__main-news {
            gap: 16px;
        }

        &__main-news-item {
            flex-direction: column;
            gap: 20px;
        }

        &__main-text {
            width: 327px;
        }

        &__main-image-container {
            img {
                width: 327px;
            }
        }

        &__main-news-title {
            h4 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &__main-news-author {
            p {
                font-size: 12px;
                line-height: 16px;
            }
        }

        &__title-container {
            display: flex;
            justify-content: center;

            h2 {
                width: 327px;
                font-size: 36px;
            }
        }

        &__calendar {
            width: 100%;
        }

        &__more-button {
            .button-v2 {
                width: 100%;
            }
        }
    }
}

.medium-view,
.large-view {
    .market-news {
        &__main-news {
            margin-inline-end: auto;

        }
    }
}