@use "../../../global-styles/palette" as c;
@use "./StatsList/StatsList";

.header-with-img-stats-banner-root {
    &__image-wrapper {
        margin-bottom: 40px;

        img {
            width: 100%;
        }
    }

    &__texts-wrapper {
        h1.h1-regular {
            line-height: 43.2px;
            text-align: center;
            color: c.$pure-white;
            margin-bottom: 8px;
        }

        h5 {
            color: c.$dark-20;
            text-align: center;
        }

        .stats-wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
        }
    }
}

.medium-view {
    .header-with-img-stats-banner-root {
        &__image-wrapper {
            display: flex;
            justify-content: center;

            img {
                max-width: 389px;
            }
        }

        &__texts-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1.h1-regular {
                width: 80%;
                line-height: 56px;
                margin-bottom: 20px;
            }

            h5 {
                width: 85%;
            }

            .stats-wrapper {
                .stats-list-root {
                    &:nth-child(2) {
                        margin-inline: 40px;
                    }
                }

            }
        }
    }
}

.large-view {
    .header-with-img-stats-banner-root {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        &__image-wrapper {
            margin: 0;

            img {
                width: 544px;
                height: 387px;
            }
        }

        &__texts-wrapper {
            display: flex;
            flex-direction: column;
            align-items: start;

            h1.h1-regular {
                text-align: start;
                margin-bottom: 20px;
                line-height: 64px;
            }

            h5 {
                text-align: start;
                width: 90%;
            }

            .stats-wrapper {
                margin-top: 60px;

                .stats-list-root {
                    &:nth-child(2) {
                        margin-inline: 40px;
                    }
                }
            }
        }
    }
}