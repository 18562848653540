@use "../../global-styles/palette" as c;
@use "./OtherCard/OtherCards";

.other-accounts-variation-cards-deck_root {
  .g-container {
    .other-accounts-variation-cards-deck_cardsWrapper-2-columns>* {
      background-color: c.$neutral-accent-background;
    }

    .button-v2-wrapper {
      margin-top: 24px;
    }

    .tmx25-round-green-lightgreen {
      width: unset;
      padding: 10px 24px;
    }

    .other-accounts-variation-cards-deck_headerWrapper {
      margin-bottom: 40px;

      .other-accounts-variation-cards-deck_textsWrapper {
        h5 {
          margin: 16px 0 20px;
          text-align: center;
        }

        h3 {
          color: c.$dark-default;
        }
      }

      .other-accounts-variation-cards-deck_imgWrapper {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .other-accounts-variation-cards-deck_cardsWrapper {
      display: grid;
      row-gap: 12px;
    }
  }
}


.medium-view {
  .other-accounts-variation-cards-deck_root {
    .g-container {
      .other-accounts-variation-cards-deck_cardsWrapper {
        grid-template-columns: repeat(2, auto);
        gap: 12px;
      }
    }
  }
}

.large-view {
  .other-accounts-variation-cards-deck_root {
    .g-container {
      .other-accounts-variation-cards-deck_headerWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .other-accounts-variation-cards-deck_textsWrapper {
          h5 {
            margin: 16px 0 0;
            max-width: 500px;
          }
        }
      }

      .other-accounts-variation-cards-deck_cardsWrapper {
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
      }

      .other-accounts-variation-cards-deck_cardsWrapper-2-columns {
        grid-template-columns: repeat(2, 1fr);
      }


    }
  }
}