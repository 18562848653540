@use "../../global-styles/palette" as c;

.image-and-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h2 {
            color: c.$dark-default;
        }
    }

    &__description {
        max-width: 327px;
        color: c.$dark-80;
    }
}

.small-view {
    .image-and-text {

        &__description {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
        }

        img {
            max-width: 327px;
        }
    }
}

.medium-view {
    .image-and-text {
        flex-direction: column;
        gap: 40px;

        &__description {
            max-width: 600px;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
        }

        img {
            width: 600px;
        }
    }
}

.large-view {
    .image-and-text {
        flex-direction: row;
        gap: 56px;

        &__description {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            max-width: 536px;
        }

        img {
            max-width: 536px;
        }
    }
}