.smartphone-frame-with-media {
    &__container {
        position: relative;
        display: inline-block;
    }

    &__frame {
        position: relative;
        z-index: 1;
        display: block;
        width: 240px;
    }

    .media-viewer {
        position: absolute;
        z-index: 0;
        inset: 0;
        overflow: hidden;
        margin: 10px 15px 10px 10px;
        border-radius: 30px;

        & > * {
            position: absolute;
            inset: 0;
            object-fit: cover;
            max-width: 100%;
        }
    }
}

.large-view {
    .smartphone-frame-with-media {
        &__frame {
            width: 380px;
        }

        .media-viewer {
            margin: 16px 24px 16px 16px;
            border-radius: 28px;
        }
    }
}