@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/layout";

.videoCard {
    box-sizing: border-box;
    background-color: #f2f4f6;
    display: flex;
    flex-direction: column;
    max-width: 360px;
    height: 100%;

    &__embedded {
        position: relative;
        width: 100%;
        height: 202px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 16px;
        max-width: v.$card-standard-width; // was 'min-width'
    }

    p {
        color: c.$dark-default;
        font-weight: bold;
        margin-bottom: 8px;
    }

    &__authorCreated {
        color: c.$dark-default;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        margin-top: auto;
    }

    &__category {
        font-size: 12px;
        font-weight: 500;
        color: c.$dark-80;
        line-height: 1.33;
        display: flex;
        align-items: center;
    }

    &__lightbox-trigger {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
    }
}

.small-view,
.medium-view {
    .videoCard {
        max-width: 280px;
        padding-bottom: 24px;

        &__embedded {
            height: 157px;
            min-width: 280px;
        }
    }
}