@use "../../../global-styles/palette" as c;

$component-root-class: banner-image-background;

.#{$component-root-class} {

    &__contentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 8px;
        padding: 40px;
        border-radius: 20px;
        background: var(--background-image-url) bottom center no-repeat, linear-gradient(267deg, var(--gradient-1) 0%, var(--gradient-2) 100%);
        color: c.$pure-white;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
    }

    &__button-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    }

    .button-v2-wrapper {
        margin: 0;

        .secondary-large-wbg {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;

            &:hover {
                background-color: c.$tm-green-default;
                color: c.$dark-90;
            }
        }

        a {
            width: 100%;
        }
    }
}

.medium-view, .large-view{
.#{$component-root-class} {
    &__button-wrapper {
        flex-direction: row;
        gap: 24px;
        margin-top: 24px;
    }
}
}