@use "../../../global-styles/palette" as c;

.large-view{
  .HeroBannerWithImagesNew{
    &__image{
      display: none;
      &--desktop{
        display: block;
      }
    }
    &__container{
      position: relative;
      >.g-container {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .titleWrapper__title{
      max-width: 547px;
    }

    .titleWrapper__subtitle{
      max-width: 470px;
    }
  }
}
.medium-view {
  .HeroBannerWithImagesNew {

    .titleWrapper__title{
      max-width: 520px;
    }
  }
}

.small-view {
  .HeroBannerWithImagesNew {

    .titleWrapper__title{
      max-width: 327px;
    }
    .titleWrapper__subtitle{
      text-align: center;
    }
  }
}
.medium-view, .small-view{
  .HeroBannerWithImagesNew{
    &.HeroBannerWithImagesWrapper{
      padding-inline: 0;
    }
    &__content{
      padding-top: 32px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .titleWrapper__title{
      text-align: center;
    }

    &__stats-container{
      padding: 29px 40px;
      overflow: hidden;
    }
    &__image{
      display: block;
      &--desktop{
        display: none;
      }
      img{
        width: 100%;
      }
    }
  }
}
.HeroBannerWithImagesNew{
  position: relative;
  min-height: 400px;
  h1{
    color: c.$pure-white;
  }
  .titleWrapper__subtitle{
    color: c.$dark-20;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px; 
    width: fit-content;
  }
  &__stats-container{
    padding: 29px 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(transparent, c.$dark-default);
    .g-container{
      overflow-x: auto;
    }
  }
  &__stats{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 720px;
  }
  &__stats-item{
    flex: 1;
  }
  &__value{
    font-size: 28px;
    font-weight: 700;
    color: c.$pure-white;
  }
  &__title{
    font-size: 12px;
    font-weight: 400;
    color: c.$dark-20;
  }
  img{
    width: 100%;
    max-height: 544px;
  }
}

.medium-view, .small-view{
  .HeroBannerWithImagesNew{
    img{
      max-height: unset;
    }
  }
}

.HeroBannerWithImagesWrapper.white-background {
  background-color: c.$pure-white;

  h1 {
    color: c.$dark-default;
  }

  .titleWrapper__subtitle {
    color: c.$dark-80;
  }
}