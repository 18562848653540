@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/layout";

.newsCard {
    display: flex;
    column-gap: 16px;
    height: 104px;
    box-sizing: border-box;
    overflow: hidden;

    &__img {
        flex-shrink: 0;
        width: 156px;
        height: 100%;
        margin-bottom: 16px;
        object-fit: cover;
        border-radius: 8px;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 4px;
    }

    &__content-inner {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
    
    &__published {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        color: c.$dark-80;

        > *:not(:first-child):before {
            content: "●";
            padding-inline: 8px;
            color: rgba(135, 135, 135, 0.35);
        }
    }

    &__category {
        color: c.$dark-default;
    }

    &__cfd-trading {
        color: c.$tm-green-120;
        font-weight: 700;
    }

    &__date {
        color: c.$dark-60;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    h4 {
        @extend %clamped-text-base;

        -webkit-line-clamp: 3;
    }

    &__text {
        display: none;
    }

    &__author {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.33;
        color: c.$dark-60;
    }

    &--list {
        max-width: 1128px;
        height: 244px;
        position: relative;
        padding: 24px 96px 24px 480px;

        & > img {
            position: absolute;
            height: unset;
            left: 0;
            top:0;
            bottom: 0;
            width: 418px;
            height: 100%;
            margin: 0;
        }

        .newsCard__published {
            margin: 0;
        }

        a {
            margin: 0 0 8px 0;
        }

        h6 {
            font-size: 27px;
            line-height: 1.19;
            -webkit-line-clamp: 2;
        }

        .newsCard__text {
            display: block;

            @extend %clamped-text-base;

            -webkit-line-clamp: 2;
        }

        .newsCard__content-inner {
            margin: auto 0 0 0;
        }
    }
}

.small-view {
    .newsCard {
        flex-direction: column;
        height: unset;

        &__img {
            aspect-ratio: 3/2;
            height: unset;
            width: 100%;
        }
    }
}