@use "../../../../global-styles/palette" as c;

.single-card-root {
    display: grid;
    background-color: c.$neutral-accent-light-grey;
    border: 1px solid c.$tm-card-border-lightblue;
    border-radius: 16px;
    padding: 32px;
    height: 250px;
    align-content: space-between;

    &__imageWrapper {
        border-radius: 10.667px;
        background: c.$pure-white;
        box-shadow: 0px 2.667px 10.667px 0px rgba(0, 0, 0, 0.10);
        padding: 16px;
        height: 32px;
        width: 32px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__textsWrapper {
        height: 110px;

        h4 {
            margin-bottom: 8px;
        }

        .single-card-root__textsWrapper__subtitle {
            color: c.$dark-80;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
        }
    }

    a {
        color: c.$dark-60;
        width: max-content;

        &:hover {
            color: c.$dark-default;
        }
    }
}