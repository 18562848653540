@use "../../../global-styles/palette" as c;

.section-item__root {
    display: grid;
    gap: 16px;

    .h3-semi-bold-small {
        color: c.$pure-white;
    }

    .content-wrapper {
        ul {
            display: grid;
            gap: 22px;

            li {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: c.$pure-white;

                &::before {
                    flex-shrink: 0;
                    content: "";
                    display: block;
                    width: 32px;
                    height: 32px;
                    background-image: var(--background-image-url);
                    margin-right: 16px;
                }

                &:dir(rtl) {
                    &::before {
                        margin: 0 0 0 16px;
                    }
                }
            }
        }
    }
}

.medium-view {
    .section-item__root {
        h3.h3-semi-bold-small {
            line-height: 29px;
        }

        .content-wrapper {
            width: 90%;
        }
    }
}

.large-view {
    .section-item__root {
        h3.h3-semi-bold-small {
            font-size: 24px;
            line-height: 28.8px;
        }

        .content-wrapper {
            width: 440px;
            font-weight: 500;
        }
    }
}