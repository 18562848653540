@use "../../global-styles/palette" as c;

.subscribeRibbon{
    position: relative;
    z-index: 1;
    &__container{
        display: flex;
        justify-content: space-between;
    }
    &__widget{
        display: flex;
        align-items: center;
    }
    &__actions{
        display: flex;
        align-items: center;
    }
    &__layout{
        transition: .3s ease-in;
        margin-right: 8px;
        cursor: pointer;
        &.active{
            background-color: c.$dark-default;
        }
    }
    &__layout-options{
        padding-right: 16px;
        margin-right: 16px;
        border-right: 1px solid c.$dark-60;
        height: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
    }
    &__layout-optionsInner {
        display: flex;
        align-items: center;
    }
    &__toggle-filters{
        color: c.$pure-white;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;
        svg{
            transition: .3s ease-in;
        }
        &.active{
            svg{
                transform: rotateX(180deg);
            }
        }

        &:hover{
            background-color: c.$dark-20;
        }

        p.active{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: c.$tm-green-120;
                border: 2px solid c.$pure-white;
                border-radius: 50%;
                left: -20px;
            }
        }
    }
    /* clears the 'X' from Internet Explorer */
    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }

    &__input{
        background-image: none;
        padding-left: 16px;
        margin-right: 24px;
        outline: none;
        &--search{
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='1iuv22noia' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg clip-path='url(%23ltf7q7g71b)' fill='%236E7783'%3E%3Cpath d='M10.4 5.6a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6zm0-1.6a6.4 6.4 0 1 1 0 12.8 6.4 6.4 0 0 1 0-12.8z'/%3E%3Cpath d='M13.834 14.965a.8.8 0 0 1 1.131-1.131l4.8 4.8a.8.8 0 0 1-1.131 1.131l-4.8-4.8z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='ltf7q7g71b'%3E%3Cpath fill='%23fff' transform='translate(4 4)' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: 12px;
            background-size: 24px;
            padding-left: 40px;
        }
    }
}

.medium-view,.small-view{
    .subscribeRibbon{
        &__container,&__widget{
            flex-direction: column;
            align-items: center;
        }
        &__layout-options{
            display: none;
        }
        &__input{
            margin-right: 0;
            margin-bottom: 24px;
        }
        &__actions{
            width: 100%;
            justify-content: center;
            border-top: 1px solid c.$dark-60;
            margin-top: 24px;
            padding-top: 16px;
        }
    }
}