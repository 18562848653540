@use "../../../../global-styles/palette" as c;

.features-list__root {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 20px;
        height: 20px;
        margin-bottom: 12px;
        padding: 6px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.05);
    }

    p.feature-title {
        color: c.$pure-white;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
    }
}

.large-view {
    .features-list__root {
        flex-direction: row;
        column-gap: 8px;

        img {
            margin: 0;
        }

        p.feature-title {
            font-size: 14px;
            line-height: 21px;
            white-space: nowrap;
            font-weight: 500;
        }
    }
}