@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.search-input {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

    &__input {
        box-sizing: border-box;
        height: 44px;
        width: 100%;
        border-radius: 8px;
        border: solid 1px c.$dark-20;
        padding-left: 16px;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        // background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='jmizk30oca' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg clip-path='url(%23k0omiwei5b)' fill='%236E7783'%3E%3Cpath d='M10.4 5.6a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6zm0-1.6a6.4 6.4 0 1 1 0 12.8 6.4 6.4 0 0 1 0-12.8z'/%3E%3Cpath d='M13.834 14.966a.8.8 0 0 1 1.132-1.132l4.8 4.8a.8.8 0 0 1-1.132 1.132l-4.8-4.8z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='k0omiwei5b'%3E%3Cpath fill='%23fff' transform='translate(4 4)' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        // background-repeat: no-repeat;
        // background-position: 16px center;
        outline: none;
        &::-webkit-search-cancel-button {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23y2qswdlpka)'%3E%3Cpath d='m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636l4.95 4.95z' fill='currentColor'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='y2qswdlpka'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'%3E%3C/path%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            -webkit-appearance: none;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
  /* clears the 'X' from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
}