@use "../../global-styles/palette" as c;

.three-cols-section-media-buttons {
  display: grid;
  grid-template-columns: 1fr min-content min-content 1fr;
  grid-template-rows: repeat(6, auto);
  column-gap: 20px;
  row-gap: 60px;
  padding: 60px 24px;

  &__container {
    background-color: #F8FAFC;
    background-image: var(--background-image-url);
    background-repeat: no-repeat;
    background-position: top center;
  }

  & > * {
    text-align: center;
  }

  &__title {
    grid-column:  1 / span 4;
    grid-row: 1 / span 1;
    justify-self: center;
    width: 100%;
    max-width: 630px;
    color: c.$pure-black;
    text-align: center;
  }

  &__media-container {
    grid-column:  1 / span 4;
    grid-row: 2 / span 1;
    align-self: center;
  }

  & > &__usps-1,
  & > &__usps-2 {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: 3 / span 3;
    align-items: start;
    gap: 28px;
  }

  &__usps-1,
  & > &__usps-1 {
    grid-column: 1 / span 2;
  }

  &__usps-2,
  & > &__usps-2 {
    grid-column: 3 / span 2;
  }

  &__cta {
    grid-column: 1 / span 4;
    grid-row: 6 / span 1;
  }
}

@mixin three-cols-section-media-buttons--large-view($row-gap: 60px) {
  row-gap: $row-gap;
  column-gap: 60px;

  &__media-container {
    grid-column:  2 / span 2;
    grid-row: 2 / span 5;
  }

  &__usps-1 {
    grid-column: 1 / span 1;
    grid-row: 3 / span 3;
  }

  &__usps-2 {
    grid-column: 4 / span 1;
    grid-row: 3 / span 3;
  }

  &__cta {
    grid-column: 1 / span 4;
    grid-row: 7 / span 1;
  }
}

.small-view,
.medium-view {
  .three-cols-section-media-buttons {
    &__container {
      .g-container {
        margin-inline: 0;
      }
    }
  }
}

.large-view {
  .three-cols-section-media-buttons {
    @include three-cols-section-media-buttons--large-view;

    column-gap: 100px;
    padding: 80px 40px;

    & > .three-cols-section-media-buttons__usps-1,
    & > .three-cols-section-media-buttons__usps-2 {
        gap: 60px;
    }
  }
}

@media (min-width: 800px) and (orientation: landscape) {
  .medium-view {
    .three-cols-section-media-buttons {
      @include three-cols-section-media-buttons--large-view(60px);
    }
  }
}
