@use "../../../global-styles/palette" as c;

.list-item__root {
    display: flex;
    align-items: flex-start;

    img {
        margin-right: 20px;
    }

    &:dir(rtl) {
        img {
            margin: 0 0 0 20px;
        }
    }

    &__textsWrapper {

        h5 {
            margin-bottom: 4px;
        }

        p.list-item__root__textsWrapper__subtitle {
            color: c.$dark-80;
            font-size: 17px;
            font-weight: 400;
            line-height: 24px;
        }
    }
}


.large-view {
    .list-item__root {
        &__textsWrapper {
            p.list-item__root__textsWrapper__subtitle {
                font-size: 16px;
            }
        }
    }
}