@use "../../../../global-styles/palette" as c;

.numbered-list-card {
    background-color: c.$pure-white;
    border-radius: 40px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    hr{
        margin: 24px 0;
        width: 100%;
        border-top-color: c.$neutral-accent-default;
    }
    h4{
        margin-top: 16px;
        color: c.$dark-default;
    }
    &__list ol{
        list-style-type: decimal;
        padding-left: 20px;
        line-height: 24px;
        font-weight: 500;
        font-size: 18px;
        color: c.$dark-default;
        li{
            margin-bottom: 16px;
        }
    }
}
