@use "../../../../../global-styles/palette" as c;

.favorite-etf-card-item {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
    margin-left: 32px;
    max-width: 264px;

    img {
        max-height: 24px;
    }

    p {
        color: c.$blue-lagoon-default;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
    }
}

.medium-view, .small-view {
    .favorite-etf-card-item {
        margin-left: 24px;
        max-width: 276px;
    }
}