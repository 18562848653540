@use "../../../global-styles/palette" as c;
@use "./FeatureCard/FeatureCard";

.feature-cards-section {

    &__cards-container {
        display: grid;
        justify-content: center;
        gap: 20px;
    }

    .item0, .item1, .item2 {
        border-radius: 20px;
        

        .h3-v2dot6, .h4-v2dot6 {
            color: c.$pure-white;
        }

        p {
            font-weight: 500;
            color: #D9D9D9;
        }
    }
    .item2{
        align-items: stretch;
        &-image-container {
            flex: 1;
            aspect-ratio: 1 / 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.large-view {
    .feature-cards-section {
        width: 100%;

        .item0 {
            grid-column: 1 / 1;
            grid-row: 1 / 4;
            width: 31.25vw; //600px
            height: 570px;
            position: relative;
            overflow: hidden;
            max-width: 520px;
            padding: 40px;

            .item0-text-wrapper {
                margin-bottom: 20px;
                margin-inline-end: 40px;
                display: flex;
                position: inherit;
                z-index: 9;
            }

            .h3-v2dot6 {
                font-size: 28px;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: normal;
            }

            p {
                position: absolute;
                z-index: 1;
                top: 50px;
                max-width: 87%;
            }

            video {
                position: absolute;
                bottom: 4px;
                left: 0px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            a {
                position: absolute;
                z-index: 1;
                bottom: 40px;
                left: 40px;
                color: c.$pure-white;
                text-decoration: none;
                border-radius: 48px;
                background-color: rgba(255, 255, 255, 0.15);
                backdrop-filter: blur(6px);
                padding: 16px;
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
            }

            a:hover {
                background-color: rgba(255, 255, 255, 0.20);
            }
        }
        
        .item1 {
            grid-column: 2;
            grid-row: 1 / 1;
            width: 780px; //40.625vw
            height: 350px;
            display: flex;
            max-width: 780px;

            .h4-v2dot6 {
                font-size: 24px;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: normal;
            }

            .item1-text-wrapper {
                max-width: 350px;
                margin-top: 40px;
                margin-bottom: 40px;
                margin-inline-start: 40px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                position: relative;

                p {
                    span {
                        margin: 0px 12px;

                        svg {
                            margin-top: 5px;
                        }
                    }

                    a {
                        color: #D9D9D9;
                    }
                }

                a.item1-button {
                    height: 38px;
                    padding: 0px 16px;
                    border-radius: 48px;
                    background-color: #0F172A;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 14px;
                    color: #F1F5F9;
                    text-decoration: none;
                    position: absolute;
                    bottom: 0px;
                    display: flex;
                    align-items: center;
                }

                a.item1-button:hover {
                    background-color: #1e293b;
                    color: #fff;
                }
            }
        }
        
        .item2 {
            grid-column: 2;
            grid-row: 2 / 2;
            width: 780px; //40.625vw;
            height: 280px;
            display: flex;
            position: relative;
            max-width: 780px;

            .h4-v2dot6 {
                font-size: 24px;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: normal;
            }

            .item2-text-wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-top: 40px;
                padding-bottom: 40px;
                padding-inline-start: 40px;
                max-width: 50%;
                box-sizing: border-box;

                a.item2-button {
                    height: 38px;
                    padding: 0px 16px;
                    border-radius: 48px;
                    background-color: c.$pure-white;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 14px;
                    color: c.$dark-default;
                    text-decoration: none;
                    position: absolute;
                    bottom: 40px;
                    display: flex;
                    align-items: center;
                }

                a.item2-button:hover {
                    background-color: #E2E8F0;
                }
            }

            .dotlottie-container {
                min-width: 390px;
            }  
        }
    }
}

@media only screen and (max-width: 1600px){
    .large-view {
        .feature-cards-section {

            .g-container {
                .g-container.g-container {
                    width: unset;
                    max-width: unset;
                    min-width: unset;
                    margin: 0px 40px;
                }
            }

            .item2 {
                .dotlottie-container {
                    min-width: 320px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px){
    .large-view {
        .feature-cards-section {
            .g-container {
                margin: 0px 40px;
            }
    
            &__cards-container {
                gap: 12px;
            }
    
            .item0 {
                grid-column: 1 / 2;
                grid-row: 3 / 4;
                width: 582px;
                height: 560px;
                overflow: hidden;
            }
            
            .item1 {
                grid-column: 2 / 2;
                grid-row: 3 / 4;
                width: 350px;
                height: unset;
                flex-direction: column;
                justify-content: space-between;

                .item1-text-wrapper {
                    margin: 40px 40px 0px 40px;

                    a.item1-button {
                        position: unset;
                        width: 67px;
                    }
                }
            }
            
            .item2 {
                grid-column: 1 / 3;
                grid-row: 4 / 4;
                width: unset;
                max-width: unset;
                height: 280px;
                justify-content: space-between;
            }
        }
    }
}

.medium-view {
    .feature-cards-section {
        .g-container {
            margin: 0px 40px;
        }

        &__cards-container {
            gap: 12px;
        }

        .item0 {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            height: 560px;
            overflow: hidden;
            position: relative;

            .item0-text-wrapper {
                position: absolute;
                top: 0px;
                padding: 32px;

                .h3-v2dot6 {
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 120%;
                    margin-bottom: 20px;
                    letter-spacing: normal;
                }

            }

            a {
                position: absolute;
                bottom: 32px;
                left: 32px;
                color: c.$pure-white;
                text-decoration: none;
                border-radius: 48px;
                background-color: rgba(255, 255, 255, 0.15);
                backdrop-filter: blur(6px);
                padding: 16px;
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
            }
        }
        
        .item1 {
            grid-column: 2 / 2;
            grid-row: 3 / 4;
            width: 350px;
            height: 560px;

            .item1-text-wrapper {
                margin: 32px 25px 0px 25px;

                .h4-v2dot6 {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 140%;
                    letter-spacing: normal;
                }

                p {
                    margin-bottom: 20px;

                    span {
                        margin: 0px 12px 0px 12px;

                        svg {
                            margin-top: 5px;
                        }
                    }

                    a {
                        color: #D9D9D9;
                    }
                }

                a.item1-button {
                    height: 38px;
                    padding: 0px 16px;
                    border-radius: 48px;
                    background-color: #0F172A;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 14px;
                    color: #F1F5F9;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 75px;
                }
            }
        }
        
        .item2 {
            grid-column: 1 / 3;
            grid-row: 4 / 4;
            height: 280px;
            display: flex;
            justify-content: space-between;

            .item2-text-wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin: 40px;
                max-width: 440px;

                .h4-v2dot6 {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 140%;
                    letter-spacing: -0.48px;
                }

                a.item2-button {
                    min-height: 38px;
                    padding: 0px 16px;
                    border-radius: 48px;
                    background-color: c.$pure-white;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 14px;
                    color: c.$dark-default;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 79px;
                    margin-top: 28px;
                }
            }

            .dotlottie-container {
                min-width: 390px;
            }  
        }
    }
}

@media only screen and (max-width: 768px){
    .medium-view {
        .feature-cards-section {
            .item0 {
                height: 554px;

                .item0-text-wrapper {
                    .h3-v2dot6 {
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 140%;
                        letter-spacing: normal;
                    }
                }
            }
            
            .item1 {
                height: 554px;
                .item1-text-wrapper {
                    .h4-v2dot6 {
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 140%;
                        letter-spacing: normal;
                        margin-bottom: 12px;
                    }
                }

                img {
                    width: 320px;
                }
            }

            .item2 {
                height: 257px;
                .item2-text-wrapper {
                    .h4-v2dot6 {
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 140%;
                        letter-spacing: normal;
                    }
                }
            }
        }
    }
}

.small-view {
    .feature-cards-section {
        .g-container {
            margin: 0px auto;
        }
        .item0, .item1, .item2 {
            p {
                font-size: 14px;
                line-height: 21px;
            }
        }

        .item0 {
            width: 340px;
            height: 420px;
            position: relative;

            video {
                width: 100%;
                height: 100%;
                object-fit: fill;
                border-radius: 20px;
                position: absolute;
            }

            .item0-text-wrapper {
                position: absolute;
                top: 32px;
                left: 32px;
                position: inherit;
                z-index: 9;

                .h3-v2dot6 {
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 140%;
                    margin-bottom: 12px;
                    letter-spacing: normal;
                }

                p {
                    max-width: 276px;
                }
            }

            a {
                bottom: 32px;
                position: absolute;
                font-size: 14px;
                font-weight: 600;
                line-height: 100%;
                text-decoration: none;
                padding: 12px 20px;
                border-radius: 18px;
                background: rgba(255, 255, 255, 0.20);
                backdrop-filter: blur(6px);
                margin-left: 32px;
                color: c.$pure-white;
            }
        }
        
        .item1 {
            width: 340px;
            height: 568px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .item1-text-wrapper {
                margin: 32px 32px 0px 32px;

                .h4-v2dot6 {
                    margin-bottom: 12px;
                    font-weight: 600;
                    letter-spacing: normal;
                }

                p {
                    margin-bottom: 20px;
                    span {
                        margin: 0px 12px;

                        svg {
                            margin-top: 5px;
                        }
                    }

                    a {
                        color: #D9D9D9;
                    }
                }
            }

            a.item1-button {
                height: 38px;
                padding: 0px 16px;
                border-radius: 48px;
                background-color: #0F172A;
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                color: #F1F5F9;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 75px;
            }
        }

        .item2 {
            width: 340px;
            height: 492px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .item2-text-wrapper {
                max-width: 276px;
                padding: 32px 32px 0px 32px;

                .h4-v2dot6 {
                    margin-bottom: 12px;
                    font-weight: 600;
                    letter-spacing: normal;
                }

                p {
                    margin-bottom: 40px;
                }

                a.item2-button {
                    height: 38px;
                    padding: 0px 16px;
                    border-radius: 48px;
                    background-color: c.$pure-white;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 14px;
                    color: c.$dark-default;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 79px;
                }
            }
            .item2-image-container{
                padding: 0 16px;
                img{
                    width: 100%;
                }
            }
        }
    }
}