@use "./Items/Item";

.section-with-image-lateral-items__root {
  &__textsWrapper {
    h2 {
      margin-bottom: 32px;
    }

    .itemsWrapper {
      display: grid;
      gap: 24px;
    }
  }

  &__imageWrapper {
    margin-top: 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.medium-view {
  .section-with-image-lateral-items__root {
    &__textsWrapper {
      .itemsWrapper {
        grid-template-columns: repeat(2, auto);
      }
    }

    &__imageWrapper {
      width: 512px;
      height: 474px;
    }
  }
}

.large-view {
  .section-with-image-lateral-items__root {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 40px;

    &__textsWrapper {
      .itemsWrapper {
        grid-template-columns: repeat(2, 270px);
      }
    }

    &__imageWrapper {
      margin: 0;
    }
  }
}