@use "./VPSproviderCard/VPSproviderCard";
@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.vps-provider-card-deck {
  max-width: v.$min-content-width-large;
  padding: 0;
  margin: 0 auto;

  &__cards {
    overflow: hidden;
    padding: v.$gutter-large;
    border-radius: 40px;
    background-color: c.$pure-white;
  }

  h2 {
    margin-bottom: 32px;
    text-align: center;
    color: c.$dark-default;
  }
}

.small-view,
.medium-view {
  .vps-provider-card-deck {
    margin: 0 23px 0 25px;
  }
}