@use "../../../global-styles/palette" as c;

.countdown-banner {
    background-color: c.$dark-80;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background-image-url);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 416px;
    background-color: c.$dark-default;
    &__image-mobile{
        display: none;
    }

    &__container{
        max-width: 510px;
        margin: 0 auto;
    }
        
    .h1-regular-small {
        color: c.$pure-white;
        max-width: 744px;
        text-align: center;
        margin: 55px auto;
    }

    &__countdown-days-wrapper, &__countdown-hours-wrapper, &__countdown-minutes-wrapper, &__countdown-seconds-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__countdown-days-wrapper {
        margin-right: 32px;
    }
    &__countdown-days-text, &__countdown-hours-text, &__countdown-minutes-text, &__countdown-seconds-text {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: c.$pure-white;
        margin-bottom: 8px;
    }

    &__countdown-days-number, &__countdown-hours-number, &__countdown-minutes-number, &__countdown-seconds-number {
        font-size: 36px;
        font-weight: 600;
        border-radius: 8px;
        background-color: #D3E2E21A;
        line-height: 64px;
        padding: 0px 16px;
        color: c.$pure-white;
        font-feature-settings: "tnum";
        min-width: 46px;
        text-align: center;
    }
    &__countdown-days-number {
        width: 68px;
    }

    &__countdown-colum {
        font-size: 36px;
        font-weight: 600;
        line-height: 64px;
        margin: 20px 4px 0px 4px;
        color: c.$pure-white;
    }


    &__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__text-content {
        margin-top: 16px;
        display: flex;
        justify-content: center;

        h5 {
            color: c.$pure-white;
        }
    }

}

.medium-view {
    .countdown-banner {
        background-repeat: round;
        height: 368px;

        &__container{
            max-width: 374px;
            margin: 0;
        }
        &__countdown-days-number, &__countdown-hours-number, &__countdown-minutes-number, &__countdown-seconds-number {
            font-size: 28px;
            box-sizing: border-box;
        }
        &__countdown-days-text, &__countdown-hours-text, &__countdown-minutes-text, &__countdown-seconds-text {
            font-size: 9.5px;
        }
        .h1-regular-small {
            max-width: 600px;
            font-size: 32px;
            line-height: 40px;
            margin: 32px auto 48px auto;
        }
    }
}
.small-view {
    .countdown-banner {
        height: unset;
        background: unset;
        background-size: unset;
        background-repeat: unset;
        background-color: c.$dark-default;
        &__image-mobile{
            display: block;
            width: 100%;
        }

        .h1-regular-small {
            max-width: 327px;
            font-size: 36px;
            line-height: 48px;
            margin: 16px auto;
        }

        &__content {
            flex-wrap: wrap;
        }

        &__countdown-days-wrapper {
            margin: 0px 50% 20px;
            display: flex;
        }

        &__text-content {
            margin-bottom: 40px;
        }
    }
}