@use "../../global-styles/palette" as c;

.access-think-portal-today {
    background-color: c.$pure-white;
    --h-header: 20px;
    scroll-margin-top: calc(var(--h-header) + 2em);
    scroll-behavior: smooth;

    .g-container {
        overflow: unset;
    }

    h2, h6 {
        color: c.$dark-default;
    }

    p {
        color: c.$dark-80;
    }
}

.large-view {
    .access-think-portal-today {
        scroll-margin-top: calc(var(--h-header) + 6em);
        
        &__desktop {
            display: flex;
        }
        &__mobile {
            display: none;
        }

        &__desktop-container {
            display: flex;
            gap: 56px;
        }

        &__desktop-container-text {
            max-width: 360px;
        }

        &__desktop-container-title {
            margin-bottom: 8px;
        }

        &__desktop-container-description {
            margin-bottom: 24px;
            p {
                font-size: 18px;
                font-weight: 500;
            }
        }

        &__desktop-container-buttons {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &__desktop-container-button-m {
            display: flex;
            flex-direction: column;
            position: relative;
            
            h6 {
                background-color: c.$neutral-accent-background;
                min-height: 32px;
                width: 312px;
                text-align: center;
                padding: 16px 24px;
                border-radius: 16px;
                font-size: 24px;
                display: flex;
                position: relative;
                gap: 8px;

                &:hover {
                    cursor: pointer;
                    background-color: lightblue;
                }

                span {
                    position: absolute;
                    right: 24px;

                    svg {
                        margin-top: 5px;
                    }
                }
            }
        }

        &__desktop-container-button-w {
            display: flex;
            flex-direction: column;
            a{
                text-decoration: none;
            }
            
            h6 {
                background-color: c.$neutral-accent-background;
                min-height: 32px;
                width: 312px;
                text-align: center;
                padding: 16px 24px;
                border-radius: 16px;
                font-size: 24px;
                display: flex;
                gap: 8px;
                position: relative;

                &:hover {
                    cursor: pointer;
                    background-color: lightblue;
                }


                span {
                    position: absolute;
                    right: 24px;

                    svg {
                        margin-top: 2px;
                    }
                }
            }
        }

        &__desktop-container-image{
            width: 712px;
            height: 408px; 

            img {
                width: 100%;
            }
        }

        &__arrow, &__arrow-d {
            cursor: pointer;

            svg {
                transform: translateY(-3px) rotate(0);
                transition: transform 500ms ease-in;
            }
        }

        &__arrow-active, &__arrow-active-d {
            cursor: pointer;

            svg {
                transform: translateY(-3px) rotate(180deg);
                transition: transform 500ms ease-in;
            }
            
        }

        &__expandableSection-open {
            background-color: c.$neutral-accent-background;
            height: 124px;
            width: 360px;
            transition: max-height 500ms ease-in;
            display: flex;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            z-index: 1;
            margin-top: -16px;

            img {
                width: 96px;
                height: 96px;
                margin: 16px 16px 16px 24px;
            }

            p {
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                display: flex;
                align-items: center;
            }
        }

        &__expandableSection-open-container {
            display: flex;
        }

        &__desktop-container-button-d {
            display: flex;
            flex-direction: column;
            position: relative;
            
            h6 {
                background-color: c.$neutral-accent-background;
                min-height: 32px;
                width: 312px;
                text-align: center;
                padding: 16px 24px;
                border-radius: 16px;
                font-size: 24px;
                display: flex;
                gap: 8px;
                position: relative;

                &:hover {
                    cursor: pointer;
                    background-color: lightblue;
                }

                span {
                    position: absolute;
                    right: 24px;

                    svg {
                        margin-top: 5px;
                    }
                }
            }
        }

        &__expandableSection-open-d {
            background-color: c.$neutral-accent-background;
            height: 164px;
            width: 360px;
            transition: max-height 500ms ease-in;
            display: flex;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            align-items: center;
            flex-direction: column;
            margin-top: -16px;

            img {
                width: 96px;
                height: 96px;
                margin: 16px 16px 16px 24px;
            }

            p {
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                display: flex;
                align-items: center;
            }
        }

        &__expandableSection-open-d-buttons-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
            
            a {
                color: c.$dark-default;
                text-decoration: none;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                border-radius: 8px;
                border: 1px solid c.$dark-default;
                padding: 16px 32px;
            }
        }
    }
}

.medium-view {
    .access-think-portal-today {        
        &__desktop {
            display: none;
        }
        &__mobile {
            display: flex;
            justify-content: center;
        }

        &__mobile-container-text {
            text-align: center;

            h2 {
                margin-bottom: 8px;
            }
            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        &__mobile-container-image {
            margin: 32px 0px;
            
            width: 600px;
            height: 343.82px; 

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__mobile-container-buttons {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 16px;
        }
        &__mobile-container-button-m {
            min-width: 552px;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 16px;
            background-color: c.$neutral-accent-background;
            padding: 16px 24px;
            border-radius: 16px;
        }
        &__mobile-container-button-text {
            display: flex;
            align-items: center;
        }
        &__mobile-container-button-logos {
            display: flex;
            gap: 16px;
        }

        &__mobile-container-button-w {
            display: flex;
            flex-direction: column;
            border-radius: 16px;
            background-color: c.$neutral-accent-background;
            align-items: center;

            h4 {
                background-color: c.$neutral-accent-background;
                min-height: 32px;
                width: 312px;
                text-align: center;
                padding: 16px 24px;
                border-radius: 16px;
                font-size: 24px;
                display: flex;
                justify-content: center;
                gap: 8px;

                div {
                    svg {
                        display: none;
                    }
                }
            }

            p {
                margin-bottom: 16px;
            }
        }

        &__mobile-container-button-d {
            display: flex;
            flex-direction: column;
            border-radius: 16px;
            background-color: c.$neutral-accent-background;
            align-items: center;

            h4 {
                background-color: c.$neutral-accent-background;
                min-height: 32px;
                width: 312px;
                text-align: center;
                padding: 16px 24px;
                border-radius: 16px;
                font-size: 24px;
                display: flex;
                justify-content: center;
                gap: 8px;

                span {
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            p {
                margin-bottom: 16px;
            }
        }
    }

}

.small-view {
    .access-think-portal-today {
        &__desktop {
            display: none;
        }
        &__mobile {
            display: flex;
            justify-content: center;
        }

        &__mobile-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__mobile-container-text {
            max-width: 327px;
            text-align: center;
            margin-bottom: 32px;

            h2 {
                margin-bottom: 8px;
            }

            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        &__mobile-container-image {
            margin-bottom: 32px;

            img {
                max-width: 327px;
            }
        }

        &__mobile-container-buttons {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &__mobile-container-button-m {
            min-width: 299px;
            gap: 16px;
            background-color: c.$neutral-accent-background;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 16px;
            padding: 16px 24px;
        }

        &__mobile-container-button-text {
            display: flex;
            gap: 8px;
            
            h4 {
                white-space: nowrap;
            }
        }

        &__mobile-container-button-logos {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__mobile-container-button-w {
            min-width: 327px;
            background-color: c.$neutral-accent-background;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding: 16px 0px;

            h4 {
                display: flex;
                gap: 8px;
                white-space: nowrap;

                div {
                    svg{
                        display: none;
                    }
                }
            }
        }

        &__mobile-container-button-d {
            min-width: 327px;
            background-color: c.$neutral-accent-background;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding: 16px 0px;

            h4 {
                display: flex;
                gap: 8px;
                white-space: nowrap;
            }
        }
    }

}