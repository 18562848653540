@use "../../global-styles/palette" as c;

.card-deck-with-title-and-animation-or-image {
    margin-bottom: 40px;

    &__text-wrapper {
        margin-bottom: 40px;
        h2 {
            color: c.$dark-90;
            font-weight: 700;
            line-height: 110%;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            margin-top: 20px;
        }

        .margin-auto {
            margin: 20px auto 0px auto;
        }
    }

    &__cards-wrapper {
        display: flex;
        gap: 20px;
    }

    &__left-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 554px;
    }

    &__left-card {
        padding: 40px;
        border-radius: 20px;
    }

    &__left-card-image {
        max-height: 70px;
        display: flex;

        img, svg {
            max-height: 70px;
        }
    }

    &__left-card-text, &__right-card-text {
        max-width: 474px;

        h5 {
            font-size: 28px;
            font-weight: 600;
            line-height: 34px;
            color: c.$dark-90;
            margin-bottom: 12px;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            color: c.$dark-80;
        }
    }

    &__labels {
        display: flex;
        gap: 8px;
        margin-top: 12px;
    }

    &__label-item {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 8px 14px 8px 10px;
        border-radius: 8px;
        background-color: c.$pure-white;

        p {
            font-size: 13px;
            font-weight: 500;
            line-height: 100%;
        }
    }

    &__right-wrapper {
        padding: 40px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 554px;
        overflow: hidden;

        img {
            max-width: 554px;
            padding: 0px 40px;
        }
    }

    &__right-card-text {
        padding: 0px 40px;
    }

    &__right-card-image {
        display: flex;
        justify-content: center;

        div {
            display: flex;
        }
    }
}

.large-view {
    .card-deck-with-title-and-animation-or-image {
        .mobile-image-top {
            flex-direction: row-reverse;
        }
    
        .mobile-image-bottom {
            flex-direction: row;
        }

        &__text-wrapper {
            p {
                max-width: 744px;
            }
        }
    }
}

.medium-view {
    .card-deck-with-title-and-animation-or-image {
        .mobile-image-top {
            flex-direction: row-reverse;
        }
    
        .mobile-image-bottom {
            flex-direction: row;
        }

        &__text-wrapper {
            margin-bottom: 40px;

            h2 {
                font-size: 34px;
            }

            p {
                max-width: 664px;
            }
        }

        &__cards-wrapper {
            justify-content: space-evenly;
        }

        &__left-card {
            padding: 30px;
        }

        &__right-wrapper {
            max-width: 322px;
            min-width: unset;
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        &__right-card-image {
            div {
                img {
                    max-width: 322px;
                }
            }
        }

        &__left-card-image {
            max-height: 55px;
    
            img, svg {
                max-height: 55px;
            }
        }

        &__left-card-text, &__right-card-text {
            max-width: 262px;

            h5 {
                font-size: 22px;
                line-height: 27px;
            }

            p {
                font-size: 16px;
            }
        }

        &__right-card-text {
            padding: 0px 30px;
        }

        .centered-image {
            div {
                img {
                    max-width: 262px;
                }
            }
        }
    }
}

.small-view {
    .card-deck-with-title-and-animation-or-image {

        display: flex;
        flex-direction: column;
        align-items: center;

        &__text-wrapper {
            margin-bottom: 40px;
            max-width: 335px;

            h2 {
                font-size: 34px;
            }

            p {
                max-width: 335px;
            }
        }

        .mobile-image-top {
            flex-direction: column-reverse!important;
        }

        .mobile-image-bottom {
            flex-direction: column!important;
        }

        &__left-card {
            max-width: 322px;
            padding: 30px;
        }

        &__left-card-image {
            margin-bottom: 20px;
            max-height: 55px;
    
            img, svg {
                max-height: 55px;
            }
        }

        &__left-wrapper {
            width: unset;
        }

        &__right-wrapper {
            max-width: 322px;
            min-width: unset;
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        &__right-card-image {
            img {
                max-width: 322px;
                padding: 0px 48px;
            }
        }

        &__left-card-text, &__right-card-text {
            max-width: 262px;

            h5 {
                font-size: 22px;
                line-height: 27px;
            }

            p {
                font-size: 15.5px;
            }
        }
        
        &__right-card-text {
            padding: 0px 30px;
        }
    }
}