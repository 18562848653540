@use "../../../../global-styles/palette" as c;

.stats-list-root {
    h3.h3-bold {
        color: c.$pure-white;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        line-height: 48px;
    }

    p.caption {
        color: c.$dark-20;
        text-align: center;
    }
}

.large-view {
    h3.h3-bold {
        text-align: start;
    }
}