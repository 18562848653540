@use "../../../global-styles/palette" as v;

.optional-button-section-banner2 {
  &__flex-middle {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: start;
    justify-content: flex-start;
  }

  &__flex-middle > * {
    text-align: start;
  }

  &__image {
    width: 100%;
  }

  &__flex-outer {
    display: flex;
    grid-template-columns: 4fr 4fr;
    gap: 30px;
    border-radius: 24px;
  }

  &__button-container {
    margin-top: 16px;
  }

  &__image-container {
    border-radius: 29px;
  }
}

.large-view {
  .optional-button-section-banner2 {
    &__img-right {
      flex-direction: row-reverse;

    }

    &__button-container {
      display: flex;
      align-self: flex-start;
      justify-content: flex-start;
    }

    &__title-container {
      text-align: start;
      font-style: normal;
    }

    &__image-container {
      flex: 6;
    }

    &__flex-middle-container {
      flex: 5;
    }
  }
}

.medium-view,
.small-view {
  .optional-button-section-banner2 {

    &__title-container {
      text-align: start;
      line-height: 48px;
      font-style: normal;
      font-size: 36px;

      h3 {
        font-size: 36px;
        line-height: 48px;
      }
    }

    &__image-container-mobile {
      border-radius: 20px;
    }

    &__flex-outer {
      padding-bottom: 20px;
    }
  }
}

.medium-view {
  .optional-button-section-banner2 {
    &__title-container {
      width: 100%;
    }

    &__flex-middle {
      gap: 22px;
    }

    &__button-container {
      margin-top: -4px;
    }
  }
}

.small-view {
  .optional-button-section-banner2 {
    &__button-container {
      margin-top: -4px;
    }

    &__flex-middle {
      gap: 27px;
    }
  }
}