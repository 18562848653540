@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;


.side-navigation {
    &__title {
        // order: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        font-size: 21px;
        color: c.$dark-default;
        transition: .0s ease-in;
    }

    &__trigger{
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .0s ease-in;
    
        svg {
            min-width: 32px;
            height: 32px;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    &__link {
        padding: 8px 20px;
        border-left: 4px solid c.$dark-10;
        font-size: 16px;
        line-height: 1.5;
        color: c.$dark-80;
        text-decoration: none;

        &--opened {
            border-left: 4px solid c.$blue-lagoon-default;
            color: c.$blue-lagoon-default;
            font-weight: bold;
        }
    }

    &--expandable {

        .side-navigation__links {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s ease-out;
        }

        &.side-navigation--opened {
            .side-navigation__links {
                transition: max-height 0.5s ease-in;
                max-height: 500px;
                // overflow: visible;
            }
        
            .side-navigation__trigger {
                transform: rotateX(-180deg);
            }
        }
    }
}
