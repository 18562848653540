@use "../../global-styles/palette" as c;

.keepTraderInOrderSwiper__container {
  .g-container {
    .keepTraderInOrderSwiper {
  .CardDescription {
    h2 {
      color: c.$dark-default;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
    }

    p {
      color: c.$dark-80;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  p.keepTraderInOrderSwiper__arrowsWrapper__currentPage {
    width: fit-content;
    color: c.$dark-80;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 24px 16px;
  }

  &__arrowsWrapper {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-inline: auto;
    align-items: center;

    .arrow-prev-selector {
      width: fit-content;
      svg {
        width: 40px;
        height: 40px;
        transform: rotate(90deg);
        color: c.$dark-default;
      }
    }

    .arrow-next-selector {
      width: fit-content;
      svg {
        width: 40px;
        height: 40px;
        transform: rotate(-90deg);
        color: c.$dark-default;
      }
    }
  }

  &__arrowsWrapper:dir(rtl) {
    flex-direction: row-reverse;
  }

  &__swiperWrapper {
    .g-container {
      margin: 0;
      .keepTraderInOrderSwiperCard {
        margin-inline: auto;
        padding: 32px;
        border-radius: 32px;
        background-color: c.$pure-white;
        min-height: 310px;
        align-content: start;
        min-height: 335px;

        &__image {
          width: 264px;
          height: 180px;
        }

        &__textWrapper {
          margin-top: 24px;

          &__title {
            width: fit-content;
          }

          &__description {
            margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .keepTraderInOrderSwiper__container{
    .g-container {
      margin-right: 0;
  
      .keepTraderInOrderSwiper {
        .CardDescription {
          h2 {
            font-size: 40px;
          }
  
          p {
            width: 585px;
          }
        }
        &__swiperWrapper {
          .g-container {
            margin: 0;
            .swiper-wrapper {
              margin-inline: auto;
            }
            .keepTraderInOrderSwiperCard {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.large-view {
  .keepTraderInOrderSwiper__container{
    .g-container {
      overflow: unset;
  
      .keepTraderInOrderSwiper {
        display: grid;
        grid-auto-flow: column;
  
        .CardDescription {
          width: fit-content;
          h2 {
            width: 230px;
            font-size: 48px;
            line-height: 56px;
          }
  
          p {
            width: 360px;
          }
        }
  
        &__swiperWrapper {
          padding-left: 64px;
          width: 76%;
  
          .g-container {
            .swiper-wrapper {
              width: fit-content;
  
              .swiper-slide {
                .keepTraderInOrderSwiperCard {
                  margin: 0;
                  width: 264px;
                }
              }
            }
          }
        }
      }
    }
  }
  .description-container {
    display: grid;
    align-content: space-between;

    span {
      width: fit-content;
      height: fit-content;
      margin: 0;
      height: 56px;
    }
  }
}