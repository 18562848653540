@use "../../../../global-styles/palette" as v;

.accordion-menu {
    //display: none;
    //padding: 24px 0 15px 0;
    //background-color: v.$pure-white;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &__menu__block-links {
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        width: 168px;
    }

    &__menu__block-links__category {
        font-weight: bold;
        color: v.$dark-default;
        margin-bottom: 8px;
    }

    &__menu__block-links__category__arrow {
        display: none;
    }

    &__menu__block-links__category__items a {
        font-weight: normal;
        color: v.$dark-60;
        text-decoration: none;
        display: flex;
        margin-bottom: 8px;
        max-width: 161px;
    }
}

.small-view,
.medium-view {

    .accordion-menu {
        display: flex;
        flex-direction: column;
        gap: 0px;

        &__menu__block-links {
            width: 100%;
            border-top: 1px solid v.$dark-10;
            font-size: 12px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            width: unset;
        }

        &__menu__block-links__category {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 19px;
            margin-top: 20px;
            font-weight: bold;
            color: v.$dark-default;
        }

        &__menu__block-links__category__arrow {
            display: flex;
        }

        &__menu__block-links__category__items a {
            margin-bottom: 16px;
            max-width: none;
            font-weight: normal;
            color: v.$dark-60;
            text-decoration: none;
            display: flex;
        }
    }
}

.large-view[dir="rtl"] {
    .g-container {
        .accordion-menu__menu__block-links__category {
            text-align: right;
        }
    }
}

[dir="rtl"] {
    .accordion-menu {
        &__menu__block-links {
            text-align: right;
        }
    }
}