@use "../../../global-styles/palette" as c;

.small-view {
  .g-tablet-header__navigation {
    display: none;
  }

  .g-mobile-header__navigation {
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
  }

  .g-header__navigation-container {
    height: calc(100vh - 40px);
  }
}

.medium-view {
  .g-tablet-header__navigation {
    display: flex;
    width: 100%;
    align-items: center;
    align-self: flex-start;
    justify-content: space-between;
    padding-top: 16px;

    .optionsWrapper {
      .icon-magnifying-glass {
        padding-top: 4px;
      }

      div:first-child {
        svg:first-child:hover {
          cursor: pointer;
        }
      }
    }
  }

  .g-mobile-header__navigation {
    display: none;
  }

  .optionsWrapper {
    display: flex;
    align-items: center;

    .languageText {
      cursor: pointer;
      color: var(--sec-nav-foreground-color);
    }

    input {
      display: none;
    }
  }

  .search-active {
    .navDropdown__widget.active {
      left: calc(100% - 375px);
      margin-top: 40px;
    }

    .navDropdown__content {
      .g-container {
        height: fit-content;
        margin: 0;
        border-radius: 16px;
        box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.2);
      }

      .sectionWrapper {
        border-radius: 8px;
        width: 100%;

        .navDropdown__headerWrapper {
          border: none;
        }

        .searchWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 0 0 8px 8px;

          input {
            display: inline;
          }

          svg {
            cursor: pointer;
          }

          &__search {
            border-radius: 4px;
            border: none;
            background-color: c.$neutral-accent-background;
            height: 48px;
            width: 80%;
            background-repeat: no-repeat;
            background-position: 10px center;
            box-sizing: border-box;
          }

          &__searchIcon {
            display: none;
          }
        }
      }
    }
  }
}

.large-view {

  .g-mobile-header__navigation,
  .g-tablet-header__navigation {
    display: none;
  }

  .navigation__list>.secondary-navigation__container {
    display: none;
  }

  .search-active {
    .navDropdown__section {
      display: none;
    }
  }
}