    //BEGIN DESIGN STYLES

    $dark-hover: #11243D;
    $dark-default: #0E1D31;
    $dark-light-bg: #18273A;
    $dark-light-input-bg: #414D5D;
    $dark-90: #1A2737;
    $dark-80: #3E4A5A;
    $dark-60: #6E7783;
    $dark-40: #9FA5AD;
    $dark-20: #CFD2D6;
    $dark-10: #E7E8EA;
    $light-dark: #E1EAEA;
    $dark-background-ribbon: #244B7F;
    $dark-background-bar: #263140;
    $tm-card-border-lightblue: #CBE1E1;
    $tm-card-text-color: #BEC7D2;
    $tm-card-text-color-light: #E4E8EE;
    $light-gray-bg: #0D1D31;
    $gray-font-color: #5D6D84;
    $dark-gray-font-color: #18283D;
    $light-gray-line-color: #E6E6E6;

    $tm-green-130: #465445;
    $tm-green-120: #4BB448;
    $tm-green-default: #5EE15A;
    $tm-green-60: #9EED9C;
    $tm-green-40: #BEF3BD;
    $tm-green-20: #DFF9DE;
    $tm-green-10: #EFFCEF;
    $tm-green: #70AA1E;
    $tm-light-border-green: #8acb86;
    $tm-light-btn-hover-green: #6BE468;
    $tm-dark-btn-green: #138518;
    $tm-dark-btn-hover-green: #2CAB0F;
    $tm-dark-btn-hover-purple: #7C3AED;
    $tm-dark-btn-hover-blue: #1D4ED8;
    $tm-btn-hover-palegreen: #9DED97;
    $tm-btn-hover-darkgreen: #74E56C;
    $tm-btn-hover-lightblue: #ECF4F4;
    $tm-btn-lightpurple: #CDC7F5;
    $tm-btn-hover-lightpurple: #B9B1F1;
    $tm-complimentary2: #358FE9;

    $support-down: #D9302C;
    $support-up: #14835B;
    $error: #DF1A3C;
    $support-maintenance: #0081F9;
    $cta-hover: #115B22;
    $pure-black: #000000;
    $pure-white: #FFF;

    $neutral-accent-default: #D3E2E2;
    $neutral-accent-80: #DCE8E8;
    $neutral-accent-60: #E5EEEE;
    $neutral-accent-light-grey: #F8FBFB;
    $neutral-accent-light-grey-font: #D8E4E4;
    $neutral-accent-dark-bg-15: rgba(211, 226, 226, 0.15);
    $neutral-accent-dark-bg-10: rgba(211, 226, 226, 0.10);
    $neutral-accent-dark-bg-5: rgba(211, 226, 226, 0.05);
    $neutral-600: #475569;
    $neutral-500: #64748B;

    .color-dark-default {
        color: $dark-default;
    }

    $neutral-accent-default: #D3E2E2;
    $neutral-accent-background: #F2F6F6;
    $middle-grey: #ababab;
    $light-grey: #F9F9F9;
    $pure-white: #FFF;
    $background: #F5F6F7;
    $background-gradient: linear-gradient(#F2F2F2 53%, #F2F2F2 35.77%, #F2F2F2 53%, #F2F2F2 0%);

    $color-text-tertiary: #99A3B2;

    .color-dark-90 {
        color: $dark-90;
    }

    .color-dark-80 {
        color: $dark-80;
    }

    .color-dark-60 {
        color: $dark-60;
    }

    .color-dark-40 {
        color: $dark-40;
    }

    .color-dark-20 {
        color: $dark-20;
    }

    .color-dark-10 {
        color: $dark-10;
    }

    .color-tm-green-120 {
        color: $tm-green-120;
    }

    .color-tm-green-default {
        color: $tm-green-default;
    }

    .color-tm-green-60 {
        color: $tm-green-60;
    }

    .color-tm-green-40 {
        color: $tm-green-40;
    }

    .color-tm-green-20 {
        color: $tm-green-20;
    }

    .color-tm-green-10 {
        color: $tm-green-10;
    }

    .color-support-down {
        color: $support-down;
    }

    .color-support-up {
        color: $support-up;
    }

    .color-support-maintenance {
        color: $support-maintenance;
    }

    .color-complimentary2 {
        color: $tm-complimentary2;
    }

    .color-error {
        color: $error;
    }

    .color-cta-hover {
        color: $cta-hover;
    }

    .color-pure-black {
        color: $pure-black;
    }

    .color-pure-white {
        color: $pure-white;
    }

    .bg-color-dark-default {
        background-color: $dark-default;
    }

    .bg-color-dark-80 {
        background-color: $dark-80;
    }

    .bg-color-dark-60 {
        background-color: $dark-60;
    }

    .bg-color-dark-40 {
        background-color: $dark-40;
    }

    .bg-color-dark-20 {
        background-color: $dark-20;
    }

    .bg-color-dark-10 {
        background-color: $dark-10;
    }

    .bg-color-dark-background-ribbon {
        background-color: $dark-background-ribbon;
    }

    .bg-color-tm-green-120 {
        background-color: $tm-green-120;
    }

    .bg-color-tm-green-default {
        background-color: $tm-green-default;
    }

    .bg-color-tm-green-60 {
        background-color: $tm-green-60;
    }

    .bg-color-tm-green-40 {
        background-color: $tm-green-40;
    }

    .bg-color-tm-green-20 {
        background-color: $tm-green-20;
    }

    .bg-color-tm-green-10 {
        background-color: $tm-green-10;
    }

    .bg-color-support-down {
        background-color: $support-down;
    }

    .bg-color-support-up {
        background-color: $support-up;
    }

    .bg-color-error {
        background-color: $error;
    }

    .bg-color-cta-hover {
        background-color: $cta-hover;
    }

    .bg-color-pure-black {
        background-color: $pure-black;
    }

    .bg-color-pure-white {
        background-color: $pure-white;
    }

    .bg-color-neutral-accent-default {
        background-color: $neutral-accent-default;
    }

    .bg-color-neutral-accent-80 {
        background-color: $neutral-accent-80;
    }

    .bg-color-neutral-accent-60 {
        background-color: $neutral-accent-60;
    }

    .bg-color-neutral-accent-background {
        background-color: $neutral-accent-background;
    }

    .bg-color-neutral-accent-light-grey {
        background-color: $neutral-accent-light-grey;
    }

    .bg-color-neutral-accent-dark-bg-15 {
        background-color: $neutral-accent-dark-bg-15;
    }

    .bg-color-neutral-accent-dark-bg-10 {
        background-color: $neutral-accent-dark-bg-10;
    }

    .bg-color-neutral-accent-dark-bg-5 {
        background-color: $neutral-accent-dark-bg-5;
    }

    //END DESIGN STYLES

    $blue-lagoon-default: #007C8C;
    $blue-lagoon-160: #004A54;
    $blue-lagoon-120: #096470;
    $blue-lagoon-60: #66B0BA;
    $blue-lagoon-40: #99CAD1;
    $blue-lagoon-20: #E4F1F2;

    $topaz-default: #14AEAE;
    $topaz-160: #084545;
    $topaz-120: #108B8B;
    $topaz-60: #72CFCF;
    $topaz-40: #A1DEDE;
    $topaz-10: #E7F7F7;

    $mint-green-default: #22F47C;
    $mint-green-160: #0E6131;
    $mint-green-120: #1BC363;
    $mint-green-60: #7AF9B1;
    $mint-green-40: #A7FACA;
    $mint-green-10: #E8FEF2;


    $light-grey-font: #C3CBD5;
    $middle-label-grey: #D7DDE4;
    $background-gradient: linear-gradient(#F2F2F2 53%, #F2F2F2 35.77%, #F2F2F2 53%, #F2F2F2 0%);

    $background-gray: #F0EEFC;
    $tm-green-160: #2D440C;
    $button-hover-effect: #6f5ae0;
    $grey-card-border: #EDEDED;
    $light-green-button-bg: #C4F4C3;
    $light-green-bg: #EFFCEE;
    $button-hover-grey-effect: #F5F5F5;
    $button-border-color: #E4E4E4;
    $buyColor: #F84948;
    $sellColor: #05D588;