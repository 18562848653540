@use "../../global-styles/palette" as v;

.big-text-section {
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__title-container {
        display: flex;
        justify-content: center;
    }

    &__button-container {
        .ghost-medium-wbg {
            border: 1px solid v.$dark-20;
            color: v.$dark-90;
            font-size: 14px;
            font-weight: 700;
            padding: 12px 20px 12px 20px;
            border-radius: 8px;
            span {
                margin: 0px 0px 3px 4px;
            }
        }

        .ghost-medium-wbg:hover {
            background-color: v.$dark-10;
            border-color: v.$dark-20;
            color: v.$dark-90;
        }
    }

    .chevronUp {
        -moz-transform: scaleY(1);
        -o-transform: scaleY(1);
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        display: flex;
        align-items: center;
    }

    .chevronDown {
        -moz-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        display: flex;
        align-items: center;
    }
}

.medium-view {
    .big-text-section {
        max-width: 600px;
    }
}

.small-view {
    .big-text-section {
        max-width: 327px;

        &__button-container {
            .ghost-medium-wbg {
                width: 100%;
            }
        }
    }
}