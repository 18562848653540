.award-card-root {
    display: flex;
    justify-content: center;
    position: relative;
    background-image: var(--background-image-url);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;
    width: 323px;
    height: 253.688px;
    
    
    &__image-container {
        position: absolute;
        border-radius: 17.669px;
        width: 189.944px;
        height: 229.7px;
        box-shadow: -8.835px 12.368px 22.087px 0px rgba(0, 0, 0, 0.25), 0px 0px 3.534px 0px rgba(0, 0, 0, 0.25);

        img {
            width: 100%;
        }
    }
}

.medium-view {
    .award-card-root {
        width: 320px;
        bottom: 17px;

        &__image-container {
            width: 190px;
            height: 230px;
        }
    }
}

.large-view {
    .award-card-root {
        width: 400px;

        &__image-container {
            width: 215px;
            bottom: 56px;
        }
    }
}