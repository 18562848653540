@use "../../../global-styles/palette" as c;

.image-toggle {
    --toggle-switch-flip-on: translateX(100%);
    --toggle-switch-flip-off: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;

    &,
    & * {
        box-sizing: border-box;
    }


    &__image-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        max-height: 100%;

        & > * {
            position: absolute;
            inset: 0;
            display: flex;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__main-image {
        img {
            transform-origin: top right;
        }
    }

    &__toggled-image {
        img {
            opacity: 0;
        }

    }

    &--activate-animations {
        .image-toggle__main-image {
            img {
                animation: 300ms mainImageIn;
                clip-path: inset(0 0 0 0);
            }
        }

        .image-toggle__toggled-image {
            img {
                animation: 500ms toggledImageOut;
            }
        }
    }

    &--toggled {
        .image-toggle__toggled-image {
            img {
                animation: 500ms toggledImageIn;
                opacity: 1;
            }
        }

        .image-toggle__main-image {
            img {
                animation: 300ms mainImageOut;
                clip-path: inset(0 50% 0 50%);
            } 
        }
    }

    img {
        display: block;
        border-radius: 12px;
        border: 3px solid;
        border-color: c.$dark-90;
    }

    &__control {
        --control-padding: 4px;

        position: relative;
        display: inline-flex;
        padding: var(--control-padding);
        border-radius: 12px;
        background: c.$neutral-accent-background;

        &::before {
            box-sizing: border-box;
            content: "";
            position: absolute;
            top: var(--control-padding);
            bottom: var(--control-padding);
            left: var(--control-padding);
            width: calc(50% - var(--control-padding));
            transition: transform 300ms ease-in;
            border-radius: 12px;
            background-color: c.$pure-white;
            transform: var(--toggle-switch-flip-off);
        }
    }

    &__control-option {
        z-index: 1;
        width: 44px;
        height: 40px;
        padding: 8px 10px;
        border-radius: 12px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }    

    &__control--toggled {
        &::before {
           transform: var(--toggle-switch-flip-on);
        }
    }

    &__control-option--main {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='20' viewBox='0 0 22 20' fill='none'%3E%3Cpath d='M8 19H9.5M9.5 19V15M9.5 19H12.5M12.5 19H14M12.5 19V15M1 1.6C1 1.26863 1.26863 1 1.6 1H20.4C20.7314 1 21 1.26863 21 1.6V14.4C21 14.7314 20.7314 15 20.4 15H1.6C1.26863 15 1 14.7314 1 14.4V1.6Z' stroke='%231A2737' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    &__control-option--toggled {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 17.5054L12.0058 17.4989' stroke='%231A2737' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 20.325V3.675C6 3.30221 6.32236 3 6.72 3H17.28C17.6777 3 18 3.30221 18 3.675V20.325C18 20.6978 17.6777 21 17.28 21H6.72C6.32236 21 6 20.6978 6 20.325Z' stroke='%231A2737' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");;
    }

    @keyframes toggledImageIn {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }

        30% {
            opacity: 1;
        }
      
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes toggledImageOut {
        0% {
            opacity: 1;
            transform: translateX(0);
        }

        30% {
            opacity: 1;
        }
      
        100% {
            opacity: 0;
            transform: translateX(-100%);
        }
    }

    @keyframes mainImageIn {
        from {
            opacity: 0;
            clip-path: inset(0 50% 0 50%);
        }

        50% {
            opacity: 0;
        }
      
        to {
            clip-path: inset(0 0 0 0);
        }
    }

    @keyframes mainImageOut {
        from {
            clip-path: inset(0 0 0 0);
        }
      
        to {
            clip-path: inset(0 50% 0 50%);
        }
    }

}

html[dir="rtl"] {
    .image-toggle {
        --toggle-switch-flip-on: none;
        --toggle-switch-flip-off: translateX(100%);
    }
}