@use "../../../global-styles/palette" as c;

.simple-banner {
    .h2-regular {
        font-weight: 700;
    }

    &__swiper-container {
        max-width: 380px;
        height: 64px;
    }

    &__image-container {
        position: relative;
    }

    &__image-container {
        background-color: c.$neutral-accent-background;
        display: flex;
        justify-content: center;
        border-radius: 20px;
        padding: 60px 50px 0px 50px;
    }

    &__text-container {
        position: absolute;
        top: 147px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title-container {

        h2 {
            color: c.$dark-90;
            font-weight: 700;
            line-height: 110%;
        }
    }

    &__description-container {
        p {
            color: c.$dark-80;
            font-size: 24px;
            font-weight: 500;
            line-height: 150%;
        }
    }
}

.medium-view {
    .simple-banner {
        display: flex;
        justify-content: center;

        &__swiper-container {
            margin-bottom: -5px;
        }

        &__image-container {
            padding: 40px 20px 0px 20px;
            max-width: 664px;

            img {
                max-width: 624px;
            }
        }

        &__text-container {
            top: 93px;
            left: 108px;
        }

        &__title-container {

            h2 {
                color: c.$dark-90;
                font-size: 34px;
            }
        }

        &__description-container {

            p {
                color: c.$dark-80;
                font-size: 18px;
            }
        }
    }
}

.small-view {
    .simple-banner {
        display: flex;
        justify-content: center;

        &__swiper-container {
            height: 50px;
            margin-bottom: -10px;
        }

        &__image-container {
            padding: 20px 20px 0px 20px;
            max-width: 295px;
            height: 199px;

            img {
                max-width: 295px;
                max-height: 103px;
            }
        }

        &__text-container {
            top: 60px;
            left: 31px;
        }

        &__title-container {
            margin-bottom: 8px;
        }

        &__description-container {
            max-width: 268px;
            text-align: center;

            p {
                color: c.$dark-80;
                font-size: 18px;
            }
        }
    }
}