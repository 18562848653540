@use "../../global-styles/palette" as c;

.itemDeckWithTitle-wrapperHorizontal {
  .g-container {
    .itemDeckWithTitle-wrapperHorizontal__grid-wrapper {
      h2 {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        margin-bottom: 24px;
      }

      &__cards-root {
        &__cards-wrapper {
          margin-bottom: 40px;
          display: grid;
          grid-template-columns: min-content auto;

          &:last-child {
            margin: 0;
          }

          img {
            width: 48px;
            height: 48px;
            margin-right: 9px;
          }

          &__texts {
            h2 {
              width: fit-content;
              font-size: 24px;
              font-weight: 600;
              line-height: 32px;
              text-align: left;
              margin-bottom: 8px;
            }

            p {
              width: fit-content;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.itemDeckWithTitle-dark-wrapperHorizontal {
  @extend .itemDeckWithTitle-wrapperHorizontal;
  .g-container {
    .itemDeckWithTitle-wrapperHorizontal__grid-wrapper {
      h2 {
        color: c.$pure-white;
      }

      &__cards-root {
        &__cards-wrapper {
          &__texts {
            h2 {
              color: c.$pure-white;
            }

            p {
              color: c.$dark-20;
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .itemDeckWithTitle-wrapperHorizontal {
    .g-container {
      .itemDeckWithTitle-wrapperHorizontal__grid-wrapper {
        h2 {
          font-size: 40px;
          line-height: 48px;
        }

        &__cards-root {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px 48px;

          &__cards-wrapper {
            margin: 0;

            &__texts {
              h2 {
                font-size: 24px;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }
}

.large-view {
  .itemDeckWithTitle-wrapperHorizontal {
    .g-container {
      .itemDeckWithTitle-wrapperHorizontal__grid-wrapper {
        h2 {
          font-size: 48px;
          line-height: 56px;
          margin-bottom: 32px;
        }

        &__cards-root {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 48px 24px;

          &__cards-wrapper {
            margin-bottom: 0px;

            img {
              width: 60px;
              height: 60px;
            }

            &__texts {
              h2 {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                text-align: left;
                margin-bottom: 8px;
              }

              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
