@use "../../global-styles/palette" as c;
@use "./CardWithImageAndTitleAnimated/CardWithImageAndTitleAnimated";

.card-deck-with-image-and-title-animated-root {
  .g-container {
    .card-deck-with-image-and-title-animated-root__container {
      &__titleWrapper {
        h2 {
          color: c.$dark-90;
          text-align: center;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 34px;
          line-height: 110%;
        }

        &--subTitle {
          color: c.$dark-80;
          width: fit-content;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
        }
      }

      &__cardsWrapper {
        display: grid;
        .card-with-image-and-title-animated {
          margin-top: 34px;
          div:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.medium-view {
  .card-deck-with-image-and-title-animated-root {
    .g-container {
      margin-inline: 40px;
      .card-deck-with-image-and-title-animated-root__container {
        &__cardsWrapper {
          display: grid;
          grid-template-columns: repeat(2, auto);
          gap: 24px;
          justify-content: space-between;
          margin-top: 37px;

          .card-with-image-and-title-animated {
            margin: 0;

            &__textWrapper {
              h5 {
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }
}

.large-view {
  .card-deck-with-image-and-title-animated-root {
    .g-container {
      max-width: 1128px;
      .card-deck-with-image-and-title-animated-root__container {
        &__titleWrapper {
          h2 {
            font-size: 48px;
            line-height: 110%;
          }

          &--subTitle {
            font-size: 18px;
            width: 670px;
            margin-inline: auto;
          }
        }

        &__cardsWrapper {
          grid-template-columns: repeat(2, auto);
          gap: 24px;
          justify-content: space-between;
          margin-top: 40px;

          .card-with-image-and-title-animated {
            margin: 0;
            max-width: 472px;

            h5 {
              font-size: 28px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%;
            }

            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
