@use "../../../../global-styles/palette" as c;

.sell-buy-price-card {
    width: 273px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #E7E7E7;
    background: c.$pure-white;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    padding: 20px;
    margin-bottom: 20px;

    &__img {
        margin-bottom: 12px;
    }

    h5 {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        color: c.$dark-90;
    }

    &__prices {
        display: flex;
        gap: 7px;
    }

    &__buy-price-wrapper, &__sell-price-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;   
    }

    p.sell-buy-price-card__price-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        margin-bottom: 8px;
    }

    &__buy-price-container, &__sell-price-container {
        width: 112px;
        display: flex;
        justify-content: center;
        padding: 8px 0px;
        border-radius: 8px;
    }

    &__buy-price-container {
        background: rgba(248, 72, 72, 0.10);
        text-decoration: none;
        color: c.$buyColor;
    }

    &__sell-price-container {
        background: rgba(5, 213, 135, 0.10);
        text-decoration: none;
        color: c.$sellColor;
    }

    p.sell-buy-price-card__buy-price, p.sell-buy-price-card__sell-price {
       font-size: 14px;
       font-weight: 600;
       line-height: 21px;
    }

    p.sell-buy-price-card__buy-price,p.sell-buy-price-card__sell-price {
        color: inherit;
    }
}

.medium-view {
    .sell-buy-price-card {
        width: 117px;
        &__prices {
            flex-direction: column;
        }

        &__buy-price-wrapper, &__sell-price-wrapper {
            flex-direction: row;
            gap: 8px;
        }

        &__buy-price-container, &__sell-price-container {
            width: 88px;
        }

        p.sell-buy-price-card__price-label {
            margin-bottom: 0px;
        }
    }
}

.small-view {
    .sell-buy-price-card {
        width: 117px;
        margin-bottom: 0px;

        &__prices {
            flex-direction: column;
        }

        &__buy-price-wrapper, &__sell-price-wrapper {
            flex-direction: row;
            gap: 8px;
        }

        &__buy-price-container, &__sell-price-container {
            width: 88px;
        }

        p.sell-buy-price-card__price-label {
            margin-bottom: 0px;
        }
    }
}