@use "../../../global-styles/palette" as c;
@use "./NumberedListImageCard/NumberedListImageCard";

.numbered-list-card-deck {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__cards{
        display: flex;
        gap: 24px;
        margin-top: 32px;
    }
    h2{
        text-align: center;
        color: c.$dark-default;
    }
    >h5{
        max-width: 744px;
        margin: 8px auto 0;
        text-align: center;
        color: c.$dark-80;
    }
}
.medium-view,.small-view{
    .numbered-list-card-deck{
        &__cards{
            flex-direction: column;
        }
    }
}