@use "../../global-styles/palette" as c;

.WideCardWithList{
  background-color: c.$neutral-accent-background;
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 24px;
  border-radius: 32px;
  &__list{
    grid-row: 1 / 3;
    grid-column: 2 / 2;
    display: flex;
    flex-direction: column;
    gap: 24px;
    li{
      background-color: c.$pure-white;
      padding: 16px 24px;
      border-radius: 12px;
    }
  }
  &__title{
    text-align: center;
    margin-bottom: 32px;
  }
  &__subtitle{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap:16px;
    h4{
      font-weight: 600;
    }
  }
  &__link{
    &.button--secondary{
      background-color: transparent;
      font-weight: 500;
    }
  }
}

.medium-view,.small-view{
  .WideCardWithList{
    display: flex;
    flex-direction: column;
    gap: 32px;
    &__link-container{
      order: 3;
      text-align: center;
    }

  }
}

.small-view{
  .WideCardWithList{
    padding: 24px;
    gap: 24px;
    &__list{
      li{
        padding: 16px;
      }
    }
  }
}