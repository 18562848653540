@use "../../../../global-styles/palette" as c;

@mixin footer-nav-section-title {
    margin-bottom: 16px;
    color: c.$pure-white;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.14;
}

.footer-nav-section {
    margin:  0;
    padding: 0;


    &__title {
        @include footer-nav-section-title;
    }

    &__links {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    &__link {
        padding: 0;
        margin: 0;
        list-style-type: none;
        color: #99A6B7;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        a,
        a:hover,
        a:link,
        a:active,
        a:visited {
            color: inherit;
            text-decoration: none;
            transition: ease 0.2s;
            word-break: break-word;
        }

        a:hover {
            color: c.$pure-white;
        }
    }
}

.small-view,
.medium-view,
.large-view {
    .footer-nav-section {
        &__title {
            @include footer-nav-section-title;
        }
    }
}