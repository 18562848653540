@use "../../global-styles/palette" as c;

.text-and-image-lp-centered_root {
    .g-container {
        .text-and-image-lp-centered_root__image-container {
            margin-bottom: 24px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .text-and-image-lp-centered_root__text-container {
            h2 {
                color: c.$dark-default;
                text-align: center;
                margin-bottom: 12px;
            }

            p {
                color: c.$dark-80;
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
            }
        }
    }
}

.medium-view {
    .text-and-image-lp-centered_root {
        .g-container {
            .text-and-image-lp-centered_root__image-container {
                margin-bottom: 40px;
            }
        }
    }
}

.large-view {
    .text-and-image-lp-centered_root {
        .g-container {
            display: grid;
            column-gap: 60px;
            grid-template-areas:
                ". imageArea"
                "textsArea imageArea"
                ". imageArea";

            .text-and-image-lp-centered_root__image-container {
                grid-area: imageArea;
                margin: 0;
                width: 520px;
            }

            .text-and-image-lp-centered_root__text-container {
                grid-area: textsArea;

                h2 {
                    text-align: left;
                }

                p {
                    text-align: left;
                }
            }
        }
    }
}