@use "../../../global-styles/palette" as c;

.category__root {
    margin-bottom: 20px;

    p {
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: start;
        color: c.$dark-60;
        margin-bottom: 12px;
    }

    .category__imagesWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }

    .centered-container &{
        .category__imagesWrapper {
            justify-content: center;
            align-items: center;
            place-content: center !important;
        }
    }
}