@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;

@use "./InformationCardDark/InformationCardDark";


.information-card-dark-deck {
    &__title-container {
        margin: 0 0 16px 0;
    }

    &__description-container p{
        color: c.$dark-80;
        margin-left: 5px;
        margin-bottom: 24px;
        font-weight: 500;
    }

    .swiper-slide {
        width: 360px;
    }
}

.medium-view, .small-view {

    .information-card-dark-deck {
        margin-bottom: 0;

        .swiper-slide {
            width: 280px;
        }
        .g-container {
            margin-right: 0px;
        }

        &__title-container {
            margin: 0 0 16px 0;
        }

        &__description-container p{
            margin-left: 0px;
            margin-bottom: 16px;
            max-width: 290px;
        }

        &__bullet{
            width: 8px;
            height: 8px;
            background-color: c.$dark-20;
            margin: 0 8px;
            border-radius: 50%;

            &.swiper-pagination-bullet-active{
                background-color: c.$dark-40;
            }
        }

        &__pagination{
            display: flex;
            justify-content: center;
            padding: 16px 0;
        }
     }
}

.small-view {
    .information-card-dark-deck {

        &__title-container {
            max-width: 360px;
        }
    }
}

.swiper-off{
    .swiper-wrapper {
        display: flex;
        gap: 24px;
        flex-flow: row wrap;
        justify-content: center;
    }
}