@use "../../../global-styles/palette" as c;

.thinkTrader-card-with-image {
    display: flex;
    gap: 57px;

    &__text-button-section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__text-container {
            max-width: 540px;

            h2 {
                margin-bottom: 12px;
            }

            &__desk-wrapper {
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
            }
        }

        &__button-container {
            margin-top: 20px;

            .button-v2-wrapper {
                justify-content: flex-start;
            }

            .secondary-medium-wbg {
                background-color: c.$dark-90;
                color: c.$pure-white;
            }

            .secondary-medium-wbg:hover {
                background-color: c.$tm-green-default;
                color: c.$dark-default;
            }

        }

        a.tmx25-round-white-gray {
            width: unset;
            padding: 10px 24px;
            background-color: var(--button-bg-color);
        }
    }

    &__image-section {
        display: flex;

        img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.withCenteredClassName {
    .thinkTrader-card-with-image__image-section {
        img {
            width: 213.788px;
            height: auto;
        }
    }

    .thinkTrader-card-with-image__text-button-section__text-container__title-wrapper {
        margin: 0;

        h2.h2-regular {
            text-align: center;
            margin: 0;
        }
    }

    .thinkTrader-card-with-image__text-button-section__text-container__desk-wrapper {
        p.copy-lp {
            text-align: center;
            color: c.$dark-80;
            width: 80%;
            margin: 20px auto 0;
        }
    }

    .thinkTrader-card-with-image__text-button-section__button-container {
        margin-inline: auto;
    }
}

.large-view {
    .image-position-right {
        flex-direction: row;
    }

    .image-position-left {
        flex-direction: row-reverse;
    }

    .withCenteredClassName {
        justify-content: start;

        .thinkTrader-card-with-image__image-section {
            margin-left: 94px;

            img {
                width: 100%;
            }
        }

        .thinkTrader-card-with-image__text-button-section__text-container__title-wrapper {
            h2.h2-regular {
                text-align: start;
            }
        }

        .thinkTrader-card-with-image__text-button-section__text-container__desk-wrapper {
            p.copy-lp {
                width: 100%;
                text-align: start;
            }
        }

        .thinkTrader-card-with-image__text-button-section__button-container {
            margin: 32px 0 0;
            width: max-content;
        }
    }
}

.medium-view, .small-view {

    .imageUP {
        flex-direction: column-reverse;
    }

    .imageDown {
        flex-direction: column;
    }

    .classButtonLeft {
        align-items: flex-start!important;
    }

    .classButtonRight {
        align-items: flex-end!important;
    }

    .classButtonCenter {
        align-items: center!important;
    }

    .thinkTrader-card-with-image {
        align-items: center;

        &__text-button-section {
            align-items: center;

            &__text-container {
                max-width: 664px;
                display: flex;
                flex-direction: column;
                align-items: start;
            }
        }
    }
}

.medium-view {
    .thinkTrader-card-with-image {
        gap: 40px;
    }

    .withCenteredClassName {
        .thinkTrader-card-with-image__image-section {
            img {
                width: 100%;
            }
        }

        .thinkTrader-card-with-image__text-button-section__text-container__desk-wrapper {
            margin-inline: auto;

            p.copy-lp {
                width: 100%;
            }
        }

        .thinkTrader-card-with-image__text-button-section__button-container {
            margin-top: 32px;
        }
    }
}

.small-view {
    .thinkTrader-card-with-image {
        gap: 24px;

        &__text-button-section {
            &__text-container {
                align-items: unset;


                &__title-wrapper {
                    text-align: start;
                }

                &__desk-wrapper {
                    text-align: start;
                }
            }
        }
    }
}