@use "../../global-styles/palette" as c;

.contact-options-root {
  .contact-options-root__itemWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 8px;
    border-radius: 24px;
    padding: 32px;
    border: 1px solid c.$pure-white;
    align-items: start;

    a {
      color: c.$dark-10;
      text-decoration: none;

      &[href^="mailto:"],
      &:first-child {
        text-decoration: underline;
      }

      h5 {
        white-space: nowrap;
      }
    }

    .still-have-questions-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        margin-inline-end: 11px;
      }
    }
  }
}

.medium-view {
  .contact-options-root {
    .contact-options-root__itemWrapper {
      flex-direction: row;
      gap: 5%;
      row-gap: 8px;

      .still-have-questions-item {
        img {
          margin-inline-end: 5px;
        }
      }
    }
  }
}

.large-view {
  .contact-options-root {
    .contact-options-root__itemWrapper {
      max-width: 189px;
    }
  }
}