@use "../../global-styles/palette" as c;

.search-banner {
    padding: 16px 0;

    &__container {
        display: flex;
        align-items: center;
        width: fit-content;
    }

    &__input {
        background-color: c.$neutral-accent-background;
        background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_15867_11697)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.9255 1.87452C9.72525 0.674284 8.09739 0 6.4 0C4.70261 0 3.07475 0.674284 1.87452 1.87452C0.674284 3.07475 0 4.70261 0 6.4C0 8.09739 0.674284 9.72525 1.87452 10.9255C3.07475 12.1257 4.70261 12.8 6.4 12.8C7.82842 12.8 9.20761 12.3225 10.3241 11.4558L14.6339 15.7656C14.7848 15.9114 14.9869 15.992 15.1967 15.9902C15.4064 15.9883 15.6071 15.9042 15.7554 15.7559C15.9037 15.6076 15.9879 15.4069 15.9897 15.1972C15.9915 14.9874 15.9109 14.7853 15.7651 14.6344L11.4554 10.3247C12.3223 9.20807 12.8 7.82866 12.8 6.4C12.8 4.70261 12.1257 3.07475 10.9255 1.87452ZM4.56312 1.96538C5.14548 1.72416 5.76966 1.6 6.4 1.6C7.67304 1.6 8.89394 2.10571 9.79411 3.00589C10.6943 3.90606 11.2 5.12696 11.2 6.4C11.2 7.67304 10.6943 8.89394 9.79411 9.79411C8.89394 10.6943 7.67304 11.2 6.4 11.2C5.76966 11.2 5.14548 11.0758 4.56312 10.8346C3.98076 10.5934 3.45161 10.2398 3.00589 9.79411C2.56017 9.34839 2.2066 8.81924 1.96538 8.23688C1.72416 7.65452 1.6 7.03034 1.6 6.4C1.6 5.76966 1.72416 5.14548 1.96538 4.56312C2.2066 3.98076 2.56017 3.45161 3.00589 3.00589C3.45161 2.56017 3.98076 2.2066 4.56312 1.96538Z" fill="%230E1D31"/></g><defs><clipPath id="clip0_15867_11697"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>');
        background-repeat: no-repeat;
        background-position: -24px center;
        background-size: 15px 15px;
        background-origin: content-box;
    }

    &__title {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: c.$pure-white;
        margin-left: 24px;
    }

    &__items {
        ul {
            display: flex;

            li {
                margin-left: 16px;

                a {
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                    color: c.$blue-lagoon-40;
                    text-decoration: none;
                }
            }
        }
    }
}
.support-search{
    &__item{
        margin-bottom: 16px;
        h5{
            font-weight: bold;
            margin-bottom: 0;
        }
        a{
            text-decoration: none;
            color: c.$blue-lagoon-default;
            font-weight: bold;
        }
    }
}
.highlight{
    background-color: #a1dede;
}

.small-view, .medium-view {
    .search-banner {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        &__input-container,
        &__input {
            width: 100%;
        }

        &__title {
            margin: 16px 0 8px 0;
        }

        &__items {
            ul {
                display: flex;
    
                li {
                    margin: 0 32px 0 0;
                    white-space: nowrap;
                }
            }
        }
    }
}