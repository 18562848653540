@use "../../../global-styles/palette" as c;

.card-deck-gradient-swiper__root {
    h3.h3-bold {
        text-align: center;
        margin-bottom: 16px;
    }

    &__swiper-view {
        .swiper-pagination {
            justify-content: center;
            display: flex;
            margin-top: 16px;

            .card-deck-gradient-swiper__bullet {
                display: block;
                width: 8px;
                height: 8px;
                background-color: c.$dark-10;
                margin: 0;
                border-radius: 50%;

                &.swiper-pagination-bullet-active {
                    background-color: c.$dark-40;
                }

                &:hover {
                    cursor: pointer;
                }

                &:nth-child(even) {
                    margin-inline: 8px;
                }
            }
        }
    }


    &__swiper-view {
        .swiper-slide {
            &:first-child {
                .single-card-root {
                    background: radial-gradient(141.61% 141.42% at 0% 0%, #322671 0%, #0E1D31 70%);
                }
            }

            &:nth-child(2) {
                .single-card-root {
                    background: radial-gradient(141.61% 141.42% at 0% 0%, #1B4470 0%, #0E1D31 70%);
                }
            }

            &:nth-child(3) {
                .single-card-root {
                    background: radial-gradient(141.61% 141.42% at 0% 0%, #416B29 0%, #0E1D31 70%);
                }
            }

            .single-card-root {
                align-content: baseline;
                height: unset;
                min-height: 378px;
                padding: 26px 32px;

                &__imageWrapper {
                    background-color: transparent;
                    border: unset;
                    box-shadow: unset;
                    width: 90px;
                    height: 90px;
                    padding: 0;
                }

                &__textsWrapper {
                    height: unset;

                    h4 {
                        margin: 20px 0 12px;
                        color: c.$pure-white;
                    }

                    .single-card-root__textsWrapper__subtitle {
                        width: 90%;
                        color: c.$dark-20;
                    }
                }
            }
        }
    }
}

.medium-view {
    .card-deck-gradient-swiper__root {
        h3.h3-bold {
            font-weight: 600;
        }

        &__swiper-view {
            .swiper-pagination {
                margin-top: 16px;
            }
        }


        &__swiper-view {
            .swiper-slide {
                .single-card-root {
                    min-height: 402px;

                    &__textsWrapper {
                        .single-card-root__textsWrapper__subtitle {
                            font-size: 16px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.large-view {
    .card-deck-gradient-swiper__root {
        &__swiper-view {
            .swiper-pagination {
                display: none;
            }
        }


        &__swiper-view {
            .swiper-slide {
                .single-card-root {
                    min-height: 354px;

                    &__textsWrapper {
                        h4 {
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 32px;
                        }

                        .single-card-root__textsWrapper__subtitle {
                            width: 100%;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}