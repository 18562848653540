@use "../../../global-styles/palette" as c;

.heroBannerWithImagesAndTitle-root {
  .g-container {
    display: grid;
    justify-items: center;
    margin: 24px 24px 64px;

    h1 {
      color: c.$pure-white;
      text-align: center;
      font-style: normal;
      width: fit-content;
    }

    .heroBannerWithImagesAndTitle-root-image {
      margin: 24px auto;
      min-width: 327px;
      min-height: 214px;
      max-width: 100%;
      max-height: 100%;
    }

    h5.heroBannerWithImagesAndTitle-root-subTitle {
      color: c.$dark-20;
      font-style: normal;
      text-align: center;
      font-weight: 500;
      margin-bottom: 24px;
    }

    h5.itemsWrapper__section__label {
      color: c.$pure-white;
      text-align: center;
      font-style: normal;
    }

    .itemsWrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
      column-gap: 32px;
      width: fit-content;

      &__section {
        display: grid;
        grid-auto-flow: column;
        width: fit-content;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.medium-view {
  .heroBannerWithImagesAndTitle-root {
    .g-container {
      margin: 32px 72px 64px;

      .heroBannerWithImagesAndTitle-root-image {
        margin: 32px 0 24px;
        min-width: 552px;
        min-height: 360px;
      }

      .itemsWrapper {
        column-gap: unset;
        grid-template-columns: unset;
        width: 100%;
        grid-auto-flow: column;
        justify-content: space-between;
        max-width: 700px;

        &__section {
          &__label {
            text-align: center;
            width: fit-content;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.large-view {
  .heroBannerWithImagesAndTitle-root {
    .g-container {
      margin: 40px auto;
      grid-template-areas:
        ". mainImage"
        "mainTitle mainImage"
        "mainSubTitle mainImage"
        "itemsWrapper mainImage"
        ". mainImage";
      column-gap: 24px;

      h1 {
        grid-area: mainTitle;
        text-align: left;
      }

      .heroBannerWithImagesAndTitle-root-image {
        grid-area: mainImage;
        margin: 0;
        width: 552px;
        height: 360px;
      }

      h5.heroBannerWithImagesAndTitle-root-subTitle {
        grid-area: mainSubTitle;
        text-align: left;
        margin: 8px 0 32px;
      }

      .itemsWrapper {
        grid-area: itemsWrapper;
        margin: 0 auto 0 0;
        column-gap: 104px;
      }
    }
  }
}
