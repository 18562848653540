@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.trust-pilot-opinion-LP-root {
  .g-container {
    h3 {
      color: c.$dark-90;
      font-size: 26px;
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 20px;
    }
    .trust-pilot-opinion-LP-root__swiperWrapper {
      .trustpilot-card {
        padding-inline: 0;
        display: grid;
        max-width: 312px;

        .trustpilot-card__title {
          color: c.$dark-90;
          min-height: 75px;

          @extend %clamped-text-base;
          -webkit-line-clamp: 3;
        }
        .trustpilot-card__rating {
          display: grid;

          .trustpilot-card__author {
            color: c.$dark-60;
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
        .trustpilot-card__review {
          color: c.$dark-80;
          height: fit-content;
          font-weight: 400;
        }
      }
    }

    .trust-pilot-opinion-LP-root__buttons-wrapper {
      display: flex;
      justify-content: space-between;

      .button-v2-wrapper {
        margin: 40px 0px 0px 0px;

        p {
          color: c.$dark-90;
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          margin-right: 12px;
        }

        .secondary-large-wbg {
          margin-right: auto;
          background-color: c.$pure-white;
          border: 1px solid c.$button-border-color;

          &:hover {
            background-color: c.$button-hover-grey-effect;
          }
        }
      }
    }
  }
}

.small-view {
  .trust-pilot-opinion-LP-root {
    .g-container {
      .trust-pilot-opinion-LP-root__swiperWrapper {
        .trustpilot-card {
          max-width: 240px;
          margin: 0px;
        }
      }
      .trust-pilot-opinion-LP-root__buttons-wrapper {
        
        .button-v2-wrapper {
          display: none;
        }

        .button-v2-wrapper:first-child {
          display: flex;
        }
      }
    }
  }
}

.medium-view {
  .trust-pilot-opinion-LP-root {
    .g-container {
      .trust-pilot-opinion-LP-root__swiperWrapper {
        .trustpilot-card {
          max-width: 210px;

          .trustpilot-card__title {
            min-height: 50px;
            -webkit-line-clamp: 2;
          }
        }
      }

      .trust-pilot-opinion-LP-root__buttons-wrapper {
        
        .button-v2-wrapper {
          display: none;
        }

        .button-v2-wrapper:first-child {
          display: flex;
        }
      }
    }
  }
}

.large-view {
  .trust-pilot-opinion-LP-root {
    .g-container {
      h3 {
        font-size: 38px;
        margin-bottom: 24px;
      }
      .trust-pilot-opinion-LP-root__swiperWrapper {
        .trustpilot-card {
          margin: 0px;

          .trustpilot-card__title {
            min-height: 20px;
            -webkit-line-clamp: 1;
          }
          .trustpilot-card__rating {
            grid-auto-flow: column;
            width: fit-content;

            .trustpilot-card__author {
              width: fit-content;
              margin-left: 8px;
            }
          }
        }

        .swiper-wrapper {
          gap: 26px;
        }
      }
    }
  }
}
