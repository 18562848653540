@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;

.squaredButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  padding: 0 24px;
  height: 40px;
  border-radius: 38px;
  text-decoration: none;
  margin: 0 auto;

  &--primary {
    background-color: c.$dark-default;
    color: c.$pure-white;
    border: 1px solid c.$pure-white;
  }

  &--primary:hover {
    background-color: c.$tm-green-120;
    border: 1px solid c.$tm-green-120;
  }

  &--secondary {
    background-color: transparent;
    color: c.$dark-default;
  } 
}
