@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/palette" as c;

.account-card{
    background-color: c.$pure-white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    box-sizing: border-box;
    &:first-child{
        margin-right: 24px;
    }
    &__title{
        padding: 32px 0;
        text-align: center;
    }
    &__section{
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        &-title{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: c.$blue-lagoon-20;
            color: c.$blue-lagoon-120;
            text-align: center;
            font-weight: bold;
            height: 32px;
            width: 100%;
        }
        &-item{
            padding: 16px 0;
            margin: 0 40px;
            box-sizing: border-box;
        }
        &-item:not(:last-child){
            border-bottom: 1px solid c.$dark-20;
        }
        &-item-title{
            color: c.$dark-60;
            margin-bottom: 4px;
        }
        &-item-text{
            font-weight: bold;
            color: c.$dark-default;
        }
        &-item-images{
            display: flex;
        }
        &-item-image-container{
            text-align: center;
            min-width: 100px;
            margin-right: 16px;
        }
        &-item-text-image-title {
            font-weight: bold;
            color: c.$dark-80;
        }
        &-item-link{
            display: flex;
            padding: 0;
        }
    }
}

.small-view{
    .account-card{
        &__section-item-image-container{
            min-width: auto;
        }
    }
}