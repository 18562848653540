@use "../../../global-styles/palette" as v;
@use "../../../global-styles/typography";
@use "../../../global-styles/layout";

.staticBanner {
  box-sizing: border-box;
  width: 100%;
  height: 520px;

  position: relative;
  padding-bottom: 48px;
  padding-top: 24px;

  &--no-cta {
    height: 400px;

    .staticBanner__cta {
      display: none;
    }
  }

  &--small {
    height: 400px;

    .staticBanner__cta {
      margin-top: 40px;
    }

    h1,
    .staticBanner__text {
      @extend %clamped-text-base;

      -webkit-line-clamp: 1;
      line-height: normal;
    }
  }

  &__bg {
    @extend %img-as-background;
  }

  h1 {
    color: v.$pure-white;
    position: relative;
    max-width: 950px;
  }

  &__text {
    @extend %card-title--size-h5;

    color: v.$dark-20;
    position: relative;
    max-width: 750px;
    margin-top: 16px;

    a {
      text-decoration: none;
      color: v.$blue-lagoon-default;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__cta {
    display: flex;
    align-items: center;
    margin-top: 60px;

    &--hide {
      display: none;
    }

    &--show {
      display: flex;
    }
  }

  &__btn {
    padding: 14px 32px;
    border-radius: 28px;
    background-color: v.$tm-green-default;
    font-size: 21px;
    font-weight: 600;
    color: v.$pure-white;
    cursor: pointer;
    outline: none;
    border: none;
    text-decoration: none;

    &--hide {
      display: none;
    }

    &--show {
      display: block;
    }
  }

  &__link {
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;
    color: v.$blue-lagoon-40;

    .staticBanner__btn + & {
      margin-left: 40px;
    }

    &--hide {
      display: none;
    }
    &--show {
      display: block;
    }
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;

    img {
      width: 26px;
      height: 26px;
      position: relative;
    }
  }

  &__breadcrumb {
    margin-left: 5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: v.$dark-20;
  }
}

.small-view,
.medium-view {
  .staticBanner {
    height: auto;
    padding-top: 50px;

    &__bg {
      filter: brightness(40%);
    }

    h1 {
      text-align: center;
      font-weight: 700;
    }

    &__text {
      margin: 30px auto 0 auto;
      text-align: center;
    }

    &__breadcrumbs {
      display: none;
    }

    &__cta {
      flex-direction: column;
      margin-top: 30px;
    }
    &__link {
      margin-left: 0;
      margin-top: 24px;
    }
  }
}
