.footer-navigation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 32px 20px;
}

.medium-view,
.large-view {
    .footer-navigation {
        grid-template-columns: repeat(6, 1fr);
        padding-inline: 40px;
    }
}