@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;

@use "./FavoriteETFCard/FavoriteETFCard";

.favorite-etf-card-deck {
    
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;
        gap: 24px;
    }
}

.medium-view, .small-view {
    .favorite-etf-card-deck {

        &__content {
            flex-wrap: wrap;
            margin-top: 24px;
        }

        &__title {
            text-align: center;
        }
    }
}