@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;

.button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    padding: 4px 24px;
    height: 40px;
    border-radius: 4px;
    text-decoration: none;
    margin: 0 auto;
    svg{
        transition: v.$transition;
        fill: c.$pure-white;
    }
    &--primary{
        background-color: c.$tm-green-default;
        color: c.$pure-white;
        border: 1px solid c.$tm-green-default;
    }
    &--primary:hover{
        background-color: c.$tm-green-120;
        border: 1px solid c.$tm-green-120;
    }
    &--primary-blue{
        background-color: c.$blue-lagoon-default;
        color: c.$pure-white;
        border: 1px solid c.$blue-lagoon-default;
    }
    &--primary-blue:hover{
        background-color: c.$blue-lagoon-120;
        border: 1px solid c.$blue-lagoon-120;
    }
    &--secondary{
        background-color: c.$pure-white;
        color: c.$dark-default;
        border: 1px solid c.$dark-default;
        svg{
            fill: c.$dark-default;
        }
    }
    &--open{
        svg{
            transform: rotateZ(-180deg);
        }
    }
}
.navigation__item--current{
    .button svg{
        transform: rotateX(-180deg);
    }
}