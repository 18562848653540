@use "../../../global-styles/palette" as c;

.large-view{
  .HeroBannerHomepageUplift{
    &__content, &__image{
      min-width: 500px;
    }
    .titleWrapper__title{
      max-width: 547px;
    }

    .titleWrapper__subtitle{
      max-width: 510px;
    }
  }
}
.medium-view {
  .HeroBannerHomepageUplift {
    .titleWrapper__title{
      max-width: 520px;
    }
  }
}
.large-view,.medium-view,.small-view{
  .HeroBannerHomepageUplift{
    .button-v2-wrapper .ghost-large-wbg.login-button{
      border: none;
      background-color: rgba(255, 255, 255, 0.15);
      color: c.$pure-white;
      backdrop-filter: blur(6px);
      border-radius: 38px;
      &:hover {
          background-color: rgba(255, 255, 255, 0.20);
      }
    }
  }
}
.medium-view, .small-view{
  .HeroBannerHomepageUplift{
    img{
      max-height: unset;
    }
    &.HeroBannerWithImagesWrapper{
      padding-inline: 0;
    }
    &__content{
      padding-top: 32px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .titleWrapper__title{
      text-align: center;
    }
  }
}
.small-view {
  .HeroBannerHomepageUplift {
    .streak1{
      top: 0;
      height: 50vh;
      left: 0;
    }
    .streak2{
      top: 20%;
      left: 20%;
      height: 50vh;
    }
    .streak3{
      top: 50%;
      left: 0;
      height: 50vh;
    }
    &__container{
      >.g-container{
        padding: 0;
        position: unset;
      }
    }
    &__action-message{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      border-radius: 0;
      line-height: 20px;
    }
    &__content{
      padding-top: 100px;
    }
    .titleWrapper__title{
      max-width: 327px;
    }
    .titleWrapper__subtitle{
      margin-top: 12px;
      text-align: center;
    }
  }
}
.HeroBannerHomepageUplift{
  overflow: hidden;
  position: relative;
  min-height: 400px;
  &__container{
    position: relative;
    >.g-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      flex-wrap: wrap;
      padding: 0 40px;
    }
  }

  &__action-message{
    padding: 8px;
    background: rgba(23, 46, 109, 0.6);
    border: 1px solid;
    border-image-source: linear-gradient(310.93deg, rgba(37, 99, 235, 0) 68.09%, rgba(37, 99, 235, 0.7) 91.11%);
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    color: #E4EDF6;
    text-decoration: none;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 8px;
    svg{
      &:dir(rtl) {
        transform: rotateY(180deg);
      }
      height: 18px;
      width: 18px;
      path{
        fill: rgba(255, 255, 255, 0.5);
      }
    }
    .badge{
      background-color: #0060E0;
      color: c.$pure-white;
      padding: 2px 8px;
      border-radius: 30px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
    }
  }

  .buttonsWrapper{
    display: flex;
    gap: 10px;
    margin-top: 40px;
    .button-v2-wrapper{
      width: auto;
      display: flex;
      margin: 0;
      .tmx25-round-green-lightgreen{
        width: auto;
      }

      .tmx25-round-green-lightgreen:hover {
        background-color: #89EF86;
      }
    }
  }
  
  h1{
    color: c.$pure-white;
  }
  .titleWrapper__subtitle {
    color: c.$dark-20;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px; 
    width: fit-content;
    margin-top: 20px;
  }
  &__title{
    font-size: 12px;
    font-weight: 400;
    color: c.$dark-20;
  }
  img{
    width: 100%;
    max-height: 700px;
  }
}

.medium-view, .small-view{
  .HeroBannerHomepageUplift{
    &__container{
      >.g-container{
        justify-content: center;
      }
    }
  }
}

.HeroBannerHomepageUplift.white-background {
  background-color: c.$pure-white;

  h1 {
    color: c.$dark-default;
  }

  .titleWrapper__subtitle {
    color: c.$dark-80;
  }
}


.streaks-container {
  background: #0E1D31;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.streaks {
  mask-image: radial-gradient(closest-side, #fff 20%, transparent 120%);
  height: 100%;
}

.streak1 {
  opacity: 0.3;
  position: absolute;
  transform: rotate(-37deg);
  left: 0%;
  top: 20%;
  width: 95vw;
  height: 30vh;
  background: linear-gradient(-90deg, #08F 0%, #0068FF 30%, rgba(0, 104, 255, 0.5) 100%);
  mask-image: radial-gradient(closest-side, #fff 30%, transparent 100%);
  filter: blur(50px);
}

.streak2 {
  opacity: 0.35;
  position: absolute;
  transform: rotate(-40deg);
  left: 25%;
  top: 30%;
  width: 100vw;
  height: 30vh;
  background: linear-gradient(-90deg, #08F 0%, #0068FF 50%, rgba(0, 104, 255, 0.00) 100%);
  mask-image: radial-gradient(closest-side, #fff 30%, transparent 100%);
  filter: blur(50px);
}

.streak3 {
  opacity: 0.35;
  position: absolute;
  transform: rotate(-40deg);
  left: 60%;
  top: 50%;
  width: 100vw;
  height: 30vh;
  background: linear-gradient(-90deg, #08F 0%, #0068FF 30%, rgba(0, 104, 255, 0.5) 100%);
  mask-image: radial-gradient(closest-side, #fff 30%, transparent 100%);
  filter: blur(50px);
}