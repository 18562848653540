@use "../../global-styles/palette" as c;

$connector-line-offset: 20%;
$card-horizontal-spacing: 7%; // 4% 

.cardsDeckWithTitle-container {
    padding-inline: 24px;
    background-color: c.$pure-white;
    display: grid;
    justify-items: center;
    justify-content: center;
    row-gap: 32px;
    &--dark{
      background-color: c.$dark-default;

      .cardsDeckWithTitle-container__mainTitle{
        h2 {
          color: c.$pure-white;
        }  
      }

      .cardsDeckWithTitle-container__text-container {
        h5 {
            color: c.$pure-white;
        }
      }
    }

    &__comments {
      color: c.$dark-20;
    }

    &__mainTitle {
        color: c.$dark-default;
        text-align: center;
    }

    &__sectionWrapper {
        display: grid;
        grid-template-rows: min-content auto;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        grid-auto-flow: column;
        row-gap: 16px;
        margin-top: 32px;
        
        .commonBeforeStyle {
            content: "";
            position: absolute;
            width: 1px;
            height: calc(100% - 16px);
            background-color: c.$neutral-accent-default;
            left: -11.5%;
            top: 16px;
        }
    }

    &__image-container {
      display: grid;
      grid-template-columns: 1fr min-content 1fr;
      align-items: center;

      &::before,
      &::after {
        content: '';
        display: block;
      }

      &:first-of-type:not(:nth-last-child(2)),
      &:not(:first-of-type):not(:nth-last-child(2)) {
        &::after {
          height: 1px;
          margin-inline-start: $connector-line-offset;
          background-color: c.$neutral-accent-default;
        }
      }

      &:nth-last-child(2),
      &:not(:first-of-type):not(:last-of-type) {
        &::before {
          height: 1px;
          margin-inline-end: $connector-line-offset;
          background-color: c.$neutral-accent-default;
        }
      }

      img {
        width: 56px;
        height: 56px;
      }
    }

    &__text-container {
      padding: 0 $card-horizontal-spacing;
      text-align: center;

      h5 {
          color: c.$dark-default;
      }
    }

    &__comments {
      color: c.$dark-80;
      font-weight: 400;
      line-height: 24px;
      margin-top: 8px;
    }

    &__conditionalButtonsWrapper {
        display: grid;
        margin-top: 40px;
        row-gap: 24px;
        width: 70%;

        .commonStyle {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: center;
            background-color: c.$neutral-accent-default;
            height: 56px;
            border-radius: 8px;

            a {
                color: c.$dark-default;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                text-decoration-line: underline;
                margin-left: 8px;
            }
        }

        &__firstButtonWrapper {
            @extend .commonStyle;
        }

        &__secondButtonWrapper {
            @extend .commonStyle;
        }
    }

    &__second-info-container {
      
      &__info-text {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
      }

      &__info-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: c.$dark-default;

        a {
          font-weight: 600;
          color: c.$dark-default;
        }
      }

      &__info-subtitle {
        color: c.$dark-60;
      }

      &__info-text-dis {
        display: flex;
        gap: 12px;

        p {
          font-weight: 500;
        }
      }
    }
}

.small-view {
  .cardsDeckWithTitle-container {
    &__sectionWrapper {
      grid-template-columns: min-content 1fr;
      grid-auto-flow: row;
      align-content: start;
      gap: 16px;
    }

    &__image-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      &::before {
        content: none;
      }

      &::after {
        flex-grow: 1;
        width: 1px;
        height: auto;
        min-height: 16px;
        margin-block-start: 16px;
      }

      &:first-of-type:not(:nth-last-child(2)),
      &:not(:first-of-type):not(:nth-last-child(2)) {
        &::after {
          margin-inline-start: 0;
        }
      }

      img {
        width: 40px;
        height: 40px;
      }
    }

    &__text-container {
      padding: 0;
      text-align: left;
    }

    &__second-info-container {
      
      &__info-text {
        margin-top: unset;
      }
    }

    &__main-button {
      width: 100%;

      .button-v2 {
        width: 100%;
      }
    }
  }
}

.medium-view {
  .cardsDeckWithTitle-container {
    row-gap: 32px;
    &__mainTitle {

      h2 {
        width: 520px;
      }
    }

    &__conditionalButtonsWrapper {
      grid-auto-flow: column;
      justify-content: space-between;

      .commonStyle {
        width: 272px;
      }

      &__firstButtonWrapper {
        margin-right: 24px;
      }
    }

    &__second-info-container {
      
      &__info-text {
        margin-top: unset;
      }
    }
    &__comments {
      a {
        font-size: 14px;
      }
    }
  }
}

.large-view {
  .cardsDeckWithTitle-container {
    justify-content: stretch;
    padding: 0;

    &__mainTitle {

      h2 {
        margin-bottom: 24px;
      }
    }

    &__sectionWrapper {
      display: grid;
      justify-content: space-between;
      width: 80%;
      max-width: 1128px;
    }

    &__conditionalButtonsWrapper {
      margin-top: 48px;
      grid-auto-flow: column;
      width: fit-content;

      .commonStyle {
        width: 272px;
      }

      &__firstButtonWrapper {
        margin-right: 24px;
      }
    }

    &__comments {
      padding: 0px 10px;
    }
  }
}