@use "../../../global-styles/palette" as v;

$color: red;

.hello {
    color: $color;
    background-color: v.$dark-10;
}

.hello__name {
    color: v.$dark-80;
}

.large-view {
    .hello__name{
        color: blue;
    }
    .hello {
        font-size: 32px;
    }
}

.medium-view {
    .hello__name{
        color: pink;
    }
    .hello {
        font-size: 28px;
    }
}

.small-view {
    .hello {
        font-size: 22px;
    }
}