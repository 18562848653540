@use "../../global-styles/palette" as c;
@use "./WideCardWithButton/WideCardWithButton";

.two-wide-cards-with-buttons-deck {
  h2 {
    margin-bottom: 6px;
    text-align: center;
  }

  &__subtitle {
    margin-block-end: 40px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  &__cards {
    display: grid;
    gap: 24px;
  }
}

.large-view {
  .two-wide-cards-with-buttons-deck {
    h2,
    &__subtitle {
      text-align: left;
    }
  
    &__cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}


html[dir="rtl"] {
  .two-wide-cards-with-buttons-deck {
    h2,
    &__subtitle {
      text-align: right;
    }
  }
}