@use "../../global-styles/palette" as c;

.instrument-info {
    &__chart {
        --chart-margin-block: 32px;

        box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 0.78;
        margin-block: var(--chart-margin-block);
        border-radius: 12px;
        border: 1px solid c.$dark-10;
    }

    &__description {
        --description-padding-block: 24px;

        padding-block: var(--description-padding-block);
    }
}


.medium-view {
    .instrument-info {
        &__chart {
            --chart-margin-block: 40px;

            width: 100%;
            aspect-ratio: 1.44;
        }

        &__description {
            --description-padding-block: 40px;
        }
    }
}

.large-view {
    .instrument-info {
        &__chart {
            --chart-margin-block: 40px;

            width: 100%;
            aspect-ratio: 1.95;
        }

        &__description {
            --description-padding-block: 40px;
        }
    }
}