@use "../../../global-styles/palette" as c;

.thinktrader-main-banner {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 80px;
    margin: 40px 0px 80px 0px;

    &__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        &__title-wrapper {
            color: c.$pure-white;
            max-width: 1000px;
            text-align: center;
        }

        &__subtitle-wrapper {
            color: c.$dark-20;
            max-width: 1000px;
            text-align: center;
        }

        &__button-wrapper {
            .cta-large-wbg {
                gap: 12px;
            }
        }
    }

    &__image-container {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
            max-width: 1000px;
        }
    }

    &__buttons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        &__mobile-app {
            display: flex;
            gap: 12px;

            .ghost-large-wbg {
                background-color: c.$dark-80;
                color: c.$pure-white;
                gap: 12px;
                padding: 11px 20px;
                font-size: 16px;
            }

            .ghost-large-wbg:hover {
                background-color: c.$dark-80;
                color: c.$pure-white;
            }
        }

        &__web-platform {

            .secondary-large-dbg {
                background-color: c.$pure-white;
                color: c.$dark-default;
                display: flex;
                gap: 12px;
                padding: 12px 32px 12px 24px;
            }
        
            .secondary-large-dbg:hover {
                background-color: c.$tm-green-default;
            }
        }

        &__text-between-buttons {
            color: c.$dark-40;
        }
    }
}

.small-view {
    .thinktrader-main-banner {
        margin: 16px 0px 40px 0px;
        gap: 26px;

        &__text-container {

            &__subtitle-wrapper {
                p {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }

        &__image-container {
           
            img {
                max-width: 254px;
            }
        }
    }
}

.medium-view {
    .thinktrader-main-banner {
        margin: 32px 0px 50px 0px;

        &__text-container {

            &__title-wrapper {
                max-width: 664px;
            }

            &__subtitle-wrapper {
                p {
                    font-size: 18px;
                }
            }
        }

        &__image-container {
           
            img {
                max-width: 664px;
            }
        }
    }
}

.large-view {
    .thinktrader-main-banner {
        &__text-container {
            &__subtitle-wrapper {
                p {
                    font-size: 18px;
                }
            }
        }
    }
}