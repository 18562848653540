@use "../../global-styles/palette" as c;

.itemDeckWithTitle-wrapper {
  .g-container {
    .itemDeckWithTitle-wrapper__grid-wrapper {
      display: grid;

      h2 {
        text-align: center;
        font-style: normal;
        margin-bottom: 24px;
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
      }

      &__cards-root {
        &__cards-wrapper {
          display: grid;
          justify-items: center;
          row-gap: 16px;
          margin-bottom: 40px;

          &:last-child {
            margin: 0;
          }

          img {
            width: 48px;
            height: 48px;
          }

          &__texts {
            h2 {
              color: c.$dark-default;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }

            p {
              color: c.$dark-80;
              margin-top: 8px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &__button-wrapper {
    margin-top: 48px;
  }
}

.itemDeckWithTitle-dark-wrapper {
  @extend .itemDeckWithTitle-wrapper;
  .g-container {
    .itemDeckWithTitle-wrapper__grid-wrapper {
      h2 {
        color: c.$pure-white;
      }

      &__cards-root {
        &__cards-wrapper {
          &__texts {
            h4 {
              color: c.$pure-white;
            }

            p {
              color: c.$dark-20;
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .itemDeckWithTitle-wrapper {
    &,
    &.itemDeckWithTitle-wrapper--2-cols {
      --min-card-width: 300px;
    }

    &.itemDeckWithTitle-wrapper--3-cols {
      --min-card-width: 190px;
    }

    &__button-wrapper {
      margin-top: 40px;
    }
    .g-container {
      margin: 0 72px;

      .itemDeckWithTitle-wrapper__grid-wrapper {
        h2 {
          margin-bottom: 32px;
        }

        &__cards-root {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(var(--min-card-width), 1fr));
          gap: 12px;

          &__cards-wrapper {
            margin: 0;
            max-width: 184px;
            margin-top: 32px;
            justify-items: start;

            h2 {
              font-size: 18px;
              line-height: 24px;
              text-align: left;
              font-weight: 700;
            }

            p {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.large-view {
  .itemDeckWithTitle-wrapper {
    &,
    &.itemDeckWithTitle-wrapper--2-cols {
      --min-card-width: 400px;
    }

    &.itemDeckWithTitle-wrapper--3-cols {
      --min-card-width: 300px;
    }
  }

  .g-container {
    .itemDeckWithTitle-wrapper__grid-wrapper {
      &__cards-root {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(var(--min-card-width), 1fr));
        gap: 48px;

        &__cards-wrapper {
          margin: 0;

          justify-items: start;

          &__texts {
            max-width: 450px;
            h2 {
              text-align: left;
            }

            p {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.large-view[dir="rtl"] {
  .g-container {
    .itemDeckWithTitle-wrapper__grid-wrapper {
      &__cards-root {

        &__cards-wrapper {

          &__texts {

            h2 {
              text-align: right;
            }

            p {
              text-align: right;
            }
          }
        }
      }
    }
  }
}