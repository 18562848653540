@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.cards-grid-title-description-icon {
  &__card {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    box-sizing: border-box;
    border-radius: 20px;
    background: #EEF5FB;
  }

  &__card-top-strip {
    display: flex;
    justify-content: space-between;
    width: 100%;

  }

  &__card-top-strip .h3-bold-small {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  &__card-top-strip>* {
    width: 24px;
  }

  &__top-end {
    display: flex;
    gap: 30px;
    margin-bottom: 35px;
  }

  &__top-end>* {
    flex: 1;
  }

  &__cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

.small-view,
.medium-view {
  .cards-grid-title-description-icon {
    &__cards-container {
      grid-template-columns: 1fr 1fr;
    }

  }
}

.small-view {
  .cards-grid-title-description-icon {
    &__top-end {
      display: flex;
      flex-direction: column;
      gap: 15px;
      text-align: center;
    }

    &__card-top-strip {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }
  }
}