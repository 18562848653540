@use "../../global-styles/palette" as c;

.two-wide-cards-root {
  .g-container {
    .wide-card-container {
      display: block;
      background-color: c.$dark-default;
      padding: 30px 40px;
      border-radius: 32px;
      text-decoration: none;

      &:first-child {
        margin-bottom: 16px;
      }

      &:hover {
        background-color: c.$dark-hover;
      }

      &__titleWrapper {
        h4 {
          color: c.$pure-white;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          width: fit-content;
        }

        p {
          color: c.$dark-20;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          width: fit-content;
        }
      }
    }
  }
}

.medium-view {
  .two-wide-cards-root {
    .g-container {
      margin-inline: 72px;
      .wide-card-container {
        height: 80px;
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        align-items: center;

        img {
          margin-right: 16px;
        }
      }
    }
  }
}

.large-view {
  .two-wide-cards-root {
    .g-container {
      display: grid;
      grid-auto-flow: column;
      overflow: visible;

      .wide-card-container {
        padding: 40px;
        width: 100%;
        max-width: 468px;

        &:first-child {
          margin: 0 32px 0 0;
        }
      }

      .one-card-version {
        display: flex;
        gap: 16px;
        min-width: 720px;
        
        &:first-child {
          margin: 0 auto;
        }
      }
    }
  }
}
