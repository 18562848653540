@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;
@use "../../../global-styles/typography";
@use "../../../global-styles/custom-scrollbar" as scrollbar;

.click-and-play-video-card-container {
  padding: 120px 0;
  background-color: c.$neutral-accent-background;
}

.click-and-play-video-card {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 57% 1fr;
  grid-auto-rows: auto;
  gap: 32px 64px;
  max-width: v.$max-content-width;
  padding: 0;
  margin: 0 auto;

  &,
  &.click-and-play-video-card--video-left {
    grid-template-areas:
      "video text"
      "video list";
  }

  &.click-and-play-video-card--video-right {
    grid-template-areas:
      "text video"
      "list video";
  }

  header {
    grid-area: text;

    p {
      @extend %h5-semi-bold;

      margin-top: 8px;
    }
  }

  ul {
    @include scrollbar.custom-scrollbar();

    grid-area: list;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    list-style-type: none;
    padding-bottom: 10px;

    li {
      display: block;

      button {
        @extend .h4-semi-bold;
        font-size: 21px !important;
        width: 100%;
        padding: 24px;
        border-radius: 16px;
        border: none;
        background-color: transparent;
        appearance: none;
        color: c.$dark-60;
        text-align: left;
        cursor: pointer;

        &.active,
        &:hover {
          @extend .h4-bold;

          color: c.$dark-default;
          background-color: c.$neutral-accent-default;
        }
      }
    }
  }

  &__video-container {
    position: relative;
    grid-area: video;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 764px;

    &--animated {
      animation: opacity 2s;
    }

    video {
      max-width: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.small-view,
.medium-view {
  .click-and-play-video-card-container {
    padding: 64px 0;
  }

  .click-and-play-video-card {
    grid-template-columns: unset;
    grid-template-areas:
      "text"
      "video"
      "list";

    h2,
    p {
      text-align: center;
    }

    ul {
      flex-direction: row;
      overflow: auto;

      li {
        flex-shrink: 0;

        button {
          padding: 16px 17px;
        }
      }
    }
  }
}

.medium-view {
  .click-and-play-video-card {
    & > * {
      padding-right: 32px;
      padding-left: 32px;
      margin-inline: auto;
    }

  }
}

.small-view {
  .click-and-play-video-card {
    & > * {
      padding-right: 24px;
      padding-left: 24px;
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}