@use "../../global-styles/palette" as c;
@import '../../global-styles/asian-text-links';

.pressArticle {
    width: 100%;
    padding-bottom: 20px;

    > div {
        position: relative;
    }

    .ghost-medium-wbg {
        svg {
            margin-right: 8px;
        }

        &:hover {
            path {
                fill: c.$pure-white;
            }
        }
    }

    .button-v2-wrapper {
        justify-content: flex-start;
        margin: 0;
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 400px;
        position: fixed;
        top: 130px;
        right: calc(50vw - 564px);

        &--fixed {
            position: absolute;
            right: 0;
            top: unset;
            bottom: 0;
        }

        .caption-bold {
            color: c.$dark-60;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            background-color: c.$pure-white;
            border: 1px solid c.$dark-10;
            border-radius: 16px;
            transition: .3s ease-out;
            padding: 24px;
            text-decoration: none;
            color: inherit;
            margin-bottom: 24px;

            div {
                display: flex;
                flex-direction: column;
            }

            &:hover {
                background-color: c.$dark-10;
            }
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: c.$neutral-accent-background;
            padding: 24px;
            border-radius: 16px;
            gap: 8px;
            margin-top: 24px;

            :first-child {
                flex: 1;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            padding: 12px 0;
            text-decoration: none;
            color: inherit;

            &:not(:nth-last-child(1)) {
                border-bottom: 1px solid c.$dark-10;
            }
            .pressBanner__data {
                &--date:dir(rtl) {
                    margin-right: 16px;
                }
            }
        }
    }

    &__sidebar:dir(rtl) {
        left: calc(50vw - 559px);
        right: unset;
    }

    &__sidebar--fixed:dir(rtl) {
        left: 16px;
        right: unset;
    }

    &__inner {
        box-sizing: border-box;
        background-color: c.$pure-white;
        width: 664px;
    }

    &__teaserImg {
        width: 100%;
        margin-bottom: 24px;
        border-radius: 16px;
    }

    &__content {
        p {
            color: c.$dark-default;
            margin-bottom: 32px;
            line-height: 1.56;
            font-weight: 500;
            letter-spacing: normal;
        }

        h3 {
            margin-bottom: 24px;
        }

        h5 {
            margin-bottom: 8px;
            color: c.$dark-80;
            font-weight: bold;
        }

        img {
            width: 100%;
            margin-bottom: 32px;
        }

        iframe {
            border-radius: 16px;
            margin-bottom: 40px;
            width: 100%;
            height: 313px;
        }

        hr {
            margin-bottom: 16px;
        }

        a {
            text-decoration: none;
            font-weight: bold;
            color: c.$blue-lagoon-default;
        }

        ul {
            list-style: inherit;
            line-height: 1.56;
            margin: 0px 0px 20px 20px;
        }

        ol {
            list-style: decimal;
            line-height: 1.56;
            margin: 0px 0px 20px 20px;
        }

        strong {
            font-weight: bold;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-style: solid;
            margin-left: auto;
            margin-right: auto;
        }

        td,
        th {
            border: 1px solid #ddd;
            padding: 8px;
        }

        tr:nth-child(even) {
            background-color: #ddd;

            td,
            th {
                border: 1px solid #fff;
            }
        }

        tbody {
            tr {
                th {
                    text-align: center;
                }

                td.width-10 {
                    width: 10%;
                }

                td.width-40 {
                    width: 40%;
                }
            }
        }
    }

    &__content:dir(rtl) {
        ul {
            margin: 0px 20px 20px 0px;
        }

        ol {
            margin: 0px 20px 20px 0px;
        }
    }

    &__blueLink {
        color: c.$blue-lagoon-default;
        text-decoration: none;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
    }

    &__greenLink {
        display: block;
        width: fit-content;
        margin: 0 auto 32px auto;
        text-decoration: none;
        padding: 8px 24px;
        border-radius: 20px;
        background-color: c.$tm-green-default;
        color: c.$pure-white;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
    }

    &__bottom {
        margin-top: 32px;
        padding-top: 16px;
        border-top: 1px solid c.$dark-20;

        &Text {
            font-style: italic;
        }

        p {
            color: c.$dark-60;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            margin-bottom: 32px;
        }

        a {
            display: flex;
            align-items: center;
            color: c.$blue-lagoon-default;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            text-decoration: none;
        }
    }

    &__disclaimerText {
        padding-top: 24px;
        margin: 24px 0;
        border-top: 1px solid c.$neutral-accent-default;
        color: c.$dark-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42;
    }
}

.small-view,
.medium-view {
    .pressArticle {
        &__sidebar {
            width: 100%;
            position: static;
        }

        &__inner {
            width: 100%;
            padding: 0;
            margin: 0;
        }

        &__blueLink {
            font-size: 16px;
        }

        &__content {
            h3 {
                margin-bottom: 16px;
            }

            p,
            img {
                margin-bottom: 24px;
            }

            iframe {
                height: 185px;
            }
        }
    }
}

html[lang="zh-TW"],
html[lang="th-TH"] {
  .pressArticle__content {
    a {
      @include asian-text-links();
    }
  }
}