@use "../../../../global-styles/palette" as c;

.tabs-filter {

  &,
  & * {
    box-sizing: border-box;
  }

  legend {
    margin-bottom: 8px;
  }

  &__radios-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 8px;
    gap: 10px;
    height: 48px;
    overflow: hidden;
    border: 1px solid c.$dark-20;
    border-radius: 8px;
  }

  input[type="radio"] {
    position: absolute;
    z-index: -1;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
  }

  input[type="radio"]:checked {
    background-color: c.$dark-default;
  }

  input[type="radio"]:checked + span {
    color: c.$pure-white;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 16px;
    color: c.$dark-80;
    line-height: 24px;
    cursor: pointer;
    transition: .3s ease-out;
    -webkit-user-select: none;
    user-select: none;

    span {
      pointer-events: none;
    }
  }
}