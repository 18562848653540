@use "../../global-styles/palette" as c;

.section-with-gradient-and-image__root {
    position: relative;

    &__gradient-background {
        position: absolute;
        background: var(--background-image-url);
        background-repeat: no-repeat;
        inset: 0;
        background-size: 100% 80px;
        background-position: top;
    }

    h2 {
        padding-top: 40px;
        text-align: center;
        color: c.$pure-white;
    }

    div.p-regular {
        color: c.$dark-10;
        text-align: center;
        margin-top: 8px;
    }

    &__gradient-image {
        margin: 28px auto 0;
        display: flex;
        justify-content: center;
    }
}

.medium-view {
    .section-with-gradient-and-image__root {
        &__gradient-background {
            background-size: 100% 130px;
        }

        h2 {
            padding-top: 80px;
        }
    }
}

.large-view {
    .section-with-gradient-and-image__root {
        &__gradient-background {
            background-size: 744px 130px;
        }

        h2 {
            padding-top: 80px;
        }

        div.p-regular {
            color: c.$dark-20;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            max-width: 800px;
            margin-inline: auto;
            margin: 10px auto 0;
        }

        &__gradient-image {
            margin-top: 32px;
        }
    }
}