@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/typography";

@use "../../../../global-styles/custom-scrollbar" as scrollbar;

.navDropdown {
  position: fixed;
  z-index: 2;
  top: 104px;
  right: 0;
  left: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: v.$transition;

  &.active {
    height: calc(100vh - 104px);
  }

  &__content {
    display: flex;
    height: 500px;
    background-color: c.$pure-white;
    overflow: hidden;
    position: relative;
  }

  &__section {
    padding: 28px 0;
    float: left;
    box-sizing: border-box;

    &--grey {
      background-color: #f2f4f6;
      position: relative;
      height: 100%;
      padding-left: 40px;
    }
  }

  &__image {
    margin: 5px;
    width: 16px;
    height: 16px;
  }

  &__text {

    &,
    & sub {
      @extend %typography--desktop-caption;

      color: c.$dark-80;
    }

    & p {
      @extend %typography--desktop-p;

      color: c.$dark-default;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    color: c.$dark-60;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-top: 10px;
  }

  &__link {
    margin-top: 24px;
    color: c.$blue-lagoon-default;
    display: flex;
    text-decoration: none;

    svg {
      width: 16px;
    }
  }

  &__cards {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 18px;

    &--two-col {
      flex-direction: row;
      flex-wrap: wrap;
      width: fit-content;
      justify-content: start;
    }
  }

  .g-col {
    width: 100%;
    height: 100%;
  }

  .g-row {
    height: 100%;
  }

  .g-container .g-container {
    position: relative;
    height: 100%;
    z-index: 1;
    background-color: c.$pure-white;
  }

  &.navDropdown__button {
    .nav-card {
      width: 100%;
    }

    .navDropdown__content {
      .g-container.g-container--fullwidth {
        min-width: unset;
        width: fit-content;

        .g-container {
          width: 100%;

          .sectionWrapper {
            box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.2);
            margin-inline: 20px;
          }
        }
      }
    }
  }

  &.navDropdown__cols-4.navDropdown__button {
    .navDropdown__section {
      width: 100%;
    }
  }
}

.nav-card {
  border-radius: 8px;
  display: block;
  transition: v.$transition;
  background-color: c.$pure-white;
  text-decoration: none;
  box-sizing: border-box;
  width: 192px;

  &__title {
    color: c.$dark-default;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 2px;
  }

  &__text {
    margin: 4px 0;
    color: c.$dark-60;
    font-size: 12px;
    line-height: 16px;

    p.nav-card__text--desc {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      color: c.$dark-60;
      width: 175px;
      border-bottom-right-radius: 8px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
    color: c.$blue-lagoon-default;
    margin-top: 8px;
    font-weight: bold;
    text-decoration: none;

    svg {
      width: 16px;
    }
  }
}

.navDropdown__widget {
  img {
    margin-inline: 8px;
  }

  .navDropdown__cards--two-col {
    width: 100%;

    .nav-widget {
      display: flex;
      font-weight: bold;
      font-size: 15px;
      color: c.$dark-default;
      text-decoration: none;
      margin: 0;
      white-space: nowrap;
      box-sizing: border-box;
      align-items: center;
      width: fit-content;

      svg {
        visibility: hidden;
      }

      &:hover {
        background-color: c.$neutral-accent-default;

        svg {
          visibility: visible;
          transform: rotate(-90deg);
          margin-left: 13px;
        }
      }

      &__title {
        width: fit-content;
      }
    }
  }

  .nav-card {
    padding: 0;
    width: 100%;
    max-width: 264px;

    &:hover {
      background-color: unset;
    }

    &__text {
      margin: 8px 0;
    }
  }
}

.medium-view {
  .navDropdown__widget.active {
    .g-container {
      z-index: 3;
      right: 375px;
      left: calc(100% - 350px);
      margin: 0;
      padding-left: 17px;
    }
  }
}

.large-view {
  .navDropdown__widget {
    .g-container{
      overflow: visible;
    }
    .sectionWrapper {
      padding: 0 14px;
    }
    .navDropdown__cards--two-col {
      gap: 0;
    }
  }
  .navDropdown {
    &__content{
      height: inherit;
      padding: 0 50px;
    }
    &.active {
      .navDropdown__content {
        overflow-x: auto;
      }
    }

    &__widget-heading {
      color: c.$dark-default;
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: none;

      svg {
        width: 40px;
        height: 40px;
      }

      span {
        display: flex;
        cursor: pointer;
      }
    }

    &__headerWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      span:first-child {
        display: none;
      }

      h5 {
        margin: 20px 0px 0px 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      h5:dir(rtl){
        margin: 20px 24px 0px 0px;
      }

      svg {
        width: 32px;
        margin-right: 20px;
        cursor: pointer;
      }
    }

    &__back {
      display: none;
    }

    .g-row {
      justify-content: space-between;
    }

    .nav-card {
      display: flex;
      width: fit-content;
      &__textWrapper {
        width: 100%;
      }
      &__title {
        width: 100%;
        display: flex;
        white-space: nowrap;
        align-items: center;
      }

      svg {
        color: c.$middle-grey;
        margin-right: 7px;
        border-top-left-radius: 5px;
      }

      svg:last-child {
        display: none;
      }

      &:hover {
        svg:last-child {
          transform: rotate(-90deg);
          display: inline;
          color: c.$dark-default;
          margin: 0 5px 0 auto;
        }
      }
    }

    .sectionWrapper {
      --correction-to-avoid-scrollbar: 33px;

      @include scrollbar.custom-scrollbar();

      width: 100%;
      border-radius: 16px;
      border: 1px solid c.$dark-10;
      box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.2);
      margin-inline: auto;
      max-height: calc(100vh - var(--large-view-dropdown-top-offset) - var(--correction-to-avoid-scrollbar));
      height: fit-content;
      overflow-y: auto;
      background-color: c.$pure-white;
      margin-bottom: 50px;
    }

    &__section {
      padding: 0;
      float: unset;
      width: fit-content;
      margin: 20px;
    }

    &__title {
      color: c.$dark-60;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }

    &__cards {
      &--two-col {
        height: fit-content;
        overflow-y: auto;
        max-width: 720px;
        width: fit-content;
      }
    }

    .registerStyleWrapper {
      display: flex;

      .nav-card {
        display: flex;
        align-items: flex-start;

        .titleWrapper {
          display: flex;
          flex-direction: column;

          span {
            display: flex;
            align-items: center;

            p {
              display: inline;
              width: fit-content;
              color: c.$dark-default;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }

            svg {
              width: 15px;
            }
          }

          span + p {
            width: 170px;
            color: c.$dark-60;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }

      .registerStyle {
        width: fit-content;
      }
    }

    &__image {
      margin-top: 0;
    }
  }

  .navDropdown__article {
    .navDropdown__content::after {
      content: "";
      position: absolute;
      background-color: transparent;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      z-index: -1;
    }
  }

  .navDropdown__cols-4.navDropdown__article {
    .g-row--4-columns .g-col {
      max-width: 192px;

      &.wide-col {
        max-width: 360px;
      }
    }
  }

  .navDropdown__cols-3 {
    .g-row--3-columns .g-col {
      max-width: 322px;

      &.wide-col {
        max-width: 360px;
      }
    }
  }

  .navDropdown__cards--two-col {
    img {
      margin-left: 12px;
      mix-blend-mode: luminosity;
    }

    .nav-card {
      width: 230px;
      height: fit-content;
      padding: 5px 0;

      &:hover {
        background-color: c.$neutral-accent-default;

        img {
          mix-blend-mode: normal;
        }
      }
    }
  }

  .navDropdown__cards--two-col-coloured {
    @extend .navDropdown__cards--two-col;
    img {
      mix-blend-mode: normal;
    }
  }

  .navDropdown__widget {
    &.navDropdown__cols-3{
      .navDropdown__section,.navDropdown__section:nth-child(2){
        width: 30%;
        .navDropdown__cards--two-col {
          width: 100%;
          a {
            width: 100%;
          }
        }
      }
    }
    &.navDropdown__cols-2{
      .navDropdown__section,.navDropdown__section:nth-child(2){
        width: 45%;
        .navDropdown__cards--two-col {
          width: 100%;
          a {
            width: 100%;
          }
        }
      }
    }
    .navDropdown__cards--two-col {
      .nav-widget {
        width: 22%;
      }
    }

    .sectionWrapper {
      display: flex;
      float: right;
      flex-wrap: wrap;
      width: 708px;
    }

    .navDropdown {
      &__headerWrapper {
        &__heading {
          margin: 25px 0px 0px 10px;
        }
      }

      &__section {
        margin: 10px;

        p {
          font-size: 12px;
        }

        .navDropdown__cards--two-col {
          display: flex;
          gap: 9px;

          a {
            width: 165px;
          }
        }

        &:nth-child(2) {
          width: 170px;
          margin: 10px 5px 0 10px;
        }

        &:nth-child(3) {
          margin-inline: 0;
        }
      }
    }
  }
}

.medium-view,
.small-view {
  .nav-card {
    display: flex;
    width: fit-content;
    min-width: 170px;

    &:hover {
      background-color: c.$dark-10;
    }

    &__textWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;

      svg {
        display: none;
      }

      &:hover {
        svg:last-child {
          color: c.$dark-default;
          margin-right: 10px;
          display: block;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .mobile-nav-active {
    .navDropdown.active {
      left: calc(100% - 420px);
    }
  }

  .navDropdown {
    position: fixed;
    right: unset;
    left: 100%;
    top: 0;
    height: calc(100% - 5px);
    max-width: 375px;

    &__widget-heading {
      display: none;
    }

    &__cards {
      flex-direction: column;

      &--two-col {
        flex-direction: column;
      }
    }

    &__section {
      width: 100%;
      padding: 0 0 0 10px;

      &--grey {
        display: none;
      }
    }

    &__content {
      max-height: 100%;
      height: 100%;
      overflow: auto;
      height: fit-content;
    }

    &__headerWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 17px 0 0;
      height: 32px;
      border-bottom: 2px solid c.$neutral-accent-default;

      span {
        cursor: pointer;

        svg {
          transform: rotate(90deg);
        }
      }

      &__heading {
        margin: 0;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-inline: auto;
        font-style: normal;
        color: c.$dark-default;
      }
    }

    &__back {
      display: flex;
      padding: 16px;
      margin-left: 17px;
      color: c.$blue-lagoon-default;
      font-size: 16px;
      line-height: 1.5;
      font-weight: bold;
    }

    &.navDropdown__button {
      .nav-card__text {
        display: none;
      }
    }

    .g-container {
      overflow-y: auto;
      height: 100vh;
      background-color: c.$pure-white;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 3;

      .sectionWrapper {
        padding: 0 24px;
      }
    }
  }

  .nav-card {
    margin-left: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &__text {
      display: none;
    }
  }

  .g-container--fullwidth {
    width: 100%;
  }

  .g-col,
  .g-row {
    height: auto;
  }

  .navDropdown__widget {
    .nav-card {
      max-width: 100%;
    }
  }

  .registerStyleWrapper {
    display: flex;
    width: fit-content;

    .nav-card {
      svg:last-child {
        display: none;
      }

      .nav-card__text {
        display: none;
      }
    }

    .titleWrapper {
      display: flex;
    }

    .registerStyle {
      margin: 0;

      p {
        margin-left: 5px;
      }
    }
  }
}

.small-view {
  .navDropdown__widget{
    .g-container{
      margin: 0;
    }
  }
  .mobile-nav-active {
    .navDropdown.active {
      max-width: 100%;
      left: 0;
      height: fit-content;
    }

    .navDropdown__closeIcon {
      display: none;
    }
  }
}

.medium-view {
  .mobile-nav-active {
    .navDropdown.active {
      height: fit-content;
    }
  }

  .navDropdown {
    .g-container {
      .sectionWrapper {
        padding: 0;
      }
    }

    &.navDropdown__button {
      .navDropdown__content {
        .g-container.g-container--fullwidth {
          .g-container {
            margin-left: auto;
            right: 0;
            top: 0;
            margin-top: 0;
          }
        }
      }
    }
  }
}

.search-active {
  .navDropdown {
    &__backIcon {
      display: none;
    }

    &__section {
      display: none;
    }

    &__content > .g-container {
      padding-left: 0;
      background-color: transparent;
    }

    &__content .navDropdown__widget-heading + .g-container:not(:empty) {
      right: 0;
      left: auto;
      box-sizing: content-box;
      width: 328px;
      height: auto;
      padding: 24px;
      top: 50px;
    }

    &__headerWrapper {
      justify-content: space-between;
      height: auto;
      margin-top: 0;

      &__heading {
        margin-inline: 0 auto;
        text-align: start;
      }
    }

    .searchWrapper {
      justify-content: stretch;

      &__searchIcon,
      &__searchIcon * {
        color: c.$dark-default;
      }
    }

    .search-input {
      width: 100%;

      &__input {
        background-color: c.$neutral-accent-background;
      }
    }
  }
}
.large-view{
  .search-active {
    .navDropdown__content .navDropdown__widget-heading+.g-container:not(:empty) {
      width: auto;
      overflow: visible;
      padding: 0;
    }
    .navDropdown {
    &__content .navDropdown__widget-heading + .g-container:not(:empty) {
      top: 0;
    }
    }
  }
  .loginAndSearchWrapper {
    .sectionWrapper {
      width: 350px;
    }
  }

  .navDropdown {

    &__cards {
      gap: 8px;
    }
  }
  .navDropdown__widget {
    .sectionWrapper {
      max-height: unset;
      .searchWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        padding: 20px;
        margin-inline: auto;

        .search-input {
          width: 100%;
          input {
            display: inline;
            background-color: c.$neutral-accent-background;
            width: 100%;
          }
        }

        svg {
          padding-left: 10px;
        }
      }
    }
  }
}
