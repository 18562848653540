@use "./SectionItem/SectionItem";

.section-with-two-lists {
    display: grid;
    gap: 44px;
}

.large-view {
    .section-with-two-lists {
        grid-auto-flow: column;
        gap: 60px;
    }
}