@use "../../../global-styles/palette" as c;
@use "./DarkNumberedCard/DarkNumberedCard";

.dark-numbered-card-deck {
    &__links {
        padding-top: 40px;
    }
    &__link {
        display: block;
    }
    &__primaryLink {
        padding: 17px 34px;
        font-size: 21px;
        text-decoration: none;
        border-radius: 28px;
        color: c.$pure-white;
        background-color: c.$tm-green-default;
    }
    &__secondaryLink {
        font-size: 17px;
        text-decoration: none;
        color: c.$blue-lagoon-default;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
    }
    &__cards {
        margin-top: 24px;
    }
    &__title {
        color: c.$dark-default;
        // max-width: 744px;
    }
    &__subTitle {
        font-weight: 500;
        color: c.$dark-80;
        max-width: 744px;
    }
    &.title-position-left, &.title-position-right{
        .dark-numbered-card-deck__title,.dark-numbered-card-deck__subTitle{
            max-width: 744px;
            margin-left: 0;
            margin-right: 0;
        }
    }

}
.large-view {
    .dark-numbered-card-deck {
        &__title {
            padding-bottom: 8px;
            margin: 0 auto;
        }

        &__subTitle {
            margin-top: 8px;
            color: c.$dark-80;
            margin: 8px auto 0 auto;
        }

        &__links {
            display: flex;
            flex-direction: row;
            gap: 40px;
            justify-content: center;
            align-items: center;
        }
    }
}

.small-view,
.medium-view {
    .dark-numbered-card-deck {
        &__title {
            text-align: left;
            margin-bottom: 16px;
        }
        &__subTitle {
            text-align: left;
        }
        &__links {
            display: flex;
            flex-direction: column;
            gap: 32px;
            align-items: center;
        }
    }
    .dark-numbered-card-deck__cards {
        .g-container {
            margin-right: 0;
        }
        .g-row {
            overflow: auto;
            flex-direction: row;
            gap: 24px;
            padding-right: 25px;
        }
        .g-row--3-columns .g-col {
            min-width: 280px;
            max-width: 280px;
        }
    }
}
