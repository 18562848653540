@use "../../global-styles/palette" as c;

.toggleable-title-content-swiper-root {
  .g-container {
    .toggleable-title-content-swiper-root__pagination {
      display: grid;
      grid-auto-flow: column;
      justify-content: center;
      margin: 16px 0;
    }

    .toggleable-title-content-swiper-root__bullet {
      display: block;
      width: 8px;
      height: 8px;
      background-color: c.$dark-60;
      margin: 0 8px;
      border-radius: 50%;

      &.swiper-pagination-bullet-active {
        background-color: c.$tm-green-default;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .swiper-pagination {
      display: grid;
      grid-auto-flow: column;
      justify-content: center;
      margin-top: 10px;
    }

    .toggleable-title-content-swiper-root__contentWrapper {
      display: grid;

      &__titleWrapper {
        margin-bottom: 16px;

        p {
          color: c.$dark-60;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }

      &__content {
        color: c.$dark-80;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        a {
          color: c.$dark-default;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-decoration-line: underline;
        }
      }

      .g-container {
        margin: 0;

        .toggleable-title-content-swiper-root__swiper-view {
          .toggleable-title-content-swiper-card-root {
            &__imageContainer {
              border-radius: 16px;
              overflow: hidden;
              
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .toggleable-title-content-swiper-root {
    .g-container {
      margin-inline: 72px;
    }
  }
}

.large-view {
  .toggleable-title-content-swiper-root {
    .g-container {
      .toggleable-title-content-swiper-root__contentWrapper {
        grid-template-areas:
          "titleArea swiperArea"
          "contentArea swiperArea"
          ". swiperArea";

        &__titleWrapper {
          grid-area: titleArea;
        }

        &__content {
          grid-area: contentArea;
          max-width: 532px;
        }

        .g-container {
          grid-area: swiperArea;
          .toggleable-title-content-swiper-root__swiper-view {
            .toggleable-title-content-swiper-card-root {
              margin-left: 64px;
            }
          }
        }
      }

      .leftImagePosition {
        @extend .toggleable-title-content-swiper-root__contentWrapper;
        grid-template-areas:
          "swiperArea titleArea"
          "swiperArea contentArea"
          "swiperArea.";

        .g-container {
          grid-area: swiperArea;
          .toggleable-title-content-swiper-root__swiper-view {
            .toggleable-title-content-swiper-card-root {
              margin-right: 64px;
            }
          }
        }
      }
    }
  }
}
