@use "../../global-styles/palette" as c;

.ribbon-with-stat-line-root {
  .g-container {
    background-color: c.$dark-90;
    border-radius: 20px;
    padding: 40px 0;
    position: relative;

    .ribbon-with-stat-line-root__contentWrapper {
      text-align: center;
      padding: 0 20px;

      .button-v2-wrapper .secondary-medium-wbg {
        font-size: 18px;
        font-weight: 600;
        padding: 14px 40px;
      }


      &__textWrapper {
        width: fit-content;
        max-width: 540px;
        margin: 0 auto;

        h3 {
          color: c.$pure-white;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          margin-top: 4px;
        }
      }

      &__subtitle {
        color: c.$middle-label-grey;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 20px;
      }

      .button-v2-wrapper {
        z-index: 1;
        position: relative;

        .secondary-medium-wbg:hover {
          background-color: var(--button-hover-color);
          color: var(--button-hover-text-color);
        }
      }
    }
    .ribbon-with-stat-line-root__bg-image {
      position: absolute;
      background: var(--background-image-url);
      background-repeat: no-repeat;
      inset: 0;
      background-position: right -114px bottom -3px;
      transform: var(--bkgr-flip-horizontal);
    }
  }
}

.medium-view {
  .ribbon-with-stat-line-root {
    .g-container {
      margin: 0px 40px;

      .ribbon-with-stat-line-root__contentWrapper {
        display: grid;
        grid-auto-flow: column;
        position: relative;
        z-index: 1;
        padding: 0px 40px;
        text-align: unset;

        &__textWrapper {
          h3 {
            margin: 0;
          }
        }

        &__subtitle {
          margin: 0;
        }
      }

      .ribbon-with-stat-line-root__bg-image {
        background-position: right -13px bottom -8px;
      }
    }
  }
}

.large-view {
  .ribbon-with-stat-line-root {
    .g-container {
      .ribbon-with-stat-line-root__contentWrapper {
        display: grid;
        grid-auto-flow: column;
        position: relative;
        z-index: 1;
        justify-content: space-between;
        padding-inline: 40px;
        text-align: unset;

        .button-v2-wrapper .secondary-medium-wbg {
          font-size: 16px;
        }

        &__textWrapper {
          max-width: unset;
          h3 {
            text-align: start;
            margin: 0 0 8px 0;
            font-size: 32px;
          } 
        }

        &__subtitle {
          margin: 0;
        }

        .button-v2-wrapper {
          .secondary-medium-wbg {
            font-size: 18px;
            font-weight: 600;
          }
        }  
      }

      .ribbon-with-stat-line-root__bg-image {
        background-position: right 0px bottom 1px;
      }
    }
  }
}
