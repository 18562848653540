@use "../../global-styles/palette" as c;

.m30-cards-with-icon-texts-link {
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    &__cards {
        display: grid;
        gap: 16px;
    }

    .button-v2-wrapper {
        margin-top: 24px;
    }

    .tmx25-round-green-lightgreen {
        width: unset;
        padding: 10px 24px;
    }
}

.medium-view {
    .m30-cards-with-icon-texts-link {
        &__cards {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.large-view {
    .m30-cards-with-icon-texts-link {
        &__cards {
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;
        }
    }
}
