@use "../../../global-styles/palette" as c;

.videoCardDeckWithPagination {
    h2 {
        color: c.$dark-default;
        margin-bottom: 32px;
    }

    &__display {
        color: c.$dark-80;
        margin: 16px auto 0 auto;
        text-align: center;
    }
}

.small-view, .medium-view {
    .videoCardDeckWithPagination {
        .videoCard {
            max-width: unset; 
            
            .videoCard__embedded {
                height: 184px;
            }
        }
    }
}