@use "../../global-styles/palette" as c;
@use "./BigOrSmallCard/BigOrSmallCard";

.grid-big-and-small-cards {

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 40px;

        h2 {
            color: c.$dark-90;
            text-align: center;
        }

        p {
            color: c.$dark-80;
            text-align: center;
            max-width: 279px;
        }
    }
}

.small-view {
    .grid-big-and-small-cards {
        &__text-wrapper {
            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
            }
        }

        &__big-cards-wrapper {
            padding: 0px 24px 12px 24px;
        }

        &__small-cards-wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0px 24px 0px 24px;
        }
    }
}

.medium-view {
    .grid-big-and-small-cards {
        &__text-wrapper {
            p {
                max-width: 600px;
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
            }
        }

        &__cards-wrapper {
            display: flex;
            flex-direction: column!important;
            align-items: center;
            gap: 12px;
        }

        &__big-cards-wrapper {
            min-width: 600px;
            border-radius: 16px;
            border: 1px solid c.$dark-10;
            border-radius: 16px;
            padding-left: 24px;
            min-width: 63%;

            .g-container{
                border-radius: 16px;
            }
        }

        &__small-cards-wrapper {
            display: flex;
            gap: 12px;
            max-width: 86%;
        }
    }
}

.large-view {
    .grid-big-and-small-cards {

        &__cards-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 20px;
        }
        &__text-wrapper {
            h2 {
                font-weight: 700;
                line-height: 110%;
            }
            p {
                max-width: unset;
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
            }
        }

        &__big-cards-wrapper {
            max-width: 49%;
        }

        &__small-cards-wrapper {
            max-width: 49%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}