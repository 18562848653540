@use "../../global-styles/palette" as c;

.why-traders-choose-us {

    .g-container {
        overflow: unset;
    }

    &__container {
        display: flex;
        gap: 64px;
    }

    &__left-wrapper, &__right-wrapper {
        width: 532px;
    }

    &__left-wrapper {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        height: 400px;
    }

    &__left-title {
        margin-bottom: 16px;

        h2 {
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
        }
    }

    &__left-description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 40px;
    }

    &__left-button {
        .button-v2-wrapper {
            justify-content: left;
        }
    }

    &__item-year {
        display: flex;

        p {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            padding: 4px 8px;
            border-radius: 0px 8px 8px 0px;
            background-color: c.$neutral-accent-60;
            color: c.$dark-default;
        }
    }

    &__items:first-child {
        
        p {
            border-radius: 8px 8px 8px 0px;
        }
    }

    &__item-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 20px 0px 30px 20px;
        border-left: 1px dashed c.$dark-20;
    }

    &__item-el {
        display: flex;
        gap: 16px;
    }

    &__el-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: c.$dark-default;
    }
}

.medium-view {
    .why-traders-choose-us {
        &__container {
            flex-direction: column;
            align-items: center;
            gap: 80px;
        }

        &__left-wrapper, &__right-wrapper {
            width: 600px;
        }

        &__left-wrapper {
            position: unset;
            height: unset;
        }

        &__left-title {
            
            h2 {
                font-size: 26px;
                line-height: 32px;
            }
        }
    }
}

.small-view {
    .why-traders-choose-us {
        &__container {
            flex-direction: column;
            align-items: center;
            gap: 80px;
        }

        &__left-wrapper, &__right-wrapper {
            width: 327px;
        }

        &__left-wrapper {
            position: unset;
            height: unset;
        }

        &__left-title {
            
            h2 {
                font-size: 22px;
                line-height: 28px;
            }
        }

        &__left-description {
            margin-bottom: 36px;
        }
    }
}