.three-cols-section-media-buttons {
    --title-background: linear-gradient(90deg, #0E1D31 0%, #0E1D31 45%, #C8D3E1 50%, #0E1D31 55%, #0E1D31 100%);
    
    &.three-cols-section-media-buttons--animation--viewportEnter-30percent {
        .three-cols-section-media-buttons__media-container,
        .simple-icon-and-text-card {
            transform: translateY(50px);
            opacity: 0;
        }

        &.three-cols-section-media-buttons--animation--triggered--1 {
            .three-cols-section-media-buttons__title {
                animation: three-cols-section-media-buttons--animation--viewportEnter-30percent__title 1s cubic-bezier(.5, 0, .5, 1) forwards;
                background-image: var(--title-background);
                background-size: 150% 100%;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                transition: -webkit-text-fill-color 0.2s ease-out;
            }
        }
    }
}

.small-view,
.medium-view {
    .three-cols-section-media-buttons {
        &.three-cols-section-media-buttons--animation--viewportEnter-30percent {
            &.three-cols-section-media-buttons--animation--triggered--1 {
                .three-cols-section-media-buttons__media-container {
                    transform: translateY(0);
                    opacity: 1;
                    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
                }
            }

            &.three-cols-section-media-buttons--animation--triggered--1 {
                .simple-icon-and-text-card {
                    transform: translateY(0);
                    opacity: 1;
                    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
                    transition-delay: 1s;

                    &:nth-child(2) {
                        transition-delay: 1.3s;
                    }

                    &:nth-child(3) {
                        transition-delay: 1.6s;
                    }
                }
            }
        }
    }
}

.large-view {
    .three-cols-section-media-buttons {
        &.three-cols-section-media-buttons--animation--viewportEnter-30percent {
            &.three-cols-section-media-buttons--animation--triggered--1 {
                .three-cols-section-media-buttons__media-container {
                    transform: translateY(0);
                    opacity: 1;
                    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
                }
            }

            &.three-cols-section-media-buttons--animation--triggered--2 {
                .simple-icon-and-text-card {
                    transform: translateY(0);
                    opacity: 1;
                    transition: transform 0.3s ease-out, opacity 0.3s ease-out;

                    &:nth-child(2) {
                        transition-delay: 0.3s;
                    }

                    &:nth-child(3) {
                        transition-delay: 0.6s;
                    }
                }
            }
        }
    }
}

@keyframes three-cols-section-media-buttons--animation--viewportEnter-30percent__title {
    0% {
        background-position: 100% 50%;
        background-image: var(--title-background);
        -webkit-text-fill-color: transparent;
    }

    99% {
        background-position: -100% 50%;
        background-image: var(--title-background);
        -webkit-text-fill-color: transparent;
    }

    100% {
        background-image: none;
        -webkit-text-fill-color: currentColor;
    }
}