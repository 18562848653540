@use "../../../global-styles/palette" as c;
@use "./TheCard/TheCard";

.three-cards-lp {
    .the-card:nth-child(1) {
        background-color: c.$dark-90;

        .the-card__place-wrapper {

            border: 1px solid c.$pure-white;

            span {
                display: flex;
                gap: 4px;
                color: c.$pure-white;
            }
        }

        .the-card__title-wrapper {
            h4 {
                color: c.$tm-green-default;
            }
        }

        .the-card__list-wrapper {

            &__list-item {
                color: c.$dark-20;
            }
        }
    }

    .the-card:nth-child(2), .the-card:nth-child(3) {
        background-color: c.$neutral-accent-background;

        .the-card__list-wrapper {
            &__list-item {
                color: c.$dark-90;
            }
        }
    }
}

.large-view {
    .three-cards-lp {

        &__cards-container {
            display: flex;
            align-items: center;
            gap: 15px;
        }

        .the-card:nth-child(2) { order: 1; }
        .the-card:nth-child(3) { order: 3; }
        .the-card:nth-child(1) { order: 2; }

        .the-card:nth-child(1) {
            padding: 44px 32px;
            height: 299px;
        }
        
        .the-card:nth-child(2), .the-card:nth-child(3) {
            padding: 32px;
            height: 299px;
        }
    }
}

.medium-view {
    .three-cards-lp {

        &__cards-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
}

.small-view {
    .three-cards-lp {

        &__cards-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
}