@use "../../../global-styles/palette" as c;
@use "./BrokerRegulationCard/BrokerRegulationCard";

.broker-regulation-card-deck {
  background-color: c.$dark-default;
  padding: 0 0 80px 0;
  overflow: auto;

  &__title {
    color: c.$pure-white;
    text-align: center;
    white-space: pre-line;
    margin: 40px 0;
  }

  &__hidden-card {
    width: 264px;
    height: 104px;
    margin: 0 0 0 24px;
    visibility: hidden;
  }

  &__table-view {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
  }

  &__swiper-view {
    display: none;
  }
}

.medium-view,
.small-view {
  .broker-regulation-card-deck {
    &__title {
      margin: 24px;
    }

    .swiper-slide {
      width: fit-content;
    }

    &__bullet {
      width: 8px;
      height: 8px;
      background-color: c.$dark-80;
      margin: 0 8px;
      border-radius: 50%;

      &.swiper-pagination-bullet-active {
        background-color: c.$pure-white;
      }
    }

    &__pagination {
      display: flex;
      justify-content: center;
      padding: 16px 0;
    }

    &__table-view {
      display: none;
    }

    &__swiper-view {
      display: block;
      margin: 0 24px;
    }
  }
}