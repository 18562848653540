@use "../../../global-styles/palette" as v;

.optional-button-section-banner {
  &__flex-middle {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;
    justify-content: flex-start;
  }

  &__flex-middle>* {
    text-align: left;
  }

  &__image {
    width: 100%;
  }

  &__grid-outer {
    display: grid;
    grid-template-columns: 3fr 2fr;
    border-radius: 24px;
  }

  &__button-container {
    margin-top: 16px;
  }

  &__image-container {
    border-radius: 24px;
  }
}

.large-view {
  .optional-button-section-banner {
    &__img-right {
      direction: rtl;
    }

    &__flex-middle {
      padding: 30px 30px 0px 30px;
    }

    &__grid-outer {
      display: grid;
      grid-template-columns: 3fr 2fr;
    }

    &__title-container {
      text-align: left;
      font-style: normal;
    }

    &__image-container {
      padding: 58px 34px;
    }
  }
}

.medium-view,
.small-view {
  .optional-button-section-banner {
    &__flex-middle>*:not(.optional-button-section-banner__image-container-mobile) {
      padding: 0px 18px 0px 18px;
    }

    &__title-container {
      text-align: left;
      line-height: 48px;
      font-style: normal;
      font-size: 36px;
      width: 200px;
      h3{
        font-size: 36px;
        line-height: 48px;
      }
    }

    &__button-container {
      display: flex;
      align-self: flex-start;
      justify-content: flex-start;
    }

    &__image-container-mobile {
      border-radius: 24px;
    }

    &__grid-outer {
      display: grid;
      grid-template-columns: 1fr;
      padding-bottom: 20px;
    }
  }
}

.medium-view {
  .optional-button-section-banner {
    &__title-container {
      width: 100%;
    }

    &__image-container-mobile {
      padding: 40px 30px;
    }
  }
}

.small-view {
  .optional-button-section-banner {
    
    &__image-container-mobile {
      padding: 20px 10px;
    }
  }
}
