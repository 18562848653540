@use "../../../global-styles/palette" as c;

.AnimatedBanner{
  max-width: 936px;
  max-height: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  perspective: 1000px;
  &.fixed{
    position: fixed;
    left: 0;
    top: 200px;
    right: 0;
    bottom: 0;
  }
  h1{
    margin-bottom: 8px;
  }
  h5{
    font-weight: 500;
    max-width: 552px;
    color: c.$dark-80;
  }
  h4{
    display: flex;
    align-items: center;
    white-space: nowrap;
    svg{
      margin-right: 8px;
    }
  }
  &__image{
    margin-top: 32px;
    width: 100%;
    margin-bottom: 40px;
    transform-style: preserve-3d;
    text-align: center;
    transform: rotateX(30deg);
    transition: 1s ease-in;
    &.animated{
      transform: rotateX(0);
    }
    img{
      max-height: 56vh;
      max-width: 100%;
    }
  }
  &__buttons{
    width: 100%;
    display: flex;
    gap: 24px;
    opacity: 0;
    transition: 1s ease-in;
    &.animated{
      opacity: 1;
    }
  }
  &__button{
    display: flex;
    // gap: 24px;
    flex: 1;
    min-width: 402px;
    padding: 16px 24px;
    border-radius: 16px;;
    align-items: center;
    justify-content: space-between;
    background-color: c.$neutral-accent-background;
    box-sizing: border-box;
    .button--primary{
      color: c.$dark-default;
      margin: 0;
    }
  }
  &__footer,&__mobile-download{
    display: none;
  }
}

.medium-view,.small-view{
  .AnimatedBanner{
    h5{
      max-width: 600px;
    }
    &__image{
      margin-bottom: 32px;
      img{
        width: calc(100% - 32px);
      }
    }
    &__footer{
      display: block;
      margin-top: 16px;
      color: c.$dark-60;
    }
    &__mobile-download{
      display: flex;
      gap: 16px;
    }
    &__button--launch,&__button--image{
      display: none;
    }
    &__button{
      gap: 24px;
    }
  }
}
.small-view{
  .AnimatedBanner{
    &__button{
      flex-direction: column;
      // h4{
      //   margin-bottom: 24px;
      // }
    }
  }
}