@use "../../../../global-styles/palette" as c;

.chartsGridContainerWrapper {
  &__mainTitle {
    padding-top: 32px;
    color: c.$dark-default;
    text-align: center;
  }

  h5.chartsGridContainerWrapper__subTitle {
    color: c.$dark-80;
    font-weight: 500;
    width: 280px;
    margin-inline: auto;
    margin: 8px auto 0;
    text-align: center;
  }

  &__chartsImageWrapper {
    margin-top: 32px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.large-view {
  .chartsGridContainerWrapper {
    width: 608px;
    margin-inline: auto;

    h5.chartsGridContainerWrapper__subTitle {
      width: 420px;
    }
  }
}
