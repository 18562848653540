@use "../../../global-styles/palette" as c;

.TradeWithComponentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;
  margin: 60px 0;
  padding-inline: 24px;
  .button-v2-wrapper{
    width: 100%;
    margin-top: 24px;
  }
 
  grid-template-areas:
    "mainImage"
    "mainTitle"
    "subtitle"
    "sectionWrapper"
    "button";

  .h2-small {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
  }

  &__mainTitle {
    grid-area: mainTitle;
    height: fit-content;
    margin-top: 31px;
    font-style: normal;
  }

  &__mainSubTitle {
    grid-area: subtitle;
    margin-top: 8px;
    font-style: normal;
    color: c.$dark-80;
    max-width: 552px;
  }

  &__image {
    grid-area: mainImage;
    width: 327px;
    height: 252px;
    margin-inline: auto;
    object-fit: contain;
    border-radius: 20px;
  }

  &__sectionWrapper {
    grid-area: sectionWrapper;
    width: 100%;
    margin-top: 16px;

    &__section {
      display: grid;
      gap: 8px;
      grid-auto-flow: column;
      justify-content: start;
      margin-top: 16px;

      &:first-child {
        margin: 0;
      }

      &__titleWrapper {
        width: fit-content;

        &__subTitle {
          font-style: normal;
          color: c.$dark-default;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
  .squaredButton {
    grid-area: button;
    background-color: c.$pure-white;
    border: 1px solid c.$dark-default;
    color: c.$dark-default;
    margin: 32px auto 0 0;

    &:hover {
      background-color: c.$dark-default;
      color: c.$pure-white;
    }
  }
}

.TradeWithComponentWrapperRight {
  @extend .TradeWithComponentWrapper;
}

.medium-view {

  .col-2 {
    column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "mainImage mainImage"
      "mainTitle sectionWrapper"
      "subtitle sectionWrapper"
      ". sectionWrapper"
      ". button";
  }

  .TradeWithComponentWrapper {
    padding-inline: 72px;

    .h2-small {
      font-size: 26px;
      line-height: 32px;
      font-weight: 600;
    }

    &__mainTitle {
      margin-top: 32px;
    }

    &__image {
      width: 456px;
      height: 395px;
      border-radius: 32px;
    }

    &__sectionWrapper {
      margin-top: 32px;

      &__section {
        margin-top: 16px;

        &:first-child {
          margin: 0;
        }
      }
    }
    .squaredButton {
      transform: translateX(-50%);
    }
  }
}

.large-view {
  .TradeWithComponentWrapper {
    .button-v2-wrapper{
      justify-content: flex-start;
    }
    max-width: 1128px;
    margin-inline: auto;
    padding: 0;
    grid-template-columns: 1fr 1fr;
    column-gap: 0;

    &__subTitle {
      margin-top: 8px;
    }

    grid-template-areas:
      "mainImage mainTitle"
      "mainImage subtitle"
      "mainImage sectionWrapper"
      "mainImage button";

    &__mainTitle {
      margin: 0;
    }

    &__image {
      width: 512px;
      height: 395px;
      margin: 0;
    }

    &__sectionWrapper {
      &__section {
        margin-top: 16px;

        &:first-child {
          margin: 0;
        }

        &__titleWrapper {
          &__subTitle {
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
    }
    .squaredButton {
      margin: 10px auto 0 0;
    }
  }
  .TradeWithComponentWrapperRight {
    @extend .TradeWithComponentWrapper;
    grid-template-areas:
      "mainTitle mainImage"
      "subtitle mainImage"
      "sectionWrapper mainImage"
      "button mainImage";
  }
}
.medium-view,.small-view{
  .TradeWithComponentWrapper__sectionWrapper__section__titleWrapper__subTitle{
    font-weight: 500;
  }
}
.small-view{
  .TradeWithComponentWrapper{
    padding: 0;

    &__mainSubTitle {
      margin-top: 16px;
    }

    &__image{
      width: 100%;
      margin-bottom: 32px;
    }
    .squaredButton{
      margin: 32px auto 0;
    }
    .h2-small {
      font-size: 22px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 32px;
    }
  }
  .tradeWithComponentBlackBackground-dark .g-container .tradeWithComponent-buttonWrapper .squaredButton--primary{
    margin: 0;
  }
}