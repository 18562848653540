@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";

.cards-grid-icon-and-title {
  &__cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__card {
    border-radius: 8px;
    padding: 16px 20px;
    box-sizing: border-box;
    text-decoration: none;
    color: c.$dark-default;
  }

  &__text-description {
    width: 30px;

  }

  &__card.bg-color-neutral-accent-background:hover {
    background-color: c.$neutral-accent-default;

  }

  &__text-container {
    width: 30px;
  }
}

.active-card {
  background-color: c.$neutral-accent-default;
}

.medium-view {
  .cards-grid-icon-and-title {
    &__cards-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.large-view {
  .cards-grid-icon-and-title {
    &__cards-grid {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}