@use "../../../global-styles/palette" as v;

.bellyBanner {
  background-color: v.$blue-lagoon-default;
}

.bellyBanner__Inner {
  h5 {
    color: v.$pure-white;
    font-weight: bold;
    padding: 24px 0;
  }

  &__cta {
    background-color: #70AA1E;
    color: #FFF;
    padding: 10px 30px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    min-width: 165px;
    box-sizing: border-box;
    text-align: center;
  }

  &__cta:hover {
    background-color: v.$tm-green-120;
  }

  span {
    color: v.$pure-white;
    font-size: 21px;
    padding: 0 20px;
  }

  span:not(:first-of-type) {
    border-left: 2px solid v.$pure-white;
  }
}

.bellyBanner__text {
  span.tmLogoGreen {
    padding: 0;
    border: none;
    color: v.$tm-green-60;
  }
}

.large-view {
  .bellyBanner__Inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;

    span:first-child {
      padding-left: 0 !important;
    }
  }
}

.small-view,
.medium-view {
  .bellyBanner__Inner {
    height: auto;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    span {
      padding: 0;
    }

    div {
      text-align: center;
    }

    span:not(:first-of-type) {
      display: none;
    }

    a {
      margin-top: 24px;
    }
  }
}