@use "../../global-styles/palette" as c;

.simple-title-subtitle-root {
    h2 {
        margin-bottom: 20px;
    }

    p.copy-lp {
        color: c.$dark-80;
    }

    p>a {
        color: c.$dark-default;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
    }
}