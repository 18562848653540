@use "./AccountCard/AccountCard";
@use "../../global-styles/palette" as v;

.accountTypes {
    background-color: v.$pure-white;

    &__wrapper {
        overflow: unset;
        margin: 0 auto;
    }

    &__inner {
        position: sticky;
        display: table;
    }

    .caption2-regular{
        height: 40px;
        margin-bottom: 20px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 24px;
    }

    &__col {
        width: 264px;
    }

    &__card-bodies-wrapper {
        display: table;
    }

    &__card-bodies {
        height: 120vh;
        overflow: auto;
    }
}

.medium-view {
    .accountTypes__row {
        gap: 50px;
    }
}

.small-view,
.medium-view {
    .accountTypes .g-container {
        margin: 0;
    }

    .accountTypes__wrapper {
        overflow: auto;
    }

    .accountTypes__card-bodies {
        height: calc(100vh - 170px);
        padding-left: 24px;
        overflow: auto;
    }

    .accountTypes__inner {
        padding-left: 24px;
    }

    .accountTypes__col {
        width: 212px;
    }
}

.small-view {
    .accountTypes__row {
        gap: 40px;
    }
}

.accountTypes {
    margin: 0;

    &__button-separator {
        border-top: solid 2px v.$dark-10;
    }

    .account-card__inner {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: -24px;
            width: 24px;
            height: 100%;
        }
    }
}

.large-view {
    .accountTypes {
        margin: 0;

        .account-card__fixed-logo {
            height: 100%;
        }

        .g-container {
            overflow: unset;
        }
    }

    .accountTypes__inner {
        position: sticky;
        top: 100px;
        z-index: 1;
    }

    .accountTypes__wrapper {
        overflow: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .accountTypes__col {
        min-height: unset;
        max-height: unset;
        background: v.$pure-white;
    }

    .accountTypes__card-bodies {
        height: auto;
        overflow: unset;
    }

    .accountTypes__card-bodies {
        height: auto;
    }
}