@use "../../../global-styles/palette" as c;
@use "../../../global-styles/typography";
@use "../../../global-styles/layout";

.pressBanner {
    box-sizing: border-box;
    width: 100%;
    &__info{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 24px;
    }
    &__share{
        display: flex;
        gap: 8px;
    }
    &__data{
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        font-weight: 400;
        color: c.$dark-default;
        span,a{
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: c.$dark-default;
            text-decoration: none;
        }
        span:not(:nth-last-child(1)){
            position: relative;
            margin-right: 16px;
            &::after{
                content: "";
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: rgba(135, 135, 135, 0.35);
                right: -10px;
            }
        }
        &--cfd{
            font-weight: 700;
            color: c.$tm-green-120;
        }
        &--date{
            color: c.$dark-60;
        }
        &--date:dir(rtl) {
            margin-inline: 6px;
        }
        &--info{
            display: flex;
            flex-wrap: wrap;
        }
    }
    h1{
        margin: 20px 0 16px;
    }
}

.small-view , .medium-view {
    .pressBanner {
        &__content {
            padding: 24px;
            width: 100%;

            h1 {
                margin-bottom: 24px;
                text-align: center;
            }
        }

        &__breadcrumbs {
            display: none;
        }

        &__text {
            text-align: center;
        }
    } 
}

.small-view{
    .pressBanner{
        &__data{
            margin-bottom: 8px;
            span{
                margin-bottom: 8px;
            }
        }
    }
}