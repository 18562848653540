@use "../../global-styles/palette" as c;

.referencing-cards{

    &__title{
        margin-bottom: 16px;
    }
    &__items{
        display: flex;
    }
    &__item{
        width: 360px;
        height: 426px;
        margin: 24px 24px 0 0;
        padding: 0 0 32px;
        border-radius: 16px;
        background-color: c.$pure-white;
        position: relative;
    }
    &__item-image{
        width: 360px;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
    }
    &__item-text{
        padding: 16px 16px 32px;
    }
    &__item-title{
        margin-bottom: 8px;
    }
    &__item-nav{
        display: flex;
        position: absolute;
        bottom: 32px;
        color: c.$blue-lagoon-default;
    }
    &__item-nav-link{
        display: flex;
        text-decoration: none;
        color: c.$blue-lagoon-default;
    }
    .mobile{
        display: none;
    }
}

.small-view, .medium-view {
    .referencing-cards {

        .g-container {
            margin: 0 0 0 24px;
        }

        &__title {
            text-align: left;
        }
        &__subTitle {
            text-align: left;
        }
        &__item {
            width: 280px;
            height: 380px;
        }
        &__item-image {
            width: 280px;
        }
        &__bullets {
            width: 8px;
            height: 8px;
            background-color: c.$dark-20;
            pointer-events: all;
            margin: 0 4px;
            border-radius: 50%;
        }
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
        .swiper-pagination-bullet-active{
            background-color: c.$dark-40;
        }
        .referencing-cards__customPagination {
            margin-top: 16px;
            pointer-events: all;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}