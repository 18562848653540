@use "../../global-styles/palette" as c;

.filter-cards{
    position: relative;
    &__results-count{
        color: c.$dark-60;
        .filters__reset{
            margin-left: 16px;
        }
    }
    .newsCardDeck{
        .g-row--3-columns .g-col{
            width: 100%;
        }
    }

    &__container {
        .spinner-wrapper {
            display: flex;
            place-content: center;
            padding: 40px;
        }
    
        .lds-spinner {
            --spinner-color: #000;
        }
    }
}
.active-filters{
    .filters-overlay{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99;
        backdrop-filter: blur(10px);
        background-color: rgba(14, 29, 49, 0.8);
    }
}
.small-view{
    .filter-cards{
        &__container{
            .g-container{
                overflow: visible;
            }
        }
    }
}