@use "../../global-styles/palette" as c;

.cards-grid-with-full-width-centered-images-and-tags {

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  &__card {
    margin-bottom: 25px;
  }

  &__text-befor-tags {
    margin-inline-end: 8px;
    color: c.$dark-60;
    font-size: 13px;
    font-weight: 500;
    line-height: 100%;
  }

  &__tags-strip {
    position: absolute;
    inset-inline-start: 12px;
    inset-block-start: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 14px 8px 10px;
    border-radius: 8px;
    background-color: c.$pure-white;
  }

  &__tag {
    box-sizing: border-box;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    gap: 6px;
  }

  &__image-container {
    border-radius: 20px;
    padding: 20px 20px 0px 20px;
    height: 290px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;

    &__imageWrapper {
      max-width: 300px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__cards-container {
    display: flex;
    flex-wrap: wrap;
  }
}

.medium-view {
  .cards-grid-with-full-width-centered-images-and-tags {
    .col-4-card {
      flex: 40%;
    }

    .col-3 {
      gap: 24px;
    }

    .col-4 {
      gap: 20px;
    }

    .col-3-card {
      flex: 1;
    }

    &__image-container {
      height: fit-content;
    }
  }
}

.large-view {
  .cards-grid-with-full-width-centered-images-and-tags {
    .col-4-card {
      flex: 49%;
    }

    .col-3 {
      gap: 24px;
    }

    .col-4 {
      gap: 20px;
    }

    .col-3-card {
      flex: 1;
    }
  }
}