@use "../../global-styles/palette" as c;

.Mt5-gradient-banner__root {
  position: relative;

  .Mt5-gradient-banner__bgGradient {
    position: absolute;
    background: var(--background-image-url);
    background-repeat: no-repeat;
    inset: 0;
    background-size: 100% auto;
  }

  .button-v2-wrapper .tmx25-round-green-lightgreen {
    width: unset;
  }

  .g-container {
    position: relative;

    .Mt5-gradient-banner__bannerImgWrapper {
      margin-top: 40px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      img {
        max-width: 160px;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 8px;
      color: c.$pure-white;
      max-width: 620px;
      margin-inline: auto;
    }

    .Mt5-gradient-banner__subTitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: c.$dark-10;
      margin-bottom: 28px;
    }

    .cta-medium-wbg {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
    }
  }
}

.medium-view {
  .Mt5-gradient-banner__root {
    .g-container {
      .Mt5-gradient-banner__bannerImgWrapper {
        margin-top: 80px;
        margin-bottom: 64px;

        img {
          max-width: 240px;
        }
      }

      .Mt5-gradient-banner__subTitle {
        width: 600px;
        margin-inline: auto;
      }
    }
  }
}

.large-view {
  .Mt5-gradient-banner__root {
    .g-container {
      .Mt5-gradient-banner__bannerImgWrapper {
        margin-top: 80px;
        margin-bottom: 64px;

        img {
          max-width: 240px;
        }
      }

      .Mt5-gradient-banner__bannerImgWrapper-margin2 {
        margin-top: 70px !important;
        margin-bottom: 27px !important;
      }

      .Mt5-gradient-banner__subTitle {
        max-width: 800px;
        margin-inline: auto;
      }
    }
  }
}