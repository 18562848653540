@use "../../global-styles/palette" as c;

.instrument-slider {

  &__title {
    text-align: center;
    white-space: pre-line;
  }

  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    width: 138px;
    margin: 24px 14px 0 14px;
    padding: 12px 12px;
    border-radius: 8px;
    background-color: c.$light-grey;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__item:hover {
    color: c.$pure-white;
    background-color: c.$blue-lagoon-default;
  }

  &__item:hover &__item-image {
    filter: brightness(0) invert(1);
  }

  &__highlighted {
    color: c.$pure-white;
    background-color: c.$blue-lagoon-default;
  }

  &__highlighted &__item-image {
    filter: brightness(0) invert(1);
  }

  &__item-image {
    width: 48px;
    height: 48px;
    margin: 0 4px 0 0;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
  }

  &__item-title {
    font-size: 18px;
    font-weight: bold;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
  }
}

.instrument-slider-active {
  opacity: 1;
}

.small-view,
.medium-view {
  .instrument-slider {
    &__items {
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &__item {
      width: 75px;
      margin: 16px 16px 0 0;
    }
  }
}