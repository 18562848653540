@use "../../../global-styles/palette" as c;
@use "./NumberedCardDemo/NumberedCardDemo";

.dark-numbered-card-deck--demo{
    .dark-numbered-card-deck__title{
        max-width: 740px;
        margin: 0 auto;
        text-align: center;
    }

    .dark-numbered-card-deck__cta {
        text-align: center;
        a {
            padding: 16px 0px;
            border-radius: 40px;
            color: c.$pure-white;
            background-color: c.$tm-green-default;
            font-size: 21px;
            font-weight: 600;
            line-height: 1.14;
            display: inline-block;
            text-decoration: none;
            margin: 50px 0 0 0;
            min-width: 263px;
        }
    }

   
}