@use "./palette" as c;

/***
    To overridedefault values only for selected parameters,
    use syntax e.g. @include custom-scrollbar($scrollbar-track-color: blue);
***/

@mixin custom-scrollbar($scrollbar-thumb-color: c.$neutral-accent-default, $scrollbar-track-color: transparent, $scrollbar-size: 10px, $with-buttons: false) {
    & {
        scrollbar-width: thin;
        scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
    }
      
    &::-webkit-scrollbar {
        width: $scrollbar-size;
        height: $scrollbar-size;
    }
      
    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
        border-radius: 5px;
    }
      
    &::-webkit-scrollbar-track {
        background-color: $scrollbar-track-color;
    }
    
    @if $with-buttons {
        &::-webkit-scrollbar-button {
            background-color: $scrollbar-thumb-color;
            border-radius: 5px;
        }
    }
}

%custom-scrollbar,
.custom-scrollbar {
    @include custom-scrollbar();
}