@use "../../global-styles/palette" as c;

.ComponentColItemsDeckWithTitle-wrapper {
  .g-container {
    .ComponentColItemsDeckWithTitle-wrapper__title-wrapper {
      margin: 0px auto 24px auto;
      max-width: 327px;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      color: c.$dark-default;
    }

    .ComponentColItemsDeckWithTitle-wrapper__button-wrapper {
        margin: 32px auto;
    }

    .main-title-position-left {
      justify-content: flex-start;
      align-items: flex-start;
    }

    .main-title-position-right {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .ComponentColItemsDeckWithTitle-wrapper__grid-wrapper {
      display: grid;

      &__cards-wrapper {
        display: grid;
        margin-bottom: 24px;

        &:last-child {
          margin: 0;
        }

        img {
          width: 45px;
          height: 45px;
        }

        &__texts {
          margin-top: 16px;

          p {
            color: c.$dark-80;
            margin-top: 8px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }

    .cardsDeckInfoBox {
      width: unset;
      max-width: 816px;
    }
  }
}

.medium-view {
  .ComponentColItemsDeckWithTitle-wrapper {
    .g-container {
      margin: 0 72px;

      .ComponentColItemsDeckWithTitle-wrapper__title-wrapper {
        max-width: 600px;
        margin: 0px auto 32px auto;
      }

      .main-title-position-left {
        margin-left: 0px;
      }

      .main-title-position-right {
        margin-right: 0px;
      }

      .ComponentColItemsDeckWithTitle-wrapper__grid-wrapper {
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 40px 24px;

        &__cards-wrapper {
          margin: 0;
        }
      }
    }
  }
}

.large-view {
  .ComponentColItemsDeckWithTitle-wrapper {
    .g-container {

      .ComponentColItemsDeckWithTitle-wrapper__title-wrapper {
        max-width: 100%;
        margin: 0px auto 32px auto;
      }

      .main-title-position-left {
        margin-left: 0px;
      }

      .main-title-position-right {
        margin-right: 0px;
      }

      .ComponentColItemsDeckWithTitle-wrapper__grid-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0 24px;
        align-items: start;

        &__cards-wrapper {
          margin: 0;
        }
      }
    }
  }
}

.small-view {
  .ComponentColItemsDeckWithTitle-wrapper {
    .g-container {
      .ComponentColItemsDeckWithTitle-wrapper__grid-wrapper {
        &__cards-wrapper {
          width: unset;
        }
      }
    }
  }
}
