@use "./CardWithOutHoverAnimation//CardWithOutHoverAnimation";

.card-without-hover-animation__root {
    .g-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;
    }
}

.larger-card-description {
    .g-container {
        .card-withOut-hover {
            width: 100%;
        }
    }
}

.medium-view {
    .larger-card-description {
        .g-container {
            flex-wrap: nowrap;

            .card-withOut-hover {
                padding: 16px;

                &__title {
                    font-size: 16px;
                }

                &__description {
                    font-size: 12px;
                    width: 90%;
                    font-weight: 400;
                    line-height: 18px;
                }
            }
        }
    }
}

.large-view {
    .card-without-hover-animation__root-3-cols {
        .card-withOut-hover {
            flex: 1 !important;
        }
    }

    .larger-card-description {
        .g-container {
            flex-wrap: nowrap;

            .card-withOut-hover {
                &__description {
                    width: 285px;
                }
            }

        }
    }
}