@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/typography" as t;


.big-awards-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 12px;
    border-radius: 20px;
    min-width: 204px;
    max-width: none;
    min-height: 380px;
    padding: 40px;
    margin: 0;
    font-family: "Figtree", sans-serif;

    &--gradient--pink {
        background: var(--background-image-url) bottom center no-repeat, radial-gradient(145.95% 131.03% at 50% 0%, var(--first-gradient-color) 60%, var(--second-gradient-color) 100%);
        color: c.$pure-white;
    }

    &--gradient--blue {
        background: var(--background-image-url) bottom center no-repeat, radial-gradient(145.95% 131.03% at 50% 0%, var(--first-gradient-color) 60%, var(--second-gradient-color) 100%);
        color: c.$pure-white;
    }

    &--gradient--purple {
        background: var(--background-image-url) bottom center no-repeat, radial-gradient(145.95% 131.03% at 50% 0%, var(--first-gradient-color) 60%, var(--second-gradient-color) 100%);
        color: c.$pure-white;
    }

    img {
        max-width: 100%;
        margin-bottom: auto;
    }

    h3 {
        @extend .h5-bold;

        overflow-wrap: anywhere;
        color: c.$pure-white;
    }

    &__subtitle,
    &__year {
        color: c.$dark-40;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
    }

    &__year {
        margin-inline-start: 8px;

        &::before {
            content: "•";
            margin-inline-end: 8px;
        }
    }
}

.small-view {
    .big-awards-card {
        min-height: 246px;
        padding: 20px 20px 24px;
        background-size: cover;

        img {
            transform: scale(0.8);
            transform-origin: 0 0;
        }
    }
}

.medium-view {
    .big-awards-card {
        min-height: 240px;
        padding: 20px 20px 24px;
        background-size: 100% auto;
    }
}

.large-view {
    .big-awards-card.big-awards-card {
        background-size: 100% auto;

        h3 {
            @extend .h4-bold;
        }
    }
}