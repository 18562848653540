@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/palette" as c;

.accordion-card.wide-range-card {
  position: relative;
  height: auto;
  min-width: 552px;
  min-height: 428px;
  .accordion-card__link {
    margin-top: 32px;
  }
  .accordion-card__text {
    margin-bottom: 0;
  }
}
.wide-range-card__instrument {
  &-title {
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 8px;
  }
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  img {
    margin-right: 8px;
    max-height: 24px;
  }
  p {
    color: c.$dark-60;
  }
}
.large-view,
.medium-view {
  .accordion-card.wide-range-card {
    &:hover {
      background-color: c.$pure-white;
      .accordion-card__title {
        color: c.$dark-default;
      }
      .accordion-card__text {
        color: c.$dark-60;
      }
      .accordion-card__link {
        color: c.$blue-lagoon-default;
        svg {
          fill: c.$blue-lagoon-default;
        }
      }
      .accordion-card__icon {
        filter: unset;
        opacity: 1;
      }
    }
    .accordion-card__icon {
      bottom: 0;
      right: 0;
    }
  }
}
.small-view {
  .accordion-card.wide-range-card {
    min-width: auto;

    .accordion-card__icon {
      display: none;
    }
  }
}
