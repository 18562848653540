@use "../../../global-styles/palette" as c;
@use "./CardWithHoverAnimation/CardWithHoverAnimation";


.cards-with-hover-animation {
    display: flex;
    flex-direction: column;
    gap: 28px;
    

    &__cards-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
}

.large-view {
    .cards-with-hover-animation {
        padding: 80px 0px;
        h2 {
            text-align: center;
            max-width: 744px;
            margin: 0px auto;
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            color: c.$pure-white;
        }
    }
}

.medium-view {
    .cards-with-hover-animation {
        padding: 80px 0px;

        h2 {
            text-align: center;
            max-width: 500px;
            margin: 0px auto;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            color: c.$pure-white;
        }

        &__cards-wrapper {
            gap: 12px;
        }
    }
}

.small-view {
    .cards-with-hover-animation {
        padding: 64px 0px;

        h2 {
            text-align: center;
            max-width: 500px;
            margin: 0px auto;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            color: c.$pure-white;
        }

        &__cards-wrapper {
            flex-direction: column;
            gap: 12px;
        }
    }
}