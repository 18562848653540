@use "./palette" as c;
@use "./variables" as v;

body {
    margin: 0;
    padding: 0;
    background-color: #f2f4f6;

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }
}

body.body-scroll-disabled {
    overflow: hidden;
}

.background-accent {
    background-color: c.$neutral-accent-default;
}

.background-neutral {
    background-color: c.$neutral-accent-default;
}

.background-pure-white {
    background-color: c.$pure-white;
}

.dark-default {
    color: c.$dark-default;
}

.g-container {
    overflow: hidden;
}

.g-header__navigation-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.g-header__navigation {
    position: relative;
}

.g-header__logo {
    position: absolute;
    left: 0;
    bottom: 20px;
    max-width: var(--logo-max-width);
    display: none;
}

.g-header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: v.$header-z-index;
    // background-color: c.$dark-default;

    &__overlay {
        display: none;
    }
}

.g-footer {
    background-color: c.$pure-white;
}

.ratio-container {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 12px;

    &__column {
        flex-direction: column;

        > div {
            flex: 1;
            width: 100%;
        }
    }

    > div {
        box-sizing: border-box;
        display: flex;
        gap: 12px;

        > div {
            width: 100%;
            display: flex;
        }
    }
}

.large-view {
    body {
        min-width: v.$min-content-width-large;
    }

    .g-container--with--horizontal-spacing {
        width: calc(100% - 2 * v.$gutter-large);
        min-width: auto;
        max-width: v.$min-content-width-large;
    }

    .g-container {
        max-width: v.$min-content-width-large;
        margin: 0 auto;
        &.g-container--new{
            min-width: 100%;
            max-width: v.$min-content-width-large-new;
        }

        .g-container {
            min-width: auto;
            max-width: v.$min-content-width-large;
            &.g-container--new{
                max-width: v.$min-content-width-large-new;
            }

            &.g-container--fullwidth {
                max-width: 100%;
            }
        }
    }

    .g-container.g-container--fullwidth {
        min-width: v.$min-content-width-large;
        max-width: 100%;
        &.g-container--new{
            min-width: 100%;
        }

        .g-container {
            @extend .g-container--with--horizontal-spacing;

            &.g-container--fullwidth {
                width: auto;
                min-width: v.$min-content-width-large;
                max-width: 100%;
                &.g-container--new{
                    min-width: 100%;
                }
            }

            & .g-container {
                width: auto;
            }
        }
    }
}

.small-view,
.medium-view {
    body.mobile-nav-active {
        overflow: hidden;
    }

    .menu-active,
    .mobile-nav-active {
        .g-header__overlay {
            top: 64px;
        }
    }

    .g-container {
        min-width: 327px;
        margin: 0 v.$g-container-margin-left 0 v.$g-container-margin-right;
    }

    .g-container.g-container--fullwidth {
        min-width: 375px;
        margin: 0;
    }

    .g-header__logo {
        padding: 16px 0;
        display: none;
    }

    .g-header {
        &__logo {
            position: static;
            margin-right: auto;
        }

        &__navigation {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 64px;
        }
    }

    .g-header__navigation > div:first-child {
        margin-right: auto;
    }

    .g-header__navigation-container {
        position: fixed;
        z-index: 2;
        right: -100%;
        top: 50px;
        background: c.$pure-white;
        height: auto;
        transition: v.$transition;
        min-width: 375px;
        align-items: stretch;
        overflow-y: auto;

        .navigation__list > .secondary-navigation__container {
            display: flex;
        }
    }

    .mobile-nav-active {
        .g-header__navigation-container {
            right: 0;
        }
    }
}

.small-view {
    .g-header__navigation {
        height: 58px;
    }

    .g-header__navigation-container {
        width: 100%;
        height: calc(100vh - 128px);
        overflow-y: auto;
        display: flex;
        align-items: center;
    }

    .mobile-nav-active {
        .g-header__navigation-container {
            max-width: 100%;
        }
    }
}

.medium-view {
    .g-header__navigation-container {
        top: 0;
    }

    .g-container.g-container--tablet-margins{
        margin: 0 73px 0 75px;  
    }
    .g-container.g-container--tablet-margins-landing-pages{
        margin: 0 40px 0 40px;  
    }
}

.small-view {
    .g-container.g-container--tablet-margins-landing-pages{
        margin: 0 20px 0 20px;  
    }
}

.small-view .mobile-nav-active,
.medium-view .mobile-nav-active,
.large-view .menu-active {
    .g-header__overlay {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(10px);
        z-index: 2;
        transition: 0.2s ease-in;
        top: 0;
        background-color: c.$dark-default;
    }
}

.small-view .mobile-nav-active,
.medium-view .mobile-nav-active {
    .g-header {
        position: fixed;
        bottom: 0;
    }

    .g-mobile-header__navigation.g-mobile-header__wrapper {
        display: none;
    }

    .g-container {
        height: 100%;
        margin: 0;

        &.g-container--fullwidth {
            min-width: auto;
        }
    }

    .g-header__navigation {
        flex-direction: column;
        height: 100%;

        &-container {
            position: static;
            height: 100%;
        }

        .navigation__container {
            .loginAndSearchWrapper {
                flex-direction: column;
            }

            .loginButtonsWrapper {
                flex-direction: column-reverse;
                box-sizing: border-box;
            }

            .navDropdown {
                position: static;
                display: flex;
                flex-grow: 1;
                order: 1;
                max-width: none;
                height: auto;
                margin-bottom: auto;
                box-sizing: border-box;
            }
        }

        .navigation__list {
            flex-grow: 1;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: auto;

            .secondary-navigation__items {
                width: 100%;
            }
        }

        .navigation__menuWrapper {
            flex-grow: 1;
            margin-bottom: auto;
            overflow: auto;
        }
    }

    &.menu-active {
        .g-header__navigation {
            .navigation__container {
                height: calc(100% - 50px);

                .loginAndSearchWrapper {
                    order: 3;
                }

                .navDropdown__menuLink.active {
                    .navDropdown__content {
                        width: 100%;
                        height: 100%;

                        .g-container {
                            position: static;
                            height: auto;
                            margin-top: 0;

                            & > .g-container {
                                position: relative;
                                display: flex;
                                height: 100%;
                            }

                            .sectionWrapper {
                                width: 100%;
                                overflow: auto;
                                margin-top: 49px;
                            }

                            .navDropdown__headerWrapper {
                                position: absolute;
                                top: 0;
                                width: auto;
                                right: 24px;
                                left: 24px;
                            }
                        }
                    }
                }

                .navigation__list {
                    order: 2;
                    flex-grow: 0;
                    height: auto;
                    margin-bottom: 0;

                    .navigation__menuWrapper {
                        display: none;
                    }
                }
            }
        }
    }
}

.medium-view .mobile-nav-active {
    .g-header__navigation {
        align-items: flex-end;

        &-container {
            width: 330px;
            min-width: auto;
        }

        &-container > .secondary-navigation__container {
            display: none;
        }

        .g-tablet-header__navigation {
            display: none;
        }

        .navigation__container {
            padding-top: 0;

            .navigation__list {
                height: auto;
            }

            .loginAndSearchWrapper {
                padding-bottom: 64px;
            }

            .loginButtonsWrapper {
                gap: 15px;
                height: auto;
            }

            .navDropdown {
                display: none;
            }
        }
    }

    &.menu-active {
        .g-header__navigation {
            .navDropdown {
                display: flex;
            }
        }
    }
}

.large-view .menu-active {
    .g-header__overlay {
        backdrop-filter: unset;
        display: none;
    }
}

%clamped-text-base {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

%swiper-slide {
    flex-basis: 0;
    flex-grow: 1;
}

%img-as-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

%faux-button-link {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-decoration: none;
}

.large-view {
    %swiper-slide {
        &:last-of-type {
            margin-right: 0 !important;
        }
    }
}

/* Override some Economic Calendar styles */
.fxs_widget {
    .fxs_c_timezone-container {
        .fxs_c_timezone_header {
            align-items: center;
        }

        input[type="radio"][name="timeFormat"] {
            width: 20px;
            height: 44px;
            margin: 0 0 0 10px;
            vertical-align: middle;

            & + label {
                display: inline-flex;
                align-items: center;
                min-width: 44px;
                height: 44px;
                padding: 0 5px;
                margin-bottom: 0;
                vertical-align: middle;
            }
        }
    }
}

.large-view {
    .navigation__container {
        padding-top: 20px;
    }

    .fxs_widget {
        &.fxs_c_calendar_wrapper {
            .fxs_c_DatePicker-Container,
            .fxs_c_configuration_rollover {
                overflow: auto;
            }

            .fxs_c_ecocal_data {
                min-height: 400px;
            }

            .fxs_options-box {
                right: 0;
                left: auto;
                overflow-x: hidden;
            }
        }
    }
}

.small-view {
    .fxs_widget {
        // temporarily "hide" notify and chart icons
        &.fxs_c_calendar_wrapper {
            .fxs_c_row {
                .fxs_c_notify svg {
                    display: none;
                }
            }

            .fxs_c_detail_buttons {
                .fxs_c_detail_button {
                    position: relative;

                    [data-icon="bell"],
                    [data-icon="chart-bar"] {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: auto;
                        height: auto;
                    }
                }

                .fxs_c_detail {
                    .fxs_c_detail_buttons {
                        .fxs_c_detail_button {
                            [data-icon="bell"],
                            [data-icon="chart-bar"] {
                                color: #f3f3f8;
                                background: #f3f3f8;
                            }
                        }
                    }
                }
            }

            .fxs_c_detail {
                .fxs_c_detail_buttons {
                    .fxs_c_detail_button {
                        [data-icon="bell"],
                        [data-icon="chart-bar"] {
                            color: #f3f3f8;
                            background: #f3f3f8;
                        }
                    }
                }
            }

            .fxs_c_detail-wrapper {
                .fxs_c_detail_buttons {
                    .fxs_c_detail_button {
                        [data-icon="bell"],
                        [data-icon="chart-bar"] {
                            color: #fff;
                            background: #fff;
                        }
                    }
                }
            }
        }

        .fxs_c_advanced-countries-filter {
            .fxs_c_filters_picker {
                display: flex;
                flex-direction: column;

                .fxs_c_countries_search_box {
                    flex-shrink: 0;
                    max-height: none;

                    .fxs_search_input {
                        max-height: none;
                    }
                }

                .fxs_c_filter_picker_list {
                    max-height: none;
                }
            }
        }
    }
}

.flex {
    display: flex;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.centered-container-column {
    @extend .centered-container;
    flex-direction: column;
}

.visibility-hidden {
    visibility: hidden;
}

//ndb - nondebounced
@media (max-width: #{v.$smallMaxWidth}) {
    .hide-small-ndb {
        display: none !important;
    }
}

@media (min-width: calc(#{v.$smallMaxWidth} + 1px)) and (max-width: #{v.$mediumMaxWidth}) {
    .hide-medium-ndb {
        display: none !important;
    }
}

@media (min-width: calc(#{v.$mediumMaxWidth} + 1px)) {
    .hide-large-ndb {
        display: none !important;
    }
}

.small-view {
    .hide-small {
        display: none !important;
    }
}

.medium-view {
    .hide-medium {
        display: none !important;
    }
}

.large-view {
    .hide-large {
        display: none !important;
    }
}

@media (orientation: landscape) {
    .medium-view .mobile-nav-active {
        .g-header__navigation {
            &-container {
                width: 100%;
            }
        }
    }
}
