@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;
@use "./MethodImage/MethodImage";

$max-row-width: 1110px;

.payment-methods-banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 56px 0px;

    p.payment-methods-banner__title{
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 12px;
        font-weight: 700;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 32px;
    }

    &__row-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        row-gap: 8px;
        column-gap: 16px;
        max-width: $max-row-width;
        margin: 0px 136px;
    }
}

.large-view {
    .payment-methods-banner {
        padding: 0 0 8px 0px;

        &::before{
            content: "";
            background-color: c.$dark-10;
            width: 1128px;
            height: 1px;
            margin-bottom: 16px;
        }

        &__row-items {
            max-width: 1100px;
            margin-right: 0;
            margin-left: 0;

            .method-image {
            margin-inline: 10px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.small-view,
.medium-view {
    .payment-methods-banner {
        overflow: auto;
        padding: 0px 0px 32px 0px;

        &__container {
            flex-wrap: nowrap;
            flex-direction: column;
            margin: 0;
            max-width: 94%;
            gap: 16px;
            align-items: unset;
        }

        &__row-items {
            margin: 0px;
            display: flex;
            align-items: center;

            .method-image:last-child {
                img {
                    margin-right: 24px;
                }
            }
        }
    }
}

.small-view {
    .payment-methods-banner {
        &__row-items {
            width: 340px;
            display: flex;
            align-items: center;
        }
    }
}

.medium-view {
    .payment-methods-banner {
        &__row-items {
            max-width: 607px;
            margin: 0px auto;
            row-gap: 8px;
            column-gap: 16px;
        }
    }
}