@use "../../../global-styles/palette" as c;

.employee-testimonial-slider-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  background-color: c.$pure-white;
  padding: 32px;
  border-radius: 24px;
  height: 536px;

  &__image {
    max-width: 120px;
    max-height: 120px;
    margin-bottom: 16px;
    border-radius: 16px;
  }

  p.employee-testimonial-slider-card__employee-name {
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: c.$dark-default;
  }

  .employee-testimonial-slider-card__employee-position {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: c.$dark-60;
    max-width: 230px;
    text-align: center;
    margin-bottom: 23px;
  }

  div.employee-testimonial-slider-card__employee-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: c.$dark-80;
  }
}

.small-view {
  .employee-testimonial-slider-card {
    max-width: 263px;
    padding: 32px 24px 56px 24px;
    margin: 0px auto;
    height: unset;
  }
}
