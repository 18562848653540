@use "../../global-styles/palette" as c;

.support-body-root {
    .g-container {
        background-color: c.$pure-white;

        .support-body-root__wrapper {
            display: flex;
            flex-direction: column-reverse;

            &__sideBar {
                display: grid;

                .side-bar-section {
                    padding: 24px;

                    &__items {
                        width: fit-content;
                        p {
                            width: fit-content;
                            color: c.$dark-60;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 16px;
                        }

                        a {
                            width: fit-content;
                            color: c.$dark-80;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            margin: 8px 0 8px 16px;
                            min-width: 240px;
                            text-decoration: none;

                            &:last-child {
                                margin-bottom: 32px;
                            }

                            &:hover{
                                color: c.$dark-default;
                                text-decoration: underline;
                            }
                        }

                        .active {
                            color: c.$dark-default;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 20px;
                        }
                    }
                }

                .side-bar-section {
                    display: flex;
                    flex-wrap: wrap;
                    background-color: c.$neutral-accent-light-grey;
                    border-radius: 24px;

                    &__items {
                        display: grid;
                    }
                }
            }

            .g-container {
                display: grid;
                margin-left: 0px;

                .faq-accordion-list {
                    a {
                        margin: 0px;
                    }
                }

                .faq-accordion-list__flex-container {
                    display: grid;

                    .faq-accordion-list__title-container {
                        .faq-accordion-list__faq-title {
                            color: c.$dark-default;
                            font-size: 22px;
                            font-weight: 600;
                            line-height: 28px;
                        }

                        .faq-accordion-list__image-container {
                            display: none;
                        }

                        .faq-accordion-list__plus-minus-text {
                            h5 {
                                color: c.$dark-default;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 24px;
                            }

                            p {
                                color: c.$dark-default;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 24px;
                                text-decoration-line: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

.medium-view {
    .support-body-root {
        .g-container {
            .support-body-root__wrapper {
                .side-bar-section__items{
                    width: 50%;
                }
                .g-container:first-child {

                    margin: 0px 48px;

                    .faq-accordion-list__flex-container {
                        .faq-accordion-list__title-container {
                            .faq-accordion-list__title-flex {
                                .faq-accordion-list__faq-title {
                                    font-size: 26px;
                                    line-height: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.large-view {
    .support-body-root {
        .g-container {
            .support-body-root__wrapper {
                flex-direction: row;
                gap: 96px;
                &__sideBar {
                    .side-bar-section {
                        align-items: flex-start;
                        flex-direction: column;
                    }
                }

                .g-container {
                    width: fit-content;

                    .faq-accordion-list__flex-container {
                        .faq-accordion-list__title-container {
                            margin: 0;

                            .faq-accordion-list__title-flex {
                                .faq-accordion-list__faq-title {
                                    margin-right: auto;
                                    font-size: 32px;
                                    line-height: 48px;
                                    letter-spacing: 0em;
                                    text-align: left;
                                }

                                p {
                                    display: none;
                                }
                            }
                        }

                        div {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.large-view
.support-body-root
.g-container
.support-body-root__wrapper
.g-container {
    width: 100%;
}

.small-view {
    .support-body-root {
        .g-container {
            .support-body-root__wrapper {
                &__sideBar {
                    .side-bar-section {
                        align-items: flex-start;
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
