@use "../../../../global-styles/palette" as c;

.toggle-expand-collapse-button {
    --collapse-button-icon-url: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.4997 2.8286L10.7424 7.07122C11.1329 7.46174 11.7661 7.46174 12.1566 7.07122C12.5471 6.68069 12.5471 6.04753 12.1566 5.65701L7.20681 0.707211C6.81629 0.316685 6.18312 0.316686 5.79259 0.707213L0.842847 5.657C0.452324 6.04753 0.452329 6.68069 0.842854 7.07122C1.23338 7.46174 1.86654 7.46174 2.25707 7.07122L6.4997 2.8286Z' fill='%230E1D31'/%3E%3C/svg%3E%0A");

    --expand-button-icon-url: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.7179 6.78C6.57738 6.92069 6.38674 6.99983 6.1879 7L5.8079 7C5.60948 6.9977 5.41962 6.91888 5.2779 6.78L0.147899 1.64C0.0532429 1.54612 6.65609e-08 1.41832 6.81507e-08 1.285C6.97405e-08 1.15168 0.0532429 1.02388 0.147899 0.93L0.857899 0.22C0.950063 0.125936 1.07621 0.0729284 1.2079 0.0729284C1.33959 0.0729284 1.46574 0.125936 1.5579 0.22L5.9979 4.67L10.4379 0.22C10.5318 0.125343 10.6596 0.0721008 10.7929 0.0721008C10.9262 0.0721008 11.054 0.125343 11.1479 0.22L11.8479 0.93C11.9426 1.02388 11.9958 1.15168 11.9958 1.285C11.9958 1.41832 11.9426 1.54612 11.8479 1.64L6.7179 6.78Z' fill='%230E1D31'/%3E%3C/svg%3E%0A");

    --toggle-button-icon-url: none;


    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    appearance: none;
    border: 1px solid c.$dark-20;
    border-radius: 4px;
    background-color: transparent;
    color: c.$dark-default;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;

    &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-image: var(--toggle-button-icon-url);
        background-repeat: no-repeat;
        background-position: center;
    }

    &--collapse {
        border: none;
        text-decoration: underline;
    }
}