@use "../../../global-styles/palette" as c;
@use "./AwardsCard/AwardCard";

.awards-banner-root {
    border: 1px solid #CCC;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    align-items: center;
    padding: 32px 0 23px;


    &__textsWrapper {
        margin-bottom: 40px;
        width: 80%;

        h3 {
            text-align: center;
            color: c.$dark-default;
        }

        h5 {
            text-align: center;
            color: c.$dark-default;

            &:first-child {
                margin: 8px 0 20px;
            }
        }
    }
}

.medium-view {
    .awards-banner-root {
        padding: 0 0 0 40px;
        flex-direction: row;
        justify-content: space-between;

        &__textsWrapper {
            width: 50%;
            margin: 0;

            h3 {
                text-align: left;
            }

            h5 {
                text-align: left;
                line-height: 25.2px;
            }
        }
    }
}

.large-view {
    .awards-banner-root {
        flex-direction: row;
        justify-content: space-around;
        padding: 0;

        &__textsWrapper {
            width: max-content;
            margin: 0;

            h5 {
                font-size: 20px;
                line-height: 28px;
            }

            h3,
            h5 {
                text-align: left;
            }
        }
    }
}