@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;
@use "../../../global-styles/button-styled-as-link" as bsl;

.mobile-app-download-popin {
    box-sizing: border-box;
    position: fixed;
    z-index: 101;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    &__inner {
        position: relative;
        box-sizing: border-box;
        display: flex;
        z-index: 1;
        width: 480px;
        max-width: 100%;
        padding: 20px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        border-radius: 16px;
        background: #fff;
    }

    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    &__subtitle {
        color: c.$neutral-600;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
    }

    &__QRCode {
        @extend .tmws_platform-desktop-display;
    }

    &__download-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;

        & > * {
            flex: 1 0 auto; /* Grow and shrink as needed */
            min-width: max-content; /* Ensure it expands to the width of the widest child */
            box-sizing: border-box; /* Include padding and borders in the width */
            display: flex;
            height: 44px;
            padding: 0px 12px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            border: 1px solid c.$dark-10;
        }
    }

    &__close-button {
        @extend %button-styled-as-link;

        position: absolute;
        inset-inline-end: 0;
        inset-block-start: 0;
        width: v.$touch-area-min-size;
        height: v.$touch-area-min-size;
    }
}

.large-view {
    .mobile-app-download-popin {
        &__inner {
            padding: 40px;
        }
    }
}
  
/* Devices likely to be smartphones/tablets */
@media (hover: none) and (pointer: coarse) {
    .tmws_platform-desktop-display {
        display: none !important;
    }
}
