@use "../../global-styles/palette" as c;

.popularQuestions {

  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__text {
    position: relative;
  }

  &__title {
    color: c.$dark-default;
    margin-bottom: 16px;
  }

  &__list-item {
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    margin-bottom: 24px;
  }

  &__list-item-link {
    text-decoration: none;
    color: c.$blue-lagoon-default;
  }
  
}

.small-view, .medium-view {
  .popularQuestions {
    padding: 0;

    &__container {
        flex-direction: column;
    }

    &__title {
        text-align: center;
        margin-bottom: 16px;
    }

    &__text {
        position: unset;
    }

    &__image {
        position: relative;
        display: flex;
        justify-content: center;

        img {
            width: 100%;
        }
    }

    &__list-item {
        font-size: 19px;
        line-height: 1.26;
    }
    &__list-items {
        position: static;
        margin-top: 32px;
    }
  }
}

.medium-view {
  .popularQuestions {

    &__image {
      img {
        width: unset;
      }
    }

    &__list-items {
      display: flex;
      justify-content: center;
    }
  }
}

.small-view {
  .popularQuestions {
    &__list-items {
      display: flex;
      justify-content: center;
    }
  }
}

    