@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;

.dropdown-wrapper {
    &__dropdown {
        padding: 12px 16px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        border-radius: 4px;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='25' height='25' viewBox='0 0 20 20' fill='%23000'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 16px center;
    }
}

[dir="rtl"] {
    .p-regular.dropdown-wrapper__dropdown {
        padding-right: 32px;
    }
}
