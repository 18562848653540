@use "./NumberedSwipeCard/NumberedSwipeCard";
@use "../../../global-styles/layout";

.numbered-swipe-card-deck {
    $inter-slide-gap: 24px;

    @extend .g-container;

    box-sizing: border-box;

    &__title-container {
        text-align: center;
        margin: 0 auto 32px auto;
        max-width: 744px;
    }

    &__button-container {
        margin-top: 26px;
        display: flex;
    }

    &__button {
        border-radius: 8px!important;
    }

    .swiper-wrapper {
        align-items: stretch;
    }

    .swiper-slide {
        display: flex;
        flex: 1 1 0;
        width: auto;
        max-width: 360px;
        min-height: 552px;
        height: auto;

        &:not(:last-child) {
            padding-inline-end: $inter-slide-gap;
        }
    }
}


.medium-view {
    .numbered-swipe-card-deck {
        margin: 0;

        .swiper-slide {
            max-width: 360px;
            min-width: 360px;

            &:first-child {
                padding-inline-start: 72px;
            }
        }
    }
}

.small-view {
    .numbered-swipe-card-deck {
        margin: 0;

        .swiper-slide {
            max-width: 296px;
            min-width: 296px;

            &:first-child {
                padding-inline-start: 24px;
            }
        }
    }
}