@use "../../../global-styles/palette" as c;

.thinktrader-account-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 31px;
    border-bottom: 1px solid #14263F;

    &__text-wrapper {
        max-width: 521px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        h1 {
            color: c.$pure-white;
        }

        h5 {
            color: c.$dark-20;
        }
    }

    &__h1-h5-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__options-wrapper {
        display: flex;
        justify-content: flex-start;
        gap: 40px;
    }

    &__option {
        display: flex;
        flex-direction: column;
        gap: 2px;

        &__p-title {
            color: c.$dark-40;
        }

        .thinktrader-account-banner__option__p-subtitle {
            color: c.$pure-white;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;

            .special-symbol {
                color: c.$dark-background-bar;
            }
        }
    }

    &__button-wrapper {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        width: max-content;
        column-gap: 20px;

        .button-v2-wrapper {
            justify-content: flex-start;
        }
        
        .tmx25-round-green-lightgreen {
            max-width: 234px;
        }

        .tmx25-round-green-lightgreen:hover {
            max-width: 234px;
            background-color: c.$tm-dark-btn-hover-green;
        }

        .link-button {
            text-align: center;
            color: c.$pure-white;
        }

    }
}

.medium-view {
    .thinktrader-account-banner {
        flex-direction: column-reverse;
        border-bottom: unset;

        &__image-wrapper {

            img {
                max-width: 240px;
            }
        }

        &__text-wrapper {
            max-width: 600px;
            gap: 32px;
            padding-bottom: 40px;
            border-bottom: 1px solid #14263F;
        }

        &__h1-h5-wrapper {
            align-items: center;
            text-align: center;
        }

        &__options-wrapper {
            justify-content: space-evenly;
            
        }

        &__option {
            align-items: center;
        }

        .thinktrader-account-banner__option:last-child {
            .thinktrader-account-banner__option__p-subtitle {
                width: 118px;
                text-align: center;

                .special-symbol {
                    display: none;
                }
            }
        }

        &__button-wrapper {
            justify-content: center;
            grid-auto-flow: row;
            margin-inline: auto;

            .button-v2-wrapper {
                justify-content: center;
            }

            .link-button {
                margin: 0;
                row-gap: 32px;
            }
        }
    }
}

.small-view {
    .thinktrader-account-banner {
        flex-direction: column-reverse;
        padding-bottom: 0px;
        border-bottom: unset;

        &__image-wrapper {

            img {
                max-width: 240px;
            }
        }

        &__text-wrapper {
            max-width: 327px;
            gap: 32px;
            padding-bottom: 40px;
            border-bottom: 1px solid #14263F;
        }

        &__h1-h5-wrapper {
            align-items: center;
            text-align: center;
        }

        &__options-wrapper {
            justify-content: space-between;
            gap: unset;
            
        }

        &__option {
            align-items: center;

            &__p-title {
                font-size: 14px;
                font-weight: 500;
            }

            &__p-subtitle {
                font-size: 18px;
            }
        }

        .thinktrader-account-banner__option:last-child {
            .thinktrader-account-banner__option__p-subtitle {
                width: 118px;
                text-align: center;

                .special-symbol {
                    display: none;
                }
            }
        }

        &__button-wrapper {
            grid-auto-flow: row;
            row-gap: 32px;
            margin-inline: auto;

            .button-v2-wrapper {
                justify-content: center;
            }

            .link-button {
                margin: 0;
            }
        }
    }
}