@use "../../global-styles/typography";

.footer-v2dot6 {
    display: flex;
    flex-direction: column;
    
    &__legal-info {
        @extend .caption;

        padding: 32px 20px;
        color: #828FA1;

        & > * {
            @extend .caption;
        }

        p:not(:first-child) {
            margin-top: 16px;
        }
    }
}

.small-view,
.medium-view {
    .footer-v2dot6 {
        .g-container {
            margin: 0;
        }
    }
}

.medium-view,
.large-view {
    .footer-v2dot6 {
        &__legal-info {
            padding-inline: 40px;
        }
    }
}