@use "../../../../global-styles/palette" as c;

.the-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 20px;

    &__place-wrapper {
        border-radius: 50px;
        border: 1px solid c.$dark-default;
        padding: 8px 12px;
        white-space: nowrap;

        span {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    &__list-wrapper {
        display: flex;
        flex-direction: column;
        gap: 9px;

        &__list-item {
            display: flex;
            gap: 12px;

            svg {
                min-width: 12px;
                margin-top: 2px;
            }
            span {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}

.large-view {
    .the-card {
        width: 302px;
        &__title-wrapper {
            h4 {
                font-size: 28px;
                font-weight: 600;
                line-height: 120%;
            }
        }

        &__place-title-wrappers {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            &__place-wrapper {
                display: flex;
                justify-content: center;
            } 
        }
    }
}

.medium-view {
    .the-card {
        padding: 32px;
        width: 600px;
        margin: 0px auto;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;

        &__place-title-wrappers {
            display: flex;
            flex-direction: column;
            gap: 20px;

            &__place-wrapper {
                display: inline-block;
            } 
        }

        &__title-wrapper {
            h4 {
                font-size: 22px;
                font-weight: 600;
                line-height: 120%;
                white-space: nowrap;
            }
        }

        &__list-wrapper {
            max-width: 352px;
        }
    }
}

.small-view {
    .the-card {
        padding: 24px;

        &__place-title-wrappers {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
        }

        &__place-wrapper {
            display: flex;
            width: 116px;
            justify-content: center;
        }
    }
}
