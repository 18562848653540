@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.big-awards-card-deck {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: v.$min-content-width-large;
    max-width: 100%;
    gap: 20px;

    h2 {
        flex-shrink: 0;
        flex-basis: auto;
        width: 100%;
        text-align: center;
    }

    & > * {
        flex: 1 1 0;
    }
}

.small-view {
    .big-awards-card-deck {
        flex-direction: column;
    }
}

.medium-view {
    .big-awards-card-deck {}
}

.large-view {
    .big-awards-card-deck {}
}