@use "../../../global-styles/palette" as c;

.keepTraderInOrderSwiperCard {
  display: grid;
  justify-items: center;
  width: fit-content;

  &__image {
    width: 264px;
    height: 180px;
  }

  &__textWrapper {
    h5 {
      color: c.$dark-default;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }

    p {
      color: c.$dark-80;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      width: 260px;
    }
  }
}
