@use "../../../global-styles/palette" as c;
@use "../../../global-styles/variables" as v;

.secondary-navigation {
    &__container {
        display: flex;
        margin: 12px 0 16px;
    }

    &__items {
        display: flex;
        margin-left: auto;

        > div:first-child:dir(ltr) {
            margin-left: 0;
        }

        > div:nth-child(3) {
            .languageText {
                cursor: pointer;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        margin-left: 16px;

        > a,
        div {
            color: c.$dark-20;
            text-decoration: none;
            cursor: pointer;
            font-size: 12px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            font-weight: 500;
            line-height: 16px;
            word-wrap: break-word;
        }
    }
}

.g-header__navigation-container {
    & > .secondary-navigation__container {
        .secondary-navigation__item > a,
        .secondary-navigation__items .languageText {
            color: var(--sec-nav-foreground-color);
            cursor: pointer;
        }
    }

    & > .navigation__container {
        .secondary-navigation__item > a,
        .secondary-navigation__items .languageText {
            color: var(--sec-nav-mobile-foreground-color);
        }
    }
}

.medium-view[dir="rtl"] {
    .g-header__navigation-container {
        .secondary-navigation__container {
            align-self: flex-start;
            .secondary-navigation__items {
                flex-direction: row-reverse;
            }
        }
    }
    .g-header__navigation {
  
        .g-tablet-header__navigation {
            flex-direction: row-reverse;
            .optionsWrapper {
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }
}


.large-view[dir="rtl"] {
        .g-header__navigation-container {
            .secondary-navigation__container {
                align-self: flex-start;
                .secondary-navigation__items {
                    flex-direction: row-reverse;
                }
            }
        }
}

.large-view {
    .secondary-navigation {
        &__container {
            transition: 0.2s ease-in;

            input {
                display: none;
            }
        }

        &__items {
            > div:nth-child(3) {
                .languageText {
                    cursor: pointer;
                }
            }
        }
    }
}

.medium-view,
.small-view {
    .secondary-navigation {
        &__items {
            margin: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

            a {
        &__item {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                color: c.$dark-default;

                svg {
                    display: none;
                }
            }

            line-height: 24px;

            &:last-child {
                border-right: 0;
                padding-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }

            > a,
            div {
                font-weight: unset;
            }
        }
    }
}

.small-view {
    .secondary-navigation {
        &__container {
            box-sizing: border-box;
            justify-content: center;
            width: 100%;
            
            .languageText {
                cursor: pointer;
                svg {
                    margin-right: 0.25rem;
                }
            }
        }
    }
}

.medium-view {
    .secondary-navigation {
        &__items {
            width: fit-content;
            margin-inline: auto;

            div:last-child{
                display: none;
            }
        }
    }
}