@use "../../../global-styles/palette" as c;

.top-time-line {
  display: grid;
  position: relative;
  height: fit-content;
  align-items: end;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 3px;
    display: block;
    background-color: c.$dark-80;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: c.$dark-80;
    border-radius: 50%;
    left: -3.5%;
    top: 99%;
  }

  span {
    padding: 8px;
    width: fit-content;
    border-radius: 0px 4px 4px 0px;
    background-color: c.$neutral-accent-dark-bg-10;
    color: c.$dark-20;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  p {
    width: 168px;
    padding: 16px 0 16px 16px;
    position: relative;
    color: c.$pure-white;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
