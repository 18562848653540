@use "../../../../global-styles/palette" as c;

.live-chat-cta {
    &--non-mobile {
        display: none;
    }

    &__text {
        color: c.$dark-20;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;

        a {
            color: c.$pure-white;
            font-size: 16px;
            font-weight: 500;
            line-height: inherit;
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            text-underline-position: from-font;
        }
    }
}


.large-view {
    .live-chat-cta {
        &--non-mobile {
            display: block;
        }

        &--non-mobile {
            display: none;
        }
    }
}

