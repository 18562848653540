@use "../../../global-styles/palette" as c;

.big-or-small-card {

    &__big-card , &__small-card {
        border-radius: 16px;
        border: 1px solid c.$dark-10;
    }
}

.small-view {
    .big-or-small-card {

        &__icon {
            margin: 24px 0px 0px 24px;
        }

        &__big-image {
            display: flex;
            justify-content: center;
            padding-bottom: 24px;

            img {
                max-width: 95%;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 24px 24px 0px 24px;

            p {
                margin-bottom: 24px;
            }
        }
    }
}

.medium-view {
    .big-or-small-card {
        &__text {
            display: flex;
            flex-direction: column;
            gap: 8px;
            max-width: 264px;

            p {
                max-width: 99%;
            }
        }

        &__small-card {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 24px;
            max-width: 294px;
            min-height: 174px;
        }

        &__big-card {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            border: unset;
        }

        &__big-image {

            img {
                max-width: 303px;
                margin-right: 15px;
            }
        }

        &__icon {
            img {
                max-width: 24px;
            }
        }
    }
}

.large-view {
    .big-or-small-card {
        &__big-card, &__small-card {
            h5 {
                font-size: 28px;
                font-weight: 600;
                line-height: 120%;
            }

            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
            }
        }

        &__big-card {
            max-width: 554px;
            border: 1px solid c.$dark-10;
            border-radius: 20px;
            
            .big-or-small-card__text {
                padding: 40px 40px 7px 40px;
            }
        }

        &__small-card {
            max-width: 474px;
            border: 1px solid c.$dark-10;
            border-radius: 20px;
            padding: 40px;
            min-height: 128px;
        }

        &__text {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        &__big-image {
            img {
                max-width: 554px;
            }
        }
    }
}