.toggle-expand-collapse {
    &__content-container {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.1s ease-out;

        &--open {
            grid-template-rows: 1fr;
        }
    }

    &__content {
        overflow: hidden;
    }
}