@use "./PlatformCard/PlatformCard";

.platformComparison__wrapper {
    overflow: unset;
    margin: 0 auto;
}
.platformComparison__inner {
    display: table;
}
.platformComparison__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 24px;
}
.platformComparison__col {
    width: 264px;
    min-height: 170px;
    max-height: 170px;
}
.platformComparison__card-bodies-wrapper {
    display: table;
}

.platformComparison__card-bodies {
    height: 120vh;
    overflow: auto;
}

.small-view, .medium-view {
    .platformComparison .g-container {
        margin: 0;
    }

    .platformComparison__wrapper {
        overflow: auto;
    }

    .platformComparison__card-bodies {
        height: calc(100vh - 170px);
        padding-left: 24px;
        overflow: auto;
    }
    .platformComparison__inner {
        padding-left: 24px;
    }
}
.platformComparison {
    margin: 0;

    .platform-card__inner {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: -24px;
            width: 24px;
            height: 100%;
        }
    }
}
.large-view {
    .platformComparison {
        margin: 0;

        .platform-card__fixed-logo {
            height: 100%;
        }
        .g-container {
            overflow: unset;
        }
    }
    .platformComparison__inner {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .platformComparison__col {
        background: #f2f4f6;
    }
    .platformComparison__wrapper {
        overflow: unset;
    }
    .platformComparison__col {
        min-height: unset;
        max-height: unset;
        background: #f2f4f6;
    }
    .platformComparison__card-bodies {
        height: auto;
        overflow: unset;
    }
    .platformComparison__card-bodies {
        height: auto;
    }
}
