@use "../../../global-styles/palette" as c;
@use "./GetHelpCard/GetHelpCard";

.getHelpCardDeck {
  background-color: c.$pure-white;
}

.medium-view {
  .getHelpCardDeck {
    .g-container {
      .getHelpCardDeck__title {
        width: fit-content;
        margin-bottom: 32px;
      }

      .getHelpCardDeck__container {
        padding-inline: 46px;

        .getHelpCardDeck__cards-container {
          .g-row--4-columns {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
          }
        }
      }
    }
  }
}

.large-view {
  .getHelpCardDeck {
    .g-container {
      .getHelpCardDeck__title {
        margin-bottom: 32px;
      }

      .getHelpCardDeck__container {
        .getHelpCardDeck__cards-container {
          .g-row--4-columns {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
          }
        }
      }
    }
  }
}
