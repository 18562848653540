@use "../../global-styles/palette" as c;
@use "./EmployeeTestimonialSliderCard/EmployeeTestimonialSliderCard";

.employee-testimonial-slider__container {
  .g-container {
    .employee-testimonial-slider {
      .CardDescription {
        h2 {
          color: c.$dark-default;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
        }

        p {
          color: c.$dark-80;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-top: 8px;
        }
      }

      p.employee-testimonial-slider__arrowsWrapper__currentPage {
        width: fit-content;
        color: c.$dark-80;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 24px 16px;
      }

      &__arrowsWrapper {
        display: grid;
        grid-auto-flow: column;
        width: fit-content;
        align-items: center;

        .arrow-prev-selector {
          width: fit-content;
          svg {
            width: 40px;
            height: 40px;
            transform: rotate(90deg);
            color: c.$dark-default;
          }
        }

        .arrow-prev-selector:hover, .arrow-next-selector:hover {
          cursor: pointer;
        }

        .arrow-next-selector {
          width: fit-content;
          svg {
            width: 40px;
            height: 40px;
            transform: rotate(-90deg);
            color: c.$dark-default;
          }
        }
      }

      &__swiperWrapper {
        .g-container {
          margin: 0;
          .employee-testimonial-slider-card {
            margin-inline: auto;
            padding: 32px;
            border-radius: 32px;
            background-color: c.$pure-white;
            min-height: 310px;
            align-content: start;
            min-height: 335px;

            &__image {
              width: 264px;
              height: 180px;
            }

            &__textWrapper {
              margin-top: 24px;

              &__title {
                width: fit-content;
              }

              &__description {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.small-view {
  .employee-testimonial-slider__container{
    .g-container {
      .employee-testimonial-slider {
        .CardDescription {
          margin-bottom: 32px;
        }

        &__arrowsWrapper {
          margin: 0px auto;
        }

        &__swiperWrapper {
          .g-container {
            .employee-testimonial-slider-card {
              max-width: 279px;
              padding: 32px 24px;
              min-height: 655px;
            }
          }
        }
      }
    }
  }
}

.medium-view {
  .employee-testimonial-slider__container{
    .g-container {
      margin-right: 0;
      margin-left: 72px;
      .employee-testimonial-slider {

        .description-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .CardDescription {
          margin-top: 64px;
          margin-bottom: 32px;
          max-width: 600px;

          h2 {
            font-size: 40px;
          }
  
          p {
            width: 585px;
          }
        }
        &__swiperWrapper {
          .g-container {
            margin: 0;
            .swiper-wrapper {
              margin-inline: auto;
            }
            .employee-testimonial-slider-card {
              margin: 0;
            }
          }
        }
      }

      .employee-testimonial-slider__arrowsWrapper {
        margin: 0px auto;
      }
    }

  }
}

.large-view {
  .employee-testimonial-slider__container{
    .g-container {
      overflow: unset;
  
      .employee-testimonial-slider {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
  
        .CardDescription {
          width: fit-content;
          margin-bottom: 32px;
          h2 {
            width: 230px;
            font-size: 48px;
            line-height: 56px;
          }
  
          p {
            width: 360px;
          }
        }
  
        &__swiperWrapper {
          padding-left: 64px;
          width: 76%;
  
          .g-container {
            .swiper-wrapper {
              width: fit-content;
            }
          }
        }
      }
    }
  }
  .description-container {
    display: grid;
    align-content: space-between;

    span {
      width: fit-content;
      height: fit-content;
      margin: 0;
      height: 56px;
    }
  }
}
