@use "../../global-styles/palette" as c;

.what-we-offer {
  &__title-container {
    display: flex;
    justify-content: center;
    max-width: 744px;
    margin: 0px auto 32px auto;
  }

  &__cards-container {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
  }

  &__left-container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }

  &__up-container {
    background-color: c.$neutral-accent-background;
    width: 720px;
    height: 212px;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
  }

  &__up-container-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 40px 10px 40px 40px;
    max-width: 360px;
  }

  &__down-container {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
  }

  &__down-container-left {
    background-color: c.$neutral-accent-background;
    width: 344px;
    height: 300px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__down-container-left-text {
    max-width: 280px;
    text-align: center;
    margin-top: 32px;
  }

  &__down-container-right {
    background-color: c.$neutral-accent-background;
    width: 344px;
    height: 300px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__down-container-right-text {
    max-width: 280px;
    text-align: center;
    margin-top: 32px;
  }

  &__down-container-right-text-title {
    margin-bottom: 8px;
  }

  &__right-container {
    background-color: c.$neutral-accent-background;
    width: 376px;
    height: 544px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__right-container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 296px;
    margin: 40px auto 0px auto;
  }
}

.medium-view {
    .what-we-offer {
        &__cards-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 24px;
        }

        &__right-container {
            width: 600px;
            height: 210px;
        }

        &__left-container {
            align-items: center;
            row-gap: 24px;
        }

        &__up-container {
            width: 600px;
            height: 224px;
        }

        &__up-container-text {
            max-width: 224px;
            margin: 32px 24px 32px 32px;
        }

        &__up-container-img {
            margin-top: 27px;
            max-width: 288px;
            max-height: 197px;
        }

        &__down-container-left {
            width: 288px;
            height: 334px;
        }

        &__down-container-left-text, &__down-container-right-text {
            max-width: 224px;
        }

        &__down-container-left-text-title {
            margin-bottom: 8px;
        }

        &__down-container-left-img, &__down-container-right-img {
            margin-bottom: 32px;
        }
        
        &__down-container-right {
            width: 288px;
            height: 334px;
        }

        &__right-container {
            flex-direction: row-reverse;
            justify-content: unset;
            gap: 72px;
        }

        &__right-container-text {
            align-items: flex-start;
            max-width: 224px;
            margin: 32px 60px 32px 0px;
            text-align: left;
        }

        &__right-container-text-title {
            margin-bottom: 8px;
        }
    }
}

.small-view {
    .what-we-offer {
        &__cards-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 24px;
        }

        &__left-container {
            align-items: center;
            row-gap: 24px;
        }

        &__up-container {
            width: 327px;
            height: 383px;
            flex-direction: column;
            justify-content: space-between;
        }

        &__up-container-text {
            max-width: 279px;
            margin: 24px auto 0px auto;
            align-items: center;
        }
        &__up-container-text-description {
            text-align: center;
        }

        &__up-container-img {
            display: flex;
            justify-content: center;
        }

        &__down-container {
            flex-direction: column;
            row-gap: 24px;
        }

        &__down-container-left {
            width: 327px;
            height: 337px;
            justify-content: unset;
            gap: 24px;
        }

        &__down-container-right {
            width: 327px;
            height: 335px;
            justify-content: unset;
            gap: 24px;
        }

        &__down-container-right-text, &__right-container-text {
            max-width: 279px;
        }

        &__right-container {
            width: 327px;
            height: 402px;
            justify-content: unset;
            gap: 24px;
        }

        &__right-container-text-title {
            margin-bottom: 8px;
        }
    }
}