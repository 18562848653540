@use "../../../../global-styles/palette" as c;

.card-image-label__root {
    display: grid;
    justify-items: center;
    background-color: c.$neutral-accent-background;
    border-radius: 32px;
    padding: 20px 23px;

    h3.h3-semi-bold-small {
        margin-bottom: 5px;
    }

    p.caption {
        color: c.$dark-80;
        text-align: center;
        margin-bottom: 10px;
        width: 97%;
    }

    .imageWrapper {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.medium-view {
    .card-image-label__root {
        padding: 40px;

        h3.h3-semi-bold-small {
            margin-bottom: 8px;
            font-size: 28px;
            line-height: 35px;
        }

        p.caption {
            margin-bottom: 24px;
            width: 90%;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.large-view {
    .card-image-label__root {
        p.caption {
            width: 85%;
            font-size: 16px;
            line-height: 24px;
        }
    }
}