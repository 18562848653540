@use "../../../global-styles/variables" as v;
@use "../../../global-styles/palette" as c;
@use "./PlatformInformationCard/PlatformInformationCard";

.information-card__deck--platform{
    padding: 0;

    .g-col {
        width: 100%;
    }

    .g-row--2-columns {
        justify-content: flex-start;
    }
}

.information-card__deck-announcement {
    margin-top: 32px;
    max-width: 744px;
    text-align: left;
    color: c.$dark-80;

    a {
        color: c.$blue-lagoon-default;
        font-weight: bold;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}