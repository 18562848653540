@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;

.risk-warning-ribbon {
    padding: 3px 0 5px 0;
    backdrop-filter: contrast(0.6);

    &--landing-container{
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
    }

    &--landing{
        background: c.$dark-10;
        backdrop-filter: none;
    }

    &__container {
        width: 100%;
        max-width: 1128px;
    }

    &__arrow-container {
        padding-top: 17px;
        margin-left: 20px;
        margin-right: 0px;
    }

    &__arrow {
        display: none;
    }

    .rotate {
        transform: rotateX(180deg);
    }
}

.small-view {
    .risk-warning-ribbon {
        text-align: left;

        &__container {
            margin: 6px 11px 3px 11px;
        }

        &__content-ellipsis {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }
    }
}

.medium-view {
    .risk-warning-ribbon {
        padding: 0px 0 5px 0;

        &__container {

            margin: 0 15px 0 15px;
        }

        &__content-ellipsis {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }
    }
}

.mobile-nav-active {
    .risk-warning-ribbon {
        display: none;
    }
}

.has-light-header {
    .risk-warning-ribbon {
        color: c.$dark-default;

        &__arrow-dark {
            display: block;
        }
    }
}

.has-dark-header {
    .risk-warning-ribbon {
        color: c.$pure-white;

        &__arrow-light {
            display: block;
        }
    }
}