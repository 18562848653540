@use "./palette" as c;

$imgPath: "./images";

$transition: 0.2s ease-in;

$max-content-width: 1440px;
$min-content-width-large: 1128px;
$min-content-width-large-new: 1400px;
$max-column-width-2columns: 552px;
$max-column-width-3columns: 360px;
$max-column-width-4columns: 264px;
$max-column-width-5columns: 206px;
$max-column-width-6columns: 168px;

$text-page-max-width: 744px;
$text-page-horiz-spacing-mobile: 24px;
$text-page-horiz-spacing-desktop: 96px;

$card-standard-width: 360px;
$card-standard-width-limited: 338px;

$gutter-small: 16px;
$gutter-medium: 24px;
$gutter-large: 32px;

$g-container-margin-right: 25px;
$g-container-margin-left: 23px;

$live-chat-icon-height: 64px;
$live-chat-icon-offset-bottom: 16px;

$smallMaxWidth: 600px;
$mediumMaxWidth: 1024px;

$header-z-index: 99;
$modal-z-index: $header-z-index + 1;
$lightbox-z-index: 999;

$breadcrumbs-bottom-spacing--large: 56px;
$breadcrumbs-bottom-spacing--medium: 32px;
$breadcrumbs-bottom-spacing--small: 24px;

$touch-area-min-size: 44px;

:root {
    --logo-max-width: 190px;

    --contract-specs_table-border-color: #{c.$dark-10};
    --contract-specs_table-border: 1px solid var(--contract-specs_table-border-color);
    --contract-specs_table-border-radius: 16px;
    --contract-specs_table-horizontal-padding: 24px;

    --bkgr-image-left-position: left;
    --bkgr-image-right-position: right;
    --bkgr-flip-horizontal: none;
}

:root[dir="rtl"] {
    --bkgr-image-left-position: right;
    --bkgr-image-right-position: left;
    --bkgr-flip-horizontal: scaleX(-1);
}

.large-view {
    --logo-max-width: 244px;
    --large-view-dropdown-top-offset: 78px;


    .has-risk-warning{
        --large-view-dropdown-top-offset: 126px;
    }
}



@media screen and (min-width: 1024px) and (max-width: $min-content-width-large) {

    // temp fix for lower-large view, to prevent logo from overlapping with main nav
    .large-view {
        --logo-max-width: 190px;
    }
}

// in case we need to generate classes
// @each $color, $value in $colors {
//   .text-#{$color} {
//     color: color-yiq($value);
//   }
// }