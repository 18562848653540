@use "../../../../global-styles/palette" as c;

.card-withOut-hover {
    display: grid;
    justify-items: center;
    background: var(--background-image-url);
    border-radius: 22px;
    padding: 32px 24px;
    align-content: start;

    .card-withOut-hover__imageWrapper {
        width: 48px;
        height: 48px;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    p.card-withOut-hover__title {
        color: var(--text-color);
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
    }

    .card-withOut-hover__description {
        color: var(--description-text-color);
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
    }
}

.medium-view {
    .card-withOut-hover {
        .card-withOut-hover__description {
            width: 248px;
        }
    }
}

.large-view {
    .card-withOut-hover {
        .card-withOut-hover__description {
            width: 225px;
        }
    }

    .card-withOut-hover:dir(rtl) {
        align-content: center;
    }
}