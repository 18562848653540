@use "../../../../global-styles/variables" as v;
@use "../../../../global-styles/palette" as c;
@use "../../../../global-styles/layout";

$horizontal-outer-spacing: 16px;
$vertical-outer-spacing: 16px;
$horizontal-outer-spacing-md: 24px;
$vertical-outer-spacing-md: 24px;
$vertical-inner-spacing: 10px;
$image-height: 190px;
$image-width-md: 264px;
$image-height-md: 184px;
$border-radius: 8px;
$animation-duration: .25s;

.hot-topic-card {
  width: 100%;
  height: auto;
  padding: 0px;
  border-radius: $border-radius;
  background-color: c.$pure-white;
  transition: .0s ease-in;
  box-sizing: border-box;

  overflow: hidden;

  &__reading-time {
    display: none;
  }

  &__title,
  &__reading-time,
  &__text,
  &__link-wrapper {
    padding-left: $horizontal-outer-spacing;
    padding-right: $horizontal-outer-spacing;
    margin-top: $vertical-inner-spacing;
  }

  h4.hot-topic-card__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $vertical-outer-spacing;
    font-size: 19px;
    color: c.$dark-default;
    transition: .0s ease-in;

    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26;
    letter-spacing: normal;
    .clamp{
      @extend %clamped-text-base;
      //-webkit-line-clamp: 1;
    }
  }

  &__trigger{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .0s ease-in;

    svg {
        min-width: 32px;
        height: 32px;
    }
  }

  &__image {
    width: 100%;
    height: 0;
    margin-top: $vertical-inner-spacing;
    
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__reading-time {
    color: c.$dark-60;
    font-size: 12px;
    margin-bottom: 0;
    transition: .0s ease-in;
  }

  &__text {
    max-height: 0;
    overflow: hidden;
    margin-top: 0;
    color: c.$dark-80;
    font-size: 16px;
  }

  &__link-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: $vertical-outer-spacing;
    padding-bottom: $vertical-outer-spacing;
    border-top: 1px solid c.$dark-10;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: c.$blue-lagoon-default;
    transition: .0s ease-in;
    text-decoration: none;
    font-weight: bold;
    text-align: center;

    svg{
      fill: c.$blue-lagoon-default;
      transition: .0s ease-in;
    }
  }

  &--opened {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);

    .hot-topic-card__image,
    .hot-topic-card__reading-time { 
      display: block;
    }

    .hot-topic-card__image {
      height: $image-height;
    }

    .hot-topic-card__text {
      max-height: 500px;
    }

    .hot-topic-card__trigger {
        transform: rotateX(-180deg);
    }

    .hot-topic-card__link-wrapper {
      border-top: none;
    }
  }

  &--animate-in {
    .hot-topic-card__image {
      animation: image-in $animation-duration ease-in;
    }

    .hot-topic-card__text {
      animation: text-in $animation-duration ease-in;
    }
  }

  &--animate-out {
    .hot-topic-card__image {
      animation: image-out $animation-duration ease-out;
    }

    .hot-topic-card__text {
      animation: text-out $animation-duration ease-out;
    }
  }
}

.large-view,
.medium-view {
  .hot-topic-card {
    display: grid;
    align-items: start;
    grid-template-columns: $image-width-md 1fr;
    grid-template-rows: repeat(3, minmax(0, min-content)) 1fr;
    width: 100%;
    height: $image-height-md;

    &__trigger{
      display: none;
    }

    &__image,
    &__reading-time,
    &__text {
      display: block;
    }

    &__title,
    &__reading-time,
    &__text,
    &__link-wrapper {
      padding-left: $horizontal-outer-spacing-md;
      padding-right: $horizontal-outer-spacing-md;
    }

    &__title {
      grid-row-start: 1;
      grid-row-end: 2;
      margin-top: $vertical-outer-spacing-md;
    }

    &__reading-time {
      grid-row-start: 2;
      grid-row-end: 3;
      // margin-bottom: auto;
      margin: 2px 0 4px 0;
    }


    &__image {
      width: $image-width-md;
      height: 100%;
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: 5;
      margin-top: 0;
    }

    &__text {
      grid-row-start: 3;
      grid-row-end: 4;
      max-height: 70px;
      font-size: 18px;
    }

    &__link-wrapper {
      align-self: end;
      grid-row-start: 4;
      grid-row-end: 5;
      justify-content: flex-start;
      padding-top: 0;
      border-top: none;
    }

    &:hover {
      background-color: c.$pure-white;

      .hot-topic-card__title {
        color: c.$dark-default;
      }

      .hot-topic-card__reading-time {
        color: c.$dark-60;
      }

      .hot-topic-card__link-wrapper {
        color: c.$blue-lagoon-default;

        svg {
          fill: c.$blue-lagoon-default;
        }
      }
    }
  }
}

@keyframes image-in {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: $image-height;
  }
}

@keyframes image-out {
  0% {
    opacity: 1;
    height: $image-height;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

@keyframes text-in {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
}

@keyframes text-out {
  0% {
    max-height: 500px;
  }
  100% {
    max-height: 0;
  }
}
