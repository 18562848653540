@use "../../../global-styles/palette" as c;

.section-with-three-columns {
    display: flex;
    justify-content: center;
    padding: 40px 0px;

    &__card-box{
        flex: 1;
    }

    &__cards-wrapper {
        display: flex;
        gap: 30px;

        img {
            margin-bottom: 12px;
        }

        h5 {
            margin-bottom: 4px;
            color: c.$pure-white;
        }

        p {
            color: c.$dark-20;
        }
    }

    &__card-box {
        flex: 1 1 0;
    }
}

.large-view {
    .section-with-three-columns {
        &__cards-wrapper {
            h5 {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
    
            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
}

.medium-view {
    .section-with-three-columns {

        padding: 0px 0px 40px 0px;

        &__cards-wrapper {
            h5 {
                font-weight: 600;
            }

            p {
                line-height: 20px;
            }
        }

        &__card-box {
            text-align: center;
        }
    }
} 

.small-view {
    .section-with-three-columns {
        &__cards-wrapper {
            flex-direction: column;
            gap: 32px;

            h5 {
                font-weight: 600;
            }

            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        &__card-box {
            text-align: center;
        }
    }
}