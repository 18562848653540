@use "../../../global-styles/palette" as v;

.small-hero-banner{
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  h1{
    margin-bottom: 8px;
  }
  p{
    color: v.$dark-80;
  }
  &__content{
    max-width: 600px;
  }
  img{
    max-width: 195px;
  }
}

.small-view{
  .small-hero-banner{
    gap: 24px;
    flex-direction: column-reverse;
    text-align: center;
    img{
      max-width: 130px;
    }
  }
}