.contact-us-wrapper_root {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.large-view {
  .contact-us-wrapper_root {
    flex-direction: row;
    justify-content: space-between;
  }
}