@use "../../global-styles/palette" as c;

.account-types-v2 {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__most-popular-text {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
    }

    &__cards-container {
        gap: 24px;
    }

    &__cards-container {
        display: grid;
        grid-template-columns: 1fr;
    }

    &__card {
        border: solid 1px c.$dark-10;
        border-radius: 12px;
        padding: 25px 25px 0px 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__card-platforms-icons-container {
        display: flex;
        gap: 14px;
        margin-top: 8px;
        margin-bottom: 7px;
    }

    &__card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__description {
        text-align: auto;
        min-height: 65px;
        color: c.$dark-80;
    }

    &__most-popular {
        transform: translateY(-50px);
    }

    &__spec-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__spec-row-left {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        color: c.$dark-60;
    }

    &__features-bullets {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: c.$dark-default;

        .flex {
            gap: 3px;
        }
    }

    &__features-bullet-tick {
        width: 20px;
        padding-top: 6px;
    }

    &__separator-line {
        border: solid 1px #EFEFF1;
    }

    .button-v2-wrapper {
        width: 100%;
    }

    .button-v2-wrapper .tmx25-square-white-lightblue {
        width: 100%;
    }

    &__expandable {
        margin-inline: -25px;
        padding: 20px 25px 10px 25px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        min-height: 176px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: c.$neutral-accent-light-grey;
    }

    &__col {
        border-radius: 13px;
    }

    &__most-popular-label {
        padding: 5px;
    }

    .mostpopular {
        background-color: #92ff9233;

        .account-types-v2__most-popular-label {
            color: #0d9209;
        }
    }

    &__card {
        background-color: c.$pure-white;
    }
}

.small-view {
    .account-types-v2 {
        &__spec-row {
            margin-top: 10px;
        }
    }

    &__cards-container {
        margin-top: -20px;
    }
}

.medium-view {
    .account-types-v2 {
        &__cards-container {
            grid-template-columns: 1fr;
        }

        &__expandable {
            background-color: c.$neutral-accent-light-grey;
        }

        &__spec-row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            gap: 7px;
        }

        &__specs {
            display: flex;
            gap: 27px;
            text-align: right;
        }

        .button-v2-wrapper .tmx25-round-black-gray {
            max-width: none;
        }
    }
}

.large-view {
    .account-types-v2 {
        &__cards-container {
            grid-template-columns: 1fr 1fr 1fr;
        }

        &__expandable {
            background-color: c.$neutral-accent-light-grey;
        }

        &__card-platforms-icons-container {
            margin-top: 10px;
            margin-bottom: 5px;
        }

        &__specs {
            gap: 4px;
            display: flex;
            flex-direction: column;
        }
    }
}