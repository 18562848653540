
@use "../../../global-styles/palette" as v;

.platform-card {

    &__is-active {
        border-left: 2px solid v.$tm-green-default;
        border-right: 2px solid v.$tm-green-default;
    }

    &__is-not-active {
        border-left: 2px solid v.$pure-white;
        border-right: 2px solid v.$pure-white;

        .platform-card__item {
            border-top: 1px solid v.$dark-20;
        }
    }

    &__fixed-logo {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
    }

    &__fixed-logo.platform-card__is-active {

        border-top: 2px solid v.$tm-green-default;
        border-left: 2px solid v.$tm-green-default;
        border-right: 2px solid v.$tm-green-default;
    }

    &__fixed-logo.platform-card__is-not-active {
        border-top: 2px solid v.$pure-white;
        border-left: 2px solid v.$pure-white;
        border-right: 2px solid v.$pure-white;
    } 

    &__fixed-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: v.$pure-white;
        min-width: 244px;
    }
    &__without-recommended {
        margin-top: 32px;
    }
    
    &__recommended {
        width: 94px;
        height: 16px;
        flex-grow: 0;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: v.$pure-white;
        max-width: 118px;
        padding: 3px 12px 2px;
        background-color: v.$tm-green-default;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-bottom: 13px;
    }
    
    &__logo-row {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 64px;
            margin-bottom: 24px;
        }
    }

    &__logo-text h4{
        font-size: 27px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 14px;
    }

    &__logo-dark-text {
        color: v.$dark-default;
    }

    &__logo-green-text {
        color: v.$tm-green-default;
    }                    

    &__list-items {
        min-width: 216px;
        padding: 0 22px;
        background-color: v.$pure-white;
    }
                
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid v.$tm-green-40;
        padding-top: 24px;
        padding-bottom: 16px;
        white-space: normal;
        max-height: 110px;
        min-height: 70px;
    }

    &__item-title {
        max-width: 181px;
        margin-bottom: 8px;
        flex-grow: 0;                    
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        color: v.$dark-80;
    }

    &__item-image {
        width: 32px;
    }

    &__item-description {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        color: v.$dark-default;
        margin-bottom: 3.2px;
    }


    &__button {
        padding-top: 18px;
        padding-bottom: 40px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        background-color: v.$pure-white;
        min-width: 260px;
        display: flex;
        justify-content: center;
    }

    &__is-active.platform-card__button {
        border-left: 2px solid v.$tm-green-default;
        border-right: 2px solid v.$tm-green-default;
        border-bottom: 2px solid v.$tm-green-default;
    }

    &__is-not-active.platform-card__button { 
        border-left: 2px solid v.$pure-white;
        border-right: 2px solid v.$pure-white;
        border-bottom: 2px solid v.$pure-white;
    }
}

.small-view, .medium-view {
    .platform-card {
        &__fixed-logo {
            min-height: 170px;
        }

        &__logo-text h4{
            font-size: 23px;
            line-height: 1.04;
        }
            
        &__list-items {
            position: relative;
            flex-grow: 1;
        }

        &__item:first-child {
            border-top: unset;
            padding-top: 14px
        }

        &__button {
            padding-bottom: 32px;
        }
    }
}
