@use "../../../global-styles/palette" as c;

.affiliate-banner {
    .breadcrumbs {
        .breadcrumbs__home-link {
          svg {
            path {
              fill: c.$dark-default;
            }
          }
        }
      }
  
    display: flex;
    flex-direction: column;

    .g-container {
        width: 100% !important;
    }

    &__wrapper {
        display: flex;
        gap: 32px;
    }

    &__text-and-button-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 32px;
    }

    &__title-and-subtitle-container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h1 {
            font-size: 38px;
            font-weight: 600;
            line-height: 48px;
            color: c.$dark-default;
        }

        h5 {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            color: c.$dark-80;
        }
    }

    &__numbers-container {
        display: flex;
        margin-bottom: 8px;

        .border-class {
            margin: 0px 32px;
            padding: 0px 32px;
            border-left: 1px solid c.$dark-20;
            border-right: 1px solid c.$dark-20;
        }
    }

    &__number-wrapper {

        h3 {
            font-size: 28px;
            font-weight: 700;
            line-height: 35px;
            color: c.$tm-green-120;
            margin-bottom: 12px;
        }
    }

    &__title-wrapper {

        p {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            color: c.$dark-default;
        }
    }

    &__buttons-container {
        display: flex;
        gap: 24px;

        .button-v2-wrapper {
            margin: unset;
        }
    }

    &__image-section, &__image {
        max-width: 548px;
    }
}

.medium-view {
    .affiliate-banner {

        &__wrapper {
            flex-direction: column;
            align-items: center;
            gap: 64px;
        }

        &__title-and-subtitle-container {

            h1 {
                font-size: 30px;
                line-height: 38px;
            }
        }

        &__text-and-button-section {
            width: 600px;
        }

        &__image-section, &__image {
            max-width: unset;
        }

        &__image {
            max-height: 360px;
        }
    }
}

.small-view {
    .affiliate-banner {

        &__wrapper {
            flex-direction: column;
            align-items: center;
            gap: 64px;
        }

        &__title-and-subtitle-container {

            h1 {
                font-size: 26px;
                line-height: 32px;
            }
        }

        &__text-and-button-section {
            width: 327px;
        }

        &__image-section, &__image {
            max-width: 327px;
        }

        &__image {
            max-height: 200px;
        }

        &__numbers-container {
            
            justify-content: space-between;

            .border-class {
                margin: unset;
                padding: unset;
                border-left: unset;
                border-right: unset;
            }
        }

        &__number-and-title-wrapper {
            width: 104px;
        }
    }
}