@use "../../global-styles/palette" as c;

.free-text {
    padding: 32px 0;

    p {
        font-size: 12px;
        line-height: 16px;
    }

    p > span {
        color: c.$dark-60;
    }

    p > a {
        color: c.$dark-default;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-decoration-line: underline;
    }

    &--warning {
        color: c.$dark-60;
        font-weight: 400;
    }

    &--list {
        color: c.$dark-80;

        h2,
        .free-text__content {
            max-width: 800px;
            margin-inline: auto;
        }

        h2 {
            margin-bottom: 20px;
            text-align: center;
        }

        ul {
            list-style: none;
            padding-inline-start: 0;

            li {
                padding-inline-start: 1em;
                padding-bottom: .75em;
            }

            li::before {
                content: "•";
                display: inline-block;
                width: 1em;
                margin-inline-start: -1em;
                text-align: start;
            }
        }
    }

    &--small-heading {
        h2:not(:empty) {
            color: c.$dark-default;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.3;
        }

        .free-text__content {
            color: c.$dark-80;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
        }
    }
}

.medium-view,
.large-view {
    .free-text {
        &--max-width-800 {
            .g-container.g-container--fullwidth .g-container > * {
                max-width: 800px;
                margin-inline: auto;
            }
        }
    }
}