@use "../../global-styles/palette" as c;
@use "../../global-styles/layout";


.status-cards-grid {


  &__text-maintenance {
    color: c.$support-maintenance;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  &__card {
    padding: 18px;
    box-sizing: border-box;
    border: solid 1px c.$dark-10;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
  }

  &__status-label {
    gap: 4px;
  }
}

.medium-view {
  .status-cards-grid__root {
    .g-container {
      margin-inline: 72px;

      .status-cards-grid {
        &__grid {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}


.large-view {
  .status-cards-grid {
    &__grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}