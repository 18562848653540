.ratio-container{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 12px;
    &__column{
        flex-direction: column;
        >div{
            flex: 1;
            width: 100%;
        }
    }
    >div{
        box-sizing: border-box;
        display: flex;
        gap: 12px;
    }
}