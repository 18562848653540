@use "../../../global-styles/palette" as c;

.ExpandableCardWrapper {
  background-color: c.$pure-white;
  border-radius: 16px;
  width: 100%;
  height: fit-content;
  overflow-y: hidden;

  &__visibleHeader {
    display: grid;
    margin: 24px;

    &__topCardInfo {
      display: grid;
      grid-auto-flow: column;
      align-items: center;

      div {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        width: fit-content;
      }

      h4 {
        font-size: 24px;
        max-width: 200px;
      }
      &__cardTitle {
        margin-left: 8px;
        color: c.$dark-default;
        width: fit-content;
        height: fit-content;
      }

      &__signsWrapper {
        width: fit-content;
        justify-self: end;
        cursor: pointer;
      }
    }
    p {
      font-size: 10px;
      line-height: 16px;
    }

    &__cardSubTitle {
      color: c.$dark-80;
      font-style: normal;
      margin-top: 16px;
    }
  }

  p.ExpandableCardWrapper__cardBody__feeLabel {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    font-style: normal;
  }

  p.ExpandableCardWrapper__cardBody__currenciesTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: c.$dark-60;
  }

  &__cardBody {
    padding-inline: 24px;

    &__cardComments {
      color: c.$dark-default;
    }

    &__feeLabel {
      color: c.$dark-60;
      margin-top: 24px;
      margin-bottom: 7px;
    }

    &__feeValue {
      color: c.$dark-default;
      margin-bottom: 24px;
    }

    &__currenciesCurrenciesWrapper {
      margin-bottom: 24px;

      &__currenciesValues {
        font-style: normal;
        color: c.$dark-default;

        span {
          color: c.$dark-default;
          text-decoration-line: underline;
          width: fit-content;
          font-weight: 700;
          margin-left: auto;
          height: fit-content;
          cursor: pointer;
        }
      }
    }
  }
}

.ExpandableCardWrapperClosed {
  @extend .ExpandableCardWrapper;
  height: 135px;
}
