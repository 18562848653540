@use "../../global-styles/palette" as c;

.innovation-block--thinkzero {
    .innovation-block-forex__footer{
        border-top: 0;
    }
    .innovation-block-forex__footer-title {
        font-weight: bold;
        margin-bottom: 16px;
    }
    .innovation-block__image--mobile {
        margin-top: 16px;
    }
    .innovation-block__image {
        align-self: flex-start;
    }
}

.medium-view  {
    .innovation-block__footer-title--md-centered {
        text-align: center;
    }
}