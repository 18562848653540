@use "../../global-styles/palette" as c;

.meetOurCEO {
  background-color: c.$dark-default;
  padding: 80px 0;

  &__content {
    display: flex;
    align-items: center;
    gap:56px;
  }

  &__img1 {
    display: block;
  }

  &__img2 {
    display: none;
  }

  &__text {
    h2 {
      color: c.$pure-white;
    }

    h4 {
      color: #e7e8ea;
      margin-bottom: 10px;
    }
  }

  p.meetOurCEO__comment {
    color: c.$dark-20;
    margin: 16px 0 40px 0;
    font-size: 18px;
  }

  p.meetOurCEO__position {
    font-size: 16px;
    color: c.$dark-20;
    margin-bottom: 24px;
  }
}

.medium-view {
  .meetOurCEO {
    padding: 64px 0;

    &__content {
      flex-direction: column;
      gap: 40px;
    }
    
    &__img1 {
      margin-right: unset;
    }
    &__text {

      h2 {
        margin-bottom: 8px;
        text-align: center;
      }
    }

    &__comment {
      text-align: left;
      max-width: 600px;
    }
  }
}

.small-view {
  .meetOurCEO {
    display: none;
  }
}