@use "../../../global-styles/palette" as c;

.interchangeable-image-card-deck__root {
    .image__wrapper {
        margin-bottom: 32px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .texts__wrapper {
        h2.h2-regular {
            text-align: center;
        }

        p.p-regular {
            text-align: center;
            color: c.$dark-80;
            margin: 12px 0 32px;
        }

        .secondary-large-dbg {
            width: 100%;
        }
    }
}

.medium-view {
    .interchangeable-image-card-deck__root {
        .texts__wrapper {
            .secondary-large-dbg {
                width: unset;
            }
        }
    }
}

.large-view {
    .interchangeable-image-card-deck__root {
        display: flex;
        width: max-content;
        column-gap: 56px;

        .image__wrapper {
            margin: 0;
        }

        .texts__wrapper {
            display: grid;
            align-content: center;
            justify-items: start;
        }

        h2.h2-regular {
            text-align: start;
        }

        p.p-regular {
            text-align: start;
            width: 470px;
        }

        .button-v2-wrapper {
            margin: 0;
        }
    }

    .rightAligned {
        @extend .interchangeable-image-card-deck__root;
        flex-direction: row-reverse;

        .texts__wrapper {
            justify-items: end;

            p.p-regular {
                text-align: end;
            }
        }
    }
}